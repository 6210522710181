import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/button'
import PayoutLayout from '../../features/layouts/payout-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { fetchGetRef } from '../../store/actions/userActions'
import { path } from '../../utils/consts'

const ReferralPage = () => {
  const { refStatuses } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()

  const { profileTexts } = useGetText()

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchGetRef())
  }, [])

  return (
    <PayoutLayout>
      <h3>{profileTexts.REFFERAL_BALANCE.HEADING}</h3>
      <div className='refferal-wrapper my-4'>
        <div className='refferal-items'>
          <h5 className='mb-0 mt-0'>{refStatuses?.total_referrals}</h5>
          <p className='mb-0'>{profileTexts.REFFERAL_BALANCE.REFERRALS}</p>
        </div>
        <div className='refferal-items'>
          <h5 className='mb-0 mt-0'>{refStatuses?.ref_balance}</h5>
          <p className='mb-0'>{profileTexts.REFFERAL_BALANCE.BALANCE}</p>
        </div>
        <div className='refferal-items'>
          <h5 className='mb-0 mt-0'>{refStatuses?.total_exchanges}</h5>
          <p className='mb-0'>{profileTexts.REFFERAL_BALANCE.EXCHANGE}</p>
        </div>
        <div className='refferal-items'>
          <h5 className='mb-0 mt-0'>{refStatuses?.ref_percent}</h5>
          <p className='mb-0'>{profileTexts.REFFERAL_BALANCE.PRECENT}</p>
        </div>
      </div>
      <Button variant='primary' onClick={() => navigate(path.PAYOUT_PAGE)}>
        {profileTexts.WITHDRAW_FUNDS}
      </Button>
    </PayoutLayout>
  )
}

export default ReferralPage
