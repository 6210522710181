export const AboutUSTexts: any = {
  ru: {
    TITLE: 'О нас',
    LISTS: [
      {
        title: 'Более 700 направлений',
        description: 'На нашем сайте вы можете воспользоваться более чем 700 направлениями обмена, включая обратные обмены.'
      },
      {
        title: '6 лет на рынке',
        description: 'Занимаемся обменами уже более 6 лет, постоянно совершенствуем свой сервис и уровень оказания услуг'
      },
      {
        title: '5000+ отзывов',
        description: 'У нас более 5000 довольных клиентов, которые пользуются нашим сервисом на постоянной основе'
      }
    ],

    CONTACTS_US: 'Напишите нам',

    INPUTS: {
      NAME: {
        label: 'Ваше имя и фамилия',
        placeholder: 'Example Example'
      },
      EMAIL: {
        label: 'Ваша почта',
        placeholder: 'Example@example.com'
      },
      TEXTS: {
        label: 'Ваше сообщение',
        placeholder: 'Комментарий'
      },
      BTN: 'Отправить'
    },
    TG_TEXT: 'Все актуальные новости и обновления на нашем сайте, а также в мире криптографии вы можете посмотреть на нашем ТГ канале',
    MORE: 'Перейти'
  },
  en: {
    TITLE: 'About us',
    LISTS: [
      {
        title: 'Over 700 destinations',
        description: 'On our site you can use more than 700 exchange directions, including reverse exchanges.'
      },
      {
        title: '6 years on the market',
        description: 'We have been dealing with exchanges for more than 6 years and are constantly improving our service and the level of services provided.'
      },
      {
        title: '5000+ reviews',
        description: 'We have over 5,000 satisfied customers who use our service on a regular basis'
      }
    ],

    CONTACTS_US: 'Contact us',

    INPUTS: {
      NAME: {
        label: 'First and last name',
        placeholder: 'Example Example'
      },
      EMAIL: {
        label: 'Your mail',
        placeholder: 'Example@example.com'
      },
      TEXTS: {
        label: 'Your message',
        placeholder: 'Comment'
      },
      BTN: 'Comment'
    },
    TG_TEXT: 'All the latest news and updates on our website, as well as in the world of cryptography, you can see on our TG channel',
    MORE: 'Go'
  },
  tu: {
    TITLE: 'Hakkımızda',
    LISTS: [
      {
        title: "700'den fazla destinasyon",
        description: "Sitemizde, ters değişim de dahil olmak üzere 700'den fazla değişim yönü kullanabilirsiniz."
      },
      {
        title: 'Piyasada 6 yıl',
        description: '6 yılı aşkın bir süredir borsalarla uğraşıyoruz ve hizmetimizi ve verilen hizmet düzeyini sürekli olarak geliştiriyoruz.'
      },
      {
        title: "5000'den fazla inceleme",
        description: "Hizmetimizi düzenli olarak kullanan 5.000'den fazla memnun müşterimiz var"
      }
    ],
    CONTACTS_US: 'Bize yazın',

    INPUTS: {
      NAME: {
        label: 'Adınız ve soyadınız',
        placeholder: 'Example Example'
      },
      EMAIL: {
        label: 'Mail adresiniz',
        placeholder: 'Example@example.com'
      },
      TEXTS: {
        label: 'Mesajın',
        placeholder: 'Yorum'
      },
      BTN: 'Göndermek'
    },
    TG_TEXT: 'Web sitemizdeki ve kriptografi dünyasındaki en son haberleri ve güncellemeleri TG kanalımızda görebilirsiniz.',
    MORE: 'Gitmek'
  }
}
