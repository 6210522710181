import React from 'react'

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { closeModal, ModalMode } from '../../store/reducers/modalReducer'
import { defineModalEl } from './defineModalEl'

import classes from './Modal.module.scss'

export const Modal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isModalOpen, modalContent, mode } = useAppSelector(state => state.modal)

  const onClickBackdrop = () => {
    if (mode === ModalMode.STRICT) {
      return
    }
    dispatch(closeModal())
  }

  return (
    // backdrop
    <div className={isModalOpen ? `${classes.root} ${classes.active}` : classes.root} onClick={onClickBackdrop}>
      {/* Modal window */}
      <div className={classes.window} onClick={e => e.stopPropagation()}>
        <div className={classes.content}>{defineModalEl(modalContent)}</div>
      </div>
    </div>
  )
}
