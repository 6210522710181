import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {
  fetchCreateBidHandler,
  fetchGetCourseHandler,
  fetchGetFromHandler,
  fetchValidatePurseHandler
} from '../actions/tradeActions'
import utils from '../../utils/utils'

export interface tradeState {
  directions: any
  filter: []
  city_arr: []
  filterTo: [] | any

  from: string
  valueFrom: number | string
  to: string
  valueTo: number | string

  city: string
  filteredCity: []

  fromCategorySelected: string
  toCategorySelected: string

  course: {
    from: number
    maxFrom: number
    maxTo: number
    minFrom: number
    minTo: number
    to: number
  }
  rate: number
  lock_get: boolean
  courseLoader: boolean

  isValidPurseCard: any
  isValidPurseWallet: any

  spb_bank: string // добавлено 27.11.2023, чтобы пробросить из trade-form в /processing

  hashId: string
  message: string
  setSent: boolean
  excTrc: string
  status: string
}

const initialState: tradeState = {
  directions: [],
  filter: [],
  filterTo: [],
  city_arr: [],

  from: '',
  to: '',
  city: '',

  valueFrom: '',
  valueTo: '',

  filteredCity: [],

  fromCategorySelected: '',
  toCategorySelected: '',

  course: {
    from: 0,
    maxFrom: 0,
    maxTo: 0,
    minFrom: 0,
    minTo: 0,
    to: 0
  },
  rate: 0,
  lock_get: false,
  courseLoader: false,

  isValidPurseCard: {},
  isValidPurseWallet: {},

  spb_bank: '',

  hashId: '',
  message: '',
  setSent: false,
  excTrc: '',
  status: ''
}

export const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setFrom(state: any, action: PayloadAction<string>) {
      state.from = action.payload
      if (!state?.filter?.filter((el: any) => el.from.code === action.payload)[0]?.to?.some((el: any) => state.to === el.code)) {
        state.to = state?.filter?.filter((el: any) => el.from.code === action.payload)[0]?.to[0]?.code
      }
      state.filterTo = state?.filter?.filter((el: any) => el.from.code === action.payload)[0]?.to
      state.toCategorySelected = 'all'

      if (state.to === 'CASHAED' || state.from === 'CASHAED') {
        state.city = 'dubai'
      } else if (state.to === 'CASHTRY' || state.from === 'CASHTRY') {
        state.city = 'stam'
      } else if (state.to === 'CASHAMD' || state.from === 'CASHAMD') {
        state.city = 'ervn'
      } else if (state.to === 'KZTCASH' || state.from === 'KZTCASH') {
        state.city = 'alm'
      } else if (state.to === 'GELCASH' || state.from === 'GELCASH') {
        state.city = 'tbil'
      } else {
        state.city = 'msk'
      }
    },
    setFromValue(state: any, action: PayloadAction<string>) {
      const value = action.payload?.replace(',', '.')
      state.valueFrom = value

      state.lock_get = false
      if (value === '') {
        state.valueTo = value
      }
      if (!state.lock_get && value !== '') {
        // state.valueTo = value * state.rate
        state.valueTo = utils.fromValueFormating(+value, 'to', state)
      }
    },

    setTo(state: any, action: PayloadAction<string>) {
      state.to = action.payload
      if (state.to === 'CASHAED' || state.from === 'CASHAED') {
        state.city = 'dubai'
      } else if (state.to === 'CASHTRY' || state.from === 'CASHTRY') {
        state.city = 'stam'
      } else if (state.to === 'CASHAMD' || state.from === 'CASHAMD') {
        state.city = 'ervn'
      } else if (state.to === 'KZTCASH' || state.from === 'KZTCASH') {
        state.city = 'alm'
      } else if (state.to === 'GELCASH' || state.from === 'GELCASH') {
        state.city = 'tbil'
      } else {
        state.city = 'msk'
      }
    },
    setToValue(state: any, action: PayloadAction<string>) {
      const value = action.payload?.replace(',', '.')
      state.valueTo = value
      state.lock_get = true

      if (value === '') {
        state.valueFrom = value
      }
      if (state.lock_get && value !== '') {
        // state.valueFrom = (+value) / state.rate
        state.valueFrom = utils.fromValueFormating(+value, 'from', state, 'reverce')
      }
    },

    setFilteredCity(state: any) {
      if (state.from === 'CASHRUB') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromRub === true)
      }
      if (state.to === 'CASHRUB') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toRub === true)
      }

      if (state.from === 'CASHUSD') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromUsd === true)
      }
      if (state.to === 'CASHUSD') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toUsd === true)
      }

      if (state.from === 'CASHTRY') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromTry === true)
      }
      if (state.to === 'CASHTRY') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toTry === true)
      }

      if (state.from === 'CASHEUR') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromEur === true)
      }
      if (state.to === 'CASHEUR') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toEur === true)
      }

      if (state.from === 'CASHAED') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromAed === true)
      }
      if (state.to === 'CASHAED') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toAed === true)
      }

      if (state.from === 'CASHAMD') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromAmd === true)
      }
      if (state.to === 'CASHAMD') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toAmd === true)
      }

      if (state.from === 'KZTCASH') state.filteredCity = state?.city_arr?.filter((el: any) => el.fromKzt === true)
      if (state.to === 'KZTCASH') state.filteredCity = state?.city_arr?.filter((el: any) => el.toKzt === true)

      if (state.from === 'GELCASH') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.fromGel === true)
      }
      if (state.to === 'GELCASH') {
        state.filteredCity = state?.city_arr?.filter((el: any) => el.toGel === true)
      }
    },

    setCity(state: any, action: PayloadAction<string>) {
      state.city = action.payload
    },
    setLockGet(state: any, action: PayloadAction<boolean>) {
      state.lock_get = action.payload
    },

    setMessage(state: any, action: PayloadAction<string>) {
      state.message = action.payload
    },
    setSended(state: any, action: PayloadAction<boolean>) {
      state.setSent = action.payload
    },
    setSpbBank(state: any, action: PayloadAction<string>) {
      state.spb_bank = action.payload
    },
    setFromCategorySelected(state: any, action: PayloadAction<string>) {
      state.fromCategorySelected = action.payload
    },
    setToCategorySelected(state: any, action: PayloadAction<string>) {
      state.toCategorySelected = action.payload
    },
    setHashID(state: any, action: PayloadAction<string>) {
      state.hashId = action.payload
    },
    setIsValidPurseCard(state: any, action: PayloadAction<boolean>) {
      state.isValidPurseCard = action.payload
    },
    setIsValidPurseWallet(state: any, action: PayloadAction<boolean>) {
      state.isValidPurseWallet = action.payload
    },
    setStatusTrade(state: any, action: PayloadAction<string>) {
      state.status = action.payload
    }
  },
  extraReducers: builder => {
    fetchGetFromHandler(builder)
    fetchGetCourseHandler(builder)
    fetchValidatePurseHandler(builder)
    fetchCreateBidHandler(builder)
  }
})

export const { setFrom, setFromValue, setTo, setToValue, setFilteredCity, setCity, setMessage, setIsValidPurseWallet, setLockGet, setSended, setFromCategorySelected, setToCategorySelected, setStatusTrade, setSpbBank, setHashID, setIsValidPurseCard } =
  tradeSlice.actions

export default tradeSlice.reducer
