import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import AuthLayout from '../../features/layouts/auth-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput, { namesType } from '../../hooks/useInput'
import { fetchResetPasswordFinish } from '../../store/actions/userActions'
import { path } from '../../utils/consts'

const PasswordPage = () => {
  const { reset_password_message_finish } = useAppSelector(state => state.user)

  const passwordInp = useInput()
  const comparePasswordInp = useInput('', passwordInp.value)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { authTexts } = useGetText()

  useEffect(() => {
    if (reset_password_message_finish === 'success') {
      navigate(path.LOGIN)
    }
  }, [reset_password_message_finish])

  const passowrdHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const token = location.search.split('?token=').join('')

      if (!passwordInp.isValid || !comparePasswordInp.isValid) return

      dispatch(
        fetchResetPasswordFinish({
          token,
          password: comparePasswordInp.value
        })
      )
    },
    [comparePasswordInp, passwordInp]
  )

  return (
    <AuthLayout title={authTexts.RESTORE}>
      <form onSubmit={passowrdHandler}>
        <CustomInput required inputHandlers={passwordInp} variant='main' name={namesType.PASSWORD} type='password' placeholder='**********' label={authTexts.PASSWORD} />
        <CustomInput required inputHandlers={comparePasswordInp} variant='main' name={namesType.COMPARE_PASSWORD} type='password' placeholder='**********' label={authTexts.CONFIRM_PASSWORD} />
        <Button variant='primary' className='w-100 mb-3 mt-5 py-3'>
          {authTexts.RECOVERY}
        </Button>
        {reset_password_message_finish && <p className='t3 text-center'>{reset_password_message_finish}</p>}
      </form>
    </AuthLayout>
  )
}

export default PasswordPage
