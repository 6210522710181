import React from 'react'
import { Link } from 'react-router-dom'
import useGetText from '../hooks/useGetText'
import { otherLinks, path } from '../utils/consts'
import Win from './Win'
import Telegram from './Telegram'

const BottomBans = () => {
  const { mainPageTexts } = useGetText()

  return (
    <div className='bottom-bans'>
      <div className='container position-relative' style={{ zIndex: 2 }}>
        <div className='row'>
          <div className='col-lg-6'>
            <Telegram />
          </div>
          <div className='col-lg-6 mt-lg-0 mt-4'>
            {/*<Win />*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomBans
