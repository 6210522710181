export const ADMIN_EMAIL = 'nazircustm@gmail.com'

export const path = {
  MAIN: '/',
  BID: '/bid',
  BID_CASH: '/create-cash',
  EXCHANGE: '/',
  EXCHANGE_SEO: '/exchange/:from/:to',
  EXCHANGE_CITY_SEO: '/exchange/:from/:to/:city',

  LOGIN: '/login',
  REGISTER: '/register',
  COMPARE_PASSWORD: '/compare-password',
  PASSWORD: '/password',

  CHANGE: '/change',
  CONTESTS: '/referral',
  API: '/api',
  API_DOCS: '/api/docs',
  PROFILE_SETTINGS: '/profile-settings',
  EXCHANGE_HISTORY_PAGE: '/exchange-history-page',
  PAYOUTS_PAGE: '/payouts',
  HISTORY_PAYOUT_PAGE: '/history-payout-page',
  PAYOUT_PAGE: '/make-payout',

  CARDS: '/cards',
  NEW_CARD: '/new-card',

  ABOUT_US: '/about-us',
  SUPPORT: '/support',
  FAQ: '/faq',
  TERMS: '/terms',
  PARTNERS: '/partners',

  PRIVACY_POLICY: '/privacy-policy',
  AML: '/aml',

  // LK api
  API_LOGIN: '/api/lk/login',
  API_CREATE_ACCOUNT: '/api/lk/create-account',
  API_STATISTICS: '/api/lk/statistic',
  API_LK_DOCS: '/api/lk/docs',
  API_LK_KEYS: '/api/lk/keys',
  API_LK_TRANSACTIONS: '/api/lk/transctions',
  API_LK_ADDRESES: '/api/lk/addreses',
  API_LK_EXCHANGE: '/api/lk/exchange'
}

export const apiEndPoints = {
  // auth
  LOGIN: '/api/auth/login',
  LOGOUT: '/api/auth/logout',
  RESET_PASSWORD: '/api/auth/reset',
  RESET_PASSWORD_FINISH: '/api/auth/reset_password_finish',
  REGISTER: '/api/auth/sign',
  VERIFICATE_EMAIL: '/api/auth/verificate_email',
  CHECK_PASSWORD: '/api/auth/check_password',
  REFRESH_TOKEN: '/api/auth/refresh',
  REFERRAL_STATUS: '/users/refStatus',
  FETCH_PAYOUTS: '/users/getPayout',
  MAKE_PAYOUTS: '/users/makePayout',
  FETCH_BIDS: '/users/bids',
  FETCH_CARDS: '/cards/getUserCards',

  // user's account
  USER_INFO: 'api/auth/client_info',
  REF_INFO: 'v1/api/ref_info/fetch',
  REF_BALANCE: 'v1/api/referral/balance',
  REF_WITHDRAWAL_LIMITS: 'v1/api/referral/limits',
  REF_WITHDRAWAL: 'v1/api/referral/withdraw',
  REF_WITHDRAWAL_HISTORY: 'v1/api/referral/withdraw_history',
  USER_BIDS_HISTORY: 'v1/api/bids/fetch',
  CHANGE_PASSWORD: 'api/auth/change_password',
  CHANGE_EMAIL: 'api/auth/change_email',

  // static
  GET_FROM: '/v1/api/direction/fetch',
  GET_TEXT: '/static/get_algorithm_text',
  GET_BANK_TO_BANK: '/v1/api/static/get_bank_to_bank_text',
  GET_AUTO_EXCHANGE_TEXT: '/v1/api/static/get_auto_exchange_text',
  HELP: '/users/help',
  DINAMIC_TEXT: '/v1/api/texts/dynamic',
  STATIC_TEXT: '/v1/api/texts/static',
  CURRENT_BALANCE: '/parser/competition',

  // trade
  COURSE: '/v1/api/direction/course',
  AUTO_SIGN: 'api/auth/auto_sign',
  CREATE_BID: '/v1/api/bids/create',
  GET_BID: '/v1/api/bids/bid',
  UPDATE_STATUS: '/v1/api/bids/update_status_realpay',
  DELETE_BID: '/bids/',
  BID_VALIDATE: '/v1/api/bids/validate',
  SITES_FLAGS : '/v1/api/bids/flag',

  // card
  CHECK_CARD_IS_VERIFIED: '/cards/is_verified',
  CHECK_CARD: '/cards/cardCheck',
  CREATE_CARD: '/cards/cardsUpload',
  FINISH_CARD: '/v1/api/bids/processing',
  GET_USER_CARDS: 'v1/api/cards/fetch',
  ADD_CARD_TO_VERIFY: 'v1/api/cards/add_verify',

  // admin
  ADMIN_CARDS: '/cards/getCards',
  APPROVE_CARD: '/cards/verificate',
  DELETE_CARD: '/cards/',
  FETCH_PHOTO: '/cards/',
  FETCH_ADMIN: '/users/adminLogin',

  // lk
  API_LOGIN: '/auth/sign_in',
  API_REGISTER: '/auth/sign_up',
  API_GET_TOKENS: '/token/fetch/all',
  API_GET_BALANCE: '/token/balances',
  API_GET_TRANSACTION: '/transaction/fetch?limit=10&offset=10&type=OUT&sort_order=DESC',
  API_GET_EXCHANGES: '/swap/fetch?limit=10&offset=1',
  API_GET_KEYS: '/api_keys',
  API_GET_ADDRESSES: '/address/fetch',
  API_LOGOUT: '/auth/logout',
  API_REFRESH_JWT_TOKENS: '/auth/refresh_token',
  API_ALL_TOKENS: '/token/fetch/all',
  API_FETCH_FOR_EXCHANGE_TOKENS: '/token/fetch/for_exchange',
  TRANSACTIONS: '/transaction/fetch',
  API_FETCH_INVOCE: '/transaction/create/invoice',
  API_CREATE_WITHDROW: '/transaction/create/withdrawal',
  API_FETCH_SWAP: '/swap/fetch',
  API_SWAP_EXCHANGE_RATE: '/swap/exchange_rate',
  API_CREATE_SWAP: '/swap/create'
}

export const navs: any = {
  ru: [
    { text: 'Главная', link: path.MAIN },
    { text: 'Правила', link: path.TERMS },
    { text: 'FAQ', link: path.FAQ },
    { text: 'Конкурс', link: path.CONTESTS },
    { text: 'API', link: path.API },
    { text: 'О нас', link: path.ABOUT_US },
    { text: 'Политика конфиденциальности', link: path.PRIVACY_POLICY },
    { text: 'Aml', link: path.AML }
  ],
  en: [
    { text: 'Home', link: path.MAIN },
    { text: 'Rules', link: path.TERMS },
    { text: 'FAQ', link: path.FAQ },
    { text: 'Contest', link: path.CONTESTS },
    { text: 'API', link: path.API },
    { text: 'About us', link: path.ABOUT_US },
    { text: 'Privacy policy', link: path.PRIVACY_POLICY },
    { text: 'Aml', link: path.AML }
  ],
  tu: [
    { text: 'Ev', link: path.MAIN },
    { text: 'Tüzük', link: path.TERMS },
    { text: 'FAQ', link: path.FAQ },
    { text: 'Yarışma', link: path.CONTESTS },
    { text: 'API', link: path.API },
    { text: 'Hakkımızda', link: path.ABOUT_US },
    { text: 'Gizlilik Politikası', link: path.PRIVACY_POLICY },
    { text: 'Aml', link: path.AML }
  ]
}

export const navsLk: any = {
  ru: [
    { text: 'Статистика', link: path.API_STATISTICS },
    { text: 'Документация', link: path.API_LK_DOCS },
    { text: 'Api-ключи', link: path.API_LK_KEYS },
    { text: 'Транзакции', link: path.API_LK_TRANSACTIONS },
    { text: 'Адреса', link: path.API_LK_ADDRESES },
    { text: 'Обмен', link: path.API_LK_EXCHANGE }
  ],
  en: [
    { text: 'Statistics', link: path.MAIN },
    { text: 'Docs', link: path.API_LK_DOCS },
    { text: 'API keys', link: path.API_LK_KEYS },
    { text: 'Transactions', link: path.API_LK_TRANSACTIONS },
    { text: 'Addresses', link: path.API_LK_ADDRESES },
    { text: 'Exchange', link: path.API_LK_EXCHANGE }
  ],
  tu: [
    { text: 'Statistics', link: path.MAIN },
    { text: 'Docs', link: path.API_LK_DOCS },
    { text: 'API keys', link: path.API_LK_KEYS },
    { text: 'Transactions', link: path.API_LK_TRANSACTIONS },
    { text: 'Addresses', link: path.API_LK_ADDRESES },
    { text: 'Exchange', link: path.API_LK_EXCHANGE }
  ]
}

export const headerAuthNav: any = {
  ru: [
    { text: 'Личный кабинет', link: path.PROFILE_SETTINGS },
    { text: 'Реферальная программа', link: path.PAYOUTS_PAGE },
    {text: 'История обменов', link: path.EXCHANGE_HISTORY_PAGE},
    {text: 'Мои карты', link: path.CARDS}
  ],
  en: [
    { text: 'Private cabinet', link: path.PROFILE_SETTINGS },
    { text: 'Referral program', link: path.PAYOUTS_PAGE },
    {text: 'Exchange history', link: path.EXCHANGE_HISTORY_PAGE},
    {text: 'My cards', link: path.CARDS}
  ],
  tu: [
    { text: 'Kişisel Alan', link: path.PROFILE_SETTINGS },
    { text: 'Tavsiye programı', link: path.PAYOUTS_PAGE },
    {text: 'Değişim geçmişi', link: path.EXCHANGE_HISTORY_PAGE},
    {text: 'Kartlarım', link: path.CARDS}
  ]
}

export const ReferralNavs: any = {
  ru: [
    { text: 'Статус', link: path.PAYOUTS_PAGE },
    { text: 'Вывод средств', link: path.PAYOUT_PAGE },
    { text: 'История выплат', link: path.HISTORY_PAYOUT_PAGE }
  ],
  en: [
    { text: 'Status', link: path.PAYOUTS_PAGE },
    { text: 'Withdrawal funds', link: path.PAYOUT_PAGE },
    { text: 'Payout history', link: path.HISTORY_PAYOUT_PAGE }
  ],
  tu: [
    { text: 'Durum', link: path.PAYOUTS_PAGE },
    { text: 'Para çekme', link: path.PAYOUT_PAGE },
    { text: 'Ödeme geçmişi', link: path.HISTORY_PAYOUT_PAGE }
  ]
}
export const languages: {
  name: string
  code: string
}[] = [
  { name: 'Русский', code: 'ru' },
  { name: 'English', code: 'en' },
  { name: 'Türk', code: 'tu' }
]

export const Category = {
  CRYPTO: `crypto`,
  PAYMENT_SYSTEM: `payment-system`,
  BANK_RUB: `bank-rub`,
  BANK_UK: `bank-uk`,
  CASH: `cash`,
  ALL: 'all'
}

export const CodeCategory: any = {
  YAMRUB: Category.PAYMENT_SYSTEM,
  PMUSD8: Category.PAYMENT_SYSTEM,
  QWRUB: Category.PAYMENT_SYSTEM,

  REP: Category.CRYPTO,
  NEO: Category.CRYPTO,
  QTUM: Category.CRYPTO,
  WAVES: Category.CRYPTO,
  BTC: Category.CRYPTO,
  BTC9: Category.CRYPTO,
  ETH: Category.CRYPTO,
  LTC: Category.CRYPTO,
  USDTERC: Category.CRYPTO,
  USDTTRC: Category.CRYPTO,
  Omni: Category.CRYPTO,
  XMR: Category.CRYPTO,
  ZEC: Category.CRYPTO,
  DASH: Category.CRYPTO,
  BNB20: Category.CRYPTO,
  XLM: Category.CRYPTO,
  BNB: Category.CRYPTO,

  DSH: Category.CRYPTO,
  LSK: Category.CRYPTO,
  TRX: Category.CRYPTO,
  XRP: Category.CRYPTO,
  DOGE: Category.CRYPTO,
  ETC: Category.CRYPTO,
  PMUSD: Category.CRYPTO,
  ADA: Category.CRYPTO,
  USDP: Category.CRYPTO,
  TUSD: Category.CRYPTO,
  DAI: Category.CRYPTO,
  BUSD: Category.CRYPTO,
  USDC: Category.CRYPTO,

  CASHUSD: Category.CASH,
  CASHRUB: Category.CASH,
  CASHEUR: Category.CASH,
  CASHAED: Category.CASH,
  CASHTRY: Category.CASH,
  CASHAMD: Category.CASH,
  KZTCASH: Category.CASH,
  GELCASH: Category.CASH,

  TCSBRUB: Category.BANK_RUB,
  SBERRUB: Category.BANK_RUB,
  TBRUB: Category.BANK_RUB,
  ACRUB: Category.BANK_RUB,
  ACCRUB: Category.BANK_RUB,
  TCSBCRUB: Category.BANK_RUB,
  Alfabank: Category.BANK_RUB,
  CARDRUB: Category.BANK_RUB,
  MIRCRUB: Category.BANK_RUB,
  GPBRUB: Category.BANK_RUB,
  PSBRUB: Category.BANK_RUB,
  RFBRUB: Category.BANK_RUB,
  HCBRUB: Category.BANK_RUB,
  OPNBRUB: Category.BANK_RUB,
  MTSBRUB: Category.BANK_RUB,
  POSTBRUB: Category.BANK_RUB,
  RUSSTRUB: Category.BANK_RUB,
  ROSBRUB: Category.BANK_RUB,
  SBPRUB: Category.BANK_RUB,

  P24UAH: Category.BANK_UK,
  UAH: Category.BANK_UK,
  USBUAH: Category.BANK_UK,
  CARDUAH: Category.BANK_UK,
  MONOBUAH: Category.BANK_UK,
  WIREUAH: Category.BANK_UK,
  OSDBUAH: Category.BANK_UK,

  CNTRUB: Category.PAYMENT_SYSTEM
}

export const filterOptions = [
  {
    label: `Все`,
    labelEn: 'All',
    id: 7,
    category: Category.ALL
  },
  {
    label: `Криптовалюты`,
    labelEn: 'Cryptocurrencies',
    id: 2,
    category: Category.CRYPTO
  },
  {
    label: `Платежные системы`,
    labelEn: 'Payment systems',
    id: 3,
    category: Category.PAYMENT_SYSTEM
  },
  {
    label: `Банки RUB`,
    labelEn: 'RUB banks',
    id: 4,
    category: Category.BANK_RUB
  },
  {
    label: `Банки UAH`,
    labelEn: 'UAH banks',
    id: 5,
    category: Category.BANK_UK
  },
  {
    label: `Наличные деньги`,
    labelEn: 'Cash',
    id: 6,
    category: Category.CASH
  }
]

export const filterCategoryOption: any = {
  ru: {
    [Category.CRYPTO]: 'Криптовалюты',
    [Category.PAYMENT_SYSTEM]: 'Платежные системы',
    [Category.BANK_RUB]: 'Банки RUB',
    [Category.BANK_UK]: 'Банки UAH',
    [Category.CASH]: 'Наличные деньги'
  },
  en: {
    [Category.CRYPTO]: 'Cryptocurrencies',
    [Category.PAYMENT_SYSTEM]: 'Payment systems',
    [Category.BANK_RUB]: 'RUB banks',
    [Category.BANK_UK]: 'UAH banks',
    [Category.CASH]: 'Cash'
  },
  tu: {
    [Category.CRYPTO]: 'Kripto para birimleri',
    [Category.PAYMENT_SYSTEM]: 'ödeme sistemleri',
    [Category.BANK_RUB]: 'Bankalar RUB',
    [Category.BANK_UK]: 'Bankalar UAH',
    [Category.CASH]: 'Nakit'
  }
}

export const codesCurrency: any = {
  P24UAH: 'UAH',
  XMR: 'XMR',
  ERC20: 'ETH',
  Omni: 'OMNI',
  TRC20: 'TRC20',
  LTC: 'LTC',
  QTUM: 'QTUM',
  BTC: 'BTC',
  ETH: 'ETH',
  ETC: 'ETC',
  USDTERC: 'USDT',

  CASHUSD: 'USD',
  CASHRUB: 'RUB',
  CASHAED: 'AED',
  CASHTRY: 'TRY',
  CASHAMD: 'AMD',
  SBERRUB: 'RUB',
  CASHEUR: 'EUR',
  KZTCASH: 'KZT',
  GELCASH: 'GEL',

  SBERRUBCARD: 'RUB',
  TCSBRUB: 'RUB',
  TBRUB: 'RUB',
  Alfacashin1: 'RUB',
  Alfabank: 'RUB',
  ACRUB: 'RUB',
  ACCRUB: 'RUB',
  WAVES: 'WAVES',
  TCSBCRUB: 'RUB',
  USDTTRC: 'USDT',
  QWRUB: 'RUB',
  PMUSD: 'USD',
  PMUSD8: 'USD',
  NEO: 'NEO',
  YAMRUB: 'RUB',
  REP: 'REP',
  AUTO: 'RUB',
  XRP: 'XRP',
  GPBRUB: 'RUB',
  PSBRUB: 'RUB',
  RFBRUB: 'RUB',
  HCBRUB: 'RUB',
  OPNBRUB: 'RUB',
  MTSBRUB: 'RUB',
  POSTBRUB: 'RUB',
  RUSSTRUB: 'RUB',
  ROSBRUB: 'RUB',
  SBPRUB: 'RUB',
  ADA: 'ADA',
  ZEC: 'ZEC',
  DASH: 'DASH',
  BNB20: 'BNB20',
  XLM: 'XLM',
  BNB: 'BNB',
  USDP: 'USDP',
  TUSD: 'TUSD',
  DAI: 'DAI',
  BUSD: 'BUSD',
  USDC: 'USDC'
}

export const CardStatus = {
  APPROVED: 'approve',
  DECLINED: 'declined',
  PANDING: 'pending',
  NOT_FOUND: 'not-found'
}

export const BidStatus: any = {
  CREATED: 'created',
  PENDING: 'pending',
  RECEIVED: 'received',
  DONE: 'done',
  ERROR: 'error'
}

export const BidStatusNameRus: any = {
  ru: {
    created: 'Принята, ожидает оплаты клиентом',
    pending: 'Криптовалюта вышла в сеть',
    received: 'Оплачена',
    done: 'Завершена'
  },
  en: {
    created: 'Accepted, awaiting customer payment',
    pending: 'Cryptocurrency went online',
    received: 'Paid',
    done: 'Completed'
  },
  tu: {
    created: 'Kabul edildi, müşteri ödemesi bekleniyor',
    pending: 'Kripto para çevrimiçi oldu',
    received: 'Paralı',
    done: 'Tamamlanmış'
  }
}

export const cookiesName = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  EMAIL: 'email',
  REF_ID: 'ref_id',

  ACCESS_TOKEN_LK_API: 'access_token_api_lk',
  REFRESH_TOKEN_LK_API: 'refresh_token',

  FINGERPRINT: 'FINGERPRINT',
  PROCESSING: 'processing',

  BID_STEP: 'bidStep',
  BID_PROCESSING: 'bid_processing',
  STEP_VERIFICATION: 'step_verification',
  CARD_HASH: 'hash'
}

export const otherLinks: any = {
  CASHEX_TELEGRAM_LINK: 'https://t.me/+58sIbfPF1yNjNGUy',
  BESTCHANGE: 'https://www.bestchange.ru/cashex-exchanger.html',
  BESTCHANGE_EN: 'https://www.bestchange.com/cashex-exchanger.html',
  EX_NODE: 'https://exnode.ru/exchangers/exchanger-2011764/',
  EX_NODE_GENERAL: 'https://exnode.ru/?utm_source=cashex',
  Bitsmedia: 'https://forum.bits.media/index.php?/topic/68114',
  CryptoTalk:
    'https://cryptotalk.org/topic/103638-cashexcc-%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D1%8B%D0%B9-%D0%B8-%D0%B1%D1%8B%D1%81%D1%82%D1%80%D1%8B%D0%B9-%D0%BE%D0%B1%D0%BC%D0%B5%D0%BD-%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE%D0%B2%D0%B0%D0%BB%D1%8E%D1%82-%D0%B2-%D0%BC%D0%BE%D1%81%D0%BA%D0%B2%D0%B5-%D0%B8-%D0%BD%D0%B0-%D0%B2%D1%81%D0%B5%D0%B9-%D1%82%D0%B5%D1%80%D1%80%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D1%80%D1%84/',
  BestEmoneys: '',
  SvgMMgp: 'https://mmgp.ru/showthread.php?t=628362',
  MaulTalk: '',
  Emon: 'https://e-mon.ru/exchanger/245',
  AmlBot: 'https://amlbot.com/',
  MyWot: 'https://www.mywot.com/ru/scorecard/cashex.cc',
  SurLy: 'https://sur.ly/',
  Reddit: 'https://www.reddit.com/user/CashexCC/',
  Otzovik: ''
}

export const memoCurrencies = ['XRP', 'XLM', 'BNB']

export const ModalId = {
  MODAL_LK: 'ModalLk',
  MODAL_LK_WITHDROW: 'MODAL_LK_WITHDROW',
  MODAL_LK_EXCHANGE: 'MODAL_LK_EXCHANGE',
  MODAL_LK_SIGN_UP_OTP_QR: 'MODAL_LK_SIGN_UP_OPT_QR'
}

export const MyCustomStatus = {
  ERROR: 'ERROR',
  CREATED: 'CREATED',
  SUCCESS: 'SUCCESS',
  COMPLATED: 'COMPLATED',
  LOADING: 'LOADING'
}

export const ErrorTexts: { [key: string]: { [key: string]: string } } = {
  InvalidUserCredentials: {
    ru: 'Неверный логин или пароль',
    en: 'Wrong login or password',
    tu: 'yanlış giriş veya şifre'
  },
  InvalidEmail: {
    ru: 'Некорректный email',
    en: 'Incorrect email address',
    tu: 'Incorrect email address'
  },
  EmailAlreadyExists: {
    ru: 'Адрес электронной почты уже существует',
    en: 'Email already exists',
    tu: 'Email already exists'
  },
  InvalidInviteCode: {
    ru: 'Неверный инвайт код',
    en: 'Invalid invite code',
    tu: 'Invalid invite code'
  },
  CodeIsAlreadyUsed: {
    ru: 'Инвайт код уже использован',
    en: 'Code is already used',
    tu: 'Code is already used'
  },
  InvalidJwtToken: {
    ru: 'Текущая сессия окончена, выполните вход',
    en: 'The current session has ended, please sign in',
    tu: 'Mevcut oturum sona erdi, lütfen oturum açın'
  },
  CommonError: {
    ru: 'Что-то пошло не так',
    en: 'Something went wrong',
    tu: 'Something went wrong'
  }
}
