export const ApiStatisticText: any = {
  ru: {
    CHART_TITILE: 'Валюты',
    WALLETS_TITLE: 'Балансы валют'
  },
  en: {
    CHART_TITILE: 'Currencies',
    WALLETS_TITLE: 'Currency balances'
  },
  tu: {
    CHART_TITILE: 'Para birimleri',
    WALLETS_TITLE: 'Döviz bakiyeleri'
  }
}
