import axios from 'axios'
import Cookies from 'cookies-ts'
import { apiEndPoints, cookiesName } from './consts'

const cookies = new Cookies()

export const BASE_API_URL = 'https://api.cashex.cc' // prod server
// export const BASE_API_URL = 'https://api.bitokk.site' // test server

export const BASE_API_LC_URL = 'https://lk.cashex.cc/lk'

export const FINGER_PRINT = 'finger-print'
export const ACCESS_TOKEN = 'access-token'

// --------------------------------------------------------------------------------------------
export const refreshTokenApi = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    [FINGER_PRINT]: localStorage.getItem(FINGER_PRINT) ?? false,
    Authorization : `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
  },
  withCredentials: true
})
// --------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------
// PUBLIC API INSTANCE


export const registerApi = axios.create({ //TODO: удалить потом
  baseURL: BASE_API_URL,
});


export const api = axios.create({
  baseURL: BASE_API_URL,
})


// set 'finger-print' to headers from localStorage before each api request
api.interceptors.request.use(config => {
  if (config.headers) {
    config.headers[FINGER_PRINT] = localStorage.getItem(FINGER_PRINT) ?? ""
    config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
  }
  return config
})

// retry request with a fresh access token on a 401 error
api.interceptors.response.use(
  config => {
    return config
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401) {
      try {
        // get a new access token
        const response = await refreshTokenApi.get(apiEndPoints.REFRESH_TOKEN)

        // save new access token in localStorage
        localStorage.setItem(ACCESS_TOKEN, response.headers.authorization)

        // retry original request with new access token
        return api.request(originalRequest)
      } catch (error) {
        localStorage.removeItem(ACCESS_TOKEN)
        window.location.replace(`${window.location.origin}/login`)
        return Promise.reject(error); // Возвращаем отклоненный Promise в случае ошибки
      }
    }
    return error
  }
)

// --------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------
// AUTH API INSTANCE
export const authApi = axios.create({
  baseURL: BASE_API_URL
})

// set 'access-token' and 'finger-print' to headers from localStorage before each authApi request
authApi.interceptors.request.use(config => {
  if (config.headers) {
    config.headers[FINGER_PRINT] = localStorage.getItem(FINGER_PRINT) ?? false
    config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
  }
  return config
})

// retry request with a fresh access token on a 401 error
authApi.interceptors.response.use(
  config => {
    return config
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401) {
      try {
        // get a new access token
        const response = await refreshTokenApi.get(apiEndPoints.REFRESH_TOKEN)

        // save new access token in localStorage
        localStorage.setItem(ACCESS_TOKEN, response.headers.authorization)

        // retry original request with new access token
        return authApi.request(originalRequest)
      } catch (error) {
        localStorage.removeItem(ACCESS_TOKEN)
        window.location.replace(`${window.location.origin}/login`)
      }
    }
  }
)

// --------------------------------------------------------------------------------------------

export const apiLk = axios.create({
  baseURL: BASE_API_LC_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

const requestHandlerApiLk = (request: any) => {
  if (request.url?.includes(apiEndPoints.API_REFRESH_JWT_TOKENS)) {
    request.headers['Authorization'] = 'Bearer ' + cookies.get(cookiesName.REFRESH_TOKEN_LK_API) || ''
  } else {
    request.headers['Authorization'] = 'Bearer ' + cookies.get(cookiesName.ACCESS_TOKEN_LK_API) || ''
  }

  request.headers['Finger-Print'] = cookies.get(cookiesName.FINGERPRINT) || ''
  return request
}

apiLk.interceptors.request.use(request => requestHandlerApiLk(request))
