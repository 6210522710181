import dayjs from 'dayjs'
import React, { useCallback, useEffect } from 'react'
import Button from '../../../components/buttons/button'
import { AuthLoader } from '../../../components/loaders/loaders'
import Tokens from '../../../features/api-lk/tokens'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { fetchAddreses, fetchAllTokens, fetchInvoice, fetchTransactions } from '../../../store/actions/apiLkActions'

const ApiAddreses = () => {
  const { xToken, rToken } = useAppSelector(state => state.apiLkAuth)
  const { selectedToken, addresess, isLoadingAddresess } = useAppSelector(state => state.apiLk)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllTokens())
  }, [xToken, rToken])

  useEffect(() => {
    dispatch(fetchAddreses())
  }, [selectedToken, xToken, rToken])

  const formatDate = useCallback((date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'), [addresess])

  return (
    <div className='container api-user pt-5 transactions'>
      <div className='row'>
        <div className='col-xl-2 col-lg-4'>
          <Button
            variant='primary'
            className='w-100 mb-3 py-3'
            onClick={() => {
              dispatch(fetchInvoice())
              setTimeout(() => {
                dispatch(fetchAddreses())
              }, 1000)
            }}
          >
            + Новый адрес
          </Button>
          <Tokens />
        </div>
        <div className='col-xl-10 col-lg-8'>
          <div className='card px-0'>
            <h3 className='px-3'>Адреса валют</h3>
            <div className='overflow-auto'>
              <table className='table api-table'>
                <thead>
                  <tr>
                    <th>Адрес</th>
                    <th>Название сети</th>
                    <th>Дата регистрации</th>
                  </tr>
                </thead>
                <tbody>
                  {addresess?.map((el, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <p className='l1'>{el.address}</p>
                        </td>
                        <td>
                          <p>
                            {selectedToken?.full_name} ( {selectedToken?.token} )
                          </p>
                        </td>
                        <td>
                          <p>{formatDate(el.date_create)}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {isLoadingAddresess ? (
              <div className='d-flex justify-content-center'>
                <AuthLoader />
              </div>
            ) : (
              !addresess && <h5 className='px-3 text-center'>Адреса отсутствуют</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiAddreses
