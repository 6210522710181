import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BidInfo from '../components/bid-info'
import BidSteps from '../components/bid-steps'
import BottomBans from '../components/bottom-bans'
import CardVerification from '../features/card-verification'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useConditionDirection from '../hooks/useConditionDirection'
import useGetText from '../hooks/useGetText'
import useTimerDown from '../hooks/useTimer'
import { fetchCardCheck, fetchGetBid, finishVerification } from '../store/actions/bidActions'
import { setBidStep, setCardCheckStatus, setIsLoadingBid, setStepVerification } from '../store/reducers/bidReducer'
import { setHashID } from '../store/reducers/tradeReducer'
import { cookiesName } from '../utils/consts'
import Utils from '../utils/utils'
import Preloader from '../components/Preloader'
import Cookies from 'cookies-ts'

const cookies = new Cookies()

const AutoBidPage = () => {
  const {
    id,
    bidStep,
    currency_get,
    sum_get,
    hash,
    card,
    currency_give,
    cardCheckStatus,
    cardStatus,
    isLoadingBid,
    bid_processing_message,
    payment_details_get,
    payment_details_give
  } = useAppSelector(state => state.bid)
  const {spb_bank, to} = useAppSelector(state => state.trade)
  const { userCards } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const condition = useConditionDirection(currency_get, currency_give)
  const success = useRef(false)

  const { fingerprint } = useAppSelector(state => state.apiLkAuth)

  const [bidId, setBidId] = useState<string>('')

  const { exchangeText } = useGetText()

  useEffect(() => {
    // получение хеша заявки
    let id, excTrc
    if (location.search.includes('excTrc=')) {
      id = location.search?.split('?id=')[1]?.split('&')[0]
      excTrc = location.search?.split('?id=')[1]?.split('&')[1]?.split('excTrc=').join('')
    } else {
      id = location.search?.split('?id=')[1]
      excTrc = ''
    }
    localStorage.setItem('bid_id', String(id))

    setBidId(id)
    dispatch(fetchGetBid()).then(res => {
      const bid = res.payload.content

      if (bid.currency_from?.includes('CASH') || bid.currency_to?.includes('CASH')) {
        return
      }

      if (!bid.payment_details_from || !bid.payment_details_to) {
        dispatch(finishVerification({ hash: bid.hash, bank : to === 'SBPRUB' ? spb_bank : null, headers: { fingerPrint: fingerprint } }))
      }
    })

    dispatch(setHashID(''))
  }, [location])

  useEffect(() => {
    if (cookies.get(cookiesName.STEP_VERIFICATION)?.length) {
      const verificationStep: any = cookies.get(cookiesName.STEP_VERIFICATION)
      if (+verificationStep !== 0) {
        dispatch(setStepVerification(+verificationStep))
      }
    }
  }, [])

  useEffect(() => {
    if (cookies.get(cookiesName.BID_STEP)?.length) {
      const cookieStep: any = cookies.get(cookiesName.BID_STEP)

      if (+cookieStep === 3 && bid_processing_message === 'Success') {
        dispatch(setBidStep(3))
      } else if (+cookieStep === 1 && bid_processing_message === 'Success') {
        dispatch(setBidStep(1))
      }
    }
  }, [bidStep])

  useEffect(() => {
    // получения статуса карты
    if (isLoadingBid && !cardCheckStatus && (condition.bankToCrypto || condition.BankRUBToBankUKAndReverse)) {
      // dispatch(fetchCardCheck())
      dispatch(setIsLoadingBid(false))
      dispatch(setCardCheckStatus(false))
    }
  }, [isLoadingBid, cardCheckStatus])

  useEffect(() => {
    // THIS!!!
    const processing = cookies.get(cookiesName.PROCESSING)

    if (
      bidId &&
      !success.current &&
      payment_details_get === '' &&
      payment_details_give === '' &&
      bid_processing_message !== 'Success' &&
      (condition.paymentSystemToCrypto || condition.cryptoToPaymentSystem || condition.cryptoToBank)
    ) {
      // dispatch(finishVerification(bidId))
      success.current = true
    }
  }, [success.current, bid_processing_message, condition, hash, bidId])

  useEffect(() => {
    if (bid_processing_message === 'Success' && condition.hasMemoTag) {
      const t = setTimeout(() => dispatch(fetchGetBid()), 5000)
      return () => clearTimeout(t)
    }
  }, [bid_processing_message, condition.hasMemoTag])

  useEffect(() => {
    if (bid_processing_message === 'Success') return

    if (cookies.get(cookiesName.BID_STEP)?.length) {
      const cookieStep: any = cookies.get(cookiesName.BID_STEP)
      if (+cookieStep === 1) {
        dispatch(setBidStep(1))
        return
      } else if (+cookieStep === 3) {
        dispatch(setBidStep(3))
        return
      } else if (+cookieStep === 0) {
        dispatch(setBidStep(0))
        return
      }
    }

    if ((cardStatus !== 'approve' && condition.bankToCrypto) || condition.BankRUBToBankUKAndReverse) {
      if (+sum_get < 3000) {
        dispatch(setBidStep(3))
        // if (bidId) dispatch(finishVerification(bidId))
        // dispatch(setBidStep(1))
        return
      }
    }

    if ((cardStatus === 'pending' && condition.bankToCrypto) || condition.BankRUBToBankUKAndReverse) {
      if (+sum_get >= 3000) {
        dispatch(setBidStep(0))
      } else {
        dispatch(setBidStep(1))
      }
    }

    if ((+sum_get >= 3000 && condition.bankToCrypto) || condition.BankRUBToBankUKAndReverse) {
      switch (cardStatus) {
        case 'pending':
          dispatch(setBidStep(0))
          break
        case 'approve':
          if (bid_processing_message !== 'Success') {
            dispatch(setBidStep(3))
            // if (bidId) dispatch(finishVerification(bidId))
            // dispatch(setBidStep(1))
          }
          break
        case 'declined':
          dispatch(setBidStep(0))
          break
      }
    }

    // if ((cardStatus === '' && condition.bankToCrypto) || condition.BankRUBToBankUKAndReverse) {
    //   if (+sum_get >= 3000 || (userCards?.length && userCards?.find(userCard => userCard?.number && Utils.writeCardNumber(userCard?.number) === Utils.writeCardNumber(card)))) {
    //     dispatch(setBidStep(0))
    //   } else {
    //     dispatch(setBidStep(1))
    //   }
    // }
  }, [cardStatus, condition, sum_get])

  if (bidStep === 0) {
    return <CardVerification />
  }

  return (
    // информация по заявке
    <>
      {(bidStep === 1 || bidStep === 2) && (
        <div className='container'>
          <h1>
            {exchangeText.HEADING} {id}
          </h1>
        </div>
      )}

      {bid_processing_message === 'loading' && <Preloader />}

      {bidStep === 1 && <BidInfo />}
      {bidStep === 3 && <BidSteps />}

      <BottomBans />
    </>
  )
}

export default AutoBidPage
