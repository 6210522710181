import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import Button from '../../components/buttons/button'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { fetchBids } from '../../store/actions/userActions'
import { BidStatusNameRus, path } from '../../utils/consts'
import { Link } from 'react-router-dom'

const limites = [5, 10, 20, 30, 50]

dayjs.extend(timezone)

const ExchangeHistoryPage = () => {
  const { bids } = useAppSelector(state => state.user)
  const { language } = useAppSelector(state => state.main)
  const [pagination, setPagination] = useState({
    limit: 5,
    offset: 0
  })

  const dispatch = useAppDispatch()

  const { profileTexts } = useGetText()

  useEffect(() => {
    dispatch(
      fetchBids({
        limit: pagination.limit,
        offset: pagination.offset
      })
    )
  }, [pagination])

  return (
    <div className='container pb-5'>
      <h1>{profileTexts?.PROFILE_HEADING}</h1>
      <div className='profile-card '>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>{profileTexts?.EXCHANGE_HEADING}</h2>

          <div>
            <div className='dropdown dropdown-limit'>
              <button className='btn p-0' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                {profileTexts?.CONCLUSION} {pagination.limit}{' '}
                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'>
                  <path d='M7 12L0.937822 0H13.0622L7 12Z' fill='#002674' />
                </svg>
              </button>
              <ul className='dropdown-menu dropdown-menu-end' aria-labelledby='dropdownMenuButton1'>
                {limites.map((el, idx) => (
                  <li className={`${pagination.limit === el ? 'active' : ''}`} onClick={() => setPagination(prev => ({ ...prev, limit: el }))} key={idx}>
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className=' overflow-auto'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>
                  <div style={{ minWidth: '140px' }}>{profileTexts?.EXCHANGE_TABLE.APPLICATION_NUM}</div>
                </th>
                <th scope='col'>
                  <div style={{ minWidth: '140px' }}>{profileTexts?.EXCHANGE_TABLE.DATE}</div>
                </th>
                <th scope='col'>
                  <div style={{ minWidth: '140px' }}>{profileTexts?.EXCHANGE_TABLE.COURSE}</div>
                </th>
                <th scope='col'>
                  <div style={{ minWidth: '200px' }}>{profileTexts?.EXCHANGE_TABLE.GIVE_GET}</div>
                </th>
                <th scope='col'>
                  <div style={{ minWidth: '140px' }}>{profileTexts?.EXCHANGE_TABLE.STATUS}</div>
                </th>
              </tr>
            </thead>
            <tbody className='border-top-0'>
              {bids !== undefined &&
                bids?.map(el => (
                  <tr key={el.id}>
                    <td>
                      <Link to={`${path.BID}/?id=${el.hash}`}>{el?.id}</Link>
                    </td>

                    <td>
                      {dayjs(el?.created_at).add(-3, 'hours').format('DD.MM.YYYY / HH:mm:ss')}</td>
                    <td>

                      {el?.course_from} <img src={`/images/currencies/${el?.currency_from}.svg`} width={17} height={17} alt='' />
                      {' — '}
                      {el?.course_to} <img src={`/images/currencies/${el?.currency_to}.svg`} width={17} height={17} alt='' />
                    </td>

                    <td>
                      {el?.amount_from} <img src={`/images/currencies/${el?.currency_from}.svg`} width={17} height={17} alt='' />
                      {' — '}
                      {el?.amount_to} <img src={`/images/currencies/${el?.currency_to}.svg`} width={17} height={17} alt='' />
                    </td>

                    <td>
                      {BidStatusNameRus[language][el?.status || '']}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {bids?.length === 0 && <p className='text-center'>{profileTexts?.CLEAR_EXCHANGE}</p>}
        </div>
        {bids !== undefined && bids?.length >= pagination?.limit - 1 && (
          <Button
            variant='primary'
            onClick={() => {
              setPagination(prev => ({
                ...prev,
                limit: pagination.limit + pagination.limit
              }))
            }}
          >
            {profileTexts?.LOAD_MORE}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ExchangeHistoryPage
