import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import apiLkService, { SignUpData, SignInData } from '../../service/api-lk-service'
import { ApiLkAuthState, Status } from '../reducers/apiLkAuthReducer'
import Cookies from 'cookies-ts'
import { cookiesName } from '../../utils/consts'
import { RootState } from '../store'
import utils from '../../utils/utils'

const cookies = new Cookies()

export type JWTTokens = {
  access_token: string
  refresh_token: string
}

export interface IOtpUrl {
  otp_url: string
}

export interface IValidationErrors extends ServerError {
  language: string
}

type ServerError = {
  description: string
  status: string
}

// ----------------------------------------------------------------

// SIGN IN API LC
export const fetchLoginApiLk = createAsyncThunk('ApiLk/fetchLoginApiLk', async (data: SignInData, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.loginLk(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

// SIGN IN API LC HANDLER
export const fetchLoginApiLkHandler = (builder: any) => {
  builder.addCase(fetchLoginApiLk.pending, (state: ApiLkAuthState, action: PayloadAction<any>) => {})
  builder.addCase(fetchLoginApiLk.fulfilled, (state: ApiLkAuthState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
      state.authMessage = utils.apiLcErrorHandler(action.payload?.msg, action.payload.language)
    } else {
      state.isAuthApi = true
      state.xToken = action.payload?.access_token
      state.rToken = action.payload?.refresh_token

      cookies.set(cookiesName.ACCESS_TOKEN_LK_API, action.payload?.access_token)
      cookies.set(cookiesName.REFRESH_TOKEN_LK_API, action.payload?.refresh_token)
    }
  })
  builder.addCase(fetchLoginApiLk.rejected, (state: any, action: PayloadAction<any>) => {})
}

// ----------------------------------------------------------------

// SIGN UP API LC
export const fetchSignUpApiLk = createAsyncThunk<IOtpUrl, SignUpData, { rejectValue: IValidationErrors; state: RootState }>('ApiLk/fetchSignUpApiLk', async (signUpData, thunkAPI) => {
  const { language } = thunkAPI.getState().main

  try {
    const response = await apiLkService.createAccount(signUpData)

    if (response.data.otp_url) {
      return response.data
    } else {
      return thunkAPI.rejectWithValue({
        description: 'iii',
        status: '777',
        language
      })
    }
  } catch (error) {
    if (utils.isAxiosError<ServerError>(error) && error.response) {
      return thunkAPI.rejectWithValue({ ...error?.response?.data, language })
    } else {
      return thunkAPI.rejectWithValue({
        description: 'iii',
        status: '777',
        language
      })
    }
  }
})

// SIGN UP API LC ACCOUNT HANDLER
export const fetchSignUpApiLkHandler = (builder: ActionReducerMapBuilder<ApiLkAuthState>) => {
  builder
    // pending
    .addCase(fetchSignUpApiLk.pending, state => {
      state.createStatus = Status.LOADING
    })

    // fulfilled
    .addCase(fetchSignUpApiLk.fulfilled, (state, action) => {
      state.createStatus = Status.SUCCESS
      state.otp_url = action.payload.otp_url
      state.isCreated = true
    })

    // rejected
    .addCase(fetchSignUpApiLk.rejected, (state, action) => {
      state.createStatus = Status.ERROR
      if (action.payload?.description) {
        state.authMessage = utils.apiLcErrorHandler(action.payload?.description, action.payload.language)
      }
    })
}

// ----------------------------------------------------------------

// LOGOUT API LC ACCOUNT
export const fetchLogoutApiLk = createAsyncThunk('ApiLk/fetchLogoutApiLk', async () => {
  try {
    const res = await apiLkService.apiLkLogout()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

// LOGOUT API LC ACCOUNT HANDLER
export const fetchLogoutApiLkHandler = (builder: any) => {
  builder.addCase(fetchLogoutApiLk.pending, (state: ApiLkAuthState, action: PayloadAction<any>) => {})
  builder.addCase(fetchLogoutApiLk.fulfilled, (state: ApiLkAuthState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
      if (action.payload?.msg === 'invalid email') {
        // state.authMessage = ErrorText[action.payload?.language].InvalidEmail
      }
    } else {
      state.rToken = ''
      state.xToken = ''
    }
    state.isAuthApi = false

    cookies.remove(cookiesName.ACCESS_TOKEN_LK_API)
    cookies.remove(cookiesName.REFRESH_TOKEN_LK_API)
  })
  builder.addCase(fetchLogoutApiLk.rejected, (state: any, action: PayloadAction<any>) => {})
}

// ----------------------------------------------------------------

// REFRESH JWT TOKEN IN API LC
export const fetchRefreshApiLkJWTTokens = createAsyncThunk<JWTTokens, undefined, { rejectValue: IValidationErrors; state: RootState }>('ApiLk/fetchRefreshApiLkJWTTokens', async (_, thunkAPI) => {
  const { language } = thunkAPI.getState().main

  try {
    const response = await apiLkService.refreshApiLkJWTTokens()

    if (response.data.access_token && response.data.refresh_token) {
      return response.data
    } else {
      thunkAPI.dispatch(fetchLogoutApiLk)

      return thunkAPI.rejectWithValue({
        description: 'invalid jwt token',
        status: '409',
        language
      })
    }
  } catch (error) {
    thunkAPI.dispatch(fetchLogoutApiLk)

    return thunkAPI.rejectWithValue({
      description: 'invalid jwt token',
      status: '409',
      language
    })
  }
})

// REFRESH JWT TOKEN IN API LC HANDLER
export const fetchRefreshLkTokenHandler = (builder: ActionReducerMapBuilder<ApiLkAuthState>) => {
  builder
    // pending
    .addCase(fetchRefreshApiLkJWTTokens.pending, (state, action) => {
      state.jwtStatus = Status.LOADING
    })

    // fulfilled
    .addCase(fetchRefreshApiLkJWTTokens.fulfilled, (state, action) => {
      state.xToken = action.payload?.access_token
      state.rToken = action.payload?.refresh_token
      cookies.set(cookiesName.ACCESS_TOKEN_LK_API, action.payload?.access_token)
      cookies.set(cookiesName.REFRESH_TOKEN_LK_API, action.payload?.refresh_token)
    })

    // rejected
    .addCase(fetchRefreshApiLkJWTTokens.rejected, (state, action) => {
      state.jwtStatus = Status.ERROR
      state.isAuthApi = false

      if (action.payload?.description) {
        state.authMessage = utils.apiLcErrorHandler(action.payload?.description, action.payload.language)
      }
    })
}

// ----------------------------------------------------------------
