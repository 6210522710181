import React, { FormEvent, useCallback, useEffect } from 'react'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import UserCard from '../../components/UserCard'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput, { namesType } from '../../hooks/useInput'
import { fetchChangePassword, fetchUserCards } from '../../store/actions/userActions'
import { Link } from 'react-router-dom'
import { path } from '../../utils/consts'
import { setBidStep } from '../../store/reducers/bidReducer'
import { IUserCard } from '../../utils/interfaces'
import useTimerDown from '../../hooks/useTimer'

const Profile = () => {
  const { email, isChangedPassword, change_password_mesage, userCards } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()
  const { timeLeft } = useTimerDown(15)
  const emailInp = useInput('')
  const oldPasswordInp = useInput('')
  const newPasswordInp = useInput('')
  const compareNewPassword = useInput('', newPasswordInp.value)

  const { profileTexts } = useGetText()

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(fetchUserCards())
    }
  }, [timeLeft])

  useEffect(() => {
    dispatch(fetchUserCards())
  }, [])

  useEffect(() => {
    emailInp.setValue(email)
  }, [email])

  const changePasswordSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!oldPasswordInp.isValid || !oldPasswordInp.isValid || !oldPasswordInp.isValid) return

      dispatch(
        fetchChangePassword({
          new_password: newPasswordInp.value,
          old_password: oldPasswordInp.value,
          email: emailInp.value
        })
      )
    },
    [oldPasswordInp, newPasswordInp, compareNewPassword]
  )

  return (
    <div className='container profile pb-5'>
      <h1>{profileTexts.PROFILE_HEADING}</h1>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='profile-card h-100'>
            {/*<div className='profile-card__body'>*/}
            {/*  <h2>{profileTexts.CARD_VERIFICATION_HEADING}</h2>*/}
            {/*  {!userCards?.length ? (*/}
            {/*    <div>Карт нет</div>*/}
            {/*  ) : (*/}
            {/*    <>*/}
            {/*      <ul className='user-card__list'>*/}
            {/*        {userCards?.map((card: IUserCard) => (*/}
            {/*          <li key={card.id} className='user-card__item'>*/}
            {/*            <UserCard number={card.number} status={card.status} />*/}
            {/*          </li>*/}
            {/*        ))}*/}
            {/*      </ul>*/}
            {/*      /!*<Link className='user-card__button' onClick={() => dispatch(setBidStep(0))} to={path.NEW_CARD}>*!/*/}
            {/*      /!*  <Button variant='primary'>Верифицировать новую карту</Button>*!/*/}
            {/*      /!*</Link>*!/*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='profile-card'>
            <h2>{profileTexts.PROFILE_INFO_HEADING}</h2>
            <form onSubmit={changePasswordSubmitHandler} className='row'>
              <div className='col-xl-7 col-lg-12'>
                <div>
                  <CustomInput readOnly label={profileTexts.PROFILE_INPUTS.EMAIL} inputHandlers={emailInp} />
                  <CustomInput type='password' required label={profileTexts.PROFILE_INPUTS.OLD_PASSWORD} inputHandlers={oldPasswordInp} />
                  <CustomInput type='password' required name={namesType.PASSWORD} label={profileTexts.PROFILE_INPUTS.NEW_PASSWORD} inputHandlers={newPasswordInp} />
                  <CustomInput type='password' required name={namesType.COMPARE_PASSWORD} label={profileTexts.PROFILE_INPUTS.COMPARE_NEW_PASSWORD} inputHandlers={compareNewPassword} />
                </div>
              </div>
              <div className='col-xl-5 col-lg-12'>
                <div className='h-100 d-flex flex-column justify-content-end mt-xl-0 mt-3'>
                  <Button variant='primary'>{isChangedPassword ? 'Loading...' : profileTexts.CHANGE_PASSWORD_BTN}</Button>
                </div>
              </div>
              {change_password_mesage && <p className='t3 text-center mt-4'>{change_password_mesage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
