import { useCallback, useMemo, createContext, useReducer, useContext, useEffect, useRef, memo } from 'react'
import { createPortal } from 'react-dom'

export const NotifyContext = createContext<any>({} as any)

const initialState: any = []

export const ADD = 'ADD'
export const REMOVE = 'REMOVE'
export const REMOVE_ALL = 'REMOVE_ALL'

export const notifyReducer = (state: any, action: any) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          content: action.payload.content,
          type: action.payload.type,
          delay: action.payload.delay || 5000
        }
      ]
    case REMOVE:
      return state.filter((t: any) => t.id !== action.payload.id)
    case REMOVE_ALL:
      return initialState
    default:
      return state
  }
}

export const NotifyProvider = memo((props: any) => {
  const { Component } = props
  const notifyRef = useRef<HTMLElement | null>(null)
  const [notifications, dispatchNotify] = useReducer(notifyReducer, initialState)
  const toastData = useMemo(
    () => ({
      notifications,
      notify: (rest: any, delay = 0) => {
        dispatchNotify({ type: ADD, payload: rest })
      }
    }),
    [notifications]
  )
  const onCloseHandle = useCallback((rest: any) => dispatchNotify({ type: REMOVE, payload: { id: rest } }), [])
  useEffect(() => {
    notifyRef.current = document.body
  }, [])

  return (
    <NotifyContext.Provider value={toastData}>
      {props.children}
      {notifyRef?.current && createPortal(<Component onClose={onCloseHandle} notifications={notifications} />, notifyRef.current)}
    </NotifyContext.Provider>
  )
})

export const useNotification = () => {
  return useContext(NotifyContext)
}
