import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../hooks/reduxHooks'
import useGetText from '../hooks/useGetText'
import dayjs from 'dayjs'
import QRCode from 'react-qr-code'
import useDocumentWidth from '../hooks/useDocumentWidth'
import Button from './buttons/button'
import validation from '../utils/validation'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useNotification} from '../hooks/useNotify'
import {useNavigate} from 'react-router-dom'
import {setHashID} from '../store/reducers/tradeReducer'
import {path} from '../utils/consts'
import {fetchDeleteBid, fetchUpdateBid} from '../store/actions/bidActions'
import {DirectionInterface} from '../utils/interfaces'
import utils from '../utils/utils'
import {setBidStep} from '../store/reducers/bidReducer'
import useConditionDirection from '../hooks/useConditionDirection'
import {CopyIcon} from './icons'
import Preloader from './Preloader'

interface BidInfoProps {}

const BidInfo: FC<BidInfoProps> = () => {
  const {
    id,
    client_email,
    created_at,
    payment_details_get,
    payment_details_give,
    status_code,
    sum_get,
    sum_give,
    currency_get,
    currency_give,
    dest_tag,
    bid_processing_message
  } = useAppSelector(state => state.bid)

  const { directions } = useAppSelector(state => state.trade)
  const { language } = useAppSelector(state => state.main)
  const { statusName, exchangeText } = useGetText()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { lg }: any = useDocumentWidth()

  const date = useMemo(() => dayjs(created_at).add(-3, 'hour').format('DD.MM.YYYY  HH:mm:ss'), [created_at])

  const wallet = useMemo(() => (payment_details_get === undefined ? ''.split(',') : payment_details_get?.split(',')), [payment_details_get])

  const condition = useConditionDirection(currency_get, currency_give)

  const [warningCondition, setWarningCondition] = useState(false)

  const directionsDate: {
    from: DirectionInterface
    to: DirectionInterface
  } = useMemo(() => {
    return {
      from: directions?.filter((el: DirectionInterface) => el?.code === currency_get)[0] || '',
      to: directions?.filter((el: DirectionInterface) => el?.code === currency_give)[0] || ''
    }
  }, [directions, id])

  const { notify } = useNotification()

  const deleteBid = useCallback(() => {
    navigate(path.MAIN)
    dispatch(fetchDeleteBid())
    dispatch(setHashID(''))
  }, [])

  useEffect(() => {
    if (directions.length && currency_give.length && currency_get.length) {
      if (currency_give === 'SBPRUB')
        return
      if (
        directions.filter((item: DirectionInterface) => item.code === currency_give)[0].category === 'crypto' &&
        directions.filter((item: DirectionInterface) => item.code === currency_get)[0].category === 'bank_rub'
      ) {
        setWarningCondition(true)
      }
    }
  }, [directions, currency_give, currency_get])

  return (
    <>
      <div className='container mb-5 pb-5 animate__animated animate__fadeInLeft wow' data-wow-duration='.4s'>
        {bid_processing_message === 'error' ? (
          <h2>Произошла ошибка, попробуйте создать заявку заново</h2>
        ) : bid_processing_message === 'loading' ? (
          <Preloader />
        ) : (
          <div className='row bid-info'>
            <div className='col-lg-7'>
              <h3>{exchangeText?.EXCHANGE_DATA}</h3>
              {warningCondition && <p className='warningMessage'>{exchangeText?.EXCHANGE_WARNING}</p>}

              {lg && !condition.fromBankRub && (
                <>
                  <h2>{exchangeText.QR_CODE}</h2>
                  <div className='d-flex mt-3'>
                    <QRCode fgColor='#002674' value={wallet[0]} size={148} />
                  </div>
                </>
              )}

              <div className='d-flex flex-wrap mt-3 g-g-10'>
                {payment_details_get?.includes('https://') || payment_details_get?.includes('http://') ? (
                  wallet.map((el, idx) => (
                    <a target={'_blank'} key={idx} href={el} className={`btn btn-primary`} rel='noreferrer'>
                      {exchangeText.BTN_PAYOUT_BID}
                    </a>
                  ))
                ) : (
                  <div className='requsits'>
                    {validation.card(wallet[0]) ? (
                      <>
                        {status_code !== 'expired' && (
                          <>
                            <p className='mb-0'>{exchangeText.PAY_WALLET}</p>

                            <p>
                              <strong>{validation.cardReplacer(wallet[0])}</strong>
                            </p>
                          </>
                        )}
                        {status_code === 'expired' && (
                          <p style={{ marginTop: '20px', maxWidth: '500px', wordBreak: 'normal' }}>
                            <strong style={{ whiteSpace: 'break-spaces' }}>{exchangeText.EXPIRED_WARNING}</strong>
                          </p>
                        )}
                      </>
                    ) : (
                      <CopyToClipboard
                        text={wallet[0]}
                        onCopy={() =>
                          notify({
                            type: 'success',
                            content: () => exchangeText.COPIED_LINK
                          })
                        }
                      >
                        <button className='p-0 btn text-start'>
                          <p className='mb-0'>{exchangeText.PAY_WALLET}</p>
                          <p className='mb-0'>
                            <strong>{wallet[0]}</strong> <CopyIcon isBool={true} />
                          </p>
                        </button>
                      </CopyToClipboard>
                    )}
                  </div>
                )}
              </div>
              {condition.hasMemoTag && (
                <>
                  <p className='mt-2 mb-0'>Dest Tag</p>
                  <p>
                    <strong>{dest_tag ? dest_tag : <img src='/images/icons/spinner.svg' alt='spinner' width={64} />}</strong>
                  </p>
                </>
              )}

              <h3>{exchangeText.PAYOUT_BID}</h3>
              <p>
                <strong>{exchangeText.SUM_FROM}</strong> {sum_get} {directionsDate.from[language === 'en' ? 'en_name' : 'name']}
              </p>
              <p>
                <strong>{exchangeText.SUM_TO}</strong> {utils.formatNumber(+sum_give)} {directionsDate.to[language === 'en' ? 'en_name' : 'name']}
              </p>
            </div>
            <div className='col-lg-5'>
              <div className=''>
                <h3>{exchangeText.CHECK_DATA}</h3>
                <div className=' mt-3'>
                  {/* <p>
                    <strong>Email:</strong> {client_email}{' '}
                  </p> */}
                  <p>
                    <strong>{exchangeText.CREATE_TIME}: </strong> {date}
                  </p>
                  <p
                    style={{
                      wordBreak: 'break-all'
                    }}
                  >
                    <strong>{exchangeText.PAYMENT_DETILS_GIVE}: </strong>
                    {validation.card(payment_details_give) ? (
                      validation.cardReplacer(payment_details_give)
                    ) : (
                      <>
                        <br />
                        {payment_details_give}
                      </>
                    )}
                  </p>
                  <p>
                    <strong>{exchangeText.ORDER_STATUS}: </strong> {statusName[status_code]}
                  </p>
                </div>
                <small className=' mt-4 d-block'>{exchangeText.BID_DESCRIPTION}</small>
                <div className='row mt-5'>
                  <div className='col-lg-6'>
                    {/* <Button variant='outlined' className='w-100' onClick={deleteBid}>{exchangeText.BTN_CANCEL_BID}</Button> */}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant='primary' onClick={() => dispatch(fetchUpdateBid())}>
                      {exchangeText.BTN_I_PAID}
                    </Button>{' '}
                    <Button variant='primary' onClick={() => dispatch(setBidStep(3))}>
                      {exchangeText.BTN_STATUS_BID}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BidInfo
