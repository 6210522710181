import React, { useEffect } from 'react'
import { useAppDispatch } from '../../hooks/reduxHooks'
import useTimerDown from '../../hooks/useTimer'
import { fetchGetCourse } from '../../store/actions/tradeActions'

function TradeTimer() {
  const seconds = 30 //секунды для обновления курса
  const { timeLeft } = useTimerDown(seconds)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(fetchGetCourse())
    }
  }, [timeLeft])
  return (
    <div className='trade-timer'>
      <span>{timeLeft}</span>
      <svg
        className='spinner'
        width={45}
        height={45}
        viewBox='0 0 50 50'
        style={{
          strokeDasharray: `${(timeLeft / seconds) * 125}, 150`,
          strokeDashoffset: 0,
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
          transition: 'all 1s linear'
        }}
      >
        <circle className='path' stroke='rgb(85, 145, 215)' cx='25' cy='25' r='20' fill='none' strokeWidth='4' />
      </svg>
    </div>
  )
}

export default TradeTimer
