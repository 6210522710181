import React, { FC, useMemo } from 'react'
import { Category, CodeCategory, memoCurrencies } from '../utils/consts'
import { t } from 'chart.js/dist/chunks/helpers.core'

const useConditionDirection = (from: string, to: string) => {
  const condition = useMemo(() => {
    // проверка на нал
    const cash: boolean = (CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.CASH) || (CodeCategory[from] === Category.CASH && CodeCategory[to] === Category.CRYPTO)

    const cryptoToCash = CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.CASH
    const cashToCrypto = CodeCategory[from] === Category.CASH && CodeCategory[to] === Category.CRYPTO
    const cryptoToCrypto = CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.CRYPTO
    const toCash = CodeCategory[to] === Category.CASH

    // проверка крипта на банки
    const cryptoToBank = CodeCategory[from] === Category.CRYPTO && (CodeCategory[to] === Category.BANK_RUB || CodeCategory[to] === Category.BANK_UK)

    // проверка банк на крипту
    const bankToCrypto = (CodeCategory[from] === Category.BANK_RUB || CodeCategory[from] === Category.BANK_UK) && CodeCategory[to] === Category.CRYPTO

    const fromPaymentSystem = CodeCategory[from] === Category.PAYMENT_SYSTEM
    const fromBankRub = CodeCategory[from] === Category.BANK_RUB

    // проверка крипта на платежные системы
    const cryptoToPaymentSystem = CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.PAYMENT_SYSTEM

    // проверка банк россии на банк украины и обратно
    const BankRUBToBankUKAndReverse = (CodeCategory[from] === Category.BANK_RUB && CodeCategory[to] === Category.BANK_UK) || (CodeCategory[from] === Category.BANK_UK && CodeCategory[to] === Category.BANK_RUB)

    const BankUKToPaymentSystem = CodeCategory[from] === Category.BANK_UK && CodeCategory[to] === Category.PAYMENT_SYSTEM
    const PaymentSystemToBankUK = CodeCategory[from] === Category.PAYMENT_SYSTEM && CodeCategory[to] === Category.BANK_UK
    // проверка платежные системы на крипту
    const paymentSystemToCrypto = CodeCategory[from] === Category.PAYMENT_SYSTEM && CodeCategory[to] === Category.CRYPTO

    const toCrypto = CodeCategory[to] === Category.CRYPTO
    const fromCrypto = CodeCategory[from] === Category.CRYPTO

    // проверка на мемо-тег
    const needsMemoTag = (CodeCategory[from] === Category.BANK_RUB || CodeCategory[from] === Category.PAYMENT_SYSTEM) && memoCurrencies.includes(to)
    const hasMemoTag = (CodeCategory[to] === Category.BANK_RUB || CodeCategory[to] === Category.PAYMENT_SYSTEM) && memoCurrencies.includes(from)

    return {
      cash,
      cryptoToBank,
      cryptoToPaymentSystem,
      bankToCrypto,
      BankRUBToBankUKAndReverse,
      paymentSystemToCrypto,
      cryptoToCash,
      cashToCrypto,
      toCash,
      toCrypto,
      fromCrypto,
      fromPaymentSystem,
      fromBankRub,
      BankUKToPaymentSystem,
      PaymentSystemToBankUK,
      cryptoToCrypto,
      needsMemoTag,
      hasMemoTag
    }
  }, [from, to])

  return condition
}

export default useConditionDirection
