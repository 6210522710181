import React, { FormEvent } from 'react'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import PayoutLayout from '../../features/layouts/payout-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput from '../../hooks/useInput'
import { fetchMakePayout } from '../../store/actions/userActions'

const PayoutPage = () => {
  const { makePayoutSended } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()

  const amountInp = useInput()
  const walletInp = useInput()

  const { profileTexts } = useGetText()

  function submitPayoutHandler(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    dispatch(
      fetchMakePayout({
        amount: Number(amountInp.value),
        address: walletInp.value,
        type: 'USDTTRC'
      })
    )
  }
  return (
    <PayoutLayout>
      <h3>{profileTexts.HEADING_PAYOUT}</h3>
      <form className='' onSubmit={submitPayoutHandler}>
        <div className='row'>
          <div className='col-lg-6 mt-3'>
            <CustomInput variant='main' required label={profileTexts.INPUTS.AMOUNT.label} placeholder={profileTexts.INPUTS.AMOUNT.placeholder} inputHandlers={amountInp} />
          </div>
          <div className='col-lg-6 mt-3'>
            <CustomInput variant='main' required label={profileTexts.INPUTS.WALLET.label} placeholder={profileTexts.INPUTS.WALLET.placeholder} inputHandlers={walletInp} />
          </div>
        </div>

        {makePayoutSended?.message && <p className='mb-0 mt-3'>{makePayoutSended?.message}</p>}

        <Button variant='primary' className='mt-3'>
          {profileTexts.WITHDRAW_FUNDS}
        </Button>
      </form>
    </PayoutLayout>
  )
}

export default PayoutPage
