import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Button from '../../../components/buttons/button'
import { CopyIcon } from '../../../components/icons'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import useGetText from '../../../hooks/useGetText'
import { fetchKeys, fetchPatchKeys } from '../../../store/actions/apiLkActions'
import { PasswordIcon } from '../../../components/icons'
import { ModalMode, setModalContentType } from '../../../store/reducers/modalReducer'
import { ModalContentTypes } from '../../../components/Modal/defineModalEl'

const ApiKeys = () => {
  const { apiKeys } = useAppSelector(state => state.apiLk)
  const { rToken, xToken } = useAppSelector(state => state.apiLkAuth)
  const [copyPrivatKey, setCopyPrivatKey] = useState(false)
  const [copyPublicKey, setCopyPublicKey] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const dispatch = useAppDispatch()
  const { apiGeneral } = useGetText()

  useEffect(() => {
    if (copyPrivatKey) {
      setTimeout(() => setCopyPrivatKey(false), 3000)
    }
    if (copyPublicKey) {
      setTimeout(() => setCopyPublicKey(false), 3000)
    }
  }, [copyPrivatKey, copyPublicKey])

  useEffect(() => {
    dispatch(fetchKeys())
  }, [xToken])

  const onClickUpdateApiKey = () => {
    dispatch(
      setModalContentType({
        modalContent: ModalContentTypes.UPDATE_API_LK_KEYS,
        mode: ModalMode.DEFAULT
      })
    )
  }

  return (
    <div className='container api-user mt-5'>
      <div className='row justify-content-center'>
        <div className='col-xl-8 col-lg-10 col-md-11 col-12'>
          <div className='card px-0'>
            <div className='px-3 d-flex align-items-center justify-content-between'>
              <h3>{apiGeneral.API_KEYS}</h3>
              <Button onClick={onClickUpdateApiKey} variant='primary' className='px-5 py-3' style={{ background: ' #3B82F6' }}>
                {apiGeneral.UPDATE_KEYS}
              </Button>
            </div>
            <table className='table api-table'>
              <thead>
                <tr>
                  <th>Public Key</th>
                  <th>Private Key</th>
                  <th>{apiGeneral.ACTION}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <input className='form-control me-2' value={apiKeys?.public_key} />
                      <CopyToClipboard onCopy={() => setCopyPublicKey(true)} text={apiKeys?.public_key}>
                        <button className='btn p-0'>
                          <CopyIcon isBool={!copyPublicKey} />
                        </button>
                      </CopyToClipboard>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <input type={!isVisible ? 'password' : 'text'} className='form-control me-2' value={apiKeys?.private_key} />
                      <CopyToClipboard onCopy={() => setCopyPrivatKey(true)} text={apiKeys?.private_key}>
                        <button className='btn p-0'>
                          <CopyIcon isBool={!copyPrivatKey} />
                        </button>
                      </CopyToClipboard>
                    </div>
                  </td>
                  <td className='text-center'>
                    <button type='button' className='btn p-0 btn-password' onClick={() => setVisible(!isVisible)}>
                      <PasswordIcon isBool={isVisible} />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiKeys
