import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../components/buttons/button'
import { TelegramIcon } from '../components/icons'
import CustomInput from '../components/inputs/custom-input'
import useGetText from '../hooks/useGetText'
import useInput, { namesType } from '../hooks/useInput'
import { otherLinks, path } from '../utils/consts'

const AboutUs = () => {
  const { aboutUsTexts } = useGetText()

  const nameInp = useInput('')
  const emailInp = useInput('')

  return (
    <div className='container about-us mb-5'>
      <h1>{aboutUsTexts.TITLE}</h1>
      <div className='row justify-content-between'>
        {aboutUsTexts.LISTS.map(
          (
            el: {
              title: string
              description: string
            },
            idx: number
          ) => (
            <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-4' key={idx}>
              <div className='card h-100'>
                <div className='heading'>
                  <h5>{el.title}</h5>
                </div>
                <div className='desc'>
                  <p>{el.description}</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className='row mt-5 align-items-center'>
        <div className='col-lg-6'>
          <div className='card p-4'>
            <div className='mb-3'>
              <a href={otherLinks.CASHEX_TELEGRAM_LINK} target='_blank' rel="noreferrer">
                <TelegramIcon fill='#002674' />
              </a>
            </div>
            <p>
              {aboutUsTexts.TG_TEXT}.
              <a href={otherLinks.CASHEX_TELEGRAM_LINK} target='_blank' rel="noreferrer">
                {aboutUsTexts.MORE}
              </a>
            </p>
            <div className='d-flex justify-content-end '>
              <img src='/images/static/cat_1.png' width={241} height={180} alt='' />
            </div>
          </div>
        </div>
        <div className='col-lg-6 d-lg-block d-none'>
          <div>
            <img src='/images/static/Startup_Flatline.svg' className='w-100 ' alt=''/>
          </div>
        </div>
      </div>

      <form className='row mt-5'>
        <h3 className='col-12'>
          <strong>{aboutUsTexts.CONTACTS_US}</strong>
        </h3>

        <div className='col-lg-6'>
          <div>
            <CustomInput inputHandlers={nameInp} name={namesType.NAME} label={aboutUsTexts.INPUTS.NAME.label} placeholder={aboutUsTexts.INPUTS.NAME.placeholder} />
            <CustomInput inputHandlers={emailInp} name={namesType.EMAIL} label={aboutUsTexts.INPUTS.EMAIL.label} placeholder={aboutUsTexts.INPUTS.EMAIL.placeholder} />
          </div>
        </div>
        <div className='col-lg-6'>
          <div>
            <div>
              <label htmlFor=''>{aboutUsTexts.INPUTS.TEXTS.label}</label>
              <textarea placeholder={aboutUsTexts.INPUTS.TEXTS.placeholder} name='' id='' cols={32} className='form-control' required></textarea>
            </div>
            <Button variant='primary' className='w-100 mt-3 py-3'>
              {aboutUsTexts.INPUTS.BTN}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AboutUs
