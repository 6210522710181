import React, {FC} from 'react';
import CustomInput from "../../../components/inputs/custom-input";
import useInput, {namesType} from "../../../hooks/useInput";
import {IBidInputsProps} from "./CashToCryptoInputs";
import RequisitesBlock from "../RequisitesBlock";

interface IPaymentSystemToCryptoInputs extends IBidInputsProps {
  qiwiInput: any,
  emailInp: any,
  walletInput: any
}

const PaymentSystemToCryptoInputs: FC<IPaymentSystemToCryptoInputs> = ({mainPageTexts, qiwiInput, walletInput, emailInp}) => {

  return (
    <>
      <div className='col-md-6'>
        <RequisitesBlock mainPageTexts={mainPageTexts}>
          <div className={`mt-2`}>
            <CustomInput
              required={true}
              maxLength={18}
              variant='main'
              label={mainPageTexts.INPUTS.QIWI_WALLET}
              placeholder={mainPageTexts.INPUTS.QIWI_WALLET}
              name={namesType.PHONE}
              inputHandlers={qiwiInput}
            />
          </div>
          <div className={'mt-2'}>
            <CustomInput
              name={namesType.WALLET}
              variant='main'
              inputHandlers={walletInput}
              label={mainPageTexts.INPUTS.WALLET}
              placeholder='3Uhdu9DJ7nd08DjdJD9Dns8hdn98DNu8adb'
              autoComplete={'on'}
            />
          </div>
        </RequisitesBlock>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='form-heading'></div>
          <div className='mt-2 email-input'>
            <CustomInput
              required={true}
              variant='main'
              placeholder='example@gmail.com'
              label='Email*'
              name='email'
              inputHandlers={emailInp}
              autoComplete={'on'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSystemToCryptoInputs;