import React from 'react'
import dayjs from 'dayjs'
import Button from '../../../components/buttons/button'
import ApiLkLayout from '../../../features/layouts/api-lk-layout'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { fetchAllTokens, fetchSwaps } from '../../../store/actions/apiLkActions'
import { setSelectedModal } from '../../../store/reducers/apiLkReducer'
import { ModalId } from '../../../utils/consts'
import { AuthLoader } from '../../../components/loaders/loaders'

const ApiExchange: React.FC = () => {
  const dispatch = useAppDispatch()

  const { xToken, rToken } = useAppSelector(state => state.apiLkAuth)
  const { swaps, isLoadingTransactions } = useAppSelector(state => state.apiLk)

  React.useEffect(() => {
    dispatch(fetchAllTokens())
  }, [xToken, rToken])

  React.useEffect(() => {
    dispatch(fetchSwaps())
  }, [])

  const formatDate = React.useCallback((date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'), [swaps])

  return (
    <ApiLkLayout>
      <div className='container api-user pt-5 transactions'>
        <div className='card px-0'>
          <div className='px-3 d-flex align-items-center justify-content-between'>
            <h3 className='px-3'>Обмен</h3>
            <Button variant='primary' type='button' onClick={() => dispatch(setSelectedModal(ModalId.MODAL_LK_EXCHANGE))} dataToggle='modal' dataTarget={`#${ModalId.MODAL_LK}`} className='px-5 py-3'>
              Обменяться
            </Button>
          </div>
          <div className='overflow-auto'>
            <table className='table api-table'>
              <thead>
                <tr>
                  {/* <th style={{ minWidth: '120px' }}>ID</th> */}
                  <th style={{ minWidth: '120px' }}>Валюту отдаю</th>
                  <th style={{ minWidth: '120px' }}>Валюту получаю</th>
                  <th style={{ minWidth: '190px' }}>Сумму отдаю</th>
                  <th style={{ minWidth: '200px' }}>Сумму получаю</th>
                  <th style={{ minWidth: '190px' }}>Курс обмена</th>
                  <th style={{ minWidth: '190px' }}>Дата</th>
                  <th style={{ minWidth: '120px' }}>Статус</th>
                </tr>
              </thead>
              <tbody>
                {swaps?.map((el, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <p>{el?.token_from}</p>
                      </td>
                      <td>
                        <p>{el?.token_to}</p>
                      </td>
                      <td>
                        <p>{el?.amount_from}</p>
                      </td>
                      <td>
                        <p>{el?.amount_to}</p>
                      </td>
                      <td>
                        <p>{el?.exchange_rate}</p>
                      </td>
                      <td>
                        <p>{formatDate(el?.date_create)}</p>
                      </td>
                      <td>
                        <p>{el.status}</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {isLoadingTransactions ? (
              <div className='d-flex justify-content-center'>
                <AuthLoader />
              </div>
            ) : (
              <>{!swaps && <h5 className='px-3 text-center'>Обмены отсуствуют</h5>}</>
            )}
          </div>
        </div>
      </div>
    </ApiLkLayout>
  )
}

export default ApiExchange
