import React from 'react'
import Button from '../../components/buttons/button'
import { AuthLoader } from '../../components/loaders/loaders'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { setSelectedToken } from '../../store/reducers/apiLkReducer'

const Tokens = () => {
  const { tokens, selectedToken, isLoadingTokens } = useAppSelector(state => state.apiLk)
  const dispatch = useAppDispatch()

  if (isLoadingTokens) {
    return (
      <div className='card'>
        <div className='d-flex justify-content-center'>
          <AuthLoader />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='card tokens p-2'>
        {tokens?.map(el => (
          <Button
            key={el.token}
            onClick={() => {
              dispatch(setSelectedToken(el))
            }}
            className={`${el.token === selectedToken?.token ? 'active' : ''}`}
          >
            <img src='' alt='' /> <img src={`/images/currencies/${el.token}.svg`} width={64} height={64} alt='' />
          </Button>
        ))}
      </div>
    </div>
  )
}

export default Tokens
