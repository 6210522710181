import React, {FC} from 'react';
import {namesType} from "../../../hooks/useInput";
import CustomInput from "../../../components/inputs/custom-input";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import {setCity} from "../../../store/reducers/tradeReducer";
import RequisitesBlock from "../RequisitesBlock";

export interface IBidInputsProps {
  mainPageTexts: any
}

interface CashInputs extends IBidInputsProps {
  tgInput: any
  phoneInput: any
  emailInp: any
}


const CashToCryptoInputs: FC<CashInputs> = ({
                                              mainPageTexts,
                                              tgInput,
                                              phoneInput,
                                              emailInp
                                            }) => {

  const {filteredCity, city} = useAppSelector(state => state.trade)
  const {language} = useAppSelector(state => state.main)

  const dispatch = useAppDispatch()

  return (
    <>
      <div className='col-md-6'>
        <RequisitesBlock mainPageTexts={mainPageTexts}>
          <div className='mt-2 inp-wrapper'>
            <label htmlFor=''>{mainPageTexts.INPUTS.SELECT_CITY}</label>
            <select value={city} onChange={({target}) => dispatch(setCity(target.value))}
                    className='form-select input-main'>
              {filteredCity?.map((el: any) => (
                <option value={el.code} key={el.code}>
                  {language === 'ru' ? el?.name_rus : el?.name_en}
                </option>
              ))}
            </select>
            <span className='btn btn-password'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'>
                    <path d='M7 12L0.937822 0H13.0622L7 12Z' fill='#CED4DA'/>
                  </svg>
                </span>
          </div>
        </RequisitesBlock>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='form-heading'></div>
          <div className='mt-2'>
            <CustomInput
              required={true}
              placeholder='@tg_user'
              variant='main'
              name={namesType.TELEGRAM}
              label='Telegram*'
              inputHandlers={tgInput}
            />
          </div>
          {/* <div className='mt-2'>
            <CustomInput
              placeholder='+7 (9**) ***-**-**'
              maxLength={18}
              variant='main'
              name={namesType.PHONE}
              label={mainPageTexts.INPUTS.PHONE}
              inputHandlers={phoneInput}
            />
          </div> */}
          {/* <div className='mt-2 email-input'>
            <CustomInput
              required={true}
              variant='main'
              placeholder='example@gmail.com'
              label='Email*'
              name='email'
              inputHandlers={emailInp}
              autoComplete={'on'}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CashToCryptoInputs;