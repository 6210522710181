import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BidInfo from '../components/bid-info'
import Button from '../components/buttons/button'
import CreateAutoText from '../components/create-auto-text'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useGetText from '../hooks/useGetText'
import { fetchDeleteBid, fetchGetBid, finishVerification } from '../store/actions/bidActions'
import { fetchDynamicText } from '../store/actions/mainActions'
import { setHashID } from '../store/reducers/tradeReducer'
import { path } from '../utils/consts'
import { DirectionInterface } from '../utils/interfaces'
import utils from '../utils/utils'
import useTimerDown from '../hooks/useTimer'
import Preloader from '../components/Preloader'

const CashBidPage = () => {
  const { id, sum_get, sum_give, currency_get, currency_give, client_email, created_at, city, course_get, course_give, bid_processing_message, hash } = useAppSelector(state => state.bid)
  const { directions } = useAppSelector(state => state.trade)
  const { language } = useAppSelector(state => state.main)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const { exchangeText } = useGetText()

  const navigate = useNavigate()

  const directionsDate: {
    from: DirectionInterface
    to: DirectionInterface
  } = useMemo(() => {
    return {
      from: directions?.filter((el: DirectionInterface) => el?.code === currency_get)[0] || '',
      to: directions?.filter((el: DirectionInterface) => el?.code === currency_give)[0] || ''
    }
  }, [directions, currency_get])

  const date = useMemo(() => dayjs(created_at).add(-3, 'hour').format('DD.MM.YYYY в HH:mm:ss'), [created_at])

  //   useEffect(() => {
  //     if(!isLoading) {
  //       dispatch(finishVerification(hash))
  //       setIsLoading(true)
  //     }

  //   }, [hash, isLoading])

  // useEffect(() => {
  //   // if(!Boolean(payment_details_get) === false && !Boolean(payment_details_give) === false &&  bid_processing_message === 'Success') {
  //   if (isLoading && bid_processing_message === 'Success' && timerBid === 0 && payment_details_get === 'null' && payment_details_give === 'null') {
  //     dispatch(fetchGetBid())
  //   }
  //
  // }, [bid_processing_message, timerBid, payment_details_get, payment_details_give])

  useEffect(() => {
    // получение хеша заявки

    let id, excTrc
    if (location.search.includes('excTrc=')) {
      id = location.search?.split('?id=')[1]?.split('&')[0]
      excTrc = location.search?.split('?id=')[1]?.split('&')[1]?.split('excTrc=').join('')
    } else {
      id = location.search?.split('?id=')[1]
      excTrc = ''
    }
    localStorage.setItem('bid_id', String(id))

    dispatch(fetchGetBid())
  }, [])

  useEffect(() => {
    dispatch(fetchDynamicText('after'))
  }, [currency_get, currency_give, city, language])

  return (
    <div className='container bid-info cash pb-5'>
      <h1>
        {exchangeText.HEADING} {id}
      </h1>

      <div className='row'>
        <div className='col-lg-6'>
          <div className='profile-card'>
            <p>
              <strong>{exchangeText.SUM_FROM}</strong> {utils.formatNumber(+sum_get)} {directionsDate.from.name}
            </p>
            <p>
              <strong>{exchangeText.SUM_TO}</strong> {utils.formatNumber(+sum_give)} {directionsDate.to.name}
            </p>
            {/* <p>
              <strong>Email:</strong> {client_email}{' '}
            </p> */}
            <p>
              <strong>{exchangeText.CREATE_TIME}</strong> {date}
            </p>
            <p>
              <strong>{exchangeText.CITY}</strong> {city[language]}
            </p>
          </div>
        </div>
        <div className='col-lg-6 mt-4'>
          <div className='h-100 d-flex flex-column justify-content-between align-items-center'>
            <div className='d-flex justify-content-center align-items-center '>
              <div className='d-flex flex-column aligin-items-center'>
                <img src={`/images/currencies/${directionsDate.from.code}.svg`} width={78} alt='' />
                <h3 className='text-center'>{course_get}</h3>
              </div>
              <div>
                <svg xmlns='http://www.w3.org/2000/svg' width='126' height='66' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                  <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' fill='#5591D7' />
                </svg>
              </div>
              <div className='d-flex flex-column aligin-items-center'>
                <img src={`/images/currencies/${directionsDate.to.code}.svg`} width={78} alt='' />
                <h3 className='text-center'>{course_give}</h3>
              </div>
            </div>
            <div>
              <Button
                variant='outlined'
                onClick={useCallback(() => {
                  navigate(path.MAIN)
                  dispatch(fetchDeleteBid())
                  dispatch(setHashID(''))
                }, [])}
              >
                {exchangeText.BTN_CANCEL_BID}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <CreateAutoText />
      </div>
    </div>
  )
}

export default CashBidPage
