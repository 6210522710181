import React, { useMemo } from 'react'
import { useAppSelector } from '../hooks/reduxHooks'
import useGetText from '../hooks/useGetText'
import Button from '../components/buttons/button'
import { path } from '../utils/consts'
import { useNavigate } from 'react-router-dom'

const Aml = () => {
  const { amlText } = useGetText()
  const navigate = useNavigate()
  return (
    <div className='container pb-5'>
      <h1>{amlText.HEADING1}</h1>
      <h2>{amlText.HEADING2}</h2>
      {amlText.LIST.map((el: string, idx: number) => (
        <p key={idx}>{el}</p>
      ))}
      <div className='button__center'>
        <Button onClick={() => navigate(path.PRIVACY_POLICY)} variant='primary'>
          {amlText.BUTTON}
        </Button>
      </div>
    </div>
  )
}

export default Aml
