import React, { FC, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { setFromValue, setToValue } from '../store/reducers/tradeReducer'
import { codesCurrency } from '../utils/consts'

interface MinMaxProps {
  type: string
}

const MinMax: FC<MinMaxProps> = ({ type }) => {
  const { course, from, to, courseLoader } = useAppSelector(state => state.trade)
  const isFrom = useMemo(() => type === 'from', [])

  const dispatch = useAppDispatch()

  const minClickHandler = useCallback(() => {
    if (isFrom) {
      dispatch(setFromValue(String(course.minFrom)))
    } else {
      dispatch(setToValue(String(course.minTo)))
    }
  }, [course])
  const maxClickHandler = useCallback(() => {
    if (isFrom) {
      dispatch(setFromValue(String(course.maxFrom)))
    } else {
      dispatch(setToValue(String(course.maxTo)))
    }
  }, [course])

  if (courseLoader) {
    return (
      <div className='min-max'>
        <small>loading...</small>
      </div>
    )
  }

  return (
    <div className='min-max'>
      {
        // !isFrom &&
        // <small>Курс:  {course.from} {codesCurrency[from]} = {course.to}  {codesCurrency[to]}</small>
      }
      <small>
        Min:{' '}
        <span onClick={minClickHandler}>
          {isFrom ? course.minFrom : course.minTo} {codesCurrency[isFrom ? from : to]}
        </span>
      </small>
      <small>
        Max:{' '}
        <span onClick={maxClickHandler}>
          {isFrom ? course.maxFrom : course.maxTo} {codesCurrency[isFrom ? from : to]}
        </span>
      </small>
    </div>
  )
}

export default MinMax
