export const admin: any = {
  ru: {
    CARDS: 'Карты'
  },
  en: {
    CARDS: 'Cards'
  },
  tu: {
    CARDS: 'Kartlar'
  }
}
