import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { fetchAutoExchangeTextHandler, fetchDynamicTextHandler, fetchStaticTextHandler, fetchStaticBalanceHandler, fetchSupportHandler, fetchTextsAlgorithmHandler } from '../actions/mainActions'

export interface MainState {
  language: string
  algorithmText: any
  autoExchangeText: any
  isNav: boolean

  isSentHelp: boolean

  dynamicTexts: any
  staticText: any
  staticBalance: any
  status: string
}

const initialState: MainState = {
  language: localStorage.getItem('language') || 'ru',
  algorithmText: [],
  autoExchangeText: [],
  isNav: false,

  isSentHelp: false,
  dynamicTexts: {},
  staticText: {},
  staticBalance: {},
  status: 'idle'
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setLang(state: any, action: PayloadAction<string>): void {
      state.language = action.payload
    },
    setNav(state: any, action: PayloadAction<boolean>): void {
      state.isNav = action.payload
    }
  },
  extraReducers: builder => {
    fetchTextsAlgorithmHandler(builder)
    fetchAutoExchangeTextHandler(builder)
    fetchSupportHandler(builder)
    fetchDynamicTextHandler(builder)
    fetchStaticTextHandler(builder)
    fetchStaticBalanceHandler(builder)
  }
})

export const { setLang, setNav } = mainSlice.actions

export default mainSlice.reducer
