import React, {FC} from 'react';
import CustomInput from "../../../components/inputs/custom-input";
import {IBidInputsProps} from "./CashToCryptoInputs";
import RequisitesBlock from "../RequisitesBlock";

interface ICryptoToRubBanks extends IBidInputsProps{
  cardInp : any,
  emailInp : any
}

const CryptoToRubBanks: FC<ICryptoToRubBanks> = ({mainPageTexts, emailInp, cardInp}) => {

  return (
    <>
      <div className='col-md-6'>
        <RequisitesBlock mainPageTexts={mainPageTexts}>
          <div className={`mt-2 card-input`}>
            <CustomInput
              required={false}
              variant='main'
              maxLength={19}
              placeholder={'4567 8726 2837 3847'}
              label={mainPageTexts.INPUTS.CARD_NUMBER}
              name='card'
              inputHandlers={cardInp}
            />
          </div>
        </RequisitesBlock>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='form-heading'></div>
          <div className='mt-2 email-input'>
            <CustomInput
              required={true}
              variant='main'
              placeholder='example@gmail.com'
              label='Email*'
              name='email'
              inputHandlers={emailInp}
              autoComplete={'on'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoToRubBanks;