import React, {FC, SetStateAction, useState} from 'react';
import styles from "./FastRegistrationModal.module.scss"
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepRestorePassword from "./StepRestorePassword";

export interface IFastRegistrationModalProps {
    emailInp: any,
    cardInp: any,
    walletInput: any,
    setIsFastRegistrationModalOpened: React.Dispatch<SetStateAction<boolean>>
}

const FastRegistrationModal: FC<IFastRegistrationModalProps> = ({
                                                                    emailInp,
                                                                    cardInp,
                                                                    walletInput,
                                                                    setIsFastRegistrationModalOpened
                                                                }) => {

    const [step, setStep] = useState(1)
    const [isUserExist, setIsUserExist] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const closeModal = () => {
        setIsFastRegistrationModalOpened(false)
    }




    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
                {
                    <>
                        {step !== 4 && <button className={styles.closeButton} onClick={closeModal}>X</button>}
                        {step === 1 && <StepOne
                            isLoading={isLoading}
                            emailInp={emailInp}
                            setStep={setStep}
                            setIsUserExist={setIsUserExist}
                            setIsLoading={setIsLoading}
                        />}
                        {step === 2 && <StepTwo
                            isUserExist={isUserExist}
                            emailInp={emailInp}
                            setStep={setStep}
                            cardInp={cardInp}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                        />}
                        {step === 3 && <StepThree
                            cardInp={cardInp}
                            isLoading={isLoading}
                            setStep={setStep}
                            setIsLoading={setIsLoading}

                        />}
                        {step === 4 && <StepFour
                            setIsFastRegistrationModalOpened={setIsFastRegistrationModalOpened}
                        />}
                        {step === 5 && <StepRestorePassword
                            emailInp={emailInp}
                            setStep={setStep}
                            setIsLoading={setIsLoading}
                        />}
                    </>
                }
            </div>
        </div>
    );
};

export default FastRegistrationModal;