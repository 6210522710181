import { Link } from 'react-router-dom'
import { path } from '../utils/consts'

export const mainPageText: any = {
  ru: {
    HEADING: 'Cashex.cc - обмен криптовалют',
    WORKS_TIME: 'На нашем сайте вы можете обменять криптовалюту по выгодным курсам. Техническая поддержка 24/7. Безопасные сделки',
    COURSE: 'Курс',

    LABEL_EXCHANGE_FROM: 'Я отправляю',
    LABEL_EXCHANGE_TO: 'Я получаю',

    SELECT_CURRENCY: 'Выберите валюту',
    NOT_FOUND_CURRENCY: 'Такой валюты не существует',
    SEARCH: 'Поиск',

    REQUISITES: 'Реквизиты',

    INPUTS: {
      CARD_NUMBER: 'Номер карты',
      NAME: 'Имя',
      SUR_NAME: 'Фамилия',
      NAME_SURNAME: 'Имя и Фамилия',
      PHONE: 'Номер телефона',
      FROM_CARD: 'С карты',
      QIWI_WALLET: 'Номер Qiwi кошелька',
      WALLET: 'Адрес кошелька получения',
      TO_CARD: 'На карту',
      SELECT_CITY: 'Выбрать город:',
      NAME_SERNAME: 'Имя и фамилия на карте',
      SELECT_SBP_BANK: 'Название банка получателя',
      MEMO_TAG: 'Мемо-тег'
    },

    TERMS_TEXT: 'Пожалуйста, перед тем как нажать кнопку “Обменять” удостоверьтесь что все введенные данные верны и актуальны, а вы ознакомились и согласились с правилами сервиса.',
    CHECKBOX_TERMS: (
      <>
        Нажимая на кнопку "Обменять" вы соглашаетесь с <Link to={path.TERMS}>правилами</Link> сервиса
      </>
    ),
    DONT_SAVE_DATA: 'Не запоминать введенные данные',

    CONTESTS_BAN: {
      HEADING_RIGHT: 'Обратите внимание на порядок выполнения обмена!',
      LIST: [
        'Заполните формы выше. Внимательно проверьте, что данные указаны верно.',
        'На почту, указанную в заявке, отправлено письмо с ссылкой на обмен. В ней есть вся информация о ходе выполнения заявки. ',
        'В случае возникновения любых вопросов по обмену , напишите в онлайн чат, указав номер заявки.'
      ],
      EXHANGE_TERM: 'Ознакомиться с правилами обмена можно',
      HEADING_LEFT: 'Наш партнер',
      LEFT_DESCRIPTION: (
        <>
          Сервис для хранения и обмена криптовалюты по всему миру
      </>
      ),
      GO_TO_CONTESTS: 'Зарегистрироваться',

      TELEGRAM_HEADING: 'Подпишись на наш телеграм!',
      TELEGRAM_DESCRIPTION: 'Узнавай новости первым и совершай самые выгодные обмены.',
      TELEGRAM_LINK: 'Перейти в телеграм'
    },

    EXCHANGE_BTN_TEXT: 'Обменять',
    SUM_WITH_COMISION: 'Комиссия: ',
    SBP_MESSAGE: (
      <>
        Внимание!
        <br /> Перевод осуществляется только на Райффайзен банк. В случае перевода на другой банк средства будут утеряны.
      </>
    ),
    TO_SBP_MESSAGE: (
      <>
        Внимание!
        <br /> Внимательно указывайте банк для перевода, иначе средства будут переведены на первый доступный банк по вашим реквизитам
      </>
    ),
    WIN_CONTESTS: 'Выиграй 5 000 ₽!',
    WIN_CONTESTS_NEW: 'Выиграй Cash',
    CLOSE_BUTTON: 'Закрыть',
    COOKIES_TEXT:
      'Чтобы обеспечить наилучшие впечатления, мы используем такие технологии, как файлы cookie, для хранения и/или доступа к информации об устройстве. Согласие с этими технологиями позволит нам обрабатывать такие данные, как поведение при просмотре или уникальные идентификаторы на этом сайте. Отсутствие согласия или отзыв согласия может отрицательно сказаться на некоторых функциях и функциях.',
    COOKIES_ACCEPT: 'Принять',
    COOKIES_DECLINE: 'Отказать',
    COOKIES_POLITICS: 'Политика Cookie',
    COOKIES_POLICY: 'Пользовательское соглашение',
    COOKIES_DISCLAIMER: 'Дисклеймер'
  },
  en: {
    HEADING: 'Cashex.cc - Exchange of cryptocurrencies',
    WORKS_TIME: 'On our site you can exchange cryptocurrencies at favorable rates. Technical support 24/7. Safe transactions',
    COURSE: 'Course',

    LABEL_EXCHANGE_FROM: 'I send',
    LABEL_EXCHANGE_TO: 'I receive',

    SELECT_CURRENCY: 'Select currency',
    NOT_FOUND_CURRENCY: 'Such a currency does not exist',
    SEARCH: 'Search',

    REQUISITES: 'Requisites',

    INPUTS: {
      CARD_NUMBER: 'Card number',
      NAME: 'Your name',
      SUR_NAME: 'Your last name',
      PHONE: 'Phone number',
      FROM_CARD: 'From card number',
      QIWI_WALLET: 'Qiwi wallet number',
      WALLET: 'Receive wallet address',
      TO_CARD: 'To card number',
      SELECT_CITY: 'Select city:',
      NAME_SERNAME: 'Name and surname on the card',
      SELECT_SBP_BANK: "Beneficiary's bank name",
      MEMO_TAG: 'Memo-tag'
    },

    TERMS_TEXT: 'Before proceeding to the next step, please make sure that all the data entered is correct and up-to-date and that you have read and agreed to the terms of the service.',
    CHECKBOX_TERMS: (
      <>
        By clicking on the "Exchange" button, you agree to the <Link to={path.TERMS}>rules</Link> of the service
      </>
    ),
    DONT_SAVE_DATA: "Don't remember the data entered",

    CONTESTS_BAN: {
      HEADING_RIGHT: 'Pay attention to the order lof the exchange!',
      LIST: [
        'Fill out the forms above. Please check carefully that the details are correct.',
        'An email with a link to the exchange has been sent to the email specified in the application. It contains all the information about the progress of the application.',
        'If you have any questions about the exchange, write to the online chat, indicating the application number.'
      ],
      EXHANGE_TERM: 'You can get acquainted with the exchange rules',
      HEADING_LEFT: 'Our partner',
      LEFT_DESCRIPTION: (
        <>
          Service for storing and exchanging cryptocurrencies around the world
        </>
      ),
      GO_TO_CONTESTS: 'REGISTER',
      TELEGRAM_HEADING: 'Subscribe to our telegram!',
      TELEGRAM_DESCRIPTION: 'Be the first to know the news and make the most profitable exchanges.',
      TELEGRAM_LINK: 'Go to telegram',
      SELECT_SBP_BANK: "Payee's bank"
    },

    EXCHANGE_BTN_TEXT: 'Exchange',
    SUM_WITH_COMISION: 'Amount including commission:',
    SBP_MESSAGE: (
      <>
        Attention
        <br /> The transfer is made only to Raiffeisenbank. In case of transfer to another bank, the funds will be lost.
      </>
    ),
    TO_SBP_MESSAGE: (
      <>
        Attention!
        <br /> Carefully indicate the bank for the transfer, otherwise the funds will be transferred to the first available bank according to your details
      </>
    ),
    WIN_CONTESTS: 'Win 5000 ₽!',
    WIN_CONTESTS_NEW: 'Win Cash',
    CLOSE_BUTTON: 'Close',
    COOKIES_TEXT:
      'To ensure you get the best experience, we use technologies such as cookies to store and/or access device information. Consent to these technologies will allow us to process data such as browsing behavior or unique identifiers on this site. Lack of consent or withdrawal of consent may adversely affect certain features and functionality.',
    COOKIES_ACCEPT: 'Accept',
    COOKIES_DECLINE: 'Refuse',
    COOKIES_POLITICS: 'Cookie policy',
    COOKIES_POLICY: 'Privacy policy',
    COOKIES_DISCLAIMER: 'Disclaimer'
  },
  tu: {
    HEADING: 'Cashex.cc - kripto para birimi değişimi',
    WORKS_TIME: 'Sitemizde uygun oranlarda kripto para alışverişi yapabilirsiniz. 7/24 teknik destek. Güvenli işlemler',
    COURSE: 'Peki',

    LABEL_EXCHANGE_FROM: 'Gönderiyorum',
    LABEL_EXCHANGE_TO: 'Alırım',

    SELECT_CURRENCY: 'Para birimini seçin',
    NOT_FOUND_CURRENCY: 'Böyle bir para birimi yok',
    SEARCH: 'Arama',

    REQUISITES: 'Gereklilikler',

    INPUTS: {
      CARD_NUMBER: 'Kart numarası',
      NAME: 'İsim',
      SUR_NAME: 'Soyadı',
      PHONE: 'Telefon numarası',
      FROM_CARD: 'Haritadan',
      QIWI_WALLET: 'Qiwi cüzdan numarası',
      WALLET: 'Cüzdan adresini al',
      TO_CARD: 'Haritaya',
      SELECT_CITY: 'Şehir seç:',
      NAME_SERNAME: 'Haritada ad ve soyad',
      SELECT_SBP_BANK: 'Lehtarın banka adı',
      MEMO_TAG: 'Memo-tag'
    },

    TERMS_TEXT: 'Lütfen “Değişim” butonuna basmadan önce girilen tüm verilerin doğru ve güncel olduğundan ve hizmet şartlarını okuyup kabul ettiğinizden emin olunuz.',
    CHECKBOX_TERMS: (
      <>
        "Değiştir" düğmesine tıklayarak hizmetin <Link to={path.TERMS}>tüzük</Link> kabul etmiş olursunuz
      </>
    ),
    DONT_SAVE_DATA: 'Girilen verileri hatırlama',

    CONTESTS_BAN: {
      HEADING_RIGHT: 'Değişim sırasına dikkat edin!',
      LIST: [
        'Yukarıdaki formları doldurun. Lütfen verilerin doğruluğunu dikkatlice kontrol ediniz.',
        'Başvuruda belirtilen postaya değişim bağlantısını içeren bir e-posta gönderildi. Uygulamanın ilerlemesiyle ilgili tüm bilgileri içerir.',
        'Değişim hakkında sorularınız varsa, başvuru numarasını belirterek çevrimiçi sohbete yazın.'
      ],
      EXHANGE_TERM: 'Borsa kuralları hakkında bilgi sahibi olabilirsiniz.',
      HEADING_LEFT: 'Our partner',
      LEFT_DESCRIPTION: (
        <>
          Service for storing and exchanging cryptocurrencies around the world
        </>
      ),
      GO_TO_CONTESTS: 'REGISTER',
      TELEGRAM_HEADING: 'Telegramımıza abone olun!',
      TELEGRAM_DESCRIPTION: 'Haberleri ilk öğrenen siz olun ve en karlı alışverişleri yapın.',
      TELEGRAM_LINK: 'telgrafa git'
    },

    EXCHANGE_BTN_TEXT: 'değiş tokuş',
    SUM_WITH_COMISION: 'Komisyon dahil tutar:',
    SBP_MESSAGE: (
      <>
        Dikkat!
        <br /> Havale sadece Raiffeisen Bank'a yapılır. Başka bir bankaya havale yapılması durumunda fonlar kaybedilecektir.
      </>
    ),
    TO_SBP_MESSAGE: (
      <>
        Dikkat!
        <br /> Transfer için bankayı dikkatlice belirtin, aksi takdirde fonlar bilgilerinize göre uygun olan ilk bankaya transfer edilecektir.
      </>
    ),
    WIN_CONTESTS: '5000 ruble kazanın!',
    WIN_CONTESTS_NEW: 'Nakit Kazan',
    CLOSE_BUTTON: 'Kapat',
    COOKIES_TEXT:
      'En iyi deneyimi yaşamanızı sağlamak için, cihaz bilgilerini depolamak ve/veya bunlara erişmek için çerezler gibi teknolojiler kullanıyoruz. Bu teknolojilere izin vermek, bu sitedeki tarama davranışı veya benzersiz tanımlayıcılar gibi verileri işlememize izin verecektir. İzin verilmemesi veya onayın geri alınması belirli özellikleri ve işlevleri olumsuz etkileyebilir.',
    COOKIES_ACCEPT: 'Kabul etmek',
    COOKIES_DECLINE: 'Reddetmek',
    COOKIES_POLITICS: 'Cookie politikası',
    COOKIES_POLICY: 'Kullanım Şartları',
    COOKIES_DISCLAIMER: 'Feragatname'
  }
}
