import { otherLinks, path } from '../utils/consts'

export const contestTexts: any = {
  ru: {
    HEADING: 'Зарабатывай Сash вместе с Cashex!',
    SECTIN_1: {
      // HEADING: 'Сделай обмен, оставь отзыв на BestChange и получи шанс выиграть 5000 рублей',
      HEADING: 'Правила использования услуги реферальной программы сайта Cashex: ',
      REQUIRED: '',
      REQUIRED_LIST: [
        {
          description: (
            <>
              Чтобы воспользоваться реферальной программой перейдите <a href={"https://t.me/cashex_referral_bot"} target='_blank'>по ссылке </a>
            </>
          ),
          title: '',
          link: path.MAIN
        },
        {
          description: (
            <>
              Информируем, что при копировании ссылки выше и перехода по ссылкам из неофициальных источников есть риски потери средств.
            </>
          ),
          title: '',
          link: otherLinks.BESTCHANGE
        },
        {
          description: (
            <>
             
            </>
          ),
          title: '',
          link: otherLinks.CASHEX_TELEGRAM_LINK
        }
      ]
    },
    SECTION_2: {
      HEADING: '',
      LIST: [
        '1. Исполнитель несет ответственность за качество предоставляемой услуги в случае перехода по ссылке из официального источника (сайт).',
        '2. В случае перехода Заказчика по фишинговым ссылкам из неофициальных источников Исполнитель не несет материальную и репутационную ответственность за исполнение услуги.',
      ]
    },
    TIME_LEFT: 'Осталось до следующего розыгрыша:',
    COMMON_BANK: 'Текущий банк:',
    LEAVE_REVIEW: 'Оставить отзыв'
  },
  en: {
    HEADING: 'Earn Cash with Cashex!',
    SECTIN_1: {
    // HEADING: 'Make an exchange, leave a review on BestChange and get a chance to win 5000 rubles',
    HEADING: 'Terms of Use of the Cashex website Referral Program service: ',
    REQUIRED: '',
    REQUIRED_LIST: [
    {
    description: (
    <>
    To use the referral program, go to <a href={"https://t.me/cashex_referral_bot"} target='_blank'>by link </a>
    </>
    ),
    title: '',
    link: path.MAIN
    },
    {
    description: (
    <>
    We inform you that when copying the link above and clicking on links from unofficial sources, there are risks of losing funds.
    </>
    ),
    title: '',
    link: otherLinks.BESTCHANGE
    },
    {
    description: (
    <>
    
    </>
    ),
    title: '',
    link: otherLinks.CASHEX_TELEGRAM_LINK
    }
    ]
    },
    SECTION_2: {
    HEADING: '',
    LIST: [
    '1. The contractor is responsible for the quality of the service provided in case of clicking on the link from the official source (website).',
    '2. If the Customer clicks on phishing links from unofficial sources, the Contractor does not bear material and reputational responsibility for the performance of the service.',
    ]
    },
    TIME_LEFT: 'Until the next draw:',
    COMMON_BANK: 'Current bank:',
    LEAVE_REVIEW: 'Leave feedback'
  },
  tu:  {
    HEADING: 'Cashex ile birlikte para kazan!',
    SECTIN_1: {
    // HEADİNG: 'Değişimi yap, Bestchange'e geri bildirim bırak ve 5000 ruble kazanma şansını yakala',
    HEADING: 'Cashex web sitesinin sevk programını kullanma kuralları: ',
    REQUIRED: '',
    REQUIRED_LIST: [
    {
    description: (
    <>
    Sevk programını kullanmak için <a href={"https://t.me/cashex_referral_bot"} target='_blank'>bağlantıdan </a> gidin
    </>
    ),
    title: '',
    link: path.MAIN
    },
    {
    description: (
    <>
    Yukarıdaki bağlantıyı kopyalarken ve resmi olmayan kaynaklardan gelen bağlantıları takip ederken fon kaybı risklerinin olduğunu bildiriyoruz.
    </>
    ),
    title: '',
    link: otherLinks.BESTCHANGE
    },
    {
    description: (
    <>
    
    </>
    ),
    title: '',
    link: otherLinks.CASHEX_TELEGRAM_LINK
    }
    ]
    },
    SECTION_2: {
    HEADING: '',
    LIST: [
    '1. Resmi kaynaktan (web sitesinden) bir bağlantıya tıklandığında, sağlanan hizmetin kalitesinden sanatçı sorumludur.',
    '2. Müşterinin resmi olmayan kaynaklardan gelen kimlik avı bağlantıları üzerinden geçmesi durumunda, İcracı hizmetin yürütülmesinden maddi ve itibarlı olarak sorumlu değildir.',
    ]
    },
    TIME_LEFT: 'Bir sonraki çekilişe kadar:',
    COMMON_BANK: 'Mevcut banka:',
    LEAVE_REVIEW: 'Geribildirim bırak'
  }
}
