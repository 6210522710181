export const aml: any = {
  ru: {
    HEADING1: 'СИСТЕМА AML/KYC (ПРОТИВОДЕЙСТВИЕ ОТМЫВАНИЮ ДЕНЕГ / ПРОВЕРКА БЛАГОНАДЕЖНОСТИ КЛИЕНТОВ) И МЕЖДУНАРОДНЫЕ ПРОЦЕДУРЫ ФИНАНСОВЫХ САНКЦИЙ',
    HEADING2: 'МЕРЫ ВНУТРЕННЕГО КОНТРОЛЯ ДЛЯ ВНЕДРЕНИЯ ЗАКОНА ОБ AML',
    LIST: [
      'ОПРЕДЕЛЕНИЯ И АББРЕВИАТУРЫ',
      // '1.1 Компания – ООО “ Smart Exchange”',
      '1.2 Клиент – физическое или юридическое лицо, которому Компания оказывает услуги.',
      ,
      '1.3 Отмывание денег – укрывательство или маскировка истинного характера, источника, местоположения, расположения, передачи, владения или других прав относительно собственности, полученной в результате преступной деятельности или собственности, полученной от неё. Изменение, передача, приобретение, владение или использование в целях сокрытия, сокрытие незаконного происхождения собственности или оказание помощи человеку, который участвовал в преступной деятельности, чтобы избежать юридических последствий его/её действий. Отмывание денег также происходит, когда преступная деятельность, которая привела к приобретению собственности, используемой для отмывания денег, произошла на территории другого государства.',
      '1.4 Финансирование террористов обозначает распределение или получение средств, согласно статьям Уголовного кодекса, для планирования или поручения выполнения действий, которые являются террористическими, или для финансирования террористических организаций, или владение информацией о том, что такие средства используются в этих целях.',
      '1.5 Международные санкции – меры невооруженного воздействия, определённые Европейским союзом, Организацией Объединенных Наций, иной международной организацией или правительством Республики с целью достижения или поддержания мира, предотвращения конфликтов и усиления международной безопасности, а также поддержки и укрепления демократии, власти закона, прав человека и международного права.',
      '1.6 Политически уязвимое лицо (PEP) – физическое лицо, которое выполняет или выполняло важные функции государственного органа, включая роль руководителя страны, главы правительства, министра и заместителя или помощника министра, члена парламента или подобного законодательного органа, члена партийного руководства, члена Верховного Суда, члена совета директоров, административного или руководящего сотрудника государственного предприятия, главы международной организации, заместителя главы и члена руководящего органа или подобного учреждения.',
      // '1.7 Местное PEP – это физическое лицо, определённое в пункте 1.6 и выполняющее или выполнявшее важные функции государственного органа Грузии.',
      '1.8 Член семьи PEP – это физическое лицо, считающееся эквивалентом супруга(и) этого лица, ребёнка этого лица и человек, считающийся эквивалентом супруга(и) этого лица или родителя этого лица.',
      '1.9 Лицо, приближённое к PEP – под ним подразумевается физическое лицо, которое, согласно общедоступной информации, является собственником-бенефициаром или совладельцем юридического лица или предприятия с гражданином страны или местным жителем, имеет близкие деловые отношения с гражданином страны или местным жителем, или физическое лицо, которое является единственным собственником-бенефициаром юридического лица или предприятия, которое, согласно общедоступной информации, было успешно учреждено в пользу гражданина страны или местного жителя.',
      '1.10 Закон об AML – описывает меры помощи при подавлении легализации неправомерной декларации о доходах.',
      'ОБЩИЕ ПОЛОЖЕНИЯ',
      '2.1 Данное Руководство было подготовлено на основе Закона об AML и Международного закона о санкциях и предназначено для внутреннего пользования.',
      '2.2 Данное Руководство излагает меры внутренней безопасности для соответствия требованиям комплексной юридической проверки по предотвращению отмывания денег и терроризма, а также требованиям международных санкций, и обнаружения подозрительных и нестандартных сделок.',
      '2.3 Сотрудники Компании должны быть ознакомлены и строго следовать требованиям Закона об AML, международных санкций, инструкций по идентификации отмывания денег, финансирования терроризма и подозрительных операций, и прочих инструкций для соответствия требованиям Закона об AML и этого Руководства.',
      '2.4 Сотрудники Компании должны независимо знакомиться с дополнениями к законам и иным законодательным актам.',
      '2.5 Совет Директоров Компании обязан внедрить эти инструкции для всех сотрудников.',
      '2.6 Сотрудники Компании обязаны подтверждать прочтение этих инструкций.',
      '2.7 Сотрудники Компании несут личную ответственность за соответствие требованиям Закона об AML в соответствии с процедурой, предусмотренной законодательством.',
      'ВЗАИМООТНОШЕНИЯ С КЛИЕНТАМИ И ИДЕНТИФИКАЦИЯ',
      '3.1 Сотрудники Компании должны применять следующие правила поведения каждый раз перед вступлением в деловые отношения с Клиентом.',
      '3.2 Компания не устанавливает взаимоотношения с клиентом посредством работы с уполномоченным представителем физического лица.',
      '3.3 Клиент – физическое лицо идентифицируется следующим образом:',
      '3.3.1 Клиент идентифицируется на основе удостоверяющего личность документа, копии страницы с личными данными и фотографии, которые хранятся в клиентской базе Компании. Проводится идентификация следующих документов: паспорт, удостоверение личности или водительские права; гражданин другой страны – проездной документ (паспорт).',
      '3.3.2 По каждому клиенту следует записать следующие данные:',
      '3.3.2.1 Имя и фамилия.',
      '3.3.2.2 Личный номер, дата и место рождения.',
      '3.3.2.3 Имя, номер, дата выдачи и наименование органа выдачи для документа, используемого для идентификации и проверки человека.',
      '3.3.2.4 Адрес места жительства.',
      '3.3.2.5 Контактные данные: адрес электронной почты и номер телефона.',
      '3.3.2.6 Выполняет или выполнял ли человек функции PEP.',
      '3.3.2.7 Является ли человек партнером PEP или членом его семьи.',
      '3.3.3 Следующие документы требуются для идентификации клиента:',
      // '3.3.3.1 Документ, удостоверяющий личность. В случае гражданина Грузии – паспорт гражданина Грузии, удостоверение личности, паспорт иностранца, вид на жительство, водительские права, выданные в Грузии. В случае гражданина другой страны – проездной документ или паспорт.',
      '3.3.3.2 Документ, подтверждающий адрес места жительства. Таким документом может быть счёт за коммунальные услуги, банковская выписка о балансе на счету или другие аналогичные документы, которые указывают на адрес и выданы раньше, чем за 3 месяца до этого.',
      '3.4 Клиент – юридическое лицо идентифицируется следующим образом:',
      '3.4.1 Клиент идентифицируется на основе выписки из Коммерческого Реестра, которая была выдана раньше, чем за 3 месяца до этого, заверенной и, если возможно, апостилированной.',
      '3.4.2 По каждому юридическому лицу следует записать следующие данные:',
      '3.4.2.1 Наименование',
      '3.4.2.2 Регистрационный номер и дата регистрации.',
      '3.4.2.3 Юридический адрес и фактический адрес.',
      '3.4.2.4 Данные представителя, акционеров и собственника-бенефициария.',
      '3.4.2.5 Контактные данные: адрес электронной почты и номер телефона.',
      '3.4.2.6 Для каждого физического лица в составе юридического лица (например, директора, акционеров, собственника-бенефициария) – данные и документы, обозначенные в пункте 3.3.',
      'ОЦЕНКА РИСКА И ОПРЕДЕЛЕНИЕ УРОВНЯ ВНЕДРЯЕМЫХ МЕР КОНТРОЛЯ',
      '4.1 Устанавливая деловые отношения с Клиентом, Компания должна оценить степень риска отмывания денег и финансирования терроризма, и, соответственно, выбрать и осуществить соответствующие меры комплексной юридической проверки.',
      '4.2 Следующие категории должны быть приняты во внимание при оценке уровня риска отмывания денег и финансирования терроризма:',
      '4.2.1 Географический риск.',
      '4.2.2 Клиентский риск.',
      '4.2.3 Операционный риск.',
      '4.3 Географический риск считается высоким, если у клиента или сделки есть известная связь со следующими странами или территориями:',
      '4.3.1 Страны и территории, подвергавшиеся санкциям ООН или Европейского союза, эмбарго или другим аналогичным мерам.',
      '4.3.2 Страны без соответствующих мер предотвращения отмывания денег и финансирования терроризма.',
      '4.3.3 Страны, которые, согласно надёжным данным, вовлечены в поддержку терроризма или имеют высокий уровень коррупции.',
      '4.3.4 Информацию о странах с высоким риском можно найти по ссылке http://www.fatf-gafi.org/countries/#high-risk',
      '4.4 Клиентский риск считается высоким, если клиент:',
      '4.4.1 Является PEP, членом его семьи или лицом, приближённым к PEP. Сотрудники Компании обязаны устанавливать, являются ли клиенты указанными лицами прежде, чем установить деловые отношения с Клиентом и совершить сделку.',
      '4.4.2 Включён ООН или Европейским союзом в список лиц, подвергающихся международным финансовым санкциям. У сотрудников компании есть обязанность отслеживать эту информацию прежде, чем устанавливать деловые отношения, с проверкой данных клиента.',
      '4.4.3 Является физическим лицом, которое ранее подозревалось в вовлечении в отмывание денег или финансирование терроризма.',
      '4.5 Операционный риск считается высоким, если:',
      '4.5.1 Лицо, которое не является одной из сторон сделки, будет платить за сделку.',
      '4.5.2 Требуется заключение сделки, одной из целей которой должно быть скрытие списка фактических участников сделки.',
      '4.5.3 Требуется заключение сделки, у которой нет чётко обоснованной коммерческой, экономической, налоговой или юридической цели.',
      '4.5.4 Виртуальные валюты всегда приходят с различных адресов или деньги переводятся с различных счетов.',
      '4.5.5 Суммы в виртуальной валюте или деньгах не являются стандартными для клиента.',
      '4.5.6 Каждый раз клиент получает различные виртуальные валюты.',
      '4.6 Риск отмывания денег или финансирования терроризма считается высоким, если по любой из причин, есть подозрение в том, что клиент или заключаемая клиентом сделка могут быть связаны с отмыванием денег или финансированием терроризма.',
      '4.7 Компания не предлагает услуги и не устанавливает взаимоотношения с клиентами, если:',
      '4.7.1 Это граждане стран с высоким риском (http://www.fatf-gafi.org/countries/#high-risk).',
      '4.7.2 Это лица из списка международных финансовых санкций.',
      '4.7.3 Это PEP, их члены семьи или лица, к ним приближённые.',
      '4.7.4 Лица, которые ранее подозревались в вовлечении в отмывание денег или финансирование терроризма.',
      '4.8 В отношении рисков, не перечисленных в пункте 4.7, к клиенту должны быть применены усиленные меры комплексной юридической проверки.',
      'IT-РИСКИ И ИХ КОНТРОЛЬ',
      '5.1 Риски, связанные с используемыми технологиями:',
      '5.1.1 Утечка информации;',
      '5.1.2 Предоставление ложной информации;',
      '5.1.3 Вредоносное программное обеспечение и кибер-атака;',
      '5.1.4 Риски, связанные с работой информационной системы.',
      '5.2 Для снижения риска утечки информации, сотрудники Компании обязаны:',
      '5.2.1 Использовать только внутренние серверы частных компаний с ограниченной ответственностью;',
      '5.2.2 Использовать программное обеспечение, одобренное и установленное Советом директоров Компании, которое на постоянной основе обновляется;',
      '5.2.3 Использовать аппаратное обеспечение Компании. Использование собственного аппаратного обеспечения, включая внешние носители, строго запрещено.',
      '5.3 Для снижения риска предоставления ложной информации:',
      '5.3.1 Для подтверждения данных у Клиента Компания должна провести переговоры при личной встрече или во время видеоконференции.',
      '5.3.2 В случае подозрения предоставления ложной информации необходимо запросить подтверждающие документы у Клиента.',
      '5.4 Для снижения рисков вредоносного программного обеспечения и кибер-атак:',
      '5.4.1 Система постоянно проверяется для определения подозрительных и необычных операций.',
      '5.4.2 Тесты безопасности системы выполняются на постоянной основе.',
      '5.4.3 Программное обеспечение, которое постоянно обновляется, используется для обнаружения вредоносного программного обеспечения и борьбы с вирусами.',
      '5.5 Для снижения рисков, связанных с работой информационной системы:',
      '5.5.1 Используется предохранительная инфраструктура сети и сервера.',
      '5.5.2 Используются отдельный основной сервер и сервер резервного копирования. В целях безопасности основной сервер и сервер резервного копирования расположены в различных местах.',
      '5.5.3 Информационная система Компании сертифицирована согласно стандарту PCI/DSS.',
      '5.6 Согласно потребности, но, по крайней мере, раз в год организуется обучение информационной безопасности для сотрудников Компании.',
      'ПРИМЕНЕНИЕ МЕР КОМПЛЕКСНОЙ ЮРИДИЧЕСКОЙ ПРОВЕРКИ',
      '6.1 Особое внимание должно уделяться действиям физических лиц или Клиентов, вовлеченных в сделку, и обстоятельствам, которые указывают или могут подразумевать отмывание денег или финансирование терроризма, включая сложные, дорогие и нестандартные сделки, у которых нет разумной экономической цели.',
      '6.2 Применимые меры комплексной юридической проверки:',
      '6.2.1 Идентификация клиента или лица, участвующего в сделке, на основе документов и данных, представленных им/ей, и с помощью видеоконференции.',
      '6.2.2 Идентификация собственника-бенефициария.',
      '6.2.3 Получение информации о деловых связях Клиента, цели и природе сделки.',
      '6.2.4 Непрерывный мониторинг деловых связей клиента, включая контроль сделок, заключаемых в ходе деловых отношений, регулярная проверка идентификационных данных, обновление соответствующих документов, данных и информации и, при необходимости, идентификация источника и происхождения средств, используемых в сделке.',
      '6.3 При проведении комплексной юридической проверки факты, которые будут установлены, обычно определяются на основе оригиналов документов, представленных клиентом. Если оригинал документа не может быть получен, могут использоваться нотариально заверенные или официально заверенные документы, включая документы, заверенные юристом. Если это нецелесообразно с учётом степени риска, копия оригинала документа должна быть скреплена печатью и/или подписана его автором, и может быть передана в электронном виде (в повторно представленной письменной форме). Копии нельзя верить, если есть сомнение относительно его соответствия оригиналу.',
      '6.4 Проверка может полагаться на информацию, которая написана в формате, соответствующем коммерческому реестру, кредитному учреждению или филиалу иностранного кредитного учреждения или кредитному учреждению, которое зарегистрировано в иной стране, где имеются равные требования к AML/KYC.',
      '6.5 Вышеупомянутые меры комплексной юридической проверки должны применяться до начала деловых отношений или заключения сделки.',
      '6.6 Личность Клиента может быть установлена и предоставленная информация может быть проверена во время начала деловых отношений или заключения сделки, если это необходимо для предотвращения нарушения нормального хода бизнеса, и если риск отмывания денег или финансирования терроризма низок. В таком случае меры комплексной юридической проверки должны быть, как можно скорее, завершены после того, как первый контакт был установлен, и прежде, чем любые обязательные меры будут приняты.',
      '6.7 Там, где возможно, физическое лицо или Клиент, вовлеченные в сделку или профессиональную деятельность, должны быть обязаны удостоверять его/её подписью точность информации и документов, представленных для проведения мер комплексной юридической проверки.',
      'ПРИМЕНЕНИЕ МЕР РАСШИРЕННОЙ КОМПЛЕКСНОЙ ЮРИДИЧЕСКОЙ ПРОВЕРКИ',
      '7.1 Меры комплексной юридической проверки должны внедряться в расширенном объёме, если:',
      '7.1.1 Личность физического лица или клиента, вовлечённого в сделку, установлена и информация предоставлена из места, не соответствующего местонахождения проверяемого физического лица или клиент.',
      '7.1.2 Идентификация или проверка вызывают сомнение или подозрение в подлинности документа, или нет возможности идентифицировать собственника (собственников)-бенефициария.',
      '7.1.3 По сути ситуация подразумевает высокий риск отмывания денег или финансирования терроризма.',
      '7.2 Сотрудник компании должен применять, по крайней мере, одну из следующих мер расширенной комплексной юридической проверки:',
      '7.2.1 Идентификация и проверка информации, полученной из дополнительных документов, данных или информации, полученной из надежного и независимого источника, или из коммерческого реестра кредитного учреждения или филиала иностранного кредитного учреждения или кредитного учреждения, которое зарегистрировано или ведёт бизнес в стране, где имеются равные требования к AML/KYC, и если личность физического лица установлена в этом кредитном учреждении в присутствии этого физического лица.',
      '7.2.2 Внедрение электронной доставки документов для их достоверности и проверки точности информации, содержащейся в них, включая их нотариальное заверение или официальное подтверждение точности собранных данных, или документ, выпущенный кредитным учреждением, упомянутым в пункте 7.2.1.',
      '7.2.3 Осуществление первого платежа на счёт, открытый на имя вовлечённого в сделку физического лица, с кредитным учреждением, которое зарегистрировано или действует в стране, имеющей одинаковые требования к AML/KYC.',
      'ИДЕНТИФИКАЦИЯ PEP',
      '8.1 Устанавливая деловые отношения (после заключения потребительского соглашения), Клиент заполняет форму, в которую он/она вводит основные обязательные данные, необходимые для идентификации и проверки, и обязательные по закону.',
      '8.2 Среди прочей информации, Клиент должен указать, является ли он, член его семьи или близкий партнёр PEP (Политически уязвимым лицом).',
      '8.3 Если член семьи Клиента или его близкий партнер является PEP, Клиент должен также указать данные этого лица.',
      '8.4 Информация о PEP проверяется работниками Компанией по информации из общественных источников, например, с сайта https://namescan.io/FreePEPCheck.aspx',
      'ИДЕНТИФИКАЦИЯ ЛИЦА, ПОДВЕРГШЕГОСЯ МЕЖДУНАРОДНЫМ САНКЦИЯМ',
      '9.1 Устанавливая деловые отношения (после заключения потребительского соглашения), Клиент заполняет форму, в которую он/она вводит основные обязательные данные, необходимые для идентификации и проверки, и обязательные по закону.',
      '9.2 Согласно информации, предоставленной Клиентом, сотрудник Компании осуществляет проверку международных санкций, применимых к Клиенту.',
      'ИДЕНТИФИКАЦИЯ ПОДОЗРЕНИЙ В ОТМЫВАНИИ ДЕНЕГ',
      '10.1 Данный раздел излагает обстоятельства, которые указывают на подозрение в отмывании денег, которым сотрудники Компании должны уделить особое внимание.',
      '10.2 «Самостоятельная сдача». Подозрительные признаки:',
      '10.2.1 Внешность и поведение человека не соответствуют сути сделки, заключаемой данным лицом, или его/ее поведение вызывает сомнения.',
      '10.2.2 Человек не в состоянии подписать документы или использует стороннюю помощь для этих целей. 10.2.3 Человека уже подозревали в «двойной игре».',
      '10.3 Человек не может объяснить необходимость данной услуги.',
      '10.4 Человек запрашивает необоснованно высокую ставку.',
      '10.5 Нестандартная операция с наличными деньгами.',
      '10.6 Отдельные крупные или периодические мелкие операции с виртуальной валютой, если такая деятельность не соответствует экономической деятельности человека или необычна.',
      '10.7 Деньги, полученные за виртуальную валюту, переводятся постороннему лицу или на банковский счет в другой стране.',
      '10.8 Человек не предоставляет данные и/или объяснения по поводу сделки.',
      '10.9 Обменивается большой объём виртуальных валют в случае, если они не соответствуют обычному ходу ведения бизнеса этого человека или необычны.',
      '10.10 Человек не может быть идентифицирован или пытается не предоставить вам информацию.',
      '10.11 Человек пытается вступить в фиктивную сделку.',
      '10.12 При создании долгосрочных взаимоотношений с клиентами человек хочет рассчитываться только наличными деньгами.',
      '10.13 Есть подозрение, что человек действует в чьих-то интересах.',
      '10.14 Человек хочет рассчитываться наличными деньгами на сумму больше 10 000 евро.',
      '10.15 Человек неоднократно рассчитывается наличными деньгами на суммы выше 10 000 евро.',
      '10.16 Платеж осуществляется через банк, учреждённый на не облагаемой налогом территории.',
      'ОТКАЗ ОТ ЗАКЛЮЧЕНИЯ КОНТРАКТА И СОВЕРШЕНИЯ СДЕЛКИ',
      '11.1 Компания не заключает контракт и не совершает сделку:',
      '11.1.1 С лицами моложе 18 лет.',
      '11.1.2 С уполномоченными представителями клиента – физического лица.',
      '11.1.3 С человеком, который отказывается предоставлять информацию и документы, упомянутые в пункте 3 настоящего Руководства, или предоставляет меньше информации, чем необходимо, или пытается что-то скрыть.',
      '11.1.4 С человеком, подозреваемым в провокации.',
      '11.1.5 С человеком, который представил документы, или о котором Компанией была получена информация, подтверждающая подозрение в отмывании денег или финансировании терроризма.',
      '11.1.6 С человеком, подвергавшимся международным финансовым санкциям.',
      '11.1.7 С человеком, который является PEP или член семьи которого является PEP, или он/она – лицо, приближённое к PEP.',
      '11.1.8 С человеком, который ранее подозревался в вовлечении в отмывание денег или финансирование терроризма.',
      '11.1.9 С гражданином страны с высоким риском http://www.fatf-gafi.org/countries/#high-risk.',
      'СБОР, ХРАНЕНИЕ И ЗАЩИТА ДАННЫХ',
      '12.1 После вступления в сделку сотрудник Компании обязан зарегистрировать следующую информацию:',
      '12.1.1 Реквизиты человека, участвующего в сделке, в соответствии с пунктом 3 данного Руководства.',
      '12.1.2 Дата или период сделки.',
      '12.1.3 Описание содержания сделки.',
      '12.1.4 Информация об отказе установить деловые отношения или вступить в сделку.',
      '12.1.5 Информация об отказе от установления деловых отношений или заключения сделки по инициативе Клиента.',
      '12.1.6 Информация о завершении деловых отношений, включая невозможность применения мер комплексной юридической проверки.',
      '12.1.7 Услуга обмена виртуальной валюты в обмен на наличные деньги, сумма в валюте, сумма денег в результате и курс обмена.',
      '12.1.8 Курс обмена виртуальной валюты по отношению к другой виртуальной валюте, сумма в валюте, сумма в другой валюте и курс обмена.',
      '12.1.9 При открытии счёта в виртуальной валюте – её тип, номер и наименование валюты.',
      '12.2 Компания должна хранить следующие документы в течение, по крайней мере, пяти (5) лет после завершения деловых отношений или заключения последней сделки:',
      '12.2.1 Информация для идентификации и проверки данных и документов.',
      '12.2.2 Корреспонденция с Клиентом.',
      '12.2.3 Данные, собранные в ходе мониторинга деловых взаимоотношений.',
      '12.2.4 Данные по подозрительным и нестандартным сделкам.',
      '12.2.5 Документы по операциям.',
      '12.3 Сотрудники Компании обязаны применять правила защиты личных данных при сборе и хранении данных и документов. Собранные данные могут обрабатываться только в целях предотвращения отмывания денег и финансирования терроризма. Обработка данных способом, который не соответствует этой цели, строго запрещена.',
      'УВЕДОМЛЕНИЕ СЛУЖБЫ ФИНАНСОВОГО МОНИТОРИНГА',
      '13.1 Если сотрудник Компании обнаружил действие или обстоятельства, которые указывают на отмывание денег или финансирование терроризма, или есть подозрение, или уверенность в том, что это – отмывание денег или финансирование терроризма, об этом следует немедленно сообщить MLRO (Инспектору по противодействию отмыванию денег) компании.',
      '13.2 Запрещено сообщать клиенту о передаче информации MLRO.',
      '13.3 Сотрудник MLRO должен приложить копии документов, на которых основывается сделка, а также копии документов, на основе которых можно идентифицировать человека, к заполненной форме уведомления. Уведомление может сопровождаться копиями других документов, обосновывающих природу сделки.',
      'ВНУТРЕННИЙ КОНТРОЛЬ И ПОДГОТОВКА КАДРОВ',
      '14.1 Соблюдение требованиям Закона об AML, Международного закона о санкциях и законодательства, установленного на основе этого, отслеживается и управляется Советом директоров Компании.',
      '14.2 Оценку степени риска и идентификацию клиента выполняют специально обученные сотрудники Компании, и всё это управляется Советом директоров Компании.',
      '14.3 Деятельность Клиентов и операции с ними проверяют специально обученные сотрудники Компании, и всё это управляется Советом директоров Компании.',
      '14.4 Совет директоров Компании несёт ответственность за обучение персонала Компании в области предотвращения отмывания денег и финансирования терроризма и соответствия требованиям международных санкций.',
      '14.5 Сотрудники обязаны независимо перепроверять поправки к законам и другому законодательству.',
      '14.6 Обучение сотрудников Компании происходит по мере необходимости, но не реже одного раза в год.',
      'Приложение 1 АНКЕТНЫЙ ОПРОС KYC (ПРОВЕРКИ БЛАГОНАДЁЖНОСТИ КЛИЕНТА) ДЛЯ ФИЗИЧЕСКИХ ЛИЦ I. РЕКВИЗИТЫ',
      'Имя и фамилия',
      'Дата и место рождения',
      'Налоговое гражданство',
      'Идентификационный номер налогоплательщика',
      'Адрес',
      'Адрес e-mail',
      'Номер телефона',
      'Является ли клиент, член семьи клиента или приближённое к нему лицо политически уязвимым лицом. Если да – укажите имя и положение.',
      'ДОКУМЕНТЫ',
      'Паспорт (для граждан ЕС – паспорт или национальное удостоверение личности)',
      'Подтверждение адреса (выписка из банка с адресом, счёт за коммунальные услуги)',
      'Селфи-фото с документом из пункта 1.',
      'Приложение 2 АНКЕТНЫЙ ОПРОС KYC ДЛЯ ЮРИДИЧЕСКИХ ЛИЦ I. РЕКВИЗИТЫ ЮРИДИЧЕСКОГО ЛИЦА',
      'Имя',
      'Регистрационный номер',
      'Дата регистрации',
      'Налоговое гражданство',
      'Идентификационный номер налогоплательщика',
      'Юридический адрес',
      'Фактический адрес',
      'Номер телефона',
      'Адрес e-mail',
      'Web-сайт (если имеется)',
      'Описание рода деятельности',
      'ДОКУМЕНТЫ ЮРИДИЧЕСКОГО ЛИЦА',
      'Заверенное и апостилированное регистрационное свидетельство, включающее в себя реквизиты директора, акционера и бенефициария.',
      'Подтверждение адреса (выписка из банка с адресом, счёт за коммунальные услуги).',
      'III. РЕКВИЗИТЫ ДИРЕКТОРА',
      'Имя и фамилия',
      'Дата и место рождения',
      'налоговое гражданство',
      'Идентификационный номер налогоплательщика',
      'Адрес',
      'Адрес e-mail',
      'Номер телефона',
      'Является ли директор, член семьи директора или приближённое к нему лицо политически уязвимым лицом. Если да – укажите имя и положение.',
      ,
      'ДОКУМЕНТЫ ДИРЕКТОРА',
      'Паспорт (для граждан ЕС – паспорт или национальное удостоверение личности)',
      'Подтверждение адреса (выписка из банка с адресом, счёт за коммунальные услуги)',
      'Селфи-фото с документом из пункта 1.',
      'РЕКВИЗИТЫ И ДОКУМЕНТЫ АКЦИОНЕРА',
      'Если акционер – физическое лицо, представить реквизиты, как в разделе III, и документы, как в разделе IV.',
      'Если акционер – юридическое лицо, представить реквизиты и документы, как в разделах с I по VI. VI. РЕКВИЗИТЫ И ДОКУМЕНТЫ БЕНЕФИЦИАРИЯ 1. Реквизиты, как в разделе III, и документы, как в разделе IV.',
      'АВТОМАТИЧЕСКАЯ AML-ПРОВЕРКА ОНЛАЙН-АДРЕСОВ УЧАСТВУЮЩИХ В ТРАНЗАКЦИЯХ НА СЕРВИСЕ',
      '15.1 Сервис Cashex вправе запросить верификацию Пользователя, если адрес онлайн-кошелька клиента, указанный в заявке, имеет связь со следующими терминами: Mixing Service, Illegal Service, Fraudulent Exchange, Darknet Service, Darknet Marketplace, Ransom, Scam, Stolen Coins.',
      '15.2 В случае подтверждения связи, средства будут отправлены на обратный онлайн-кошелек, только после прохождения верификации. Средства будут возвращены за вычетом комиссии, установленной правилами обменного сервиса.'
      // 'Наименование компании: Smart Exchange LLC',
      // 'Организационная форма: Limited Liability Company',
      // 'Дата регистрации: 21/05/2021',
      // 'Регистрирующий орган: LEPL National Agency of Public Registry',
      // 'Юридический адрес: Georgia, Kutaisi, Avtomshenebeli Street, N88, Hualing Free Industrial Zone',
      // 'Лицензия: Free Industrial Zone Licence No.0110/462',
      // 'Страна: Georgia',
      // 'Вид деятельности: Financial Service and Crypto Exchange'
    ],
    BUTTON: 'Перейти к политике конфиденциальности'
  },
  en: {
    HEADING1: 'AML/KYC SYSTEM (ANTI-MONEY LAUNDERING / CUSTOMER TRUSTWORTHINESS CHECK) AND INTERNATIONAL FINANCIAL SANCTIONS PROCEDURES',
    HEADING2: 'INTERNAL CONTROL MEASURES FOR THE IMPLEMENTATION OF THE AML LAW',
    LIST: [
      `DEFINITIONS AND ABBREVIATIONS`,
      // `1.1 Company – Smart Exchange LLC`,
      `1.2 Client – a natural or legal person to whom the Company provides services.`,
      `1.3 Money laundering – concealment or disguise of the true nature, source, location, location, transfer, possession or other rights regarding property obtained as a result of criminal activity or property obtained from it. Modification, transfer, acquisition, possession or use for the purpose of concealment, concealment of the illegal origin of property or assistance to a person who participated in criminal activity in order to avoid the legal consequences of his/her actions. Money laundering also occurs when the criminal activity that led to the acquisition of property used for money laundering occurred on the territory of another State.`,
      `1.4 Terrorist financing refers to the distribution or receipt of funds, according to articles of the Criminal Code, for planning or ordering the execution of actions that are terrorist, or for the financing of terrorist organizations, or possession of information that such funds are used for these purposes.`,
      `1.5 International sanctions are measures of unarmed influence determined by the European Union, the United Nations, another international organization or the Government of the Republic for the purpose of achieving or maintaining peace, preventing conflicts and strengthening international security, as well as supporting and strengthening democracy, the rule of law, human rights and international law.`,
      `1.6 A Politically vulnerable person (PEP) is an individual who performs or has performed important functions of a state body, including the role of the head of the country, head of government, minister and deputy or assistant minister, member of parliament or similar legislative body, member of the party leadership, member of the Supreme Court, member of the board of directors, administrative or executive officer of a state enterprise, head of an international organization, deputy head and member of a governing body or similar institution.`,
      // `1.7 A local PEP is a natural person defined in clause 1.6 who performs or has performed important functions of a state body of Georgia.`,
      `1.8 A PEP family member is an individual who is considered the equivalent of the spouse(s) of this person, the child of this person and a person who is considered the equivalent of the spouse(s) of this person or the parent of this person.`,
      `1.9 A person close to PEP – it means an individual who, according to publicly available information, is the beneficial owner or co-owner of a legal entity or enterprise with a citizen of the country or a local resident, has close business relations with a citizen of the country or a local resident, or an individual who is the sole beneficial owner of a legal entity or an enterprise that, according to publicly available information, was successfully established in favor of a citizen of the country or a local resident.`,
      `1.10 The AML Law – describes measures of assistance in suppressing the legalization of illegal income declarations.`,
      `GENERAL PROVISIONS`,
      `2.1 This Manual has been prepared on the basis of the AML Law and the International Sanctions Law and is intended for internal use.`,
      `2.2 This Guide sets out internal security measures to comply with the requirements of due diligence to prevent money laundering and terrorism, as well as the requirements of international sanctions, and the detection of suspicious and non-standard transactions.`,
      `2.3 The Company's employees must be familiar with and strictly follow the requirements of the AML Law, international sanctions, instructions on the identification of money laundering, terrorist financing and suspicious transactions, and other instructions to comply with the requirements of the AML Law and this Manual.`,
      `2.4 The Company's employees must independently familiarize themselves with amendments to laws and other legislative acts.`,
      `2.5 The Board of Directors of the Company is obliged to implement these instructions for all employees.`,
      `2.6 The Company's employees are obliged to confirm the reading of these instructions.`,
      `2.7 The Company's employees are personally responsible for compliance with the requirements of the AML Law in accordance with the procedure provided for by law.`,
      `CUSTOMER RELATIONS AND IDENTIFICATION`,
      `3.1 The Company's employees must apply the following rules of conduct every time before entering into a business relationship with a Client.`,
      `3.2 The Company does not establish relations with the client by working with an authorized representative of an individual.`,
      `3.3 The Client – an individual is identified as follows:`,
      `3.3.1 The Client is identified on the basis of an identity document, a copy of the page with personal data and photos that are stored in the Company's client database. Identification of the following documents is carried out: identity card, passport of a foreigner, residence permit or driver's license; a citizen of another country – a travel document (passport).`,
      `3.3.2 The following data should be recorded for each client:`,
      `3.3.2.1 First and last name.`,
      `3.3.2.2 Personal number, date and place of birth.`,
      `3.3.2.3 Name, number, date of issue and the name of the issuing authority for the document used to identify and verify a person.`,
      `3.3.2.4 Address of the place of residence.`,
      `3.3.2.5 Contact details: email address and phone number.`,
      `3.3.2.6 Does or has the person performed the functions of PEP.`,
      `3.3.2.7 Whether the person is a PEP partner or a member of his family.`,
      `3.3.3 The following documents are required to identify the client:`,
      // `3.3.3.1 Identity document. In the case of a citizen of Georgia – a passport of a citizen of Georgia, an identity card, a passport of a foreigner, a residence permit, a driver's license issued in Georgia. In the case of a citizen of another country – a travel document or passport.`,
      `3.3.3.2 A document confirming the address of the place of residence. Such a document may be a utility bill, a bank statement on the account balance or other similar documents that indicate the address and were issued earlier than 3 months before.`,
      `3.4 The Client – legal entity is identified as follows:`,
      `3.4.1 The Client is identified on the basis of an extract from the Commercial Register, which was issued earlier than 3 months before, certified and, if possible, apostilled.`,
      `3.4.2 The following data should be recorded for each legal entity:`,
      `3.4.2.1 Name`,
      `3.4.2.2 Registration number and date of registration.`,
      `3.4.2.3 Legal address and actual address.`,
      `3.4.2.4 Data of the representative, shareholders and beneficial owner.`,
      `3.4.2.5 Contact details: email address and phone number.`,
      `3.4.2.6 For each individual as part of a legal entity (for example, director, shareholders, beneficiary owner) - the data and documents indicated in paragraph 3.3.`,
      `RISK ASSESSMENT AND DETERMINATION OF THE LEVEL OF IMPLEMENTED CONTROL MEASURES`,
      `4.1 When establishing business relations with a Client, the Company must assess the degree of risk of money laundering and terrorist financing, and, accordingly, select and implement appropriate due diligence measures.`,
      `4.2 The following categories should be taken into account when assessing the level of risk of money laundering and terrorist financing:`,
      `4.2.1 Geographical risk.`,
      `4.2.2 Client risk.`,
      `4.2.3 Operational risk.`,
      `4.3 Geographical risk is considered high if the client or the transaction has a known connection with the following countries or territories:`,
      `4.3.1 Countries and territories that have been subject to UN or European Union sanctions, embargoes or other similar measures.`,
      `4.3.2 Countries without appropriate measures to prevent money laundering and terrorist financing.`,
      `4.3.3 Countries that, according to reliable data, are involved in supporting terrorism or have a high level of corruption.`,
      `4.3.4 Information about high-risk countries can be found at http://www.fatf-gafi.org/countries/#high-risk`,
      `4.4 The Client's risk is considered high if the client:`,
      `4.4.1 is a PEP, a member of his family or a person close to PEP. The Company's employees are obliged to establish whether the clients are specified persons before establishing business relations with the Client and making a transaction.`,
      `4.4.2 Is included by the UN or the European Union in the list of persons subject to international financial sanctions. The company's employees have a duty to track this information before establishing a business relationship, with the verification of customer data.`,
      `4.4.3 Is an individual who was previously suspected of involvement in money laundering or terrorist financing.`,
      `4.5 The operational risk is considered high if:`,
      `4.5.1 A person who is not one of the parties to the transaction will pay for the transaction.`,
      `4.5.2 The conclusion of a transaction is required, one of the purposes of which should be to hide the list of actual participants in the transaction.`,
      `4.5.3 It is required to conclude a transaction that does not have a clearly justified commercial, economic, tax or legal purpose.`,
      `4.5.4 Virtual currencies always come from different addresses or money is transferred from different accounts.`,
      `4.5.5 Amounts in virtual currency or money are not standard for the client.`,
      `4.5.6 Each time the client receives different virtual currencies.`,
      `4.6 The risk of money laundering or terrorist financing is considered high if, for any reason, there is a suspicion that the client or the transaction concluded by the client may be related to money laundering or terrorist financing.`,
      `4.7 The Company does not offer services and does not establish relationships with customers if:`,
      `4.7.1 These are citizens of high-risk countries (http://www.fatf-gafi.org/countries/#high-risk ).`,
      `4.7.2 These are persons from the list of international financial sanctions.`,
      `4.7.3 These are PEP, their family members or persons close to them.`,
      `4.7.4 Persons who were previously suspected of involvement in money laundering or terrorist financing.`,
      `4.8 In respect of risks not listed in clause 4.7, enhanced due diligence measures should be applied to the client.`,
      `IT RISKS AND THEIR CONTROL`,
      `5.1 Risks associated with the technologies used:`,
      `5.1.1 Information leakage;`,
      `5.1.2 Providing false information;`,
      `5.1.3 Malicious software and cyber attack;`,
      `5.1.4 Risks associated with the operation of the information system.`,
      `5.2 In order to reduce the risk of information leakage, the Company's employees are obliged to:`,
      `5.2.1 Use only internal servers of private limited liability companies;`,
      `5.2.2 Use the software approved and installed by the Board of Directors of the Company, which is constantly updated;`,
      `5.2.3 Use the Company's hardware. The use of proprietary hardware, including external media, is strictly prohibited.`,
      `5.3 To reduce the risk of providing false information:`,
      `5.3.1 In order to confirm the data with the Client, the Company must negotiate in person or during a video conference.`,
      `5.3.2 In case of suspicion of providing false information, it is necessary to request supporting documents from the Client.`,
      `5.4 To reduce the risks of malicious software and cyber attacks:`,
      `5.4.1 The system is constantly checked to identify suspicious and unusual transactions.`,
      `5.4.2 System security tests are performed on an ongoing basis.`,
      `5.4.3 Software that is constantly updated is used to detect malicious software and fight viruses.`,
      `5.5 To reduce the risks associated with the operation of the information system:`,
      `5.5.1 The safety infrastructure of the network and the server is used.`,
      `5.5.2 A separate primary server and a backup server are used. For security reasons, the main server and the backup server are located in different locations.`,
      `5.5.3 The Company's information system is certified according to the PCI/DSS standard.`,
      `5.6 According to the need, but at least once a year, information security training is organized for the Company's employees.`,
      `APPLICATION OF DUE DILIGENCE MEASURES`,
      `6.1 Special attention should be paid to the actions of individuals or Clients involved in the transaction and circumstances that indicate or may imply money laundering or terrorist financing, including complex, expensive and non-standard transactions that do not have a reasonable economic purpose.`,
      `6.2 Applicable due diligence measures:`,
      `6.2.1 Identification of the client or the person involved in the transaction on the basis of documents and data submitted by him/her and via videoconference.`,
      `6.2.2 Identification of the beneficial owner.`,
      `6.2.3 Obtaining information about the Client's business relations, the purpose and nature of the transaction.`,
      `6.2.4 Continuous monitoring of the client's business relations, including control of transactions concluded in the course of business relations, regular verification of identification data, updating of relevant documents, data and information and, if necessary, identification of the source and origin of funds used in the transaction.`,
      `6.3 When conducting a comprehensive legal audit, the facts that will be established are usually determined on the basis of the original documents submitted by the client. If the original document cannot be obtained, notarized or officially certified documents may be used, including documents certified by a lawyer. If it is impractical, taking into account the degree of risk, a copy of the original document must be sealed and/or signed by its author, and can be transmitted electronically (in a re-submitted written form). A copy cannot be trusted if there is doubt about its compliance with the original.`,
      `6.4 Verification may rely on information that is written in a format corresponding to a commercial registry, a credit institution or a branch of a foreign credit institution or a credit institution that is registered in another country where there are equal requirements for AML/KYC.`,
      `6.5 The above-mentioned due diligence measures should be applied before starting a business relationship or concluding a transaction.`,
      `6.6 The identity of the Client can be established and the information provided can be verified during the start of a business relationship or the conclusion of a transaction, if this is necessary to prevent disruption of the normal course of business, and if the risk of money laundering or terrorist financing is low. In such a case, due diligence measures should be completed as soon as possible after the first contact has been established and before any mandatory measures are taken.`,
      `6.7 Where possible, an individual or a Client involved in a transaction or professional activity should be required to certify with his/her signature the accuracy of the information and documents submitted for conducting due diligence measures.`,
      `APPLICATION OF EXTENDED DUE DILIGENCE MEASURES`,
      `7.1 Due diligence measures should be implemented in an expanded scope if:`,
      `7.1.1 The identity of the individual or client involved in the transaction is established and information is provided from a place that does not correspond to the location of the audited individual or client.`,
      `7.1.2 Identification or verification raises doubts or suspicions about the authenticity of the document, or it is not possible to identify the owner(s)- beneficiary.`,
      `7.1.3 In essence, the situation implies a high risk of money laundering or terrorist financing.`,
      `7.2 An employee of the company must apply at least one of the following measures of extended due diligence:`,
      `7.2.1 Identification and verification of information obtained from additional documents, data or information obtained from a reliable and independent source, or from the commercial register of a credit institution or a branch of a foreign credit institution or credit institution that is registered or does business in a country where there are equal requirements for AML/KYC, and if the identity of an individual is established in this credit institution in the presence of this individual.`,
      `7.2.2 Introduction of electronic delivery of documents for their reliability and verification of the accuracy of the information contained therein, including their notarization or official confirmation of the accuracy of the collected data, or a document issued by a credit institution referred to in paragraph 7.2.1.`,
      `7.2.3 Making the first payment to an account opened in the name of an individual involved in the transaction with a credit institution, which is registered or operates in a country that has the same AML/KYC requirements.`,
      `IDENTIFICATION PEP`,
      `8.1 When establishing a business relationship (after the conclusion of a consumer agreement), the Client fills out a form in which he/she enters the basic mandatory data necessary for identification and verification, and mandatory by law.`,
      `8.2 Among other information, the Client must indicate whether he, his family member or a close partner is a PEP (Politically Vulnerable Person).`,
      `8.3 If a member of the Client's family or his close partner is a PEP, the Client must also provide the details of this person.`,
      `8.4 Information about PEP is verified by the Company's employees based on information from public sources, for example, from the website https://namescan.io/FreePEPCheck.aspx`,
      `IDENTIFICATION OF A PERSON SUBJECTED TO INTERNATIONAL SANCTIONS`,
      `9.1 When establishing a business relationship (after the conclusion of a consumer agreement), the Client fills out a form in which he/she enters the basic mandatory data necessary for identification and verification, and mandatory by law.`,
      `9.2 According to the information provided by the Client, an employee of the Company checks the international sanctions applicable to the Client.`,
      `IDENTIFICATION OF SUSPECTED MONEY LAUNDERING`,
      `10.1 This section sets out the circumstances that indicate suspicion of money laundering, which the Company's employees should pay special attention to.`,
      `10.2 "Self-delivery". Suspicious signs:`,
      `10.2.1 The person's appearance and behavior do not correspond to the essence of the transaction concluded by this person, or his/her behavior is questionable.`,
      `10.2.2 A person is unable to sign documents or uses third-party assistance for these purposes. 10.2.3 The person was already suspected of a "double game".`,
      `10.3 A person cannot explain the need for this service.`,
      `10.4 A person requests an unreasonably high bid.`,
      `10.5 Non-standard cash transaction.`,
      `10.6 Individual large or periodic small transactions with virtual currency, if such activity does not correspond to human economic activity or is unusual.`,
      `10.7 Money received for virtual currency is transferred to an outsider or to a bank account in another country.`,
      `10.8 The person does not provide data and/or explanations about the transaction.`,
      `10.9 A large volume of virtual currencies is exchanged if they do not correspond to the usual course of business of this person or are unusual.`,
      `10.10 The person cannot be identified or tries not to provide you with information.`,
      `10.11 A person is trying to enter into a fictitious transaction.`,
      `10.12 When creating long-term relationships with clients, a person wants to pay only in cash.`,
      `10.13 There is a suspicion that a person is acting in someone's interests.`,
      `10.14 A person wants to pay in cash in the amount of more than 10,000 euros.`,
      `10.15 A person is repeatedly paid in cash for amounts above 10,000 euros.`,
      `10.16 Payment is made through a bank established in a tax-free territory.`,
      `REFUSAL TO CONCLUDE A CONTRACT AND MAKE A DEAL`,
      `11.1 The Company does not enter into a contract and does not make a transaction:`,
      `11.1.1 With persons under 18 years of age.`,
      `11.1.2 With authorized representatives of the client – an individual.`,
      `11.1.3 With a person who refuses to provide the information and documents referred to in paragraph 3 of this Manual, or provides less information than necessary, or tries to hide something.`,
      `11.1.4 With a person suspected of provocation.`,
      `11.1.5 With the person who submitted the documents, or about whom the Company received information confirming suspicion of money laundering or terrorist financing.`,
      `11.1.6 With a person who has been subjected to international financial sanctions.`,
      `11.1.7 With a person who is a PEP or whose family member is a PEP, or he/she is a person close to PEP.`,
      `11.1.8 With a person who was previously suspected of involvement in money laundering or terrorist financing.`,
      `11.1.9 With a citizen of a high-risk country http://www.fatf-gafi.org/countries/#high-risk .`,
      `DATA COLLECTION, STORAGE AND PROTECTION`,
      `12.1 After entering into a transaction, an employee of the Company is obliged to register the following information:`,
      `12.1.1 Details of the person involved in the transaction, in accordance with paragraph 3 of this Manual.`,
      `12.1.2 Date or period of the transaction.`,
      `12.1.3 Description of the transaction content.`,
      `12.1.4 Information about the refusal to establish a business relationship or enter into a transaction.`,
      `12.1.5 Information about the refusal to establish business relations or conclude a transaction on the initiative of the Client.`,
      `12.1.6 Information on the termination of business relations, including the impossibility of applying due diligence measures.`,
      `12.1.7 Virtual currency exchange service in exchange for cash, the amount in the currency, the amount of money as a result and the exchange rate.`,
      `12.1.8 The exchange rate of the virtual currency in relation to another virtual currency, the amount in the currency, the amount in another currency and the exchange rate.`,
      `12.1.9 When opening an account in a virtual currency – its type, number and name of the currency.`,
      `12.2 The Company must keep the following documents for at least five (5) years after the end of the business relationship or the conclusion of the last transaction:`,
      `12.2.1 Information for identification and verification of data and documents.`,
      `12.2.2 Correspondence with the Client.`,
      `12.2.3 Data collected during the monitoring of business relationships.`,
      `12.2.4 Data on suspicious and non-standard transactions.`,
      `12.2.5 Documents on operations.`,
      `12.3 The Company's employees are obliged to apply the rules of personal data protection when collecting and storing data and documents. The collected data may be processed only for the purpose of preventing money laundering and terrorist financing. Data processing in a way that does not meet this purpose is strictly prohibited.`,
      `NOTIFICATION OF THE FINANCIAL MONITORING SERVICE`,
      `13.1 If an employee of the Company has discovered an action or circumstances that indicate money laundering or terrorist financing, or there is suspicion or confidence that this is money laundering or terrorist financing, this should be immediately reported to the MLRO (Anti–Money Laundering Inspector) of the company.`,
      `13.2 It is prohibited to inform the client about the transfer of MLRO information.`,
      `13.3 The MLRO employee must attach copies of the documents on which the transaction is based, as well as copies of documents on the basis of which a person can be identified, to the completed notification form. The notification may be accompanied by copies of other documents justifying the nature of the transaction.`,
      `INTERNAL CONTROL AND PERSONNEL TRAINING`,
      `14.1 Compliance with the requirements of the AML Law, the International Sanctions Law and the legislation established on the basis of this is monitored and managed by the Company's Board of Directors.`,
      `14.2 The risk assessment and identification of the client are carried out by specially trained employees of the Company, and all this is managed by the Board of Directors of the Company.`,
      `14.3 The activities of Clients and transactions with them are checked by specially trained employees of the Company, and all this is managed by the Board of Directors of the Company.`,
      `14.4 The Company's Board of Directors is responsible for training the Company's personnel in the prevention of money laundering and terrorist financing and compliance with the requirements of international sanctions.`,
      `14.5 Employees are required to independently recheck amendments to laws and other legislation.`,
      `14.6 Training of the Company's employees takes place as needed, but at least once a year.`,
      `Appendix 1 KYC QUESTIONNAIRE (CUSTOMER TRUSTWORTHINESS CHECKS) FOR INDIVIDUALS I. requisites`,
      `Name and surname`,
      `Date and place of birth`,
      `Tax citizenship`,
      `Taxpayer Identification Number`,
      `Address`,
      `E-mail address`,
      `Phone number`,
      `Whether the client, a member of the client's family or a person close to him is a politically vulnerable person. If yes, specify the name and position.`,
      `documents`,
      `Passport (for EU citizens – passport or national identity card)`,
      `Confirmation of the address (bank statement with the address, utility bill)`,
      `Selfie photo with the document from point 1.`,
      `Appendix 2 KYC QUESTIONNAIRE FOR LEGAL ENTITIES I. DETAILS OF THE LEGAL ENTITY`,
      `Name`,
      `Registration number`,
      `Registration date`,
      `Tax citizenship`,
      `Taxpayer Identification Number`,
      `Legal address`,
      `Actual address`,
      `Phone number`,
      `E-mail address`,
      `Web site (if available)`,
      `Description of the type of activity`,
      `DOCUMENTS OF A LEGAL ENTITY`,
      `Certified and apostilled registration certificate, including the details of the director, shareholder and beneficiary.`,
      `Confirmation of the address (bank statement with the address, utility bill).`,
      `III. DETAILS OF THE DIRECTOR`,
      `Name and surname`,
      `Date and place of birth`,
      `tax citizenship`,
      `Taxpayer Identification Number`,
      `Address`,
      `E-mail address`,
      `Phone number`,
      `Whether the director, a family member of the director or a person close to him is a politically vulnerable person. If yes, specify the name and position.`,
      `DIRECTOR'S DOCUMENTS`,
      `Passport (for EU citizens – passport or national identity card)`,
      `Confirmation of the address (bank statement with the address, utility bill)`,
      `Selfie photo with the document from point 1.`,
      `DETAILS AND DOCUMENTS OF THE SHAREHOLDER`,
      `If the shareholder is an individual, submit the details as in section III and the documents as in section IV.`,
      `If the shareholder is a legal entity, submit the details and documents as in sections I to VI. VI. DETAILS AND DOCUMENTS OF THE BENEFICIARY 1. Details as in section III, and documents as in section IV.`,
      `AUTOMATIC AML-VERIFICATION OF ONLINE ADDRESSES INVOLVED IN TRANSACTIONS ON THE SERVICE`,
      `15.1 The Cashex Service has the right to request User verification if the client's online wallet address specified in the application has a connection with the following terms: Mixing Service, Illegal Service, Fraudulent Exchange, Darknet Service, Darknet Marketplace, Ransom, Scam, Stolen Coins.`,
      `15.2 In case of confirmation of the connection, the funds will be sent to the return online wallet only after verification. The funds will be refunded minus the commission established by the rules of the exchange service.`
      // `Company Name: Smart Exchange LLC`,
      // `Organizational form: Limited Liability Company`,
      // `Registration date: 21/05/2021`,
      // `Registration Authority: LEPL National Agency of Public Registry`,
      // `Legal address: Georgia, Kutaisi, Avtomshenebeli Street, N88, Hualing Free Industrial Zone`,
      // `License: Free Industrial Zone License No.0110/462`,
      // `Country: Georgia`,

      // `Type of activity: Financial Service and Crypto Exchange`
    ],
    BUTTON: 'Go to privacy policy'
  },
  tu: {
    HEADING1: 'AML/KYC SİSTEMİ (KARA PARA AKLAMAYA KARŞI MÜCADELE / MÜŞTERİ GÜVENLİĞİNİN DOĞRULANMASI) VE ULUSLARARASI MALİ YAPTIRIM PROSEDÜRLERİ',
    HEADING2: 'AML YASASINI UYGULAMAK İÇİN İÇ KONTROL ÖNLEMLERİ',
    LIST: [
      `TANIMLAR VE KISALTMALAR`,
      // `1.1 Şirket – Smart Exchange LLC`,
      `1.2 Müşteri, Şirketin hizmet verdiği gerçek veya tüzel kişidir.`,
      `1.3 Kara para aklama – Bir suç faaliyetinden veya ondan elde edilen mülkten elde edilen mülkle ilgili gerçek niteliği, kaynağı, yeri, yeri, devri, mülkiyeti veya diğer hakların gizlenmesi veya gizlenmesi. Gizleme amacıyla değiştirme, devretme, satın alma, sahip olma veya kullanma, mülkün yasadışı kökenini gizleme veya eylemlerinin yasal sonuçlarından kaçınmak için suç faaliyetlerinde bulunan bir kişiye yardım etme. Kara para aklama, kara para aklamak için kullanılan mülkün satın alınmasına yol açan suç faaliyeti başka bir devletin topraklarında meydana geldiğinde de gerçekleşir.`,
      `1.4 Teröristlerin finansmanı, Ceza Kanunu maddelerine göre, terörist olan veya terör örgütlerini finanse etmek için eylemlerin yürütülmesini planlamak veya görevlendirmek veya bu tür fonların bu amaçla kullanıldığına dair bilgilerin bulundurulması amacıyla fonların tahsisini veya alınmasını ifade eder.`,
      `1.5 Uluslararası Yaptırımlar, Avrupa Birliği, Birleşmiş Milletler, başka bir uluslararası kuruluş veya Cumhuriyet Hükümeti tarafından barışı sağlamak veya sürdürmek, çatışmaları önlemek ve uluslararası güvenliği güçlendirmek, demokrasiyi, hukukun otoritesini, insan haklarını ve uluslararası hukuku desteklemek ve güçlendirmek amacıyla tanımlanan silahsız maruz kalma önlemleridir.`,
      `1.6 Politik açıdan savunmasız bir kişi (PEP), bir ülkenin başı, hükümet başkanı, bakan ve bakan yardımcısı veya yardımcısı, parlamento üyesi veya benzeri bir yasama organı üyesi, parti liderliği üyesi, Yüksek Mahkeme üyesi, yönetim kurulu üyesi, bir devlet kuruluşunun idari veya yönetici görevlisi, uluslararası bir organizasyonun başkanı, başkan yardımcısı ve yönetim organı veya benzeri bir kurumun üyesi rolü de dahil olmak üzere bir devlet organının önemli işlevlerini yerine getiren veya yerine getiren bir kişidir.`,
      // `1.7 Yerel PEP, Madde 1.6'da tanımlanan ve Gürcistan devlet organının önemli işlevlerini yerine getiren veya yerine getiren bir kişidir.`,
      `1.8 PEP ailesinin bir üyesi, o kişinin eşine, o kişinin çocuğuna ve o kişinin eşine veya o kişinin ebeveyne eşdeğer olduğu düşünülen bireye eşdeğer olarak kabul edilen bir kişidir.`,
      `1.9 Pep'e yakın bir kişi – bu kişi, kamuya açık bilgilere göre, bir ülkenin vatandaşı veya yerel sakini olan bir tüzel kişiliğin veya işletmenin sahibi-yararlanıcısı veya ortak sahibi olan, bir ülkenin vatandaşı veya yerel sakini ile yakın bir iş ilişkisine sahip olan veya kamuya açık bilgilere göre bir ülkenin vatandaşı veya yerel sakini lehine başarılı bir şekilde kurulmuş bir tüzel kişiliğin veya işletmenin tek sahibi-yararlanıcısı olan bir kişi anlamına gelir.`,
      `1.10 AML Yasası - haksız gelir beyannamesinin yasallaştırılmasının bastırılmasına yönelik yardım önlemlerini açıklar.`,
      `GENEL HÜKÜMLER`,
      `2.1 Bu Kılavuz, AML Yasası ve Uluslararası Yaptırımlar Yasası temelinde hazırlanmıştır ve iç kullanıma yöneliktir.`,
      `2.2 Bu Kılavuz, kara para aklamanın ve terörizmin önlenmesine yönelik kapsamlı yasal incelemenin gerekliliklerinin yanı sıra uluslararası yaptırımların gerekliliklerine ve şüpheli ve standart dışı işlemlerin tespit edilmesine yönelik iç güvenlik önlemlerini özetlemektedir.`,
      `2.3 Şirket çalışanları, AML Yasası, uluslararası yaptırımlar, kara para aklamanın tanımlanması, terörizmin finansmanı ve şüpheli operasyonların tanımlanması ile ilgili talimatlar ve AML Yasası ve bu Kılavuzun gerekliliklerine uymak için diğer talimatların gerekliliklerine aşina olmalı ve sıkı bir şekilde uymalıdır.`,
      `2.4 Şirket çalışanları, yasalara ve diğer yasal düzenlemelere ek olarak bağımsız olarak bilgi sahibi olmalıdır.`,
      `2.5 Şirketin Yönetim Kurulu bu talimatları tüm çalışanlara uygulamakla yükümlüdür.`,
      `2.6 Şirket çalışanlarının bu talimatları okuduğunu onaylamaları gerekmektedir.`,
      `2.7 Şirket çalışanları, yasaların izin verdiği prosedür uyarınca AML Yasası'nın gerekliliklerine uymaktan kişisel olarak sorumludur.`,
      `MÜŞTERİ İLİŞKİLERİ VE TANIMLAMA`,
      `3.1 Şirket çalışanları, Müşteriyle iş ilişkisine girmeden önce her seferinde aşağıdaki davranış kurallarını uygulamalıdır.`,
      `3.2 Şirket, bir kişinin yetkili temsilcisiyle çalışarak müşteriyle ilişki kurmaz.`,
      `3.3 Müşteri - birey aşağıdaki gibi tanımlanır:`,
      `3.3.1 Müşteri, kimlik belgesi, kişisel verilerin bulunduğu sayfanın bir kopyası ve Şirketin müşteri tabanında saklanan fotoğraflar temelinde tanımlanır. Aşağıdaki belgelerin tanımlanması gerçekleştirilir: kimlik kartı, yabancının pasaportu, oturma izni veya sürücü belgesi, başka bir ülkenin vatandaşı için seyahat belgesi (pasaport) bulunmaktadır.`,
      `3.3.2 Her müşteri için aşağıdaki veriler kaydedilmelidir:`,
      `3.3.2.1 İlk ad ve soyadı.`,
      `3.3.2.2 Kişisel numara, doğum tarihi ve yeri.`,
      `3.3.2.3 Kişiyi tanımlamak ve doğrulamak için kullanılan belgenin adı, numarası, yayınlanma tarihi ve yayın organının adı.`,
      `3.3.2.4 İkamet yerinin adresi.`,
      `3.3.2.5 İletişim bilgileri: e-posta adresi ve telefon numarası.`,
      `3.3.2.6 Kişinin PEP işlevlerini yerine getirip getirmediğini veya gerçekleştirip gerçekleştirmediğini.`,
      `3.3.2.7 Kişinin bir Pep'in ortağı mı yoksa ailesinin bir üyesi mi olduğu.`,
      `3.3.3 Müşteriyi tanımlamak için aşağıdaki belgeler gereklidir:`,
      // `3.3.3.1 Kimlik belgesi. Gürcistan vatandaşı olması durumunda - Gürcistan vatandaşı pasaportu, kimlik kartı, yabancının pasaportu, oturma izni, Gürcistan'da verilen ehliyet. Başka bir ülkenin vatandaşı olması durumunda - seyahat belgesi veya pasaport.`,
      `3.3.3.2 İkamet yerinin adresini teyit eden belge. Bu belge, bir fatura, hesap bakiyesinin banka ekstresi veya bir adrese işaret eden ve bundan 3 ay önce verilmiş olan diğer benzer belgeler olabilir.`,
      `3.4 Müşteri - tüzel kişilik aşağıdaki gibi tanımlanır:`,
      `3.4.1 Müşteri, bundan 3 ay önce verilmiş, onaylanmış ve mümkünse apostile edilmiş Ticari Kayıt Defterinden alınan bir ekstreye dayanarak tanımlanır.`,
      `3.4.2 Her tüzel kişilik için aşağıdaki veriler kaydedilmelidir:`,
      `3.4.2.1 İsim`,
      `3.4.2.2 Kayıt numarası ve kayıt tarihi.`,
      `3.4.2.3 Yasal adres ve gerçek adres.`,
      `3.4.2.4 Temsilcinin, hissedarların ve yararlanıcı sahibinin verileri.`,
      `3.4.2.5 İletişim bilgileri: e-posta adresi ve telefon numarası.`,
      `3.4.2.6 Tüzel kişilikteki her birey için (örneğin, müdür, hissedarlar, yararlanıcı sahibi), Madde 3.3'te belirtilen veriler ve belgeler bulunmaktadır.`,
      `RİSK DEĞERLENDİRMESİ VE UYGULANAN KONTROLLERİN DÜZEYİNİ BELİRLEME`,
      `4.1 Müşteriyle iş ilişkisi kurarak Şirket, kara para aklama ve terörizmin finansmanı riskini değerlendirmeli ve buna göre uygun kapsamlı yasal doğrulama önlemlerini seçmeli ve uygulamalıdır.`,
      `4.2 Kara para aklama ve terörizmin finansmanı risk düzeyini değerlendirirken aşağıdaki kategoriler dikkate alınmalıdır:`,
      `4.2.1 Coğrafi risk.`,
      `4.2.2 Müşteri riski.`,
      `4.2.3 Operasyonel risk.`,
      `4.3 Müşterinin veya anlaşmanın aşağıdaki ülke veya bölgelerle bilinen bir bağlantısı varsa coğrafi risk yüksek kabul edilir:`,
      `4.3.1 BM veya Avrupa Birliği yaptırımlarına, ambargoya veya diğer benzer önlemlere maruz kalan ülkeler ve bölgeler.`,
      `4.3.2 Kara para aklamayı ve terörizmin finansmanını önlemek için uygun önlemleri olmayan ülkeler.`,
      `4.3.3 Güvenilir verilere göre, terörizmi desteklemeye dahil olan veya yüksek düzeyde yolsuzluğa sahip olan ülkeler.`,
      `4.3.4 Yüksek riskli ülkeler hakkında bilgi için bağlantıya bakın http://www.fatf-gafi.org/countries/#high-risk`,
      `4.4 Müşteri riski, müşteri ise yüksek kabul edilir:`,
      `4.4.1 Pep'dir, ailesinin bir üyesidir veya Pep'e yakın bir kişidir. Şirket çalışanları, Müşteriyle iş ilişkisi kurmadan ve işlem yapmadan önce müşterilerin söz konusu kişi olup olmadığını belirlemelidir.`,
      `4.4.2 BM veya Avrupa Birliği tarafından uluslararası mali yaptırımlara maruz kalan kişiler listesine dahil edilmiştir. Şirket çalışanlarının, müşteri verilerinin doğrulanmasıyla bir iş ilişkisi kurmadan önce bu bilgileri takip etme sorumluluğu vardır.`,
      `4.4.3 Daha önce kara para aklama veya terörizmin finansmanına karıştığından şüphelenilen bir kişidir.`,
      `4.5 Aşağıdaki durumlarda operasyonel risk yüksek kabul edilir:`,
      `4.5.1 Anlaşmanın taraflarından biri olmayan bir kişi, işlemin bedelini ödeyecektir.`,
      `4.5.2 Bir işlemin sonuçlandırılması gerekir, bunun amaçlarından biri gerçek anlaşma katılımcılarının listesini gizlemek olmalıdır.`,
      `4.5.3 Açıkça haklı bir ticari, ekonomik, vergi veya yasal amacı olmayan bir işlemin sonuçlandırılması gerekmektedir.`,
      `4.5.4 Sanal para birimleri her zaman farklı adreslerden gelir veya para çeşitli hesaplardan aktarılır.`,
      `4.5.5 Sanal para birimi veya para cinsinden tutarlar müşteri için standart değildir.`,
      `4.5.6 Müşteri her seferinde farklı sanal para birimleri alır.`,
      `4.6 Herhangi bir nedenden ötürü, müşterinin veya müşterinin imzaladığı işlemin kara para aklama veya terörizmin finansmanı ile ilgili olabileceğinden şüpheleniliyorsa, kara para aklama veya terörizmin finansmanı riski yüksek kabul edilir.`,
      `4.7 Şirket şu durumlarda hizmet sunmaz veya müşteri ilişkileri kurmaz:`,
      `4.7.1 Bunlar yüksek riskli ülkelerin vatandaşlarıdırhttp://www.fatf-gafi.org/countries/#high-risk ).`,
      `4.7.2 Bunlar uluslararası mali yaptırımlar listesindeki kişilerdir.`,
      `4.7.3 Bunlar Pep'dir, aile üyeleri veya onlara yakın olan kişilerdir.`,
      `4.7.4 Daha önce kara para aklama veya terörizmin finansmanına karıştığından şüphelenilen kişiler.`,
      `4.8 Madde 4.7'de listelenmeyen risklerle ilgili olarak, müşteriye kapsamlı yasal incelemenin artırılmış önlemleri uygulanmalıdır.`,
      `BT RİSKLERİ VE KONTROLLERİ`,
      `5.1 Kullanılan teknolojilerle ilgili riskler:`,
      `5.1.1 Bilgi sızıntısı;`,
      `5.1.2 Yanlış bilgi verilmesi;`,
      `5.1.3 Kötü amaçlı yazılım ve siber saldırı;`,
      `5.1.4 Bilgi sisteminin çalışmasıyla ilgili riskler.`,
      `5.2 Bilgi sızıntısı riskini azaltmak için Şirket çalışanları zorunludur:`,
      `5.2.1 Yalnızca özel limited şirketlerin dahili sunucularını kullanın;`,
      `5.2.2 Şirketin Yönetim Kurulu tarafından onaylanan ve kurulan ve sürekli olarak güncellenen yazılımı kullanın;`,
      `5.2.3 Şirketin donanımını kullanın. Harici medya da dahil olmak üzere kendi donanımınızın kullanılması kesinlikle yasaktır.`,
      `5.3 Yanlış bilgi verme riskini azaltmak için:`,
      `5.3.1 Müşteriden gelen verileri doğrulamak için Şirket, kişisel görüşmede veya video konferans sırasında görüşmelerde bulunmalıdır.`,
      `5.3.2 Yanlış bilgi sağladığından şüpheleniyorsanız, Müşteriden destekleyici belgeler talep etmeniz gerekir.`,
      `5.4 Kötü amaçlı yazılım ve siber saldırı risklerini azaltmak için:`,
      `5.4.1 Şüpheli ve olağandışı işlemleri belirlemek için sistem sürekli olarak kontrol edilir.`,
      `5.4.2 Sistem güvenliği testleri sürekli olarak yapılır.`,
      `5.4.3 Sürekli güncellenen yazılım, kötü amaçlı yazılımları tespit etmek ve virüslerle savaşmak için kullanılır.`,
      `5.5 Bilgi sisteminin çalışmasıyla ilgili riskleri azaltmak için:`,
      `5.5.1 Ağın ve sunucunun güvenlik altyapısını kullanır.`,
      `5.5.2 Ayrı bir birincil sunucu ve yedekleme sunucusu kullanılır. Güvenlik amacıyla birincil sunucu ve yedekleme sunucusu farklı konumlarda bulunur.`,
      `5.5.3 Şirketin bilgi sistemi PCI/DSS standardına göre sertifikalandırılmıştır.`,
      `5.6 İhtiyaca göre, ancak yılda en az bir kez Şirket çalışanları için bilgi güvenliği eğitimi düzenlenmektedir.`,
      `KAPSAMLI YASAL DOĞRULAMA ÖNLEMLERİNİN UYGULANMASI`,
      `6.1 Anlaşmaya dahil olan bireylerin veya Müşterilerin eylemlerine ve makul bir ekonomik amacı olmayan karmaşık, pahalı ve standart dışı işlemler de dahil olmak üzere kara para aklamayı veya terörizmin finansmanını belirten veya ima eden koşullara özel dikkat gösterilmelidir.`,
      `6.2 Uygulanabilir kapsamlı yasal doğrulama önlemleri:`,
      `6.2.1 Kendisine sunulan belgeler ve verilere dayanarak ve video konferans yoluyla bir işlemde yer alan bir müşteriyi veya kişiyi tanımlamak.`,
      `6.2.2 Yararlanıcı sahibinin tanımlanması.`,
      `6.2.3 Müşterinin iş bağlantıları, işlemin amacı ve doğası hakkında bilgi edinme.`,
      `6.2.4 İş ilişkileri sırasında yapılan işlemlerin kontrolü, kimlik bilgilerinin düzenli olarak doğrulanması, ilgili belgelerin, verilerin ve bilgilerin güncellenmesi ve gerekirse işlemde kullanılan fonların kaynağının ve kökeninin tanımlanması da dahil olmak üzere müşterinin iş ilişkilerinin sürekli izlenmesi.`,
      `6.3 Kapsamlı bir yasal inceleme yapılırken, kurulacak gerçekler genellikle müşteri tarafından sunulan orijinal belgelere dayanarak belirlenir. Belgenin orijinali alınamazsa, avukat tarafından onaylanmış belgeler de dahil olmak üzere noter tasdikli veya resmi olarak onaylanmış belgeler kullanılabilir. Bu, risk derecesine göre uygun değilse, belgenin orijinalin bir kopyasının yazarı tarafından mühürlenmesi ve/ veya imzalanması gerekir ve elektronik ortamda (yeniden gönderilen yazılı olarak) aktarılabilir. Orijinaline uygunluğundan şüphe duyulduğunda kopyalara inanılamaz.`,
      `6.4 Doğrulama, yabancı bir kredi kuruluşunun ticari siciline, kredi kurumuna veya şubesine veya eşit AML/KYC gereksinimlerinin bulunduğu başka bir ülkede kayıtlı olan kredi kurumuna uygun biçimde yazılmış bilgilere güvenebilir.`,
      `6.5 Yukarıda belirtilen kapsamlı yasal doğrulama önlemleri, bir iş ilişkisine başlamadan veya anlaşma yapılmadan önce uygulanmalıdır.`,
      `6.6 Müşterinin kimliği belirlenebilir ve sağlanan bilgiler, bir iş ilişkisinin başlangıcında veya bir işlemin sonuçlandırılması sırasında, işin normal seyrinin bozulmasını önlemek için gerekirse ve kara para aklama veya terörizmin finansmanı riski düşükse doğrulanabilir. Böyle bir durumda, kapsamlı yasal doğrulama önlemleri, ilk temas kurulduktan sonra ve herhangi bir bağlayıcı önlem alınmadan önce mümkün olan en kısa sürede tamamlanmalıdır.`,
      `6.7 Mümkün olan yerlerde, bir ticarete veya profesyonel faaliyete katılan bir kişi veya Müşteri, kapsamlı yasal doğrulama önlemleri için sunulan bilgilerin ve belgelerin doğruluğunu imzalayarak onaylamalıdır.`,
      `KAPSAMLI KAPSAMLI YASAL DOĞRULAMA ÖNLEMLERİNİN UYGULANMASI`,
      `7.1 Kapsamlı yasal doğrulama önlemleri, aşağıdaki durumlarda genişletilmiş kapsamda uygulanmalıdır:`,
      `7.1.1 İşlemde yer alan kişinin veya müşterinin kimliği belirlenir ve bilgiler, doğrulanan kişinin veya müşterinin bulunduğu yere uygun olmayan bir yerden sağlanır.`,
      `7.1.2 Tanımlama veya doğrulama, belgenin orijinalliğini sorgulamaya veya şüphe etmeye neden olur veya yararlanıcının sahibini (sahiplerini) tanımlamanın bir yolu yoktur.`,
      `7.1.3 Aslında durum, kara para aklama veya terörizmin finansmanı için yüksek risk anlamına gelir.`,
      `7.2 Şirket çalışanı, aşağıdaki kapsamlı kapsamlı yasal doğrulama önlemlerinden en az birini uygulamalıdır:`,
      `7.2.1 Güvenilir ve bağımsız bir kaynaktan elde edilen ek belgelerden, verilerden veya bilgilerden elde edilen bilgilerin veya kredi kurumunun ticari sicilinden veya AML/KYC'YE eşit gereksinimlerin olduğu bir ülkede kayıtlı veya iş yapan yabancı bir kredi kurumunun veya kredi kuruluşunun şubesinden elde edilen bilgilerin tanımlanması ve doğrulanması ve bir bireyin kimliği bu kredi kurumuna o kişinin huzurunda kurulmuşsa.`,
      `7.2.2 Belgelerin geçerlilikleri için elektronik olarak teslim edilmesinin uygulanması ve bunların içinde yer alan bilgilerin doğruluğunu doğrulamak, bunların noter tasdiki veya toplanan verilerin doğruluğunun resmi kanıtı da dahil olmak üzere veya 7.2.1. maddede belirtilen kredi kurumu tarafından verilen belge de dahil olmak üzere, bunların doğruluğu ve doğruluğunu doğrulamak.`,
      `7.2.3 Aynı AML/KYC gerekliliklerine sahip bir ülkede kayıtlı veya faaliyet gösteren bir kredi kuruluşuyla, işlemde yer alan kişinin adına açılan bir hesaba ilk ödemenin yapılması.`,
      `PEP TANIMLAMA`,
      `8.1 Bir iş ilişkisi kurarak (tüketici anlaşması yapıldıktan sonra) Müşteri, tanımlama ve doğrulama için gerekli olan ve yasaya göre zorunlu olan temel zorunlu verileri girdiği formu doldurur.`,
      `8.2 Diğer bilgiler arasında Müşteri, kendisinin, ailesinin bir üyesi veya yakın bir Pep'in (Politik açıdan savunmasız bir kişi) ortağı olup olmadığını belirtmelidir.`,
      `8.3 Müşterinin aile üyesi veya yakın ortağı PEP ise, Müşteri bu kişinin verilerini de belirtmelidir.`,
      `8.4 PEP ile ilgili bilgiler, çalışanlar tarafından Şirket tarafından web sitesi gibi kamu kaynaklarından alınan bilgiler için doğrulanır https://namescan.io/FreePEPCheck.aspx`,
      `ULUSLARARASI YAPTIRIMLARA MARUZ KALAN BİR KİŞİNİN TANIMLANMASI`,
      `9.1 Bir iş ilişkisi kurarak (tüketici anlaşması yapıldıktan sonra) Müşteri, tanımlama ve doğrulama için gerekli olan ve yasaya göre zorunlu olan temel zorunlu verileri girdiği formu doldurur.`,
      `9.2 Müşteri tarafından sağlanan bilgilere göre, Şirket çalışanı Müşteriye uygulanan uluslararası yaptırımları denetlemektedir.`,
      `KARA PARA AKLAMA ŞÜPHELERİNİN TANIMLANMASI`,
      `10.1 Bu bölüm, kara para aklama şüphesine işaret eden ve Şirket çalışanlarının özellikle dikkat etmesi gereken koşulları özetlemektedir.`,
      `10.2 "Kendi kendine teslim". Şüpheli işaretler:`,
      `10.2.1 Bir kişinin görünüşü ve davranışı, söz konusu kişi tarafından yapılan anlaşmanın özüne uymuyor veya davranışları şüphelidir.`,
      `10.2.2 Kişi belgeleri imzalayamaz veya bu amaçla üçüncü taraf yardımını kullanamaz. 10.2.3 Kişi zaten "çifte oyun" dan şüphelenmişti.`,
      `10.3 Kişi bu hizmetin gerekliliğini açıklayamaz.`,
      `10.4 Kişi makul olmayan yüksek bir ücret talep ediyor.`,
      `10.5 Standart olmayan nakit işlem.`,
      `10.6 Böyle bir faaliyetin bir kişinin ekonomik faaliyetine uygun olmadığı veya sıra dışı olduğu durumlarda, sanal para birimi ile ayrı büyük veya periyodik küçük işlemler.`,
      `10.7 Sanal para birimi için alınan para bir yabancıya veya başka bir ülkedeki bir banka hesabına aktarılır.`,
      `10.8 Kişi işlemle ilgili veri ve/veya açıklama sunmaz.`,
      `10.9 Bu kişinin normal iş akışına uygun olmadıkları veya sıra dışı olmaları durumunda büyük miktarda sanal para birimi değiştirilir.`,
      `10.10 Kişi size bilgi vermemek için tanımlanamaz veya denenemez.`,
      `10.11 Kişi hayali bir anlaşmaya girmeye çalışıyor.`,
      `10.12 Müşterilerle uzun vadeli ilişkiler kurarken, bir kişi yalnızca nakit olarak ödeme yapmak ister.`,
      `10.13 Kişinin birinin yararına hareket ettiğine dair bir şüphe var.`,
      `10.14 Kişi nakit olarak 10 bin Euro'dan fazla ödeme yapmak istiyor.`,
      `10.15 kişi sürekli olarak nakit olarak 10.000 Euro'nun üzerindeki tutarlara göre hesaplanır.`,
      `10.16 Ödeme, vergiden muaf bir bölgede kurulmuş bir banka aracılığıyla yapılır.`,
      `SÖZLEŞMENİN REDDEDİLMESİ VE İŞLEM YAPILMASI`,
      `11.1 Şirket sözleşme yapmaz veya işlem yapmaz:`,
      `11.1.1 18 yaşından küçük kişilerle birlikte.`,
      `11.1.2 Müşterinin yetkili temsilcileri ile – gerçek kişi.`,
      `11.1.3 Bu Kılavuzun 3. maddesinde belirtilen bilgileri ve belgeleri vermeyi reddeden veya gerekenden daha az bilgi veren veya bir şeyi gizlemeye çalışan bir kişi ile.`,
      `11.1.4 Provokasyondan şüphelenilen kişiyle.`,
      `11.1.5 Belgeleri gönderen veya Şirketin kara para aklama veya terörizmin finansmanı şüphesini doğrulayan bilgileri aldığı kişiyle.`,
      `11.1.6 Uluslararası mali yaptırımlara maruz kalan bir kişiyle.`,
      `11.1.7 PEP olan veya aile üyesi PEP olan bir kişiyle veya Pep'e yakın bir kişidir.`,
      `11.1.8 Daha önce kara para aklama veya terörizmin finansmanına karıştığından şüphelenilen biriyle.`,
      `11.1.9 Yüksek riskli bir ülkenin vatandaşı ile http://www.fatf-gafi.org/countries/#high-risk .`,
      `VERİLERİN TOPLANMASI, DEPOLANMASI VE KORUNMASI`,
      `12.1 Anlaşmaya girdikten sonra Şirket çalışanı aşağıdaki bilgileri kaydettirmekle yükümlüdür:`,
      `12.1.1 Bu Kılavuzun 3. fıkrasına uygun olarak, işlemde yer alan kişinin ayrıntıları.`,
      `12.1.2 İşlemin tarihi veya dönemi.`,
      `12.1.3 İşlemin içeriğinin açıklaması.`,
      `12.1.4 Bir iş ilişkisi kurmayı veya bir anlaşmaya girmeyi reddetmeyle ilgili bilgiler.`,
      `12.1.5 Müşterinin inisiyatifiyle bir iş ilişkisinin kurulmasını veya bir işlemin yapılmasını reddetmekle ilgili bilgiler.`,
      `12.1.6 Kapsamlı yasal doğrulama önlemlerinin uygulanamaması da dahil olmak üzere iş ilişkilerinin sona ermesiyle ilgili bilgiler.`,
      `12.1.7 Nakit karşılığında sanal para birimi değişimi hizmeti, para birimi cinsinden tutar, sonuçtaki para miktarı ve döviz kuru.`,
      `12.1.8 Sanal para biriminin başka bir sanal para birimine karşı döviz kuru, para birimi cinsinden tutar, diğer para birimindeki tutar ve döviz kuru.`,
      `12.1.9 Sanal para biriminde bir hesap açtığınızda, hesabın para biriminin türü, numarası ve adı belirtilir.`,
      `12.2 Şirket, iş ilişkisinin sona ermesinden veya son işlemin tamamlanmasından en az beş (5) yıl sonra aşağıdaki belgeleri tutmalıdır:`,
      `12.2.1 Verileri ve belgeleri tanımlamak ve doğrulamak için bilgiler.`,
      `12.2.2 Müşteriyle yazışmalar.`,
      `12.2.3 İş ilişkilerinin izlenmesi sırasında toplanan veriler.`,
      `12.2.4 Şüpheli ve standart olmayan işlemlerle ilgili veriler.`,
      `12.2.5 İşlem belgeleri.`,
      `12.3 Şirket çalışanları, veri ve belgelerin toplanmasında ve depolanmasında kişisel verilerin korunması için kurallar uygulamalıdır. Toplanan veriler yalnızca kara para aklamayı önlemek ve terörizmi finanse etmek amacıyla işlenebilir. Verilerin bu amaca uygun olmayan bir şekilde işlenmesi kesinlikle yasaktır.`,

      `FİNANSAL İZLEME HİZMETİ BİLDİRİMİ`,
      `13.1 Bir Şirket çalışanı kara para aklama veya terörizmin finansmanına işaret eden bir eylem veya durum tespit ettiyse veya bunun kara para aklama veya terörizmin finansmanı olduğuna dair bir şüphe veya güvence varsa, şirketin Mlro'suna (Kara Para Aklama Müfettişine) derhal bildirilmelidir.`,
      `13.2 Müşteriye MLRO bilgilerinin aktarıldığını bildirmesi yasaktır.`,
      `13.3 Bir MLRO çalışanı, işlemin dayandığı belgelerin kopyalarını ve kişiyi tanımlayabileceğiniz belgelerin kopyalarını tamamlanmış bildirim formuna eklemelidir. Bildirime, işlemin niteliğini kanıtlayan diğer belgelerin kopyaları eşlik edebilir.`,
      `İÇ KONTROL VE EĞİTİM`,
      `14.1 AML Yasası'na, Uluslararası Yaptırım Yasası'na ve buna dayalı mevzuata uygunluk, Şirketin Yönetim Kurulu tarafından izlenir ve yönetilir.`,
      `14.2 Risk değerlendirmesi ve müşteri kimliği, Şirketin özel eğitimli çalışanları tarafından gerçekleştirilir ve bunların hepsi Şirketin Yönetim Kurulu tarafından yönetilir.`,
      `14.3 Müşterilerin faaliyetleri ve faaliyetleri, Şirketin özel eğitimli çalışanları tarafından denetlenir ve bunların hepsi Şirketin Yönetim Kurulu tarafından yönetilir.`,
      `14.4 Şirketin Yönetim Kurulu, kara para aklamanın önlenmesi ve terörizmin finansmanı ve uluslararası yaptırımların gerekliliklerine uyulması konusunda Şirket personelini eğitmekle sorumludur.`,
      `14.5 Çalışanların yasa ve diğer mevzuattaki değişiklikleri bağımsız olarak iki kez kontrol etmeleri gerekmektedir.`,
      `14.6 Şirket çalışanlarının eğitimi gerektiğinde gerçekleşir, ancak yılda en az bir kez gerçekleşir.  `,
      `Ek 1 BİREYLER İÇİN KYC (MÜŞTERİ GÜVENLİĞİ KONTROLLERİ) ANKETİ I. AYRINTILAR`,
      `İlk ad ve soyadı`,
      `Doğum tarihi ve yeri`,
      `Vergi vatandaşlığı`,
      `Vergi Kimlik numarası`,
      `Adres`,
      `E-posta adresi`,
      `Telefon numarası`,
      `Müşterinin, müşterinin ailesinin bir üyesinin veya ona yakın bir kişinin politik açıdan savunmasız bir kişi olup olmadığı. Eğer öyleyse, bir isim ve konum belirtin.`,
      `EVRAK`,
      `Pasaport (AB vatandaşları için - pasaport veya ulusal kimlik kartı)`,
      `Adres onayı (adresli banka ekstresi, fatura)`,
      `1. maddeden belgeli bir selfie fotoğrafı.  `,
      `Ek 2 TÜZEL KİŞİLER İÇİN KYC ANKETİ I. TÜZEL KİŞİLİK DETAYLARI`,
      `Ad`,
      `Kayıt numarası`,
      `Kayıt tarihi`,
      `Vergi vatandaşlığı`,
      `Vergi Kimlik numarası`,
      `Yasal adres`,
      `Gerçek adres`,
      `Telefon numarası`,
      `E-posta adresi`,
      `Web sitesi (varsa)`,
      `Faaliyet türünün tanımı`,
      `TÜZEL KİŞİLİK BELGELERİ`,
      `Müdürün, hissedarın ve yararlanıcının ayrıntılarını içeren onaylanmış ve apostile edilmiş bir kayıt belgesi.`,
      `Adresin onaylanması (adrese sahip banka ekstresi, fatura).`,
      `III. MÜDÜRÜN DETAYLARI`,
      `İlk ad ve soyadı`,
      `Doğum tarihi ve yeri`,
      `vergi vatandaşlığı`,
      `Vergi Kimlik numarası`,
      `Adres`,
      `E-posta adresi`,
      `Telefon numarası`,
      `Müdürün, müdürün ailesinin bir üyesi veya ona yakın bir kişinin politik açıdan savunmasız bir kişi olup olmadığı. Eğer öyleyse, bir isim ve konum belirtin.`,
      `MÜDÜRÜN BELGELERİ`,
      `Pasaport (AB vatandaşları için - pasaport veya ulusal kimlik kartı)`,
      `Adres onayı (adresli banka ekstresi, fatura)`,
      `1. maddeden belgeli bir selfie fotoğrafı.`,
      `HİSSEDARIN DETAYLARI VE BELGELERİ`,
      `Hissedar bir bireyse, Bölüm Iıı'teki gibi ayrıntıları ve Bölüm Iv'teki gibi belgeleri gönderin.`,
      `Hissedar tüzel kişilik ise, ayrıntıları ve belgeleri I'den Vı'ya kadar olan bölümlerde olduğu gibi sunun. VI. YARARLANICININ DETAYLARI VE BELGELERİ 1. Bölüm Iıı'teki gibi ayrıntılar ve Bölüm Iv'teki gibi belgeler.`,
      `HİZMETTEKİ İŞLEMLERE KATILAN ÇEVRİMİÇİ ADRESLERİN OTOMATİK AML DOĞRULAMASI`,
      `15.1 Cashex, başvuruda belirtilen müşterinin çevrimiçi cüzdan adresinin aşağıdaki terimlerle bağlantılı olması durumunda Kullanıcının doğrulanmasını talep edebilir: Karıştırma Hizmeti, Yasadışı Hizmet, Aldatıcı Değişim, Karanlık Ağ Hizmeti, Karanlık Ağ Pazarı, Ransom, Aldatmaca, Stolen Coins.`,
      `15.2 İletişim onaylanırsa, fonlar yalnızca doğrulama yapıldıktan sonra iade edilen çevrimiçi cüzdanınıza gönderilecektir. Fonlar, döviz hizmetleri kuralları tarafından belirlenen komisyonun eksi olarak iade edilecektir.`

      // `Şirket Adı: Smart Exchange LLC`,

      // `Organizasyon Formu: Limited Liability Company`,

      // `Kayıt tarihi: 21/05/2021`,

      // `Kayıt Organı: LEPL National Agency of Public Registry`,

      // `Yasal Adres: Georgia, Kutaisi, Avtomshenebeli Caddesi, N88, Hualing Free Industrial Zone`,

      // `Lisans: Free Industrial Zone Licence No.0110/462`,

      // `Ülke: Georgia`,

      // `Faaliyet türü: Financial Service and Crypto Exchange`
    ],
    BUTTON: 'Gizlilik politikasına git'
  }
}
