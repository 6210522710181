import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { BidStatus, otherLinks } from '../utils/consts'
import { RightVector, StepIcons } from './icons'
import useDocumentWidth from '../hooks/useDocumentWidth'
import Button from './buttons/button'
import { setBidStep } from '../store/reducers/bidReducer'
import useGetText from '../hooks/useGetText'
import { finishVerification } from '../store/actions/bidActions'

const BidSteps = () => {
  const { status_code, created_at, hash, payment_details_get, payment_details_give, transactionInfo } = useAppSelector(state => state.bid)
  const { language } = useAppSelector(state => state.main)

  const { exchangeText } = useGetText()
  const dispatch = useAppDispatch()

  const date = useMemo(() => dayjs(created_at).add(-3, 'hour').format('DD.MM.YYYY  HH:mm:ss'), [created_at])

  const { lg }: any = useDocumentWidth()

  const addFavorites = () => {
    if (language === 'ru') alert('Добавьте сайт в закладки комбинацией клавиш:\nCTRL + D / ⌘ + D')
    else alert('Add the site to your bookmarks with the following keys:\nCTRL + D / ⌘ + D')
  }

  const steps = [
    {
      text: (
        <>
          {exchangeText.BID_STATUS.PENDING} {date}{' '}
        </>
      ),
      status: 'success'
    },
    {
      text: exchangeText.BID_STATUS.REALPAY,
      status: `${status_code === BidStatus.CREATED ? 'loading' : status_code === BidStatus.PENDING || status_code === BidStatus.RECEIVED || status_code === BidStatus.DONE ? 'success' : ''}`
    },
    {
      text: exchangeText.BID_STATUS.RECIVED,
      status: `${status_code === BidStatus.PENDING ? 'loading' : status_code === BidStatus.RECEIVED || status_code === BidStatus.DONE ? 'success' : ''}`
    },
    {
      text: exchangeText.BID_STATUS.DONE,
      status: `${status_code === BidStatus.RECEIVED ? 'loading' : status_code === BidStatus.DONE ? 'success' : ''}`
    }
  ]

  const getBidInfo = () => {
    // dispatch(finishVerification(hash))
    if (payment_details_get === '' && payment_details_give === '') {
      // dispatch(finishVerification(hash))
    }
    dispatch(setBidStep(1))
  }

  return (
    <>
      <div className='container pb-5 animate__animated animate__fadeInLeft wow' data-wow-duration='.4s'>
        <div className='row'>
          <div className='col-lg-8'>
            <div>
              <div className='col-lg-11'>
                <p>
                  <strong>{exchangeText.BID_STATUS_HEADING}</strong>
                </p>
                <p>{exchangeText.BID_STATUS_DESCRIPTION}</p>
              </div>
              <div className='steps-wrapper'>
                {steps.map((el, idx) => (
                  <div className='step-item' key={idx}>
                    <StepIcons status={el.status} />
                    <small>{el.text}</small>
                    {idx !== steps.length - 1 && (
                      <span className='arr-right'>
                        <RightVector />
                      </span>
                    )}
                  </div>
                ))}
              </div>
              <div className='d-flex flex-xl-row d-lg-column d-md-row flex-column g-g-10 mt-5'>
                <Button onClick={getBidInfo} variant='outlined' className='req-info-btn btn-pulse'>
                  {exchangeText.BID_INFO_BTN}
                </Button>
                <Button onClick={() => window.open(language === 'ru' ? otherLinks.BESTCHANGE : otherLinks.BESTCHANGE_EN)} style={{ background: '#1F82F6' }} variant='primary' className=''>
                  {exchangeText.LEAVE_FEEDBACK}
                </Button>
                <Button onClick={addFavorites} variant='outlined' className=''>
                  {exchangeText.ADD_FAVORITES}
                </Button>
                {/*{transactionInfo !== null ? (*/}
                {/*  transactionInfo?.urlList?.map((item: string, idx: number) => (*/}
                {/*    <Button key={idx} variant='outlined' onClick={() => window.open(item)}>*/}
                {/*      {exchangeText.TRANSACTION_LINK}*/}
                {/*    </Button>*/}
                {/*  ))*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}

                {transactionInfo &&
                    <Button variant='outlined' onClick={() => window.open(transactionInfo)}>
                      {exchangeText.TRANSACTION_LINK}
                    </Button>
                }
              </div>
            </div>
          </div>
          {lg && (
            <div className='col-lg-4'>
              <div className='d-flex justify-content-end flex-column'>
                <img src='/images/steps-icon.svg' alt='' className='img-fluid' />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default BidSteps
