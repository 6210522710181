import React, { FC, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useApiLk from '../hooks/useApiLk'
import useGetText from '../hooks/useGetText'
import { fetchLogout } from '../store/actions/userActions'
import { ADMIN_EMAIL, path } from '../utils/consts'
import { NavsInterface } from '../utils/interfaces'
import Button from './buttons/button'

type navsOption = {
  [key: string]: NavsInterface[]
}

interface HeaderInterface {
  className?: string
  classNameA?: string
  navs: navsOption
  isLogout?: boolean
  sideMenu?: boolean
}

const HeaderNavItems: FC<HeaderInterface> = ({ className, classNameA, sideMenu = false, navs, isLogout }) => {
  const { language } = useAppSelector(state => state.main)
  const { isAuth, email } = useAppSelector(state => state.user)
  const { isAdmin } = useAppSelector(state => state.admin)
  const { adminText } = useGetText()
  const { isLK } = useApiLk()

  const dispatch = useAppDispatch()
  const { authTexts } = useGetText()

  return (
    <ul className={className}>
      {navs[language]?.map((el: NavsInterface, idx: number) => (
        <li key={idx}>
          <NavLink className={classNameA} to={el.link}>
            {el.text}
          </NavLink>
        </li>
      ))}
      {isAuth && (email === 'info@cashex.cc' || email === 'auto-exchanges@autoex.com') && sideMenu && (
        <li>
          <NavLink className={classNameA} to={path.CARDS}>
            {adminText.CARDS}
          </NavLink>
        </li>
      )}
      {!isLK && isLogout && (
        <li className='w-100 mt-2'>
          <Button variant='secondary' className='w-100' onClick={() => dispatch(fetchLogout())}>
            {authTexts?.LOGOUT}
          </Button>
        </li>
      )}
    </ul>
  )
}

export default HeaderNavItems
