import { FC } from 'react'
import useGetText from '../hooks/useGetText'
import Button from './buttons/button'
import { Link } from 'react-router-dom'
import { path } from '../utils/consts'
import clsx from 'clsx'

interface CookieProps {
  closeCookie: () => void
  openCookies: boolean
}

const Cookie: FC<CookieProps> = ({ closeCookie, openCookies }) => {
  const { mainPageTexts } = useGetText()

  return (
    <div className={clsx('cookie', { open: openCookies })}>
      <div className='cookie__header'>
        <div className='cookie__image'>
          <img src='/images/icons/cookies.svg' alt='cookie' />
          Cookies!
        </div>
        <div onClick={closeCookie} className='cookie__close'>
          {mainPageTexts.CLOSE_BUTTON}
          <img src='/images/icons/close.svg' alt='close' />
        </div>
      </div>
      <div className='cookie__text'>{mainPageTexts.COOKIES_TEXT}</div>
      <div className='cookie__buttons'>
        <Button onClick={closeCookie} variant='primary' className='cookie__button'>
          {mainPageTexts.COOKIES_ACCEPT}
        </Button>
        <Button onClick={closeCookie} variant='secondary' className='cookie__button'>
          {mainPageTexts.COOKIES_DECLINE}
        </Button>
      </div>
      <div className='cookie__links'>
        <Link onClick={closeCookie} to={path.PRIVACY_POLICY} className='cookie__link'>
          {mainPageTexts.COOKIES_POLITICS}
        </Link>
        <Link onClick={closeCookie} to={path.TERMS} className='cookie__link'>
          {mainPageTexts.COOKIES_POLICY}
        </Link>
        <Link onClick={closeCookie} to={path.AML} className='cookie__link'>
          {mainPageTexts.COOKIES_DISCLAIMER}
        </Link>
      </div>
    </div>
  )
}

export default Cookie
