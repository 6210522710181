import {ChangeEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks';
import {useCopyToClipboard} from "../../hooks/useCopyToClipboard";
import {fetchAddUserCardToVerification, fetchUserCardsInfo} from "../../store/actions/userActions";
import "../../styles/pages/my-cards.scss"
import {IUserCardsItem} from "../../store/reducers/userReducer";
import {PacmanLoader} from "react-spinners";
import {toast, ToastContainer} from "react-toastify";
import MyCardsSkeleton from "../../components/MyCardSkeleton";
import VerificationRules from "./components/VerificationRules";

export const statusList: {
  [key: string]: string
} = {
  'SUCCESS': 'Карта одобрена',
  'PENDING': 'Ожидание обработки',
  'CANCELED': 'Карта отклонена'
}


const MyCards = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  // const [selectedCard, setSelectedCard] = useState<{[N: string]: string | number;}>();
  const {copyToClipboard} = useCopyToClipboard()
  const [newCardValue, setNewCardValue] = useState('')
  const [newCardHolderValue, setNewCardHolderValue] = useState('')
  const [cardImage, setCardImage] = useState<null | File>(null)
  const [selectedCard, setSelectedCard] = useState<IUserCardsItem | null>(null)
  const [isDataSending, setIsDataSending] = useState(false)

  const {cardsInfo, isCardsInfoLoading} = useAppSelector((state) => state.user);
  const setPhotoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      setCardImage(e.target.files[0])
      e.target.value = ''
    }
  }
  /*const { client_id } = useAppSelector((state) => state.user);
  const { verifiedCards } = useAppSelector((state) => state.bid);*/

  const dispatch = useAppDispatch();

  const onCopyToClipboard = async (value: string | number) => {
    await copyToClipboard(value).then(
      () => toast.success('Номер карты скопирован!')
    )
  }

  useEffect(() => {
    dispatch(fetchUserCardsInfo())
  }, []);

  useEffect(() => {
    if (!selectedCard) {
      if (cardsInfo && cardsInfo.cards !== null && cardsInfo.cards[0].status === 'PENDING') {
        setSelectedCard(cardsInfo.cards[0])
      }
    } else if (cardsInfo && cardsInfo.cards !== null) {
      setSelectedCard(cardsInfo.cards[0])
    }
    setIsDataSending(false)
  }, [cardsInfo]);

  useEffect(() => {
    if (selectedCard !== null && selectedCard.status === 'PENDING') {
      const timeoutID = setInterval(() => {
        if (selectedCard !== null)
          dispatch(fetchUserCardsInfo())
      }, 30000);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [selectedCard]);

  /*function openCardImg(card: { [N: string]: string | number }) {
    setSelectedCard(card);
    setOpenModal(true);
  }*/

  /*useEffect(() => {
    const data = {
      client_id: client_id,
    };

  }, [client_id]);*/

  const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      setNewCardValue('');
    } else {
      let number: string = e.target.value.replace(/[^\d\s]/g, '');
      number = number.split(' ').join('')
      const chunks = (number.match(/.{1,4}/g) || []).join(' ')
      if (chunks.length <= 19)
        setNewCardValue(chunks);
    }
    e.target.maxLength = 30;
  }
  const handleCardholderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewCardHolderValue(e.target.value)
  }

  const onNewCardClick = () => {
    setSelectedCard(null)
  }

  const onVerificationClick = async () => {
    if (!cardImage) {
      console.log('Отсутствует картинка') //TODO: toast notification
      return;
    }
    if (newCardValue.length !== 19) {
      console.log('Не заполнен номер карты')
      return
    }
    if (!newCardHolderValue) {
      console.log('Имя держателя пустое')
      return;
    }

    const reader = new FileReader();


    reader.onload = (loadEvent) => {
      const img = new Image();
      img.src = loadEvent.target?.result as string;

      img.onload = function (e) {
        const MAX_WIDTH = 1680;
        const scaleSize = MAX_WIDTH / (e.target as HTMLImageElement).width;

        const canvas = document.createElement("canvas");
        canvas.width = MAX_WIDTH;
        canvas.height = (e.target as HTMLImageElement).height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx?.drawImage(e.target as HTMLImageElement, 0, 0, canvas.width, canvas.height);

        const srcEncoded = canvas.toDataURL("image/jpeg")
          .split(',')
          .slice(1, (loadEvent.target as FileReader).result?.toString().split(',').length)
          .join(',');

        setIsDataSending(true)
        dispatch(fetchAddUserCardToVerification({
          card_number: newCardValue,
          cardholder_name: newCardHolderValue,
          image: srcEncoded
        })).then(
          () => {
            setIsDataSending(false)
            setNewCardHolderValue('');
            setNewCardValue('');
            dispatch(fetchUserCardsInfo());
            toast.success('Заявка успешно создана!');
            setCardImage(null);
          }
        );
      };
    };
    reader.readAsDataURL(cardImage);

  }

  return (
    <div className={'container'}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      {/*<div className={'about-verification-block'}>
        <div>Почему мы требуем верификацию банковской карты и как это сделать?</div>
      </div>*/}
      <VerificationRules/>
      {
        isCardsInfoLoading
          ? <MyCardsSkeleton/>
          : <div className={'my-cards'}>
            <div className={'my-card-block-title'}>Ваши карты</div>
            <div className={'card-list-block'}>
              <div className={'card-list-block-item'}>
                <div className={'card-list-orange-top-border'}></div>
                <div className={'card-list-block-content'}>
                  {
                    isDataSending
                      ? <div className={'card-request-loader'}>
                        <PacmanLoader color="#002674"/>
                        Отправка заявки..
                      </div>
                      : <>
                        <div
                          className={'card-list-block-new-card-title'}>{selectedCard ? 'Последняя заявка' : 'Верифицировать новую карту'}</div>
                        <input className={'new-card-data-input'} type="text"
                               value={selectedCard ? selectedCard.card_number : newCardValue}
                               disabled={!!selectedCard}
                               onChange={handleCardNumberChange}
                               placeholder={'0000 0000 0000 0000'}
                               autoComplete={'cc-number'}
                        />
                        <input className={'new-card-data-input'} type="text"
                               disabled={!!selectedCard}
                               value={selectedCard ? selectedCard.cardholder_name : newCardHolderValue}
                               onChange={handleCardholderChange}
                               placeholder={'Иванов Иван Иванович'}
                               autoComplete={'name'}
                        />
                        <div className="new-card-action-buttons">
                          {!selectedCard
                            ? <label className={'load-image-button'} htmlFor='downloadPhoto'>
                              <input
                                type='file'
                                id='downloadPhoto'
                                accept="image/png, image/jpeg"
                                onChange={setPhotoHandler}
                              />
                              {cardImage ? (
                                'Фото загружено'
                              ) : (
                                'Загрузить фото карты')}
                            </label>
                            : <button
                              onClick={onNewCardClick}
                              className={'create-new-card-button'}>Новая заявка</button>
                          }
                          <div className={'new-card-action-buttons-right'}>
                            <button
                              className={`verification-status-button ${selectedCard ? 'status_' + selectedCard.status.toLowerCase() : ''}`}
                              onClick={onVerificationClick}
                              disabled={!!selectedCard}
                            >{
                              selectedCard
                                ? statusList[selectedCard.status]
                                : 'Верифицировать'
                            }
                            </button>
                          </div>
                        </div>
                      </>
                  }
                </div>
              </div>
              <div className={'my-cards-list-block-item'}>
                <div className={'card-list-orange-top-border'}></div>
                <div className={"my-cards-list-content"}>
                  {cardsInfo && cardsInfo.cards !== null
                    ? cardsInfo.cards.map(card => {
                      return <div className="verificated-card-item">
                        <div className={'verificated-card-number'}>{card.card_number}</div>
                        <div className={'copy-number-icon'} onClick={() => onCopyToClipboard(card.card_number)}>
                          {card.status === 'PENDING' &&
                            <img className={'pending-icon'} src="/images/icons/pending.svg" alt=""/>}
                          {card.status === 'SUCCESS' &&
                            <img className={'success-icon'} src="/images/icons/success.svg" alt=""/>}
                          {card.status === 'CANCELED' &&
                            <img className={'canceled-icon'} src="/images/icons/canceled.svg" alt=""/>}
                          <img src="/images/icons/copyIcon.svg" alt=""/>
                        </div>
                      </div>
                    })
                    : <div className={'card-list-block-empty'}>Список карт пуст</div>
                  }
                </div>
              </div>
            </div>
          </div>
      }
      {/*<div className='container py-5 mb-5 my-cards'>
      <h1 className='h1'>Список карт</h1>

       <VerificationCard readOnly={false}/>
            {card.id}


      <div className='row'>
        {verifiedCards?.length > 0 ? (
          verifiedCards?.map((card, index) => (
            <div className='col-12 mt-5' key={card?.id}>
              <div className='card sm pb-5 verification'>
                <div className='row'>
                  <div className='col-lg-6 d-flex flex-column'>
                    <h4>Карта</h4>
                    {card?.bid_id ? (
                      <p className='text'>Номер заявки {card?.bid_id}</p>
                    ) : null}
                    <div>
                      <UIInput
                        value={card.holder}
                        onChangeHandler={() => {}}
                        label={'Имя пользователя'}
                        readOnly
                      />
                      <UIInput
                        value={card.number}
                        onChangeHandler={() => {}}
                        label={'Номер карты'}
                        className='mt-4'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='col-lg-6 mt-4 mt-lg-0'>
                    <div
                      className='UI-button primaryButton btn'
                      onClick={() => openCardImg(card)}
                      style={{ cursor: 'pointer', display: 'inline-block' }}>
                      Фотография вашей карты
                    </div>
                  </div>
                  <div className='col-lg-6 mt-4 d-flex flex-column-reverse flex-lg-row justify-content-between '>
                    {card?.status === 'approve' ? (
                      <div className='alert alert-success' role='alert'>
                        Карта подтверждена
                      </div>
                    ) : card?.status === 'declined' ? (
                      <div className='alert alert-danger' role='alert'>
                        Карта отклонена
                      </div>
                    ) : card?.status === 'pending' ? (
                      <div className='alert alert-primary' role='alert'>
                        Карта на верификации. Ожидайте ответа
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className='text'>Ваш список пуст</p>
        )}
      </div>

      <GeneralModalWrap
        className='card_img'
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        closeOnOverlayClick={false}
        showCloseIcon={true}
        children={<AdminCardItem card={selectedCard} />}
      />
    </div>*/}
    </div>
  );
};

export default MyCards;
