import { serviceIcons } from '../components/icons'

export const ApiTexts: any = {
  ru: {
    HEADING: (
      <>
        API <span>Cashex - лучшее решение для Вашего</span> бизнеса
      </>
    ),
    API_INFO: 'API Cashex позволит Вам обрабатывать криптовалютные и фиатные транзакции в автоматическом режиме. Ведется постоянное улучшение стабильности, а также расширение возможностей по запросам наших клиентов.',
    CONNECT_API: 'Подключиться к API',
    GO_TO_DOCS: 'Перейти к документации',
    OPERATIONS: [
      {
        imageUrl: '/images/icons/cat_rub.svg',
        title: 'Рублевые операции',
        description: 'Автоматическая обработка входящих и исходящих платежей, занимающая не более 5 минут. Метод обработки Вы можете выбрать сами: Платежные формы Qiwi-Банка, либо банковские реквизиты банков РФ.'
      },
      {
        imageUrl: '/images/icons/car_btc.svg',
        title: 'Криптовалютные операции',
        description:
          'Начните автоматически обрабатывать платежи в любой криптовалюте быстро и безопасно. Вы получите широкий спектр популярных криптовалют и полностью автоматизированную обработку платежей. Автоматическая генерация уникальных кошельков для каждой транзакции, время обработки транзакций занимает не более 10 минут.'
      }
    ],
    SERVICES: [
      {
        icon: serviceIcons('gipkost'),
        title: 'Гибкость',
        description: 'Поддерживаем широкий список направлений криптовалют и банков РФ'
      },
      {
        icon: serviceIcons('support'),
        title: 'Поддержка',
        description: 'Наши тех. Специалисты круглосуточно поддерживают работу сервиса'
      },
      {
        icon: serviceIcons('speed'),
        title: 'Скорость',
        description: 'Обработка всех транзакций происходит автоматически и занимает не более 10 минут'
      },
      {
        icon: serviceIcons('individual'),
        title: 'Индивидуальность',
        description: 'Настроим API под любые ваши запросы'
      }
    ],
    FEEDBACK: 'Для подключения напишите нам.',
    MASSAGE_TO_TALKME: 'Написать нам в чат ',
    CONTACT_US: 'Свяжитесь с нами!',
    LK_BTN: 'Личный кабинет'
  },
  en: {
    HEADING: (
      <>
        API <span>Cashex.cc help you earn </span> more.
      </>
    ),
    API_INFO: 'The API is designed to increase the benefits of interacting with cryptocurrencies and help people who use alternative economic instruments associated with cryptocurrencies.',
    CONNECT_API: 'Connect',
    GO_TO_DOCS: 'Documentation',
    OPERATIONS: [
      {
        imageUrl: '/images/icons/cat_rub.svg',
        title: 'Ruble operations',
        description: 'Automatic processing of incoming and outgoing payments, which takes no more than 5 minutes. You can choose the processing method yourself: Payment forms of Qiwi Bank, or bank details of Russian banks. '
      },
      {
        imageUrl: '/images/icons/car_btc.svg',
        title: 'Cryptocurrency transactions',
        description:
          'Start automatically processing payments in any cryptocurrency quickly and securely. You will get a wide range of popular cryptocurrencies and fully automated payment processing. Automatic generation of unique wallets for each transaction, transaction processing time takes no more than 10 minutes.'
      }
    ],
    SERVICES: [
      {
        icon: serviceIcons('gipkost'),
        title: 'Flexibility',
        description: 'We support a wide list of cryptocurrencies and banks of the Russian Federation'
      },
      {
        icon: serviceIcons('support'),
        title: 'Support',
        description: 'Our techs. Specialists support the work of the service around the clock'
      },
      {
        icon: serviceIcons('speed'),
        title: 'Speed',
        description: 'All transactions are processed automatically and take no more than 10 minutes'
      },
      {
        icon: serviceIcons('individual'),
        title: 'Individuality',
        description: 'We will configure the API for any of your requests'
      }
    ],
    FEEDBACK: 'Write us to connect our service.',
    MASSAGE_TO_TALKME: 'Chat with us',
    CONTACT_US: 'Contact us!',
    LK_BTN: 'Personal Area'
  },
  tu: {
    HEADING: (
      <>
        API <span>Cashex.cc help you earn </span> more.
      </>
    ),
    API_INFO: 'The API is designed to increase the benefits of interacting with cryptocurrencies and help people who use alternative economic instruments associated with cryptocurrencies.',
    CONNECT_API: 'Connect',
    GO_TO_DOCS: 'Documentation',
    OPERATIONS: [
      {
        imageUrl: '/images/icons/cat_rub.svg',
        title: 'Ruble operations',
        description: 'Automatic processing of incoming and outgoing payments, which takes no more than 5 minutes. You can choose the processing method yourself: Payment forms of Qiwi Bank, or bank details of Russian banks. '
      },
      {
        imageUrl: '/images/icons/car_btc.svg',
        title: 'Cryptocurrency transactions',
        description:
          'Start automatically processing payments in any cryptocurrency quickly and securely. You will get a wide range of popular cryptocurrencies and fully automated payment processing. Automatic generation of unique wallets for each transaction, transaction processing time takes no more than 10 minutes.'
      }
    ],
    SERVICES: [
      {
        icon: serviceIcons('gipkost'),
        title: 'Flexibility',
        description: 'We support a wide list of cryptocurrencies and banks of the Russian Federation'
      },
      {
        icon: serviceIcons('support'),
        title: 'Support',
        description: 'Our techs. Specialists support the work of the service around the clock'
      },
      {
        icon: serviceIcons('speed'),
        title: 'Speed',
        description: 'All transactions are processed automatically and take no more than 10 minutes'
      },
      {
        icon: serviceIcons('individual'),
        title: 'Individuality',
        description: 'We will configure the API for any of your requests'
      }
    ],
    FEEDBACK: 'Write us to connect our service.',
    MASSAGE_TO_TALKME: 'Bizle sohbet et',
    CONTACT_US: 'Contact us!',
    LK_BTN: 'Personal Area'
  }
}
