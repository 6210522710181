import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  fetchCardCheckHandler,
  fetchCreateNewCardHandler,
  fetchGetBidHandler,
  fetchSiteSettingsHandler,
  finishVerificationHandler
} from '../actions/bidActions'
import { fetchGetCourseHandler } from '../actions/tradeActions'

export interface BidState {
  city_from: string
  client_email: string
  client_id: string | null
  client_name: string
  client_phone: string
  course_get: number
  course_give: number
  currency_get: string
  currency_give: string
  direction_id: number
  excTrc: string
  hash: string
  cardHash: string
  id: number
  internal_id: string
  payment_details_get: string
  payment_details_give: string
  ref_id: number
  status_code: string
  status_id: number
  sum_get: string //to
  sum_give: string //from
  created_at: string
  user_ip: string
  isLoadingBid: boolean
  city: any

  cardStatus: string
  cardName: string | number
  card: string
  cardMessage: string

  bidStep: number
  transactionInfo: any
  dest_tag: string | number
  statusBid: boolean
  bid_processing_message: string
  bid_processing_hash: string
  step_verification: number
  cardCheck: boolean
  cardCheckStatus: boolean

  withCardVerification : boolean
}

const initialState: BidState = {
  city_from: '',
  client_email: '',
  client_id: '',
  client_name: '',
  client_phone: '',
  course_get: 0,
  course_give: 0,
  currency_get: '',
  currency_give: '',
  direction_id: 0,
  excTrc: '',
  hash: '',
  cardHash: '',
  id: 0,
  internal_id: '',
  payment_details_get: '',
  payment_details_give: '',
  ref_id: 0,
  status_code: '',
  status_id: 0,
  sum_get: '',
  sum_give: '',
  user_ip: '',
  created_at: '',
  isLoadingBid: false,
  city: {},

  cardStatus: '',
  cardName: '',
  card: '',


  cardMessage: '',

  bidStep: 1,
  transactionInfo: null,

  dest_tag: '',
  statusBid: false,
  bid_processing_message: '',
  bid_processing_hash: '',
  step_verification: 0,
  cardCheck: false,
  cardCheckStatus: false,

  withCardVerification : true
}

export const bidSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    setCard(state: any, action: PayloadAction<string | number>): void {
      state.card = action.payload
    },
    setCardName(state: any, action: PayloadAction<string>) {
      state.cardName = action.payload
    },
    setCardStatus(state: any, action: PayloadAction<string>) {
      state.cardStatus = action.payload
    },
    setBidStep(state: BidState, action: PayloadAction<number>) {
      state.bidStep = action.payload
    },
    setCardMessage(state: BidState, action: PayloadAction<string>) {
      state.cardMessage = action.payload
    },
    setStepVerification(state: BidState, action: PayloadAction<number>) {
      state.step_verification = action.payload
    },
    setIsLoadingBid(state: BidState, action: PayloadAction<boolean>) {
      state.isLoadingBid = action.payload
    },
    setCardCheckStatus(state: BidState, action: PayloadAction<boolean>) {
      state.cardCheckStatus = action.payload
    },
    setHash(state: BidState, action: PayloadAction<string>) {
      state.hash = action.payload
    },
    resetBid(state: BidState) {
      state.city_from = ''
      state.client_email = ''
      state.client_id = ''
      state.client_name = ''
      state.client_phone = ''
      state.course_get = 0
      state.course_give = 0
      state.currency_get = ''
      state.currency_give = ''
      state.direction_id = 0
      state.excTrc = ''
      state.hash = ''
      state.cardHash = ''
      state.id = 0
      state.internal_id = ''
      state.payment_details_get = ''
      state.payment_details_give = ''
      state.ref_id = 0
      state.status_code = ''
      state.status_id = 0
      state.sum_get = ''
      state.sum_give = ''
      state.user_ip = ''
      state.created_at = ''
      state.isLoadingBid = false
      state.city = {}

      state.cardStatus = ''
      state.cardName = ''
      state.card = ''

      state.cardMessage = ''

      state.bidStep = 1
      state.transactionInfo = null

      state.dest_tag = ''
      state.statusBid = false
      state.bid_processing_message = ''
      state.bid_processing_hash = ''
      state.step_verification = 0
      state.cardCheck = false
      state.cardCheckStatus = false
    }
  },
  extraReducers: builder => {
    fetchGetBidHandler(builder)
    fetchGetCourseHandler(builder)
    fetchCreateNewCardHandler(builder)
    finishVerificationHandler(builder)
    fetchCardCheckHandler(builder)
    fetchSiteSettingsHandler(builder)
  }
})

export const { setHash, setCard, setCardName, resetBid, setCardCheckStatus, setIsLoadingBid, setCardStatus, setStepVerification, setBidStep, setCardMessage } = bidSlice.actions

export default bidSlice.reducer
