import React, { FC, ReactNode } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { CopyIcon } from '../../components/icons'
import { useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { useNotification } from '../../hooks/useNotify'
import { ReferralNavs } from '../../utils/consts'
import { NavsInterface } from '../../utils/interfaces'

interface PayoutLayoutProps {
  children: ReactNode
}

const PayoutLayout: FC<PayoutLayoutProps> = ({ children }) => {
  const { language } = useAppSelector(state => state.main)
  const { refStatuses } = useAppSelector(state => state.user)

  const { notify } = useNotification()

  const { profileTexts } = useGetText()
  return (
    <div className='container referral-layout pb-5'>
      <h1>{profileTexts.PROFILE_HEADING}</h1>
      <div className='profile-card'>
        <h2>{profileTexts.REFERRAL_HEADING}</h2>
        <div className='row align-items-center mb-4'>
          <div className='col-lg-7'>
            <div>
              <ul className='referral-navs'>
                {ReferralNavs[language].map((el: NavsInterface, idx: number) => (
                  <li key={idx}>
                    <NavLink to={el.link}>{el.text}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='d-lg-flex flex-column align-items-end'>
              <h3 className='mb-0'>{profileTexts.REFERRAL_LINK}</h3>
              <CopyToClipboard
                onCopy={() =>
                  notify({
                    content: () => 'Ссылка успешно скопирована'
                  })
                }
                text={`https://cashex.cc/?rid=${refStatuses?.client_id}`}
              >
                <div className='copy-link'>
                  <button className='btn btn-refferal-link link p-0 rounded-0'>
                    https://cashex.cc/?rid={refStatuses?.client_id} <CopyIcon isBool={true} />
                  </button>
                </div>
              </CopyToClipboard>
              <h6 className='mb-00098'>{profileTexts.REFERRAL_TEXT}</h6>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default PayoutLayout
