import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MainService from '../../service/main-service'
import { CodeCategory } from '../../utils/consts'
import { RootState } from '../store'

export const fetchTextsAlgorithm = createAsyncThunk('bid/fetchTextsAlgorithm', async () => {
  try {
    const res = await MainService.getTexts()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

export const fetchTextsAlgorithmHandler = (builder: any) => {
  builder.addCase(fetchTextsAlgorithm.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchTextsAlgorithm.fulfilled, (state: any, action: PayloadAction<any>) => {
    state.algorithmText = action.payload.content
  })
  builder.addCase(fetchTextsAlgorithm.rejected, (state: any, action: PayloadAction<any>) => {})
}

export const fetchAutoExchangeText = createAsyncThunk('bid/fetchAutoExchangeText', async (_, api) => {
  try {
    const state: any = api.getState()
    const { currency_code_give, currency_code_get } = state.bid
    const data = {
      from: currency_code_give,
      to: currency_code_get
    }
    const res = await MainService.getAutoExchangeText(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

export const fetchAutoExchangeTextHandler = (builder: any) => {
  builder.addCase(fetchAutoExchangeText.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchAutoExchangeText.fulfilled, (state: any, action: PayloadAction<any>) => {
    state.autoExchangeText = action.payload.content
  })
  builder.addCase(fetchAutoExchangeText.rejected, (state: any, action: PayloadAction<any>) => {})
}

export const fetchSupport = createAsyncThunk('bid/fetchSupport', async (data: any) => {
  try {
    const res = await MainService.sendSupport(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

export const fetchSupportHandler = (builder: any) => {
  builder.addCase(fetchSupport.pending, (state: any, action: PayloadAction<any>) => {
    state.isSentHelp = true
    state.helpMessage = ''
  })
  builder.addCase(fetchSupport.fulfilled, (state: any, action: PayloadAction<any>) => {
    if (action.payload.success) {
      state.isSentHelp = false
    } else {
      state.isSentHelp = true
      state.helpMessage = ''
    }

    // state.autoExchangeText = action.payload.content
  })
  builder.addCase(fetchSupport.rejected, (state: any, action: PayloadAction<any>) => {})
}

export const fetchDynamicText = createAsyncThunk('bid/fetchDynamicText', async (appointment: string, { getState }) => {
  try {
    let data
    const { language } = (getState() as RootState).main

    if (appointment === 'before') {
      const { from, to, city, city_arr } = (getState() as RootState).trade

      const getCity: any = city_arr?.filter((el: any) => el?.code === city)[0]
      data = {
        type: CodeCategory[from] === 'cash' || CodeCategory[to] === 'cash' ? 'cash' : 'auto',
        from: from === 'SBPRUB' ? 'SBERRUB' : from,
        to: to === 'SBPRUB' ? 'SBERRUB' : to,
        language,
        appointment,
        city: getCity?.main === 'true' ? 'main' : 'other'
      }
    } else {
      const { currency_get, currency_give, city } = (getState() as RootState).bid
      data = {
        type: CodeCategory[currency_get] === 'cash' || CodeCategory[currency_give] === 'cash' ? 'cash' : 'auto',
        from: currency_get === 'SBPRUB' ? 'SBERRUB' : currency_get,
        to: currency_give === 'SBPRUB' ? 'SBERRUB' : currency_give,
        language,
        appointment,
        city: city?.main ? 'main' : 'other'
      }
    }

    const res = await MainService.dynamicText(data)

    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})
export const fetchDynamicTextHandler = (builder: any) => {
  builder.addCase(fetchDynamicText.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchDynamicText.fulfilled, (state: any, action: PayloadAction<any>) => {
    state.dynamicTexts = action.payload?.content?.content
  })
}

export const fetchStaticText = createAsyncThunk('bid/fetchStaticText', async (name: string, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await MainService.staticText({
      language: language.toUpperCase(),
      name
    })

    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})
export const fetchStaticTextHandler = (builder: any) => {
  builder.addCase(fetchStaticText.pending, (state: any, action: PayloadAction<any>) => {
    state.status = 'loading static text'
  })
  builder.addCase(fetchStaticText.fulfilled, (state: any, action: PayloadAction<any>) => {
    state.status = 'idle'
    if (action.payload?.status >= 400) {
      state.staticText = {}
    } else {
      state.staticText = action.payload?.content?.content
    }
  })
}

export const fetchStaticBalance = createAsyncThunk('parser/competition', async () => {
  try {
    const res = await MainService.staticBalance()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})
export const fetchStaticBalanceHandler = (builder: any) => {
  builder.addCase(fetchStaticBalance.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchStaticBalance.fulfilled, (state: any, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
      state.staticBalance = {}
    } else {
      state.staticBalance = action.payload?.content?.data[0]
    }
  })
}
