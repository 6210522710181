import { IOtpUrl, JWTTokens } from '../store/actions/apiLkAuthActions'
import { apiLk } from '../utils/api'
import { apiEndPoints, cookiesName } from '../utils/consts'

export type SignInData = {
  email: string
  password: string
  otp_code: string
}
export type SignUpData = {
  email: string
  password: string
  repeated_password: string
  invite_code: string
}

export interface TokenBalanceIn {
  token: string
  balance: number
}

export interface AddressIn {
  address: string
  date_create: string
}

export interface WithdorwIn {
  amount: number
  call_back_url: ''
  client_transaction_id: string
  otp_code: string
  receiver: string
  token: string
}

export interface ApiKeysIn {
  private_key: string
  public_key: string
}

export interface SwapFetchIn {
  amount_from: number
  amount_to: number
  date_create: string
  exchange_rate: number
  status: string
  token_from: string
  token_to: string
}

export interface SwapCreateDataIn {
  otp_code: string
  amount_from: number
  amount_to: number
  token_to: string
  token_from: string
  exchange_rate: number
}

export interface TokenIn {
  token: string
  full_name: string
}

export interface Tokens {
  tokens: string[]
}

export interface TransactionsIn {
  amount: 1
  callback_url: string | null
  client_transaction_id: string
  date_create: string
  dest_tag: string | number
  hash: string | null
  receiver: string | null
  status: string
  token: string
  tracker_id: string
  transaction_commission: number
  type?: 'IN' | 'OUT'
}

export interface SwapExchangeRateData {
  token_from: string
  token_to: string
}

class ApiLkService {
  public loginLk(data: SignInData) {
    return apiLk
      .post(apiEndPoints.API_LOGIN, data)
      .then(res => res)
      .catch(rej => rej)
  }

  public createAccount(data: SignUpData) {
    return apiLk.post<IOtpUrl>(apiEndPoints.API_REGISTER, data)
  }

  public apiLkLogout() {
    return apiLk
      .post(apiEndPoints.API_LOGOUT)
      .then(res => res)
      .catch(rej => rej)
  }

  public refreshApiLkJWTTokens() {
    return apiLk.post<JWTTokens>(apiEndPoints.API_REFRESH_JWT_TOKENS)
  }

  public getTokens() {
    return apiLk
      .get(apiEndPoints.API_GET_TOKENS)
      .then(res => res)
      .catch(rej => rej)
  }

  public getBalance(query?: string) {
    return apiLk
      .get(apiEndPoints.API_GET_BALANCE + query)
      .then(res => res)
      .catch(rej => rej)
  }

  public getApiKays() {
    return apiLk
      .get(apiEndPoints.API_GET_KEYS)
      .then(res => res)
      .catch(rej => rej)
  }

  public patchApiKays() {
    return apiLk
      .patch(apiEndPoints.API_GET_KEYS)
      .then(res => res)
      .catch(rej => rej)
  }

  public getAllTokens() {
    return apiLk
      .get(apiEndPoints.API_ALL_TOKENS)
      .then(res => res)
      .catch(rej => rej)
  }

  // TRANSACTIONS
  public getTransactionInfo(dataQuery: { limit?: number; offset?: number; type?: string; sort_order?: 'DESC' | 'ASC'; token?: string }) {
    //
    const query = `?limit=${dataQuery.limit}&offset=${dataQuery.offset}&type=${dataQuery.type}&sort_order=${dataQuery.sort_order}&token=${dataQuery.token}`
    return apiLk
      .get(apiEndPoints.TRANSACTIONS + query)
      .then(res => res)
      .catch(rej => rej)
  }

  public getAddresses(dataQuery: { token: string; limit: number; offset: number }) {
    //
    const query = `?token=${dataQuery.token}&limit=${dataQuery.limit}&offset=${dataQuery.offset}`

    return apiLk
      .get(apiEndPoints.API_GET_ADDRESSES + query)
      .then(res => res)
      .catch(rej => rej)
  }

  public createInvoice(dataBody: { token: string; client_transaction_id: string; call_back_url: string | '' }) {
    return apiLk
      .post(apiEndPoints.API_FETCH_INVOCE, dataBody)
      .then(res => res)
      .catch(rej => rej)
  }

  public createWithdrow(dataBody: WithdorwIn) {
    return apiLk
      .post(apiEndPoints.API_CREATE_WITHDROW, dataBody)
      .then(res => res)
      .catch(rej => rej)
  }

  public getAllSwaps(dataQuery: { limit: number; offset: number }) {
    return apiLk
      .get(apiEndPoints.API_FETCH_SWAP + `?limit=${dataQuery.limit}&offset=${dataQuery.offset}`)
      .then(res => res)
      .catch(rej => rej)
  }

  public createSwap(data: SwapCreateDataIn) {
    return apiLk
      .post(apiEndPoints.API_CREATE_SWAP, data)
      .then(res => res)
      .catch(rej => rej)
  }

  public getForExchange(dataQuery: { token: string }) {
    return apiLk
      .get(apiEndPoints.API_FETCH_FOR_EXCHANGE_TOKENS + `?token=${dataQuery.token}`)
      .then(res => res)
      .catch(rej => rej)
  }

  public getExchangeRate(dataQuery: SwapExchangeRateData) {
    return apiLk
      .get(apiEndPoints.API_SWAP_EXCHANGE_RATE + `?token_from=${dataQuery.token_from}&token_to=${dataQuery.token_to}`)
      .then(res => res)
      .catch(rej => rej)
  }
}

export default new ApiLkService()
