import { createSlice } from '@reduxjs/toolkit'
import { approveCardHandler, fetchCardsHandler, fetchIsAdminHandler, fetchPhotoHandler } from '../actions/adminActions'

export interface IAdminCard {
  id: number
  bid: number
  bid_id: number
  number: string
  email: string
  holder: string
  status: string
  hash: string
  created_at: string
}

export interface AdminState {
  cards: IAdminCard[]
  status: string
  photo: any
  offset: number
  isAdmin: boolean
}

const initialState: AdminState = {
  cards: [],
  status: 'idle',
  photo: null,
  offset: 1,
  isAdmin: false
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setOffset(state, action) {
      state.offset = action.payload
    }
  },
  extraReducers: builder => {
    fetchCardsHandler(builder)
    approveCardHandler(builder)
    fetchPhotoHandler(builder)
    fetchIsAdminHandler(builder)
  }
})

export const { setOffset } = adminSlice.actions

export default adminSlice.reducer
