import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/button'
import { useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { path } from '../../utils/consts'

const ApiLanding = () => {
  const { isAuthApi } = useAppSelector(state => state.apiLkAuth)
  const { apiText } = useGetText()
  const navigate = useNavigate()

  useEffect(() => {
    const open_talkme = document.querySelector('.open_talkme')
    open_talkme?.setAttribute('onclick', "MeTalk('openSupport'); return false;")
  }, [])
  return (
    <div className='container api-landing py-4'>
      <div className='row align-items-center'>
        <div className='col-lg-6'>
          <div className=''>
            <h1>{apiText?.HEADING}</h1>
            <p>{apiText?.API_INFO}</p>
            <div className='d-flex g-g-10 flex-wrap'>
              <div className='d-flex w-100 gap-2'>
                <a href='api#contact_us' className='btn-primary btn w-100'>
                  {apiText.CONNECT_API}
                </a>
                <Button variant='primary' className='w-100' onClick={() => navigate(path.API_DOCS)} style={{ background: '#1F82F6' }}>
                  {apiText.GO_TO_DOCS}
                </Button>
              </div>
              <Button variant='outlined' className='w-100' onClick={() => navigate(isAuthApi ? path.API_STATISTICS : path.API_LOGIN)}>
                {apiText.LK_BTN}
              </Button>
            </div>
          </div>
        </div>
        <div className='col-lg-6 d-lg-block d-none'>
          <div className='d-flex justify-content-center'>
            <img width={425} height={483} src='/images/static/blockChain.png' alt='' />
          </div>
        </div>
      </div>
      <div className='row justify-content-around mt-sm-5 py-5'>
        {apiText.OPERATIONS.map(
          (
            el: {
              imageUrl: string
              title: string
              description: string
            },
            idx: number
          ) => (
            <div className='col-xl-5 col-lg-6 col-12 mt-lg-0 mt-4' key={idx}>
              <div className='card h-100'>
                <div>
                  <img width={178} height={134} src={el.imageUrl} alt={el.title} />
                </div>
                <h5>{el.title}</h5>
                <p>{el.description}</p>
              </div>
            </div>
          )
        )}
      </div>
      <div className='card p-0 rounded-0 mt-5'>
        <div className='row justify-content-around mt-5'>
          {apiText.SERVICES.map(
            (
              el: {
                icon: string
                title: string
                description: string
              },
              idx: number
            ) => (
              <div className='col-xl-3 col-lg-4 col-sm-6 col-12' key={idx}>
                <div className='card_services h-100'>
                  <div className='icon-wrapper'>{el.icon}</div>
                  <h5>{el.title}</h5>
                  <p className='text-white'>{el.description}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className='card p-0 rounded-0 my-5' id='contact_us'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card_sm text-center'>
              <h3>{apiText.CONTACT_US}</h3>
              <div>
                <img width={310} height={232} src='/images/static/car_1_320x200.png' className='img-fluid' alt='' />
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='card_sm h-100' style={{ background: '#001E56' }}>
              <h6>{apiText.FEEDBACK}</h6>
              <small className='mt-4'>Email</small>
              <h4>
                <a href='mailto:info@cashex.cc'> info@cashex.cc</a>
              </h4>
              <small className='mt-4'>{apiText.CONTACT_US}</small>
              <Button variant='primary' className='open_talkme' style={{ background: '#1F82F6' }}>
                {apiText.MASSAGE_TO_TALKME}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiLanding
