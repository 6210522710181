import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/buttons/button'
import CustomInput from '../components/inputs/custom-input'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useDocumentWidth from '../hooks/useDocumentWidth'
import useInput, { namesType } from '../hooks/useInput'
import { fetchCardCheck, fetchCreateNewCard, fetchDeleteBid, finishVerification } from '../store/actions/bidActions'
import { setHashID } from '../store/reducers/tradeReducer'
import { cookiesName, path } from '../utils/consts'
import { setBidStep, setCard, setCardMessage, setCardName, setStepVerification } from '../store/reducers/bidReducer'
import BottomBans from '../components/bottom-bans'
import clsx from 'clsx'
import useTimerDown from '../hooks/useTimer'
import Cookies from 'cookies-ts'
import { fetchUserCards } from '../store/actions/userActions'

const plasticCardList: any = [
  {
    id: 1,
    text: 'Сделать фото своей карты на фоне этого окна так, чтобы был виден номер заявки, номер карты, фамилия и имя владельца карты полностью.'
  },
  {
    id: 2,
    text: 'Прикрепить данное фото в поле ниже.'
  },
  {
    id: 3,
    text: 'Отправить фото на верификацию.'
  },
  {
    id: 4,
    text: 'Дождаться в течение 1-2 минут подтверждения о прохождении верификации.'
  }
]
const cookies = new Cookies()

const CardVerification: FC = () => {
  const { id, card, hash, cardName, cardMessage, cardCheck, cardStatus } = useAppSelector(state => state.bid)
  const { userCards, isAuth } = useAppSelector(state => state.user)
  const { email } = useAppSelector(state => state.user)
  const [step, setStep] = useState(0)
  const [isVirtualCard, setVirtualCard] = useState<boolean>(false)
  const [cardEl, setCardEl] = useState(null)
  const [newCard, setNewCard] = useState(true)
  const { timeLeft } = useTimerDown(5)
  const { timeLeft: timerBid } = useTimerDown(1)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const [cardError, setCardError] = useState(false)

  const cardInp = useInput()
  const nameInp = useInput()

  const { lg }: any = useDocumentWidth()

  useEffect(() => {
    if (cookies.get(cookiesName.STEP_VERIFICATION)?.length) {
      const verificationStep: any = cookies.get(cookiesName.STEP_VERIFICATION)
      if (+verificationStep !== 0) {
        setStep(+verificationStep)
      }
    }
  }, [])

  useEffect(() => {
    if (timerBid === 0 && cookies.get(cookiesName.CARD_HASH)?.length && !userCards?.length) {
      // dispatch(fetchUserCards())
    }
  }, [timerBid, userCards])

  useEffect(() => {
    if (timeLeft === 0 && cardCheck) {
      // dispatch(fetchCardCheck())
    }
  }, [cardCheck, timeLeft])

  useEffect(() => {
    if (cardCheck) {
      // dispatch(fetchCardCheck())
    }
  }, [cardCheck])

  useEffect(() => {
    if (timeLeft === 0 && cookies.get(cookiesName.CARD_HASH)?.length && !userCards?.length) {
      dispatch(fetchCardCheck())
    }
  }, [userCards, timerBid])

  useEffect(() => {
    if (userCards?.length) {
      // dispatch(fetchCardCheck())
    }
  }, [userCards])

  useEffect(() => {
    cardInp.setValue(card.trim())
    dispatch(setCardName(nameInp.value))
  }, [card, nameInp.value])

  useEffect(() => {
    cookies.set(cookiesName.STEP_VERIFICATION, step)
  }, [step])

  useEffect(() => {
    if (userCards?.length) {
      const findCard = userCards?.some(card => card.number === cardInp?.value?.replace(/\s/g, ''))
      if (findCard) {
        setStep(3)
      }
    }
  }, [cardInp.value])

  useEffect(() => {
    if (!isAuth) {
      switch (cardStatus) {
        case 'pending':
          setStep(3)
          break
        case 'approve':
          setStep(1)
          break
        case 'declined':
          setStep(3)
          break
      }
    }
  }, [isAuth, cardStatus])

  useEffect(() => {
    if (!newCard) {
      cardInp.setValue(cardInp.value)
      dispatch(setCard(cardInp.value))
    }
  }, [newCard, cardInp.value])

  useEffect(() => {
    if (cardMessage === 'File too large') {
      setCardError(prev => !prev)
      setTimeout(() => {
        setCardError(prev => !prev)
      }, 3000)
      // setCardEl(null)
    }
  }, [cardMessage])

  useEffect(() => {
    if (cardMessage === 'success' && timeLeft === 15) {
      // dispatch(fetchCardCheck())
    }
  }, [timeLeft, cardMessage])

  useEffect(() => {
    if (step === 3 && timeLeft === 0) {
      dispatch(fetchCardCheck())
    }
  }, [timeLeft, step])

  const goToExangePage = useCallback(() => {
    navigate(path.MAIN)
    dispatch(fetchDeleteBid())
    dispatch(setHashID(''))
  }, [])

  function uploadCard(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  const sendCard = () => {
    dispatch(fetchCreateNewCard(cardEl))
  }

  useEffect(() => {
    if (step === 1) {
      dispatch(setCardMessage(''))
    }
  }, [step])

  useEffect(() => {
    if (cardMessage === 'success') {
      dispatch(setCardMessage(''))
      // dispatch(finishVerification(hash))
      setStep(3)
    }
    if (location.pathname === '/new-card') {
      setNewCard(false)
    }
  }, [cardMessage])

  const goToExchange = () => {
    dispatch(setBidStep(1))
  }

  const writeTitle = () => {
    switch (cardStatus) {
      case 'approve':
        return 'Успешно! Карта прошла верификацию'
      case 'declined':
        return 'Отказ! Что-то пошло не так'
      case 'pending':
        return 'Ожидаем верификации карты'
    }
  }

  const writeDescription = () => {
    switch (cardStatus) {
      case 'approve':
        return 'Теперь вы можете совершить обмен'
      case 'declined':
        return 'Попробуйте верифицировать карту снова'
      case 'pending':
        return 'Пожалуйста, не уходите с этой страницы, верификация может занять некоторое время.'
    }
  }

  const finish = () => {
    // dispatch(finishVerification(hash))

    dispatch(setBidStep(3))
  }

  if (step === 0) {
    return (
      <div className='card-verification container pb-5'>
        <div className='row'>
          <div className='col-lg-6 text-wrapper'>
            <div>
              <h1 className='mb-0'>Требуется верификация</h1>
              <small>ID Заявки: {id}</small>
              <h2 className='mt-3'>Уважаемый клиент!</h2>
              <p>
                Вы в первый раз совершаете покупку криптовалюты с этой карты. Для обеспечения безопасности проведения сделки, нам необходимо единожды провести верификацию этой карты. Данная операция занимает 1-3 минуты и доступна с 06:00 до 23:00 Мск
                с понедельника по пятницу
              </p>
              <small>Проверьте данные Вашей карты и, если информация верна, переходите к верификации:</small>
            </div>
          </div>
          {lg && (
            <div className='col-md-6'>
              <div className='text-end'>
                <img src='/images/static/card_ver.svg' alt='' />
              </div>
            </div>
          )}

          <div className='col-md-6 mt-4'>
            <div>
              <CustomInput readOnly={newCard} maxLength={23} name={namesType.CARD} label='Номер карты' variant='main' inputHandlers={cardInp} />
            </div>
          </div>
          <div className='col-md-6 mt-sm-4 mt-3'>
            <div>
              <CustomInput maxLength={23} name={namesType.CARD_NAME} label='Имя и фамилия на карте' variant='main' inputHandlers={nameInp} />
              <div className='mt-4 d-flex flex-lg-row flex-column g-g-10 justify-content-between'>
                <Button variant='outlined' className='px-lg-5' onClick={goToExangePage}>
                  Вернуться назад
                </Button>
                <Button
                  disabled={nameInp?.value?.length < 4}
                  variant='primary'
                  className='px-lg-5'
                  onClick={() => {
                    if (cardInp.value.length > 2 && nameInp.value.length > 4) setStep(1)
                  }}
                >
                  Перейти на следующий шаг
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (step === 1) {
    return (
      <div className='card-verification container pb-5'>
        <h1 className='mb-0'>Верификация карты</h1>
        <small>ID Заявки: {id}</small>

        <div className='mt-3'>
          <div className='navs'>
            <button onClick={() => setVirtualCard(false)} className={`btn p-0 ${!isVirtualCard ? 'active' : ''}`}>
              Пластиковая карта
            </button>
            /{' '}
            <button className={`btn p-0 ${isVirtualCard ? 'active' : ''}`} onClick={() => setVirtualCard(true)}>
              Виртуальная карта
            </button>
          </div>
          <small>Для прохождения верификации Вам необходимо:</small>
          <ul className='list'>
            {plasticCardList.map((el: any) => (
              <li key={el.id}>
                <span>{el.id}</span>
                {el.text}
              </li>
            ))}
          </ul>
          {!isVirtualCard ? (
            <div>
              <small>Сделать это можно двумя способами:</small>
              <div className='row'>
                <div className='col-lg-6 mt-4'>
                  <div className='row pe-lg-4'>
                    <div className='col-lg-4'>
                      <div>
                        <img src='/images/static/card-pl-1.png' className='img-fluid' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <h2>Первый способ</h2>
                        <p className='t3'>Загрузите фото лицевой стороны карты на фоне сделки. Можно закрыть данные карты кроме цифр номера карты</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 mt-4'>
                  <div className='row ps-lg-4'>
                    <div className='col-lg-4'>
                      <div>
                        <img src='/images/static/card-pl-2.png' className='img-fluid' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <h2>Второй способ</h2>
                        <p className='t3'>Загрузить фото лицевой стороны карты на фоне надписи “Покупка криптовалюты на обменном сервисе cashex.cc по сделке “№ сделки”. Можно закрыть данные карты кроме цифр номера карты.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <small>Сделать это можно следующем способом:</small>
              <div className='row'>
                <div className='col-lg-6 mt-4'>
                  <div className='row pe-lg-4'>
                    <div className='col-lg-4'>
                      <div>
                        <img src='/images/static/card-pl-1.png' className='img-fluid' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      <div>
                        <p className='t3'>Загрузите скриншот экран, на котором одновременно открыто два окна: первое — страница сделки с номером вашего заказа, второе — окно с данными карты на странице вашего банка</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <form className='row mt-3' onSubmit={uploadCard}>
            <div className='col-6 mt-3'>
              <div>
                <input
                  type='file'
                  id='add_img'
                  className='d-none'
                  accept='.heic, .jpg, .jpeg, .png, .gif'
                  onChange={(e: any) => {
                    setCardEl(e.target.files[0])
                  }}
                />
                <label className='btn btn-dashed mb-0' htmlFor='add_img'>
                  {cardEl ? 'Фотография загружена' : 'Загрузить фотографию карты'}
                </label>
              </div>
            </div>
            <div className='col-6 card-upload'>
              <div className='mt-3 d-flex flex-lg-row flex-column g-g-10 justify-content-end'>
                <Button type='button' variant='outlined' className='px-lg-5' onClick={() => setStep(0)}>
                  Вернуться назад
                </Button>
                <Button disabled={!cardEl || cardMessage === 'loading'} variant='primary' className='px-lg-5 position-relative' onClick={sendCard}>
                  {cardMessage === 'loading' ? (
                    <>
                      Отправить карту <img className='card-upload__image' src='/images/icons/spinner.svg' alt='' />
                    </>
                  ) : (
                    'Отправить карту'
                  )}
                </Button>
                {cardError && <div className='card-upload__error'>Слишком большой размер файла</div>}
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='container'>
        <div className='card-verification__body'>
          <h1 className={clsx('card-verification__title', { approve: cardStatus === 'approve' }, { decline: cardStatus === 'declined' })}> {writeTitle()} </h1>
          <small className='card-verification__bid'>ID Заявки: {id}</small>
          <p>{writeDescription()}</p>
          {cardStatus === 'approve' && (
            <Button onClick={finish} className='card-verification__btn' variant='primary'>
              Завершить верификацию
            </Button>
          )}
          <div className='card-verification__button'>
            {/*{*/}
            {/*  cardStatus === 2 &&*/}
            {/*	<Button onClick={goToExchange}*/}
            {/*	        className='card-verification__btn'*/}
            {/*	        variant='primary'>*/}
            {/*		Перейти к обмену*/}
            {/*	</Button>*/}
            {/*}*/}
            {cardStatus === 'declined' && (
              <Button onClick={() => navigate(path.MAIN)} className='card-verification__btn' variant='primary'>
                Вернуться на главную
              </Button>
            )}
          </div>
        </div>
      </div>
      <BottomBans />
    </>
  )
}

export default CardVerification
