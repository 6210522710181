import React, { useEffect, useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import useGetText from '../../../hooks/useGetText'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { fetchBalances, fetchTokens } from '../../../store/actions/apiLkActions'
import { AuthLoader } from '../../../components/loaders/loaders'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement)

const ApiStatistic = () => {
  const { tokens_balance, isLoadingStatistic } = useAppSelector(state => state.apiLk)
  const { apiStatisticText } = useGetText()

  const dispatch = useAppDispatch()

  const dataDoughnut = useMemo(() => {
    const isBalance = tokens_balance?.filter(token => token.balance > 0).map(token => token.token)

    return {
      labels: isBalance.length ? tokens_balance?.filter(token => token.balance > 0).map(token => token.token) : ['USDT', 'RUB', 'BTC'],
      datasets: [
        {
          label: 'Балансы валют',
          data: isBalance.length ? tokens_balance?.filter(token => token.balance > 0).map(token => token.balance) : [1, 1, 1],
          backgroundColor: ['#26A17B', '#F7931A', '#627EEA', '#23292F'],
          // borderColor: ['#ffffff'],
          hoverOffset: 8
        }
      ],
      text: '23%'
    }
  }, [tokens_balance])

  useEffect(() => {
    dispatch(fetchBalances(''))
  }, [])

  useEffect(() => {
    dispatch(fetchTokens())
  }, [])

  return (
    <div className='container pt-5 api-user'>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='card h-100'>
            <h3>{apiStatisticText.CHART_TITILE}</h3>
            {dataDoughnut && <Doughnut data={dataDoughnut} />}
          </div>
        </div>
        <div className='col-lg-6 mt-lg-0 mt-4'>
          <div className='card px-0'>
            <h3 className='px-4'>{apiStatisticText.WALLETS_TITLE}</h3>
            {isLoadingStatistic ? (
              <div className='d-flex justify-content-center'>
                <AuthLoader />
              </div>
            ) : (
              <div className='tokens'>
                <table className='table '>
                  <tbody>
                    {tokens_balance.length &&
                      tokens_balance.map(el => (
                        <tr key={el?.token}>
                          <td style={{ maxWidth: '50px' }}>
                            <img src={`/images/currencies/${el?.token}.svg`} width='70' alt='' />{' '}
                          </td>
                          <td className='text-start'>
                            <p className='mb-2'>{el?.token}</p>
                            <p className='mb-0'>{el?.balance}</p>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ApiStatistic)
