import {api, authApi, registerApi} from '../utils/api'
import {apiEndPoints} from '../utils/consts'
import {ChangePasswordInterface, PoginationInterface, UserAuthInterface} from '../utils/interfaces'
import {ResetPasswordData} from '../store/actions/userActions'
import {IUserCards} from "../store/reducers/userReducer";


class User {
  autoSign(data: {email: string}) {
    return authApi
      .post(apiEndPoints.AUTO_SIGN, data)
      .then((res) => res)
      .catch((rej) => rej);
  }

  login(data: UserAuthInterface) {
    return api
      .post(apiEndPoints.LOGIN, data, {withCredentials: true})
      .then(res => res)
      .catch(rej => rej)
  }
  verificateEmail(email : string) {
    return api
      .post(apiEndPoints.VERIFICATE_EMAIL, {email})
      .then(res => res)
      .catch(rej => rej)
  }
  register(data: UserAuthInterface) {
    return registerApi
      .post(apiEndPoints.REGISTER, data)
      .then(res => res)
      .catch(rej => rej)
  }
  refresh() {
    return api
      .get(apiEndPoints.REFRESH_TOKEN, {withCredentials: true})
      .then(res => res)
      .catch(rej => rej)
  }

  resetPassword(data: ResetPasswordData) {
    return api
      .post(apiEndPoints.RESET_PASSWORD, data)
      .then(res => res)
      .catch(rej => rej)
  }
  resetPasswordFinish(data: { password: string; token: string }) {
    return api
      .post(apiEndPoints.RESET_PASSWORD_FINISH, data)
      .then(res => res)
      .catch(rej => rej)
  }

  logout() {
    return api
      .post(apiEndPoints.LOGOUT)
      .then(res => res)
      .catch(rej => rej)
  }
  changePassword(data: ChangePasswordInterface) {
    return api
      .post(apiEndPoints.CHANGE_PASSWORD, data)
      .then(res => res)
      .catch(rej => rej)
  }

  getRefStatus() {
    return api
      .get(apiEndPoints.REFERRAL_STATUS)
      .then(res => res)
      .catch(rej => rej)
  }
  getBids({ offset, limit }: PoginationInterface) {
    return api
      .get(apiEndPoints.USER_BIDS_HISTORY + `?offset=${offset}&limit=${limit}`)
      .then(res => res)
      .catch(rej => rej)
  }

  makePayout(data: any) {
    return api
      .post(apiEndPoints.REF_WITHDRAWAL, data)
      .then(res => res)
      .catch(rej => rej)
  }

  getPayouts() {
    return api
      .get(apiEndPoints.REF_WITHDRAWAL_HISTORY)
      .then(res => res)
      .catch(rej => rej)
  }

  getCards(id: any) {
    return api
      .get(apiEndPoints.FETCH_CARDS)
      .then(res => res)
      .catch(rej => rej)
  }

  getRefInfo() {
    return api
      .get(apiEndPoints.REF_INFO)
      .then((res) => res)
      .catch((rej) => rej)
  }

  getReferralBalances() {
    return api
      .get(apiEndPoints.REF_BALANCE)
      .then((res) => res)
      .catch((rej) => rej)
  }

  getClientInfo() {
    return authApi
      .get(apiEndPoints.USER_INFO)
      .then((res) => res)
      .catch((rej) => rej)
  }

  getUserCardsInfo() {
    return api.get<IUserCards>(apiEndPoints.GET_USER_CARDS)
      .then(res  => res)
      .catch(rej => rej)
  }

  addUserCardToVerify(data: any) {
    return api.post(apiEndPoints.ADD_CARD_TO_VERIFY, data)
      .then(res => res)
      .catch(rej => rej)
  }
}
export default new User()
