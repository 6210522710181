import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/buttons/button'
import HeaderNavItems from '../components/header-nav-items'
import { BurgerIcon, DarkLogo, EnIcon, InputIconArrow, Logo, RuIcon, TuIcon, UserIcon } from '../components/icons'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useApiLk from '../hooks/useApiLk'
import useDocumentWidth from '../hooks/useDocumentWidth'
import useGetText from '../hooks/useGetText'
import { AuthTexts } from '../local/auth-texts'
import { fetchLogoutApiLk } from '../store/actions/apiLkAuthActions'
import { fetchLogout } from '../store/actions/userActions'
import { setLang, setNav } from '../store/reducers/mainReducer'
import { headerAuthNav, languages, navs, navsLk, path } from '../utils/consts'
import clsx from 'clsx'

const Header = () => {
  const { isAuth, email } = useAppSelector(state => state.user)
  const { isNav, language } = useAppSelector(state => state.main)
  const media: any = useDocumentWidth()
  const [isDropdown, setDropdown] = useState<boolean>(false)
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isLK } = useApiLk()

  const { authTexts } = useGetText()

  useEffect(() => {
    if (document.body.clientWidth < 992) {
      if (isNav) document.body.classList.add('overflow-hidden')
      else document.body.classList.remove('overflow-hidden')
    }
  }, [isNav])

  const getLanguageIcon = (language: string) => {
    switch (language) {
      case 'ru':
        return <RuIcon />
      case 'en':
        return <EnIcon />
      case 'tu':
        return <TuIcon />
    }
  }

  return (
    <header>
      <div className={clsx('container', { 'open-burger': isNav })} style={{ zIndex: 30 }}>
        <div className='d-flex align-items-center burger__label justify-content-between'>
          <div className='d-flex align-items-center'>
            <Link className='logo' to={path.MAIN}>
              {!isNav ? <Logo /> : <DarkLogo />}
            </Link>
          </div>
          {media.lg && <HeaderNavItems navs={isLK ? navsLk : navs} className={`header-nav w-100 header__links ${isNav ? 'fade-in-header' : 'fade-out-header'}`} />}

          <div className='d-flex align-items-center'>
            <div className='dropdown language'>
              <button
                className='btn dropdown-btn'
                onClick={() => setDropdown(dropdownButtonRef.current?.classList.contains('show') ? true : false)}
                ref={dropdownButtonRef}
                type='button'
                id='dropdownMenuButton1'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                {getLanguageIcon(language)}
                <span>
                  <InputIconArrow className={isDropdown ? 'rotate-180' : ''} />
                </span>
              </button>
              <ul className='dropdown-menu dropdown-menu-end' aria-labelledby='dropdownMenuButton1'>
                {languages.map((el, idx) => (
                  <li key={idx}>
                    <button
                      onClick={() => {
                        dispatch(setLang(el?.code))
                        let talkMeLang = el?.code === 'en' ? 'en' : 'ru'
                        localStorage.setItem('language', el?.code)
                        localStorage.setItem('talkMeLang', talkMeLang)
                        setTimeout(() => {
                          // @ts-ignore
                          window.location.reload(false)
                        }, 1500)
                      }}
                      type='button'
                      className={`dropdown-item ${el.code === language ? 'active' : ''}`}
                    >
                      {el?.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {isLK && (
              <Button variant='primary' className='w-100' onClick={() => dispatch(fetchLogoutApiLk())}>
                {authTexts?.LOGOUT}
              </Button>
            )}
            {!isLK && media.lg ? (
              !isAuth ? (
                <Button style={{ background: '#1F82F6', whiteSpace: 'nowrap' }} className='px-5' variant='primary' onClick={() => navigate(path.LOGIN)}>
                  {authTexts?.SIGN_IN}
                </Button>
              ) : (
                <div className='dropdown dropdown-profile'>
                  <button className='btn btn-lk' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                    <span>
                      <span>
                        {authTexts?.LK}
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                          <circle cx='12' cy='6' r='4' stroke='#002674' strokeWidth='2' />
                          <path d='M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634' stroke='#002674' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                      </span>
                      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none' className='ms-2'>
                        <path d='M7 12L0.937822 0H13.0622L7 12Z' fill='#002674' />
                      </svg>
                    </span>
                    {/* <p className='mb-0'>{email}</p> */}
                  </button>
                  <HeaderNavItems sideMenu isLogout={true} className='dropdown-menu dropdown-menu-end' classNameA='dropdown-item' navs={headerAuthNav} />
                </div>
              )
            ) : null}
            <button className='btn p-0 ms-sm-3 ms-1 toggler burger' type='button' onClick={() => dispatch(setNav(!isNav))}>
              <BurgerIcon isBool={isNav} />
            </button>
          </div>
        </div>
      </div>
      {!media.lg && (
        <div className={`${isNav ? 'fade-in-header' : 'fade-out-header'} header-nav`}>
          <div className='container'>
            <HeaderNavItems sideMenu navs={isLK ? navsLk : navs} />
            {!isLK ? (
              !isAuth ? (
                <div className='auth-btns-wrapper'>
                  <Button variant='primary' onClick={() => navigate(path.LOGIN)}>
                    {authTexts?.LOGIN}
                  </Button>
                  <Button variant='secondary' onClick={() => navigate(path.REGISTER)}>
                    {authTexts?.REGISTRATION}
                  </Button>
                </div>
              ) : (
                <>
                  <ul className='profile-mobile-nav'>
                    <HeaderNavItems classNameA='dropdown-item' navs={headerAuthNav} />
                  </ul>
                  <Button variant='primary' className='w-100' onClick={() => dispatch(fetchLogout())}>
                    {authTexts?.LOGOUT}
                  </Button>
                </>
              )
            ) : null}
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
