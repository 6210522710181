import { Link } from 'react-router-dom'
import { path } from '../utils/consts'

export const AuthTexts: any = {
  ru: {
    LOGIN: 'Вход',
    FORIGIN_PASSWORD: 'Забыли пароль?',
    PASSWORD: 'Пароль',
    REPEAT_PASSWORD: 'Повторите пароль',
    SIGN_IN: 'Войти',
    VERIFICATION_CODE: 'Код подтверждения',
    NO_ACCOUNT: (
      <>
        Еще нет аккаунта? <Link to={path.REGISTER}>Зарегистрироваться</Link>
      </>
    ),
    RESTORE: 'Восстановление',
    RECOVERY: 'Восстановить',
    REGISTRATION: 'Регистрация',
    CONFIRM_PASSWORD: 'Подтвердите пароль',
    JOIN: 'Зарегистрироваться',
    ALREDY_REGISTER: (
      <>
        Уже зарегистрированы? <Link to={path.LOGIN}>Войти</Link>
      </>
    ),
    LOGOUT: 'Выйти',
    LK: 'Личный кабинет'
  },
  en: {
    LOGIN: 'Log in',
    FORIGIN_PASSWORD: 'Forgotten your password?',
    PASSWORD: 'Password',
    SIGN_IN: 'Sign in',
    REPEAT_PASSWORD: '',
    VERIFICATION_CODE: 'Confirmation Code',
    NO_ACCOUNT: (
      <>
        No account? <Link to={path.REGISTER}>Sign Up</Link>
      </>
    ),
    RESTORE: 'Restore',
    RECOVERY: 'Recover',
    REGISTRATION: 'Registration',
    CONFIRM_PASSWORD: 'Confirm password',
    JOIN: 'Join',
    ALREDY_REGISTER: (
      <>
        Already registered? <Link to={path.LOGIN}>Log in</Link>
      </>
    ),
    LOGOUT: 'Logout',
    LK: 'Personal Area'
  },
  tu: {
    LOGIN: 'Giriş',
    FORIGIN_PASSWORD: 'Parolanızı mı unuttunuz?',
    PASSWORD: 'Şifre',
    SIGN_IN: 'İçeri gel',
    REPEAT_PASSWORD: 'Repeat password',
    VERIFICATION_CODE: 'Onay kodu',
    NO_ACCOUNT: (
      <>
        Henüz bir hesabınız yok mu? <Link to={path.REGISTER}>Kayıt ol</Link>
      </>
    ),
    RESTORE: 'Kurtarma',
    RECOVERY: 'Нeniden kurmak',
    REGISTRATION: 'Kayıt',
    CONFIRM_PASSWORD: 'şifreyi onayla',
    JOIN: 'Kayıt ol',
    ALREDY_REGISTER: (
      <>
        Zaten kayıtlı? <Link to={path.LOGIN}>İçeri gel</Link>
      </>
    ),
    LOGOUT: 'Dışarı çıkmak',
    LK: 'Kişisel Alan'
  }
}
