import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CreateAutoText from '../components/create-auto-text'
import { CloseDropdownWalletsIcon } from '../components/icons'
import Trade from '../features/trade/trade'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import useConditionDirection from '../hooks/useConditionDirection'
import useGetText from '../hooks/useGetText'
import { fetchDynamicText } from '../store/actions/mainActions'
import { setCity, setFrom, setHashID, setTo } from '../store/reducers/tradeReducer'
import { codesCurrency, otherLinks, path } from '../utils/consts'
import Win from '../components/Win'
import { fetchUserCards } from '../store/actions/userActions'
import { fetchIsAdmin } from '../store/actions/adminActions'
import { resetBid } from '../store/reducers/bidReducer'
import FingerprintJS from 'fingerprintjs2'

function MainPage() {
  const navigate = useNavigate()
  const {
    filteredCity,
    directions,
    course,
    valueFrom,
    valueTo,
    status,
    from: selectorFrom,
    to: selectorTo,
    city: selectorCity
  } = useAppSelector(state => state.trade)
  const { language } = useAppSelector(state => state.main)
  const { isAuth } = useAppSelector(state => state.user)
  const [isPopup, setPopup] = useState<boolean>(false)
  const [isErrorPopup, setErrorPopup] = useState<boolean>(false)

  let { from, to, city } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    let cur_from = searchParams.get('cur_from')
    let cur_to = searchParams.get('cur_to')
    const cur_city = searchParams.get('city')
    let changeTo = to
    let changeFrom = from

    cur_from = cur_from === 'USDTTRC20' ? 'USDTTRC' : cur_from === 'USDTERC20' ? 'USDTERC' : cur_from
    cur_to = cur_to === 'USDTTRC20' ? 'USDTTRC' : cur_to === 'USDTERC20' ? 'USDTERC' : cur_to

    changeFrom = from === 'USDTTRC20' ? 'USDTTRC' : changeFrom === 'USDTERC20' ? 'USDTERC' : from
    changeTo = to === 'USDTTRC20' ? 'USDTTRC' : changeTo === 'USDTERC20' ? 'USDTERC' : changeTo

    if (cur_from && cur_to && cur_city) {
      localStorage.setItem('from', cur_from)
      dispatch(setFrom(cur_from.toUpperCase()))

      localStorage.setItem('to', cur_to)
      dispatch(setTo(cur_to.toUpperCase()))

      localStorage.setItem('city', cur_city)
      dispatch(setCity(cur_city.toLowerCase()))
    } else if (from && to && city) {
      localStorage.setItem('from', from)
      dispatch(setFrom(changeFrom!.toUpperCase()))

      localStorage.setItem('to', to)
      dispatch(setTo(changeTo!.toUpperCase()))

      localStorage.setItem('city', city)
      dispatch(setCity(city.toLowerCase()))
    } else {
      dispatch(setFrom('CASHRUB'))
      dispatch(setTo('USDTTRC'))
      dispatch(setCity('MSK'))

      // localStorage.setItem('to', 'BTC')
      // localStorage.setItem('from', 'SBERRUB')
      // localStorage.setItem('city', 'MSK')

      navigate('exchange/CASHRUB/USDTTRC/msk')
    }
  }, [city, from, navigate, to, searchParams])

  const dispatch = useAppDispatch()
  const { mainPageTexts } = useGetText()

  useEffect(() => {
    dispatch(setFrom(from!))
    dispatch(setTo(to!))
    dispatch(setCity(city!))
  }, [city, dispatch, from, to])

  const condition = useConditionDirection(from!, to!)

  useEffect(() => {
    if (directions?.length > 0) {
      dispatch(fetchDynamicText('before'))
    }
  }, [from, to, city, directions, language])

  useEffect(() => {
    dispatch(setHashID(''))
    dispatch(resetBid())
  }, [])

  useEffect(() => {
    if (isAuth) {
      // dispatch(fetchUserCards())
      // dispatch(fetchIsAdmin())
    }
  }, [isAuth])

  useEffect(() => {
    if (isPopup) {
      document.body.addEventListener('click', () => {
        setPopup(false)
      })
    }
  }, [isPopup])

  useEffect(() => {
    if (condition.cash) {
      if (isErrorPopup) {
        document.body.addEventListener('click', () => {
          setErrorPopup(false)
        })
      }
      if (!isErrorPopup) {
        if (to === 'CASHAED' || from === 'CASHAED') {
          dispatch(setCity('dubai'))
        } else if (to === 'CASHTRY' || from === 'CASHTRY') {
          dispatch(setCity('stam'))
        } else if (to === 'CASHAMD' || from === 'CASHAMD') {
          dispatch(setCity('ervn'))
        } else if (to === 'KZTCASH' || from === 'KZTCASH') {
          dispatch(setCity('alm'))
        } else if (to === 'GELCASH' || from === 'GELCASH') {
          dispatch(setCity('tbil'))
        } else {
          dispatch(setCity('msk'))
        }
      }
    }
  }, [isErrorPopup])

  useEffect(() => {
    if (condition.cash) {
      if (status === 'succes') {
        setErrorPopup(false)
      }
      if (status === 'error') {
        setErrorPopup(true)
      }
    }
  }, [status])

  const getCity: any = (code: string) => filteredCity.find((el: any) => el.code === code)

  return (
    <>
      <div className='container pb-5 mb-5'>
        <div className='d-lg-flex mt-4'>
          <a
            className='ex_node'
            href='https://exnode.ru/?utm_source=cashex'
            target={'_blank'}
            // onClick={() => ym(61727125,'reachGoal','bank')}
          >
            <img src='/images/static/EX_NODE.png' alt='EX_NODE' />
          </a>
        </div>

        <div className='d-lg-flex mt-4 align-items-center justify-content-between'>
          <h1 className='mb-2 m-0'>{mainPageTexts?.HEADING}</h1>
          <Link className='win_sum' to={path.CONTESTS}>
            <img src='/images/static/cat_1.png' width={54} alt='' />
            {mainPageTexts?.WIN_CONTESTS_NEW}
          </Link>
        </div>

        <div className='d-lg-flex mb-4 align-items-center justify-content-between'>
          {condition.cash && <h1 className='mb-2 m-0'>{language === 'ru' ? getCity(city)?.name_rus : getCity(city)?.name_en}</h1>}
        </div>

        <div className='d-md-flex justify-content-between'>
          <p className='mb-0 t3 description'>{mainPageTexts?.WORKS_TIME}</p>
          <div>
            <p className='mb-0'>
              {mainPageTexts?.COURSE}: {course.from} {codesCurrency[from!]}{' '}
              <img src={`/images/currencies/${from}.svg`} className='mb-1' width='24' alt='' /> = {course.to} {codesCurrency[to!]}{' '}
              <img src={`/images/currencies/${to}.svg`} alt='' height='24' className='mb-1' />
            </p>
            {!condition.cash && (
              <>
                {condition.bankToCrypto && +valueFrom < 3000 && (
                  <p>
                    <strong>
                      {' '}
                      {mainPageTexts?.SUM_WITH_COMISION} {+valueFrom + 30}₽{' '}
                    </strong>
                  </p>
                )}
                {condition.cryptoToBank && +valueTo < 3000 && (
                  <p>
                    {' '}
                    <strong>
                      {' '}
                      {mainPageTexts?.SUM_WITH_COMISION} {+valueTo + 30}₽
                    </strong>
                  </p>
                )}
              </>
            )}
          </div>
        </div>

        <Trade />
      </div>
      {isPopup && (
        <div className='popup'>
          <div className='position-relative'>
            <button
              className='close-popup btn p-0'
              onClick={e => {
                setPopup(false)
              }}
            >
              <CloseDropdownWalletsIcon />
            </button>
            <div className='text-wrapper'>
              <CreateAutoText />
            </div>
          </div>
        </div>
      )}
      {condition.cash && isErrorPopup && (
        <div className='error_popup'>
          <div className='position-relative'>
            <button
              className='close-popup btn p-0'
              onClick={e => {
                setErrorPopup(false)
              }}
            >
              <CloseDropdownWalletsIcon />
            </button>
            <div className='text-wrapper'>
              <div>Выбранное направление недоступно в городе {language === 'ru' ? getCity(city)?.name_rus : getCity(city)?.name_en}</div>
            </div>
          </div>
        </div>
      )}
      <section className='contests-ban'>
        <div className='container position-relative' style={{ zIndex: 4 }}>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='contests-ban-card'>
                <h2>{mainPageTexts?.CONTESTS_BAN.HEADING_RIGHT}</h2>
                <ul>
                  {mainPageTexts?.CONTESTS_BAN.LIST?.map((el: any, idx: number) => (
                    <li key={idx}>
                      <span>{idx + 1}</span>
                      {el}
                    </li>
                  ))}
                </ul>
                <p className='t3 mt-4'>
                  {mainPageTexts?.CONTESTS_BAN.EXHANGE_TERM}
                  <span
                    className='link c-p'
                    onClick={e => {
                      e.stopPropagation()
                      setPopup(true)
                    }}
                  >
                    {language === 'en' ? 'here' : language === 'tu' ? 'burada' : 'тут'}
                  </span>
                </p>
              </div>
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 mt-lg-0 mt-4'>{/*<Win />*/}</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MainPage
