import React, { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthLoader } from '../../components/loaders/loaders'
import { useAppSelector } from '../../hooks/reduxHooks'
import { path } from '../../utils/consts'

interface AuthLayoutProps {
  children?: ReactNode
  title?: string
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, title }) => {
  return (
    <div className='container py-5'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='auth-card'>
            {/* <AuthLoader /> */}
            {title && <h2 className='text-center'>{title}</h2>}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
