export const ExchangeTexts: any = {
  ru: {
    HEADING: 'ID ЗАЯВКИ:',
    CHECK_DATA: 'Проверьте введенные данные:',
    QR_CODE: 'QR-код для оплаты',
    CREATE_TIME: 'Время создания',
    PAYMENT_DETILS_GIVE: 'Реквизиты получателя',
    ORDER_STATUS: 'Статус заявки',
    PAYOUT_BID: 'Оплатите заявку:',
    SUM_FROM: 'Сумма платежа:',
    SUM_TO: 'Сумма к получению:',
    BTN_PAYOUT_BID: 'Оплатить заявку',
    COPIED_LINK: 'Ссылка скопирована',
    BID_DESCRIPTION: `
        Выполнение заявки на обмен криптовалюты занимает от 1 до 15 минут.
        Заявка изменит свой статус на "Оплачена пользователем" после выхода криптовалюты в сеть.`,
    BTN_CANCEL_BID: 'Отменить заявку',
    BTN_STATUS_BID: 'Статус по заявке',
    BTN_I_PAID: 'Я оплатил',

    BID_STATUS_HEADING: 'После поступления платежа к нам на счёт, обработка и оплата заявки займет по регламенту от 1 до 15 минут',
    BID_STATUS_DESCRIPTION:
      'На этой странице Вы можете отслеживать статус Вашей заявки в режиме онлайн и написать нам в чат, если появятся какие-либо вопросы. Также мы отправили ссылку на эту страницу Вам на почту, чтобы Вы могли в любой момент вернуться и узнать, на каком этапе оплата заявки.',

    BID_STATUS: {
      PENDING: 'Клиент подтвердил оплату заявки',
      REALPAY: 'Обработка платежа',
      RECIVED: 'Криптовалюта вышла в сеть',
      DONE: 'Заявка оплачена'
    },
    BID_INFO_BTN: 'Страница оплаты',
    CITY: 'Город:',
    LEAVE_FEEDBACK: 'Оставить отзывы',
    ADD_FAVORITES: 'Добавить в закладки',
    TRANSACTION_LINK: 'Ссылка на транзакцию',
    PAY_WALLET: 'Реквизиты для оплаты',
    EXCHANGE_DATA: 'Данные об оплате',
    EXCHANGE_WARNING:
      'Запрещено оплачивать с корпоративных карт, юридических счетов, ИП, такие заявки обработаны не будут, средства будут безвозвратно утеряны.',
    EXPIRED_WARNING: 'Время оплаты заявки вышло. Если вы оплатили, но статус не поменялся, напишите в чат поддержки.'
  },
  en: {
    HEADING: 'APPLICATION ID:',
    CHECK_DATA: 'Check the data you have entered:',
    QR_CODE: 'QR code for payment',
    CREATE_TIME: 'Creation time',
    PAYMENT_DETILS_GIVE: 'Account number',
    ORDER_STATUS: 'Order status',
    PAYOUT_BID: 'Pay order',
    SUM_FROM: 'Payment amount:',
    SUM_TO: 'Amount to be received:',
    BTN_PAYOUT_BID: 'Оплатить заявку',
    COPIED_LINK: 'Ссылка скопирована',
    BID_DESCRIPTION: `
        To make the exchange, click "Pay" and go to the payment page of the payment system.
        Execution of the crypto exchange request takes 1 to 15 minutes.
        `,
    BTN_CANCEL_BID: 'Cancel',
    BTN_STATUS_BID: 'Application status',
    BTN_I_PAID: 'I paid',

    BID_STATUS_HEADING: 'After receipt of the cryptocurrency to our wallet, processing and payment of the Application will take from 1 to 15 minutes',
    BID_STATUS_DESCRIPTION:
      'On this page you can monitor the status of your Application online and chat with us if you have any questions. Also, we have sent a link to this page to your e-mail so that you can return at any time to find out at what stage the payment is.',

    BID_STATUS: {
      PENDING: 'Customer confirmed payment of Application',
      REALPAY: 'Payment processing',
      RECIVED: 'Cryptocurrency is online',
      DONE: 'The Application is paid'
    },
    BID_INFO_BTN: 'Payment page',
    CITY: 'City:',
    LEAVE_FEEDBACK: 'Leave feedback',
    ADD_FAVORITES: 'Add to favorites',
    TRANSACTION_LINK: 'Transaction link',
    PAY_WALLET: 'Payment details',
    EXCHANGE_DATA: 'Payment details',
    EXCHANGE_WARNING:
      'It is forbidden to pay with corporate cards, legal accounts, sole proprietors, such applications will not be processed, the funds will be irretrievably lost.',
    EXPIRED_WARNING: 'The time to pay for your application has expired. If you paid, but the status has not changed, write to the support chat.'
  },
  tu: {
    HEADING: 'ID UYGULAMALAR:',
    CHECK_DATA: 'Girilen verileri kontrol edin:',
    QR_CODE: 'Ödeme için QR kodu',
    CREATE_TIME: 'Yaratılış zamanı',
    PAYMENT_DETILS_GIVE: 'Yaratılış zamanı',
    ORDER_STATUS: 'Uygulama durumu',
    PAYOUT_BID: 'Uygulama için ödeme yapın:',
    SUM_FROM: 'Ödeme miktarı:',
    SUM_TO: 'Alınacak tutar:',
    BTN_PAYOUT_BID: 'Uygulama için ödeme yapın',
    COPIED_LINK: 'Bağlantı kopyalandı',
    BID_DESCRIPTION: `
        Kripto para birimi değişimi için bir başvurunun yürütülmesi 1 ila 15 dakika sürer.
        Kripto para birimi ağa girdikten sonra uygulama durumunu "Kullanıcı tarafından ödendi" olarak değiştirecektir.`,
    BTN_CANCEL_BID: 'uygulamayı iptal et',
    BTN_STATUS_BID: 'Uygulama durumu',
    BTN_I_PAID: 'Ben ödedim',

    BID_STATUS_HEADING:
      'Ödeme hesabımıza ulaştıktan sonra başvurunun işlenmesi ve ödemesinin yapılması mevzuata göre 1 ila 15 dakika arasında sürecektir.',
    BID_STATUS_DESCRIPTION:
      'Bu sayfada, başvurunuzun durumunu çevrimiçi olarak takip edebilir ve herhangi bir sorunuz varsa sohbet yoluyla bize ulaşabilirsiniz. İstediğiniz zaman geri dönebilmeniz ve başvuru ödemesinin hangi aşamada olduğunu öğrenebilmeniz için e-postanıza bu sayfanın bağlantısını da gönderdik.',

    BID_STATUS: {
      PENDING: 'Müşteri, başvurunun ödemesini onayladı',
      REALPAY: 'Odeme yapiliyor',
      RECIVED: 'Kripto para çevrimiçi oldu',
      DONE: 'Uygulama ödendi'
    },
    BID_INFO_BTN: 'ödeme sayfası',
    CITY: 'Şehir:',
    LEAVE_FEEDBACK: 'Geribildirim bırak',
    ADD_FAVORITES: 'Yer imlerine ekle',
    TRANSACTION_LINK: 'işlem bağlantısı',
    PAY_WALLET: 'ödeme detayları',
    EXCHANGE_DATA: 'ödeme detayları',
    EXCHANGE_WARNING:
      "Kurumsal kartlardan, yasal faturalardan, ıp'lerden ödeme yapmak yasaktır, bu tür başvurular işlenmeyecek, fonlar geri dönülmez bir şekilde kaybedilecektir.",
    EXPIRED_WARNING: 'Başvurunuzun ödeme süresi dolmuştur. Ödeme yaptıysanız ancak durum değişmediyse destek sohbetine yazın.'
  }
}
