import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react'
import { fetchValidatePurse } from '../store/actions/tradeActions'
import { setIsValidPurseCard, setIsValidPurseWallet } from '../store/reducers/tradeReducer'
import utils from '../utils/utils'
import validation from '../utils/validation'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import useDebounce from './useDebounce'

interface useInputParams {
  (initialValue?: any, password?: string, token?: string): any
}

const validationText: any = {
  ru: {
    // текст при отрицательной валидации
    email: 'Введите корректный email адрес, пример: test@test.ru',
    name: 'Введите корректное имя (больше 2 символов, латиница)',
    telegram: 'Введите корректный Telegram (больше 4 символов)',
    phone: 'Введите корректный номер телефона в формате +7 (999) 888-77-66',
    card: 'Неверный номер карты',
    crypto: 'Введите корректный номер кошелька (20 и более символов)',
    id: 'Введите корректный ID, только цифры (4 и больше)',
    message: 'Опишите проблему',
    smallSum: (min: string | number) => `Слишком маленькая сумма, введите сумму больше ${min}`,
    bigSum: (max: string | number) => `Слишком большая сумма, введите сумму меньше ${max}`,
    password: 'Пароль должен содержать цифры, большие и маленькие буквы, длина пароля от 8 до 32 символов',
    password_login: 'Длина пароля от 8 до 32 символов',
    comparePasswordFalse: 'Пароли не совпадают',
    comparePasswordTrue: 'Пароли совпадают',
    texts: 'В поле должно быть больше 2 слов',
    required: 'Поле обязательно для заполнения'
  },
  en: {
    email: 'Enter a valid email address, like: test@test.ru',
    name: 'Enter a valid name',
    telegram: 'Enter a valid Telegram',
    phone: 'Enter a valid phone number in the format + 7(999) 888 - 77 - 66',
    card: 'Invalid card number',
    crypto: 'Enter a valid wallet number(20 or more)',
    id: 'Enter a valid ID, only numbers(4 or more)',
    message: 'Describe the problem',
    smallSum: (min: string | number) => `Too little amount, enter a large amount ${min}`,
    bigSum: (max: string | number) => `Too much amount, enter a smaller amount ${max}`,
    password: `The password must contain numbers, uppercase and lowercase letters, password length from 8 to 32 characters`,
    password_login: 'Password length from 8 to 32 characters',
    comparePasswordFalse: 'Passwords do not match',
    comparePasswordTrue: 'Passwords match',
    texts: 'The field must contain more than 2 words',
    required: 'Required field'
  },
  tu: {
    email: 'Geçerli bir e-posta adresi girin, örneğin: test@test.ru',
    name: 'geçerli bir ad girin',
    telegram: 'Geçerli bir Telegram girin',
    phone: '+7 (999) 888-77-66 biçiminde geçerli bir telefon numarası girin',

    card: 'geçersiz kart numarası',
    crypto: 'Lütfen geçerli bir cüzdan numarası girin (20 karakter veya daha fazla)',
    id: 'Lütfen geçerli bir kimlik girin, yalnızca sayılar (4 veya daha fazla)',
    message: 'sorunu açıkla',
    smallSum: (min: string | number) => `Tutar çok küçük, lütfen daha fazla girin ${min}`,
    bigSum: (max: string | number) => `Tutar çok büyük, daha az bir miktar girin ${max}`,
    password: 'Parola sayı, büyük ve küçük harf içermeli, parola uzunluğu 8 ila 32 karakter olmalıdır',
    password_login: '8 ila 32 karakter arası parola uzunluğu',
    comparePasswordFalse: 'Parolalar uyuşmuyor',
    comparePasswordTrue: 'Şifre uyuşması',
    texts: "Alan 2'den fazla kelime içermelidir",
    required: 'Gerekli alan'
  }
}

const classesTypes = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const namesType = {
  // название инпутов
  CARD: 'card',
  PHONE: 'phone',
  EMAIL: 'email',
  CARD_NAME: 'card_name',
  NAME: 'name',
  NAME_SURNAME: 'name_surname',
  WALLET: 'wallet',
  PASSWORD: 'password',
  COMPARE_PASSWORD: 'compare-password',
  VERIFICATION_CODE: 'verification-code',
  PASSWORD_LOGIN: 'password-login',
  CREATE_ACCOUNT: 'password-login',
  TEXTS: 'texts',
  TELEGRAM: 'telegram',
  MEMO_TAG: 'memo-tag'
}

const useInput: useInputParams = (initialValue, password) => {
  const { language } = useAppSelector(state => state.main)
  const { isValidPurseCard, to, isValidPurseWallet, from } = useAppSelector(state => state.trade)

  const [value, setValue] = useState(initialValue || '')
  const [msg, setMsg] = useState('')
  const [classes, setClasses] = useState('')
  const [isValid, setValid] = useState(false)
  const [name, setName] = useState('')

  const debounceValue = useDebounce(value, 500)

  const dispatch = useAppDispatch()

  function successInput(msgValue: string): void {
    // положительный инпут
    setMsg(msgValue)
    setClasses(classesTypes.SUCCESS)
    setValid(true)
  }
  function errorInput(msgValue: string): void {
    // отрицательный инпут
    setMsg(msgValue)
    setClasses(classesTypes.ERROR)
    setValid(false)
  }

  // server validation

  useEffect(() => {
    if (name === namesType.CARD) {
      // валидация номера карты
      if (isValidPurseCard?.card) successInput('')
      else errorInput(validationText[language].card)
    }
  }, [isValidPurseCard?.card, language])

  useEffect(() => {
    // валидация кошелька
    if (name === namesType.WALLET) {
      if (isValidPurseWallet?.wallet) successInput('')
      else errorInput(validationText[language].crypto)
    }
  }, [isValidPurseWallet?.wallet, language])

  // server validation

  useEffect(() => {
    // debounce эффект
    if (name === namesType.CARD) {
      if (validation.card(value?.split(' ').join(''))) {
        dispatch(setIsValidPurseCard(false))
        dispatch(
          fetchValidatePurse({
            refer: value,
            symbol: 'CARD'
          })
        )
      } else errorInput(validationText[language].card)
    }

    if (name === namesType.EMAIL) {
      if (validation.email(value) || 'bestchange' === value) successInput('')
      else errorInput(validationText[language].email)
    }

    if (name === namesType.CARD_NAME) {
      if (validation.holder(value)) successInput('')
      else errorInput(validationText[language].name)
    }
    if (name === namesType.NAME) {
      if (value.length > 2) successInput('')
      else errorInput(validationText[language].name)
    }
    if (name === namesType.TELEGRAM) {
      if (value.length >= 4) successInput('')
      else errorInput(validationText[language].telegram)
    }
    if (name === namesType.PHONE) {
      if (validation.phone(value)) successInput('')
      else errorInput(validationText[language].phone)
    }

    if (name === namesType.PASSWORD) {
      if (validation.password(value)) successInput('')
      else errorInput(validationText[language].password)
    }

    if (name === namesType.COMPARE_PASSWORD) {
      if (utils.comparePassword(value, password || '')) successInput('')
      else errorInput(validationText[language].comparePasswordFalse)
    }

    if (name === namesType.PASSWORD_LOGIN) {
      if (value.length >= 8) successInput('')
      else errorInput(validationText[language].password_login)
    }

    if (name === namesType.WALLET) {
      if (value.length > 0) {
        dispatch(setIsValidPurseWallet(false))
        const data = {
          refer: value,
          symbol: to === 'USDTTRC' ? 'trx' : to === 'USDTERC' ? 'eth' : to === 'PMUSD' ? 'usdc' : to
        }
        // const walletsWithValidation = [
        //     'BTC', 'USDTTRC', 'ETH'
        // ]
        // if (walletsWithValidation.some(el => el === from) || walletsWithValidation.some(el => el === to)) {
        dispatch(fetchValidatePurse(data))
        // } else successInput('')
      } else errorInput(validationText[language].crypto)
    }

    if (name === namesType.TEXTS) {
      if (value.split(' ').length > 2) successInput('')
      else errorInput(validationText[language].texts)
    }
  }, [debounceValue, language])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const name: string = event.target.name
      const evtValue = event.target.value
      setName(name)
      setClasses('')
      if (name === namesType.CARD) {
        setValue(validation.cardCleaner(evtValue))
        setMsg('')
        return
      }
      if (name === namesType.PHONE) {
        setMsg('')
      }
      if (name === namesType.CARD_NAME) {
        setMsg('')
        setValue(evtValue.toUpperCase())
        return
      }

      if (name === namesType.PHONE) {
        if (evtValue?.includes('+7 (')) if (evtValue?.split('+7 (')[1][0] === '7' || evtValue?.split('+7 (')[1][0] === '8') return

        setValue(validation.phoneCleaner(evtValue))

        return
      }

      if (event.target.required) {
        if (evtValue.length > 0) successInput('')
        else errorInput(validationText[language].required)
      }

      setMsg('')
      setValue(evtValue)
      setValid(true)
    },
    [language]
  )

  const onBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const name: string = event.target.name
    setName(name)

    if (name === namesType.CARD) {
      if (validation.card(value?.split(' ').join(''))) {
        dispatch(
          fetchValidatePurse({
            refer: value,
            symbol: 'CARD'
          })
        )
      }
    }
  }, [])

  const onKeyDown = (evt: any) => {
    const name = evt.target.name
    setName(name)

    if (name === namesType.CARD) {
      if (evt?.keyCode === 8) {
        setValue(value.replace(/^\w$/, ''))
      } else {
        setValue(validation.cardReplacer(value))
      }
      return
    }

    if (name === namesType.PHONE) {
      if (evt.keyCode === 8) {
        setValue(value.replace(/^\w$/, ''))
      } else {
        setValue(validation.formattingPhone(value, value.length))
      }
      return
    }
  }

  const clearInput = useCallback((event: any) => {
    setMsg('')
    setClasses('')
    setValid(false)
    setValue('')
  }, [])

  return {
    value,
    setValue,
    msg,
    setMsg,
    classes,
    setClasses,
    isValid,
    setValid,
    onChange,
    onKeyDown,
    clearInput,
    onBlur
  }
}

export default useInput
