import React from 'react'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/button'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { setIsCreated } from '../../store/reducers/apiLkAuthReducer'
import { closeModal } from '../../store/reducers/modalReducer'
import { path } from '../../utils/consts'

const ModalSignUpQr: React.FC = () => {
  const dispatch = useAppDispatch()

  const { otp_url } = useAppSelector(state => state.apiLkAuth)
  const { mainPageTexts } = useGetText()

  const [clientAddedOtp, setClientAddedOtp] = React.useState(false)

  const navigate = useNavigate()

  const onClickClose = () => {
    setClientAddedOtp(false)
    dispatch(closeModal())
    dispatch(setIsCreated(false))
    navigate(path.API_LOGIN)
  }

  return (
    <div>
      <div className='row mt-3'>
        <div className='d-md-flex justify-content-center'>
          <div className='justify-content-center'>
            {/* HEADER */}
            <h3 className='row mb-4'>Ваш персональный ключ для Google Authenticator.</h3>

            {/* QR CODE */}
            <p className='mb-4 text-center'>{otp_url && <QRCode fgColor='#002674' value={otp_url} size={175} />}</p>

            {/* WARNING */}
            <h6 className='mb-4'>*После закрытия этого окна, доступ к ключу получить будет невозможно.</h6>

            {/* CHECKBOX FOR CLOSE MODAL */}
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div>
                <input type='checkbox' id='checkbox_id' style={{ marginRight: '6px' }} checked={clientAddedOtp} onChange={() => setClientAddedOtp(prev => !prev)} />
                <label htmlFor='checkbox_id'>
                  <p>Я добавил ключ Google Authenticator</p>
                </label>
              </div>

              {/* BUTTON FOR CLOSE */}
              <Button variant='primary' className='' disabled={!clientAddedOtp} onClick={onClickClose}>
                Закрыть
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ModalSignUpQr)
