import React, { useEffect, useMemo, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { fetchStaticText } from '../store/actions/mainActions'
import Preloader from '../components/Preloader'

const faqData = [
  {
    title: 'Когда будет выплата?',
    description:
      'Время выплаты зависит от выбранного направления и в среднем занимает 15 минут. Выплата выхода транзакции в сеть RUB-BTC, RUB-ETH - 15-20 минут,в редких случаях до 60 минут, пожалуйста, дождитесь этого времени прежде чем писать в поддержку.'
  },
  {
    title: 'Я оплатил сделку, а она отменилась. Что мне делать?',
    description:
      'Рекомендуем сверить указанные вами реквизиты с автоматического письма на вашей почте и проверить баланс,в случае непоступления оплаты,вам следует проверить отправленную транзакцию на наличие подтверждений. Выплата осуществляется на момент второго подтверждения.'
  },
  {
    title: 'Нужно ли регистрироваться для совершения обмена?',
    description: 'Для того чтобы совершить обмен на нашем сервисе регистрация необязательна.'
  },
  {
    title: 'Режим работы обменного сервиса?',
    description: 'Мы работаем круглосуточно. Режим работы оператора по безналичным операциям с 8:00 до 22:00. Режим работы оператора по наличному обмену круглосуточно'
  },
  {
    title: 'Нужна ли верификация банковской карты для совершения обмена?',
    description: 'Да, верификация требуется при оплате с карты. Зачастую этот процесс занимает не более 5-ти минут. Верифицированную карту можно сохранить и использовать для дальнейших сделок.'
  },
  {
    title: 'Как происходит верификация карты?',
    description: 'Необходимо сделать фото карты на фоне сайта или фото карты на фоне листа, на котором написано «Покупка криптовалюты на сайте Cashex.cc и номер сделки», при этом данные (кроме номера карты) можно закрыть.'
  },
  {
    title: 'Откуда мне поступят деньги на рублёвую карту?',
    description: 'Выплата осуществляется автоматически через платежную систему Qiwi по указанным реквизитам заказчика.'
  }
]

const Faq = () => {
  const { staticText, language, status } = useAppSelector(state => state.main)
  const isLoading = useRef(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading.current) {
      dispatch(fetchStaticText('FAQ'))
    }
    isLoading.current = true
  }, [language])

  const faqText = useMemo(() => Object.entries(staticText).slice(1, Object.entries(staticText).length - 1), [staticText])

  if (!isLoading.current) {
    return (
      <div>
        <img src='' alt='' />
      </div>
    )
  }

  return (
    <div className='container mb-5 pb-5'>
      <div>
        <h1>FAQ</h1>
        {status === 'loading static text' ? (
          <Preloader />
        ) : (
          <div className='row'>
            {faqText.map((item, idx) => {
              const el: any = item[1]
              return (
                <div key={idx} className='accordion accordion-flush col-lg-6'>
                  <div className='accordion-item'>
                    <h5 className='accordion-header mt-sm-4 mt-2' id={`flush-headingOne-${idx}`}>
                      <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={`#flush-collapseOne-${idx}`} aria-expanded='false' aria-controls={`flush-collapseOne-${idx}`}>
                        <h5 className='m-0'>{el?.title}</h5>
                      </button>
                    </h5>
                    <div id={`flush-collapseOne-${idx}`} className='accordion-collapse collapse' aria-labelledby={`flush-headingOne-${idx}`}>
                      {el?.descriptionList.map((p: string, pIdx: number) => (
                        <p key={pIdx} className='accordion-body mb-0'>
                          {p}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Faq
