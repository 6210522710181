import React, {FC, RefObject, SetStateAction, useEffect, useRef, useState} from 'react';
import {IUserCardsItem} from "../../store/reducers/userReducer";
import {ArrowBottom} from "../icons";
import {useAppSelector} from "../../hooks/reduxHooks";


interface IProps {
    onClick: (data: any) => void,
    withCardVerification: boolean,
    setIsNewCardInputShow: React.Dispatch<SetStateAction<boolean>>,
    cardsList: IUserCardsItem[],
    value: string
}

const CreditCardSelect: FC<IProps> = ({
                                          onClick,
                                          value,
                                          cardsList,
                                          withCardVerification,
                                          setIsNewCardInputShow
                                      }) => {
    const [isCardMenuOpened, setIsCardMenuOpened] = useState(false)
    const onClickHandler = () => {
        setIsCardMenuOpened(!isCardMenuOpened)
    }
    const cardBlockRef = useRef<HTMLDivElement>(null)
    const openCardBlockRef = useRef<HTMLDivElement>(null)


    const useOnClickOutside = (ref: RefObject<HTMLDivElement>,
                               menuButtonRef: RefObject<HTMLDivElement>,
                               handler: (event: { target: any }) => void,
    ) => {

        useEffect(() => {
            const listener = (event: { target: any; }) => {
                if (!ref.current
                    || ref.current.contains(event.target)
                    || !menuButtonRef.current
                    || menuButtonRef.current.contains(event.target)
                ) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
            };
        }, [ref, handler]);
    };

    useOnClickOutside(cardBlockRef, openCardBlockRef, () => {
        setIsCardMenuOpened(false)
    })

    useEffect(() => {
        if (cardsList.length)
            onClick(cardsList[0]?.card_number)
    }, []);

    return (
        <>
            <div className={'cardSelector'}>
                <div className={'cardPickerTitle'}>С карты</div>
                <div className={'cardPickerContent'} style={{
                    borderRadius: `${isCardMenuOpened ? '9px 9px 0 0' : '9px  '}`
                }}>
                    <input disabled={true} className={'cardValue'} value={value ? value : ''}></input>
                    <div ref={openCardBlockRef} onClick={onClickHandler} className={'cardSelectButton'}>
                        <ArrowBottom/>
                    </div>
                </div>
                {isCardMenuOpened && <div ref={cardBlockRef} className={'cardPicker'}>
                    {cardsList.map((card, index) => <div key={`card_${index}`} onClick={() => {
                        onClick(card.card_number)
                        setIsCardMenuOpened(false)
                    }} className={'cardPickerItem'}>{card.card_number}</div>)}
                    {!withCardVerification &&
                        <div onClick={() => {
                            setIsNewCardInputShow(true)
                            onClick('')
                            setIsCardMenuOpened(false)
                        }} className={'cardPickerItem'}>Использовать другую карту..
                        </div>
                    }
                </div>
                }
            </div>
        </>
    );
};

export default CreditCardSelect;