import React from 'react'
import Button from '../../components/buttons/button'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { fetchPatchKeys } from '../../store/actions/apiLkActions'
import { closeModal } from '../../store/reducers/modalReducer'

export const ModalUpdateApiLkKeys: React.FC = React.memo(() => {
  const dispatch = useAppDispatch()

  const [clientAgreeUpdate, setClientAgreeUpdate] = React.useState(false)

  const onClickClose = () => {
    dispatch(fetchPatchKeys())
    setClientAgreeUpdate(prev => !prev)
    dispatch(closeModal())
  }

  return (
    <div>
      <div className='row mt-3'>
        <div className='d-md-flex justify-content-center'>
          <div className='justify-content-center'>
            {/* HEADER */}
            <h3 className='row mb-4'>Вы уверены, что хотите обновить API-ключи?</h3>

            {/* WARNING */}
            <h6 className='mb-4'>*Старые ключи перестанут работать.</h6>

            {/* CHECKBOX FOR CLOSE MODAL */}
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div>
                <input type='checkbox' id='checkbox_id' style={{ marginRight: '6px' }} checked={clientAgreeUpdate} onChange={() => setClientAgreeUpdate(prev => !prev)} />
                <label htmlFor='checkbox_id'>
                  <p>Я согласен сменить Api ключи</p>
                </label>
              </div>
            </div>

            {/* BUTTONS FOR UPDATE AND CLOSE */}
            <div className='d-flex gap-4'>
              <Button variant='primary' className='w-100' disabled={!clientAgreeUpdate} onClick={onClickClose}>
                Обновить
              </Button>
              <Button variant='primary' className='w-100' disabled={clientAgreeUpdate} onClick={() => dispatch(closeModal())}>
                Закрыть
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
