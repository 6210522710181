import {createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import Bid from '../../service/bid-service'
import {CardStatus, cookiesName} from '../../utils/consts'
import {RootState} from '../store'
import Cookies from 'cookies-ts'

const cookies = new Cookies()

export const fetchGetBid = createAsyncThunk('bid/fetchGetBid', async (_, {getState}) => {
    try {
        const {bid_processing_hash}: any = (getState() as RootState).bid

        const bid_id = localStorage.getItem('bid_id')

        const res = await Bid.getBid(bid_id)
        if (res?.response?.status >= 400) {
            return {
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
        console.log(err)
    }
})

export const fetchUpdateBid = createAsyncThunk('bid/updateBid', async (_, {getState}) => {
    try {
        const {bid_processing_hash}: any = (getState() as RootState).bid

        const bid_id = localStorage.getItem('bid_id')

        const res = await Bid.updateBid(bid_id)
        if (res?.response?.status >= 400) {
            return {
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
        console.log(err)
    }
})

export const fetchGetBidHandler = (builder: any) => {
    builder.addCase(fetchGetBid.pending, (state: any, action: PayloadAction<any>) => {
        state.isLoadingBid = false
    })
    builder.addCase(fetchGetBid.fulfilled, (state: any, action: PayloadAction<any>) => {
        const content = action?.payload?.content

        state.city_from = content?.city_from
        state.client_email = content?.client_email
        state.client_id = content?.client_id
        state.client_name = content?.client_name
        state.client_phone = content?.client_phone
        state.course_get = content?.course_from
        state.course_give = content?.course_to
        state.currency_get = content?.currency_from
        state.currency_give = content?.currency_to
        state.direction_id = content?.direction_id
        state.excTrc = content?.excTrc
        state.hash = content?.hash
        state.id = content?.id
        state.internal_id = content?.internal_id
        state.payment_details_get = content?.payment_details_from
        state.payment_details_give = content?.payment_details_to
        state.ref_id = content?.ref_id
        state.status_code = content?.status_code
        state.status_id = content?.status_id
        state.sum_get = content?.amount_from
        state.sum_give = content?.amount_to
        state.created_at = content?.created_at
        state.user_ip = content?.user_ip
        state.card = content?.cards_number
        state.transactionInfo = content?.transaction_info
        state.dest_tag = content?.dest_tag
        state.city = {
            en: content?.title_eng,
            ru: content?.title_rus,
            main: content.main === 'true'
        }
        // state.cardMessage = 'success'

        state.isLoadingBid = true
    })
    builder.addCase(fetchGetBid.rejected, (state: any, action: PayloadAction<any>) => {
        state.isLoadingBid = false
    })
}

// export const fetchCardIsVerified = createAsyncThunk('bid/fetchCardIsVerified', async (_, {getState}) => {
//   try {
//     const {card, cardHash}: any = (getState() as RootState).bid;
//     // const {hash}: any = (getState() as RootState).bid;
//     const user: any = (getState() as RootState).user;
//     // const {email}: any = (getState() as RootState).user;
//     if (card === '') {
//       return
//     }
//
//
//     let str = '';
//     if (cardHash?.length) {
//       str = cardHash
//     } else {
//       str = user?.userCards?.filter((cardUser: any) => cardUser?.number?.replace(/\s/g, '') === card?.replace(/\s/g, ''))[0]?.hash
//     }
//     // cookies.set(cookiesName.CARD_HASH, str)
//
//     const res = await Bid.checkIsVerified({hash: str})
//
//     if (res?.response?.status >= 400) {
//       return {
//         ...res?.response.data,
//         msg: res.response.data,
//         status: res?.response?.status
//       }
//     } else {
//       return res.data
//     }
//   } catch (err) {
//   }
// })
//
//
// export const fetchCardIsVerifiedHandler = (builder: any) => {
//   builder.addCase(fetchCardIsVerified.pending, (state: any, action: PayloadAction<any>) => {
//
//   })
//   builder.addCase(fetchCardIsVerified.fulfilled, (state: any, action: PayloadAction<any>) => {
//     if (action?.payload?.status) {
//       state.cardStatus = action?.payload?.content?.status
//     } else {
//       // state.cardStatus = ''
//     }
//
//   })
//   builder.addCase(fetchCardIsVerified.rejected, (state: any, action: PayloadAction<any>) => {
//
//   })
//
// }

export const fetchCardCheck = createAsyncThunk('bid/fetchCardCheck', async (_, {getState}) => {
    try {
        const {card, client_email}: any = await (getState() as RootState).bid

        const res = await Bid.cardCheck({
            card_number: card,
            email: client_email
        })

        // if (res?.response?.status >= 400) {
        //   return {
        //     ...res?.response.data,
        //     msg: res.response.data,
        //     status: res?.response?.status
        //   }

        // } else {
        return res.data
        // }
    } catch (err) {
    }
})

export const fetchCardCheckHandler = (builder: any) => {
    builder.addCase(fetchCardCheck.pending, (state: any, action: PayloadAction<any>) => {
        state.cardCheck = false
        state.cardCheckStatus = false
    })
    builder.addCase(fetchCardCheck.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.cardCheck = true
        state.cardCheckStatus = true
        state.cardStatus = action?.payload?.content
    })
    builder.addCase(fetchCardCheck.rejected, (state: any, action: PayloadAction<any>) => {
    })
}

export const fetchCreateNewCard = createAsyncThunk('bid/fetchCreateNewCard', async function (card: any, {getState}) {
    try {
        const bid: any = (getState() as RootState).bid
        const user: any = (getState() as RootState).user

        const form = new FormData()

        form.append('holder', bid?.cardName)
        form.append('card_number', bid?.card.split(' ').join(''))
        form.append('file', card)
        form.append('email', bid?.client_email)
        form.append('bid_id', bid?.id)

        const res = await Bid.createNewCard(form, {
            'Content-Type': card?.type
        })

        if (res?.response?.status >= 400) {
            return {
                msg: res?.response?.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchCreateNewCardHandler = (builder: any) => {
    builder.addCase(fetchCreateNewCard.pending, (state: any, action: PayloadAction<any>) => {
        state.cardMessage = 'loading'
    })
    builder.addCase(fetchCreateNewCard.fulfilled, (state: any, action: PayloadAction<any>) => {
        if (action.payload?.status >= 400) {
            state.cardMessage = action?.payload?.msg?.message
            if (action.payload.status === 500) {
                state.cardMessage = 'Неверный формат'
            }
        } else {
            state.cardMessage = 'success'
            state.cardHash = action?.payload?.content?.hash
            state.cardStatus = CardStatus.PANDING
        }
    })
    builder.addCase(fetchCreateNewCard.rejected, (state: any, action: PayloadAction<any>) => {
        // state.cardMessage = 'error';
    })
}

export const fetchDeleteBid = createAsyncThunk('bid/fetchDeleteBid', async function () {
    try {
        const res = await Bid.deleteBid()
        return res.data
    } catch (err) {
    }
})

export const finishVerification = createAsyncThunk('bid/finishVerification', async function (data: any, {rejectWithValue}) {
    try {
        // if (!bid?.cardHash?.length) {
        //   const hash = user?.userCards?.filter((card: any) => card.number.replace(/\s/g, '') === bid.card.replace(/\s/g, ''))
        //   const res = await Bid.finishVerification(
        //     {
        //       hash_bid: bid?.hash,
        //       hash_card: hash[0].hash
        //     }
        //   )
        //   return res.data
        // } else {
        //
        // }

        const dataToSend = data.bank === null
            ? {
                hash: data.hash
            }
            : {
                hash: data.hash,
                bank: data.bank
            }

        const res = await Bid.finishVerification(
            dataToSend,
            {
                fingerPrint: data.headers.fingerPrint
                // fingerPrint: '123'
            }
        )
        if (res?.response?.status >= 400) {
            return {
                msg: res.response.message,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err: any) {
        return rejectWithValue(err.message)
    }
})

export const finishVerificationHandler = (builder: any) => {
    builder.addCase(finishVerification.pending, (state: any, action: PayloadAction<any>) => {
        state.cardMessage = 'loading'
        state.bid_processing_message = 'loading'
    })
    builder.addCase(finishVerification.fulfilled, (state: any, {payload}: PayloadAction<any>) => {
        if (payload?.code >= 400) {
            if (payload.code === 500) {
                state.cardMessage = 'Неверный формат'
                state.bid_processing_message = 'error'
            } else if (payload?.code === 409) {
                state.cardMessage = 'error'
                state.bid_processing_message = 'error'
            } else {
                state.bid_processing_message = 'error'
            }
        } else {
            state.cardMessage = 'success'
            state.statusBid = true
            state.bid_processing_message = 'Success'
            state.bid_processing_hash = payload?.content?.hash
            state.payment_details_get = payload.content.payment_details_get
            state.payment_details_give = payload.content.payment_details_give
            cookies.set(cookiesName.PROCESSING, 'true')
        }
    })
    builder.addCase(finishVerification.rejected, (state: any, action: PayloadAction<any>) => {
        state.bid_processing_message = 'error'
    })
}

export const fetchSiteSettings = createAsyncThunk('user/fetch_vrf_status', async () => {
    try {
        return await Bid.getCardVerificationStatus();
    } catch (error) {
        console.log(error);
    }
})

export const fetchSiteSettingsHandler = (builder: any) => {
    builder.addCase(fetchSiteSettings.fulfilled, (state: any, action: PayloadAction<any>) => {
        if (action.payload.data) {
            state.withCardVerification = action.payload.data.value
        }else{
            state.withCardVerification = true
        }
    })
}
