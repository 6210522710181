const general = {
  ru: {
    PROFILE_HEADING: 'Личный кабинет',
    CONCLUSION: 'Выводить по',
    LOAD_MORE: 'Загрузить еще',
    REFERRAL_HEADING: 'Реферальная программа',
    REFERRAL_LINK: 'Реферальная ссылка',
    REFERRAL_TEXT: 'Вы можете поделиться вашей реферальной ссылкой, чтобы получить кешбек'
  },
  en: {
    PROFILE_HEADING: 'Private cabinet',
    CONCLUSION: 'Withdraw by',
    LOAD_MORE: 'Load more',
    REFERRAL_HEADING: 'Referral program',
    REFERRAL_LINK: 'Referral link',
    REFERRAL_TEXT: 'You can share your referral link to get cashback'
  },
  tu: {
    PROFILE_HEADING: 'Kişisel Alan',
    CONCLUSION: 'Tarafından çekilmek',
    LOAD_MORE: 'Daha fazla yükle',
    REFERRAL_HEADING: 'Tavsiye programı',
    REFERRAL_LINK: 'Yönlendirme bağlantısı',
    REFERRAL_TEXT: 'Para iadesi almak için tavsiye bağlantınızı paylaşabilirsiniz.'
  }
}

const exchange = {
  ru: {
    EXCHANGE_HEADING: 'История обменов',
    EXCHANGE_TABLE: {
      APPLICATION_NUM: 'Номер заявки',
      DATE: 'Дата / Время',
      COURSE: 'Курс',
      GIVE_GET: 'Отдаете / Получаете',
      STATUS: 'Статус'
    },
    CLEAR_EXCHANGE: 'Нет обменов'
  },
  en: {
    EXCHANGE_HEADING: 'Exchange history',
    EXCHANGE_TABLE: {
      APPLICATION_NUM: 'Application number',
      DATE: 'Date / Time',
      COURSE: 'Course',
      GIVE_GET: 'Give / Receive',
      STATUS: 'Status'
    },
    CLEAR_EXCHANGE: 'No exchanges'
  },
  tu: {
    EXCHANGE_HEADING: 'Değişim geçmişi',
    EXCHANGE_TABLE: {
      APPLICATION_NUM: 'Başvuru numarası',
      DATE: 'Tarih / Saat',
      COURSE: 'Peki',
      GIVE_GET: 'Ver / Al',
      STATUS: 'Durum'
    },
    CLEAR_EXCHANGE: 'Takas yok'
  }
}

const referral = {
  ru: {
    REFERRAL_HEADING: 'Реферальная программа',
    REFERRAL_LINK: 'Реферальная ссылка',
    REFERRAL_TEXT: 'Вы можете поделиться вашей реферальной ссылкой, чтобы получить кешбек',
    HEADING_PAYOUT_HISTORY: 'История выплат',
    PAYOUT_TABLE: {
      APPLICATION_NUM: 'Номер заявки',
      DATE: 'Дата',
      WALLET: 'Кошелек',
      AMOUNT: 'Сумма',
      AMOUNT_USD: 'Сумма USDT',
      STATUS: 'Статус'
    },
    PAID: 'Оплаченно',
    UNPAID: 'Не оплачено',
    NO_PAYMANTS: 'Выплат нету',

    HEADING_PAYOUT: 'Вывод средств',
    WITHDRAW_FUNDS: 'Вывети средства',
    INPUTS: {
      AMOUNT: {
        label: 'Сумма выплаты',
        placeholder: 'Сумма выплаты USD'
      },
      WALLET: {
        label: 'Ваш счет',
        placeholder: 'Номер карты или адрес кошелька (TRC20)'
      }
    },
    REFFERAL_BALANCE: {
      HEADING: 'Балансы',
      REFERRALS: 'Всего рефералов',
      BALANCE: 'остаток на счете',
      EXCHANGE: 'обмены',
      PRECENT: 'ваш процент',
      CASHBACK: 'кэшбек'
    }
  },
  en: {
    REFERRAL_HEADING: 'Referral program',
    REFERRAL_LINK: 'Referral link',
    REFERRAL_TEXT: 'You can share your referral link to get cashback',
    HEADING_PAYOUT_HISTORY: 'Payment history',
    PAYOUT_TABLE: {
      APPLICATION_NUM: 'Application number',
      DATE: 'Date',
      WALLET: 'Wallet',
      AMOUNT: 'Amount',
      AMOUNT_USD: 'Amount USDT',
      STATUS: 'Status'
    },
    PAID: 'Paid',
    UNPAID: 'Not paid',
    NO_PAYMANTS: 'No payments',

    HEADING_PAYOUT: 'Withdrawal',
    WITHDRAW_FUNDS: 'Withdraw funds',
    INPUTS: {
      AMOUNT: {
        label: 'Payment amount',
        placeholder: 'Payment amount USD'
      },
      WALLET: {
        label: 'Your account',
        placeholder: 'Card number or crypto wallet (TRC20)'
      }
    },
    REFFERAL_BALANCE: {
      HEADING: 'Balances',
      REFERRALS: 'Total referrals',
      BALANCE: 'Account balance',
      EXCHANGE: 'Exchanges',
      PRECENT: 'Your interest',
      CASHBACK: 'Cashback'
    }
  },
  tu: {
    REFERRAL_HEADING: 'Tavsiye programı',
    REFERRAL_LINK: 'Yönlendirme bağlantısı',
    REFERRAL_TEXT: 'Para iadesi almak için tavsiye bağlantınızı paylaşabilirsiniz.',
    HEADING_PAYOUT_HISTORY: 'Ödeme geçmişi',
    PAYOUT_TABLE: {
      APPLICATION_NUM: 'Başvuru numarası',
      DATE: 'Tarih',
      WALLET: 'Cüzdan',
      AMOUNT: 'Toplam',
      AMOUNT_USD: 'USDT tutarı',
      STATUS: 'Durum'
    },
    PAID: 'Paralı',
    UNPAID: 'Ödenmeyen',
    NO_PAYMANTS: 'Ödeme yok',

    HEADING_PAYOUT: 'Para çekme',
    WITHDRAW_FUNDS: 'Para Çekme',
    INPUTS: {
      AMOUNT: {
        label: 'ödeme tutarı',
        placeholder: 'Ödeme tutarı USD'
      },
      WALLET: {
        label: 'Hesabınız',
        placeholder: 'Kart numarası veya kripto cüzdanı (TRC20)'
      }
    },
    REFFERAL_BALANCE: {
      HEADING: 'Bakiyeler',
      REFERRALS: 'Toplam tavsiyeler',
      BALANCE: 'Hesap bakiyesi',
      EXCHANGE: 'değiş tokuş',
      PRECENT: 'senin yüzden',
      CASHBACK: 'Nakit para'
    }
  }
}
const profile = {
  ru: {
    CARD_VERIFICATION_HEADING: 'Верифицированные карты',
    CARD_VERIFICATION_IN_PROCESS: 'В процессе разработки',
    PROFILE_INFO_HEADING: 'Личная информация',
    PROFILE_INPUTS: {
      EMAIL: 'Почта:',
      OLD_PASSWORD: 'Старый пароль:',
      NEW_PASSWORD: 'Новый пароль:',
      COMPARE_NEW_PASSWORD: 'Повторите новый пароль:'
    },
    CHANGE_PASSWORD_BTN: 'Сменить пароль'
  },
  en: {
    CARD_VERIFICATION_HEADING: 'Verified cards',
    CARD_VERIFICATION_IN_PROCESS: 'On development stage',
    PROFILE_INFO_HEADING: 'Basic personal information',
    PROFILE_INPUTS: {
      EMAIL: 'E-mail:',
      OLD_PASSWORD: 'Old password:',
      NEW_PASSWORD: 'New password:',
      COMPARE_NEW_PASSWORD: 'Repeat new password:'
    },
    CHANGE_PASSWORD_BTN: 'Change password'
  },
  tu: {
    CARD_VERIFICATION_HEADING: 'Doğrulanmış kartlar',
    CARD_VERIFICATION_IN_PROCESS: 'Geliştirme aşamasında',
    PROFILE_INFO_HEADING: 'Kişisel bilgi',
    PROFILE_INPUTS: {
      EMAIL: 'Posta:',
      OLD_PASSWORD: 'Eski Şifre:',
      NEW_PASSWORD: 'Yeni Şifre:',
      COMPARE_NEW_PASSWORD: 'Yeni şifreyi tekrar girin:'
    },
    CHANGE_PASSWORD_BTN: 'Şifre değiştir'
  }
}

export const ProfileTexts: any = {
  ru: {
    ...general.ru,
    ...exchange.ru,
    ...referral.ru,
    ...profile.ru
  },
  en: {
    ...general.en,
    ...exchange.en,
    ...referral.en,
    ...profile.en
  },
  tu: {
    ...general.tu,
    ...exchange.tu,
    ...referral.tu,
    ...profile.tu
  }
}
