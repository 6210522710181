import React, { CSSProperties, FC, ReactNode } from 'react'
import {PulseLoader, RingLoader} from "react-spinners";

interface ButtonProps {
  className?: string
  variant?: 'primary' | 'secondary' | 'outlined' | 'warning' | string
  children?: ReactNode
  style?: CSSProperties
  disabled?: boolean | undefined
  name?: string | undefined
  type?: 'submit' | 'reset' | 'button' | undefined
  onClick?: any
  dataToggle?: string | undefined
  dataTarget?: string | undefined
  ref?: any
  isLoading? : boolean
}

const Button: FC<ButtonProps> = ({ className, children, variant, type, name, style, disabled, onClick, dataToggle, dataTarget, ref, isLoading = false }) => {
  return (
    <button
      className={`btn 
            ${variant === 'primary' ? 'btn-primary' : ''} 
            ${variant === 'secondary' ? 'btn-secondary' : ''} 
            ${variant === 'outlined' ? 'btn-outline-primary' : ''} 
            ${variant === 'warning' ? 'btn-warning' : ''} 
            ${variant === 'link' ? 'btn-primary-link' : ''}
            ${className}`}
      type={type}
      style={style}
      name={name}
      disabled={disabled}
      onClick={onClick}
      data-bs-toggle={dataToggle}
      data-bs-target={dataTarget}
      ref={ref}
    >
      {isLoading ? <><PulseLoader size={10} color={'#FFF'}/></> : children}
    </button>
  )
}

export default Button
