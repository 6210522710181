import { path } from '../utils/consts'
import AutoBidPage from './auto-bid-page'
import Contests from './contests-page'
import ExchangeHistoryPage from './profile/exchange-history-page'
import Faq from './faq-page'
import HistoryPayoutPage from './profile/history-payout-page'
import LoginPage from './auth/login-page'
import MainPage from './main-page'
import ReferralPage from './profile/referral-page'
import Terms from './terms-page'
import RegisterPage from './auth/register-page'
import ComparePassworPage from './auth/compare-password-page'
import PasswordPage from './auth/password-page'
import Profile from './profile/profile-page'
import PayoutPage from './profile/payout-page'
import CashBidPage from './cash-bid-page'
import CardsPage from './profile/cards-page'
import Api from './api/api-page-docs'
import ApiDocs from './api/api-page-docs'
import ApiLanding from './api/api-landing'
import AboutUs from './about-us'
import Login from './api/lk/login'
import CreateAccount from './api/lk/create-account'
import ApiStatistic from './api/lk/api-statistic'
import PrivacyPolicy from './privacy-policy'
import Aml from './aml'
import CardVerification from '../features/card-verification'
import ApiKeys from './api/lk/api-keys'
import ApiTransactions from './api/lk/api-transactions'
import ApiAddreses from './api/lk/api-addreses'
import ApiExchange from './api/lk/api-exchange'

export const pages = [
  { element: <MainPage />, path: path.MAIN },
  { element: <MainPage />, path: path.MAIN + '/en' },
  { element: <MainPage />, path: path.EXCHANGE_SEO },
  { element: <MainPage />, path: path.EXCHANGE_CITY_SEO },
  { element: <Contests />, path: path.CONTESTS },
  { element: <Faq />, path: path.FAQ },
  { element: <Terms />, path: path.TERMS },
  { element: <AutoBidPage />, path: path.BID },
  { element: <CashBidPage />, path: path.BID_CASH },

  { element: <LoginPage />, path: path.LOGIN },
  { element: <RegisterPage />, path: path.REGISTER },
  { element: <ComparePassworPage />, path: path.COMPARE_PASSWORD },
  { element: <PasswordPage />, path: path.PASSWORD },
  { element: <ApiDocs />, path: path.API_DOCS },
  { element: <ApiLanding />, path: path.API },
  { element: <AboutUs />, path: path.ABOUT_US },
  { element: <Login />, path: path.API_LOGIN },
  { element: <CreateAccount />, path: path.API_CREATE_ACCOUNT },
  { element: <PrivacyPolicy />, path: path.PRIVACY_POLICY },
  { element: <Aml />, path: path.AML }
]

export const authPages = (isAuth: boolean, isAuthApi: boolean) => {
  let Pages = [...pages]
  const authPagesArr = [
    { element: <ExchangeHistoryPage />, path: path.EXCHANGE_HISTORY_PAGE },
    { element: <ReferralPage />, path: path.PAYOUTS_PAGE },
    { element: <HistoryPayoutPage />, path: path.HISTORY_PAYOUT_PAGE },
    { element: <PayoutPage />, path: path.PAYOUT_PAGE },
    { element: <Profile />, path: path.PROFILE_SETTINGS },
    { element: <CardsPage />, path: path.CARDS },
    { element: <CardVerification />, path: path.NEW_CARD }
  ]
  const authApiPagesArr = [
    { element: <ApiStatistic />, path: path.API_STATISTICS },
    { element: <ApiDocs />, path: path.API_LK_DOCS },
    { element: <ApiKeys />, path: path.API_LK_KEYS },
    { element: <ApiTransactions />, path: path.API_LK_TRANSACTIONS },
    { element: <ApiAddreses />, path: path.API_LK_ADDRESES },
    { element: <ApiExchange />, path: path.API_LK_EXCHANGE }
  ]

  if (isAuth) Pages = [...Pages, ...authPagesArr]
  if (isAuthApi) Pages = [...Pages, ...authApiPagesArr]
  return Pages
}
