import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'cookies-ts'

import { cookiesName } from '../../utils/consts'
import { fetchSignUpApiLkHandler, fetchLoginApiLkHandler, fetchLogoutApiLkHandler, fetchRefreshLkTokenHandler } from '../actions/apiLkAuthActions'
import {FINGER_PRINT} from "../../utils/api";

const cookies = new Cookies()

export enum Status {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface ApiLkAuthState {
  isAuthApi: boolean
  isCreated: boolean

  authMessage: string

  xToken: string
  rToken: string
  jwtStatus: Status

  email: string
  fingerprint: string
  otp_url: string

  createStatus: Status
}

const initialState: ApiLkAuthState = {
  isAuthApi: Boolean(cookies.get(cookiesName.ACCESS_TOKEN_LK_API)) && Boolean(cookies.get(cookiesName.REFRESH_TOKEN_LK_API)) ? true : false,
  isCreated: false,

  authMessage: '',

  jwtStatus: Status.IDLE,
  xToken: '',
  rToken: '',
  email: '',

  fingerprint: Boolean(cookies.get(cookiesName.FINGERPRINT)) ? String(cookies.get(cookiesName.FINGERPRINT)) : '',
  otp_url: '',

  createStatus: Status.IDLE
}

export const apiLkAuthSlice = createSlice({
  name: 'apiLkAuth',
  initialState,
  reducers: {
    setMessage(state: ApiLkAuthState, action: PayloadAction<string>) {
      state.authMessage = action.payload
    },
    setFingerprint(state: ApiLkAuthState, action: PayloadAction<string>) {
      localStorage.setItem(FINGER_PRINT, action.payload)
      state.fingerprint = action.payload
    },
    setIsCreated(state: ApiLkAuthState, action: PayloadAction<boolean>) {
      state.isCreated = action.payload
    }
  },
  extraReducers: builder => {
    fetchLoginApiLkHandler(builder)
    fetchSignUpApiLkHandler(builder)
    fetchLogoutApiLkHandler(builder)
    fetchRefreshLkTokenHandler(builder)
  }
})

export const { setMessage, setFingerprint, setIsCreated } = apiLkAuthSlice.actions

export default apiLkAuthSlice.reducer
