import React, {FC, SetStateAction} from 'react';
import styles from "./FastRegistrationModal.module.scss";
import Button from "../../buttons/button";
import {useAppSelector} from "../../../hooks/reduxHooks";
import {registerApi} from "../../../utils/api";
import {apiEndPoints} from "../../../utils/consts";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {MoonLoader} from "react-spinners";

interface IStepOneProps {
    emailInp: any,
    isLoading: boolean,
    setStep: React.Dispatch<SetStateAction<number>>
    setIsUserExist: React.Dispatch<SetStateAction<boolean>>
    setIsLoading: React.Dispatch<SetStateAction<boolean>>
}

const StepOne: FC<IStepOneProps> = ({
                                        emailInp,
                                        setStep,
                                        setIsUserExist,
                                        setIsLoading,
                                        isLoading
                                    }) => {

    const {isAuth} = useAppSelector(state => state.user)

    const onClickHandler = () => {
        if (isAuth) {
            setStep(3)
        } else {
            setIsLoading(true)
            registerApi.post(apiEndPoints.VERIFICATE_EMAIL, {
                email: emailInp.value.toLowerCase(),
                type: "password"
            }).then(
                (res) => {
                    setIsLoading(false)
                    setStep(2)
                }
            ).catch(
                (error: AxiosError) => {
                    if (error?.response?.status === 409) {
                        setIsUserExist(true)
                        setStep(2)
                    }
                }
            ).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            {isLoading
                ? <div className={styles.loader}>
                    <MoonLoader color={'#002674'}/>
                    <div>Создаем Ваш аккаунт, пожалуйста, ожидайте</div>
                </div>
                : <>
                    <div className={styles.headText}>
                        {isAuth
                            ? "Для оформления заявки, Вам необходимо верифицровать банковскую карту."
                            : "Для оформления заявки, Вам необходимо подтвердить почту и верифицировать банковскую карточку"
                        }
                    </div>
                    <div className={styles.headTextSmall}>Это займёт не более пяти минут!</div>
                    <div className={styles.buttonsBlock}>
                        <Button variant='primary'
                                className='py-3 mt-3 position-relative'
                                onClick={onClickHandler}>
                            Далее
                        </Button>
                    </div>
                </>
            }
        </>
    )
        ;
};

export default StepOne;