import { Link } from 'react-router-dom'
import { path } from '../../utils/consts'

export const ApiGeneral: any = {
  ru: {
    CREATE_HEADING: 'Создать аккаунт',
    CREATED: 'Создать',
    IS_CREATED: (
      <>
        Уже есть аккаунт? <Link to={path.API_LOGIN}>Войти в аккаунт</Link>
      </>
    ),

    LOGIN_HEADING: 'Войти в аккаунт',
    NOT_ACCOUNT: (
      <>
        Нет аккаунта? <Link to={path.API_CREATE_ACCOUNT}>Создать</Link>
      </>
    ),

    API_KEYS: 'Api-ключи',
    UPDATE_KEYS: 'Обновить API-ключ',
    ACTION: 'Действия'
  },
  en: {
    CREATE_HEADING: 'Create an account',
    CREATED: 'Create',
    IS_CREATED: (
      <>
        Already have an account? <Link to={path.API_LOGIN}>Sign in</Link>
      </>
    ),

    LOGIN_HEADING: 'Sign in',
    NOT_ACCOUNT: (
      <>
        No account? <Link to={path.API_CREATE_ACCOUNT}>Create</Link>
      </>
    ),

    API_KEYS: 'API keys',
    UPDATE_KEYS: 'Update API Key',
    ACTION: 'Action'
  },
  tu: {
    CREATE_HEADING: 'Hesap oluştur',
    CREATED: 'Oluşturuldu',
    IS_CREATED: (
      <>
        Zaten hesabınız var mı? <Link to={path.API_LOGIN}>Kayıt olmak</Link>
      </>
    ),

    LOGIN_HEADING: 'Kayıt olmak',
    NOT_ACCOUNT: (
      <>
        Hesap yok? <Link to={path.API_CREATE_ACCOUNT}>Oluşturmak</Link>
      </>
    ),

    API_KEYS: 'API keys',
    UPDATE_KEYS: 'API Anahtarını Güncelle',
    ACTION: 'eylem'
  }
}
