import React, {FormEvent, useEffect, useState} from 'react'
import Button from '../../../components/buttons/button'
import { Logo } from '../../../components/icons'
import CustomInput from '../../../components/inputs/custom-input'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import useGetText from '../../../hooks/useGetText'
import useInput, { namesType } from '../../../hooks/useInput'
import { SignInData } from '../../../service/api-lk-service'
import { fetchLoginApiLk } from '../../../store/actions/apiLkAuthActions'
import { setFingerprint, setMessage } from '../../../store/reducers/apiLkAuthReducer'
import { Link, useNavigate } from 'react-router-dom'
import { cookiesName, path } from '../../../utils/consts'
import Cookies from 'cookies-ts'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const cookies = new Cookies()

const Login = () => {
  const { authMessage, isAuthApi } = useAppSelector(state => state.apiLkAuth)
  const { authTexts, apiGeneral } = useGetText()
  const navigate = useNavigate()
  const emailIn = useInput('')
  const passwordIn = useInput('')
  const otp_code = useInput('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setMessage(''))
  }, [emailIn.value, passwordIn.value])

  useEffect(() => {
    if (isAuthApi) {
      ;(async () => {
        // fingerprint
        const agent = await FingerprintJS.load()
        const fingerprint = await agent.get()
        dispatch(setFingerprint(fingerprint.visitorId))
        cookies.set(cookiesName.FINGERPRINT, fingerprint.visitorId)
      })()
      setTimeout(() => {
        navigate(path.API_STATISTICS)
      }, 200)
    }
  }, [isAuthApi])

  function signInHandler(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!emailIn.isValid || !passwordIn.isValid) return

    const data: SignInData = {
      email: emailIn.value?.trim(),
      password: passwordIn.value?.trim(),
      otp_code: otp_code.value?.trim()
    }
    setIsLoading(true)
    dispatch(fetchLoginApiLk(data)).finally(() => {setIsLoading(false)})
  }

  return (
    <div className='login-lk-api'>
      <div className='container'>
        <div className='row justify-content-center align-items-center full-screen py-4'>
          <div className='col-xl-5 col-lg-6  col-md-8 col-sm-10'>
            <form onSubmit={signInHandler} className='card'>
              <div className='d-flex justify-content-between flex-lg-row flex-column-reverse align-items-center'>
                <h2 className='mb-0'>{apiGeneral.LOGIN_HEADING}</h2>
                <Link to={path.MAIN}>
                  <Logo width='100' />
                </Link>
              </div>
              <div className='my-3'>
                <CustomInput variant='input-main' type='email' name={'email'} required inputHandlers={emailIn} placeholder='test@test.com' label={'Email'} autoComplete={'on'} />
                <CustomInput variant='input-main' type='password' required inputHandlers={passwordIn} placeholder='*********' label={authTexts.PASSWORD} />
                <CustomInput variant='input-main' type='text' required inputHandlers={otp_code} placeholder='123456' label={'Google 2FA'} />
              </div>
              <Button variant='primary' className='py-3' isLoading={isLoading} disabled={isLoading}>
                {authTexts.LOGIN}
              </Button>
              <small className='text-center mt-4'>{apiGeneral.NOT_ACCOUNT}</small>
              {authMessage.length > 0 && <div className='alert alert-danger mt-3'>{authMessage}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
