import React, {FC} from 'react';
import {IBidInputsProps} from "./CashToCryptoInputs";
import CustomInput from "../../../components/inputs/custom-input";
import {namesType} from "../../../hooks/useInput";
import RequisitesBlock from "../RequisitesBlock";

interface ICryptoToPaymentSystemInputsProps extends IBidInputsProps {
  qiwiInput: any
  emailInp: any
}

const CryptoToPaymentSystemInputs: FC<ICryptoToPaymentSystemInputsProps> = ({
                                                                              mainPageTexts,
                                                                              qiwiInput,
                                                                              emailInp
                                                                            }) => {

  return (
    <>
      <div className='col-md-6'>
        <RequisitesBlock mainPageTexts={mainPageTexts}>
          <div className={`mt-2`}>
            <CustomInput
              required={true}
              maxLength={18}
              variant='main'
              label={mainPageTexts.INPUTS.QIWI_WALLET}
              placeholder={mainPageTexts.INPUTS.QIWI_WALLET}
              name={namesType.PHONE}
              inputHandlers={qiwiInput}
            />
          </div>
        </RequisitesBlock>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='form-heading'></div>
          <div className='mt-2 email-input'>
            <CustomInput
              required={true}
              variant='main'
              placeholder='example@gmail.com'
              label='Email*'
              name='email'
              inputHandlers={emailInp}
              autoComplete={'on'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoToPaymentSystemInputs;