export const generalTexts: any = {
  ru: {
    register: 'Регистрация',
    isAccount: 'Уже есть аккаунт',
    exchange: 'Обменять',
    course: 'Курс',
    set_reviews: 'Оставить отзыв',
    partners: 'Партнерская программа'
  },
  en: {
    register: 'Registration',
    isAccount: 'Already have an account',
    exchange: 'Exchange',
    course: 'Course',
    set_reviews: 'Leave feedback',
    partners: 'Affiliate program'
  },
  tu: {
    register: 'Регистрация',
    isAccount: 'Zaten hesabınız var mı',
    exchange: 'değiş tokuş',
    course: 'Peki',
    set_reviews: 'Geribildirim bırak',
    partners: 'Ortaklık Programı'
  }
}
