import React, { useEffect, useMemo, useState } from 'react'

function getWindowSize() {
  const { innerWidth, innerHeight }: any = window
  return { innerWidth, innerHeight }
}
interface WindowSizeInterface {
  innerWidth?: number
  innerHeight?: number
}

function useDocumentWidth() {
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const xl = useMemo(() => windowSize?.innerWidth > 1200, [windowSize])
  const xl2 = useMemo(() => windowSize?.innerWidth > 1140, [windowSize])
  const lg = useMemo(() => windowSize?.innerWidth > 992, [windowSize])
  const md = useMemo(() => windowSize?.innerWidth > 756, [windowSize])
  const sm = useMemo(() => windowSize?.innerWidth > 576, [windowSize])
  const xs = useMemo(() => windowSize?.innerWidth > 320, [windowSize])
  if (windowSize?.innerHeight !== undefined && windowSize?.innerWidth !== undefined)
    return {
      innerHeight: windowSize?.innerHeight,
      innerWidth: windowSize?.innerWidth,
      lg,
      xl,
      md,
      sm,
      xs
    }
}

export default useDocumentWidth
