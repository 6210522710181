import React, {FormEvent, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import AuthLayout from '../../features/layouts/auth-layout'
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput, {namesType} from '../../hooks/useInput'
import {fetchRegister} from '../../store/actions/userActions'
import {apiEndPoints, path} from '../../utils/consts'
import {registerApi} from "../../utils/api";
import axios, {AxiosError} from "axios";
import {toast} from "react-toastify";
import {PayloadAction} from "@reduxjs/toolkit";
import {IAuthResponse} from "../../utils/interfaces";
import RegisterTimer from "../../components/registerTimer/RegisterTimer";
import styles from "../../components/registerTimer/registerTimer.module.scss"


const RegisterPage = () => {
  const { register_message } = useAppSelector(state => state.user)
  const [isEmailVerificated, setIsEmailVerificated] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const emailInp = useInput()
  const passwordInp = useInput()
  const comparePasswordInp = useInput('', passwordInp.value)
  const verificationCode = useInput()
  const { authTexts } = useGetText()
  const [isFetching, setIsFetching] = useState(false)
  const [isResendAvailable, setIsResendAvailable] = useState(false)


  const registerHandler = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!emailInp.isValid || !passwordInp.isValid || !comparePasswordInp.isValid) return
    setIsFetching(true)
    if (isEmailVerificated) {
      try {
        const response = await dispatch(fetchRegister({
            email: emailInp.value.toLowerCase(),
          password: passwordInp.value,
          vrf_code: verificationCode.value
        })) as PayloadAction<IAuthResponse>
        if (response.payload.success) {
          toast.success('Вы успешно зарегистрировались!')
          setIsEmailVerificated(false)
          navigate(path.LOGIN)
        } else {
          toast.error('Вы ввели неправильный код!')
        }
      } finally {
        setIsFetching(false)
      }
    } else {
      registerApi.post(apiEndPoints.VERIFICATE_EMAIL, {
        email: emailInp.value.toLowerCase(),
        type : 'code'
      }).then(
        (res) => {
          setIsEmailVerificated(true)
        }
      ).catch(
        (error: AxiosError) => {
          if (error?.response?.status === 409)
            toast.error('Пользователь уже зарегистрирован!')
        }
      ).finally(
        () => setIsFetching(false))
    }
  }


  const resendVerificationCode = () => {
    setIsFetching(true)
    axios.post('https://api.cashex.cc/api/auth/verificate_email', {email: emailInp.value.toLowerCase(), type : 'code'})
      .then(() => {
        toast.success('Новый код подтверждения был выслан Вам на почту')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          toast.error('Данный адрес электронный почты уже занят!')
          setIsEmailVerificated(false)
        }
      })
      .finally(() => {
        setIsFetching(false)
        setIsResendAvailable(false)
      })

  };


  return (
    <AuthLayout title={authTexts.REGISTRATION}>
      <form onSubmit={registerHandler}>
        <CustomInput required inputHandlers={emailInp} variant='main' name={namesType.EMAIL}
                     placeholder='example@gmail.com' label='Email' disabled={isEmailVerificated}/>
        <CustomInput required inputHandlers={passwordInp} variant='main' name={namesType.PASSWORD} type='password'
                     placeholder='**********' label={authTexts.PASSWORD} disabled={isEmailVerificated}/>
        <CustomInput required inputHandlers={comparePasswordInp} variant='main' name={namesType.COMPARE_PASSWORD}
                     type='password' placeholder='**********' label={authTexts.CONFIRM_PASSWORD}
                     disabled={isEmailVerificated}/>
        {isEmailVerificated &&
          <>
            <div className='mt-4'>Вам на почту был выслан код подтверждения.</div>
            <div>Пожалуйста, введите его в поле ниже.</div>
            <CustomInput required inputHandlers={verificationCode} variant='main' name={namesType.VERIFICATION_CODE}
                         type='text' label={authTexts.VERIFICATION_CODE}/>

            {isResendAvailable
              ? <button type={'button'} disabled={isFetching} className={styles.resendCodeButton}
                        onClick={resendVerificationCode}>
                Нажмите, чтобы повторно запросить код.
              </button>
              : <div className={styles.verificationTimerText}>
                Вы можете повторно запросить код через <RegisterTimer setIsResendAvailable={setIsResendAvailable}/>
              </div>
            }
          </>
        }
        <Button isLoading={isFetching} disabled={isFetching} variant='primary'
                className={`w-100 mb-3 mt-5 py-3 ${isEmailVerificated ? 'mt-[20px]' : ''}`}>
          {authTexts.JOIN}
        </Button>
        <p className='t3 text-center'>{authTexts.ALREDY_REGISTER}</p>
        {register_message && <p className='t3 text-center'>{register_message}</p>}
      </form>
    </AuthLayout>
  )
}

export default RegisterPage
