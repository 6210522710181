import {useSelector} from "react-redux";
import React, {FC, SetStateAction, useEffect, useState} from "react";
import styles from "./registerTimer.module.scss"
import useRegisterTimer from "./useRegisterTimer";


interface IRegisterTimerProps{
  setIsResendAvailable : React.Dispatch<SetStateAction<boolean>>
}

const RegisterTimer:FC<IRegisterTimerProps> = ({setIsResendAvailable}) => {
  const [seconds, setSeconds] = useState(60)
  const {timeLeft} = useRegisterTimer(seconds);
  const minutes = Math.floor(timeLeft / 60);
  const remainingSeconds = timeLeft % 60;

  useEffect(() => {
    if (timeLeft === 0) {
      setIsResendAvailable(true)
    }
  }, [timeLeft]);

  return (
    <span className={`${styles.registerTimerSpan} ${styles.tradeTimer}`}>
  {minutes < 10
    ? `0${minutes} : `
    : `${minutes} : `
  }
      {remainingSeconds < 10
        ? `0${remainingSeconds}`
        : `${remainingSeconds}`}
  </span>
  );
};

export default RegisterTimer;