import { useEffect, useRef, useState } from 'react'

function useTimerDown(seconds: number) {
  const [timeLeft, setTimeLeft] = useState(seconds)
  const intervalRef = useRef<any>()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft(t => t - 1)
    }, 1000)
    return () => clearInterval(intervalRef.current)
  }, [])

  useEffect(() => {
    if (timeLeft <= 0) {
      setTimeLeft(seconds)
    }
  }, [timeLeft])

  function upDateTimer() {
    setTimeLeft(seconds)
  }
  function setDateTimer(sec: number) {
    setTimeLeft(sec)
  }

  return { timeLeft, upDateTimer, setDateTimer }
}

export default useTimerDown
