import React, {ChangeEvent, FC, SetStateAction, useEffect, useState} from 'react';
import styles from "./FastRegistrationModal.module.scss";
import Button from "../../buttons/button";
import {IFastRegistrationModalProps} from "./FastRegistrationModal";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import CustomInput from "../../inputs/custom-input";
import useInput, {namesType} from "../../../hooks/useInput";
import useGetText from "../../../hooks/useGetText";
import {fetchAddUserCardToVerification, fetchUserCardsInfo} from "../../../store/actions/userActions";
import {toast} from "react-toastify";
import {IUserCardsItem} from "../../../store/reducers/userReducer";
import {statusList} from "../../../pages/profile/cards-page";
import {fetchCreateBid} from "../../../store/actions/tradeActions";
import {MoonLoader} from "react-spinners";

interface IStepThreeProps {
    cardInp: any,
    isLoading: boolean,
    setStep: React.Dispatch<SetStateAction<number>>
    setIsLoading: React.Dispatch<SetStateAction<boolean>>
}

const StepThree: FC<IStepThreeProps> = ({
                                            cardInp,
                                            setStep,
                                            setIsLoading,
                                            isLoading
                                        }) => {

    const {isAuth} = useAppSelector(state => state.user)
    const userName = useInput()
    const {authTexts, mainPageTexts} = useGetText()
    const [cardImage, setCardImage] = useState<null | File>(null)
    const [selectedCard, setSelectedCard] = useState<IUserCardsItem | null>(null)
    const {cardsInfo, isCardsInfoLoading} = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch()
    const [isInstructionShow, setIsInstructionShow] = useState(false)

    useEffect(() => {
        if (!selectedCard) {
            if (cardsInfo && cardsInfo.cards !== null && cardsInfo.cards[0].status === 'PENDING') {
                setSelectedCard(cardsInfo.cards[0])
            }
        } else if (cardsInfo && cardsInfo.cards !== null) {
            if (cardsInfo.cards[0].status === 'SUCCESS') {
                setStep(4)
            } else {
                setSelectedCard(cardsInfo.cards[0])
            }
        }
        setIsLoading(false)
    }, [cardsInfo]);

    useEffect(() => {
        if (selectedCard !== null && selectedCard.status === 'PENDING') {
            const timeoutID = setInterval(() => {
                if (selectedCard !== null) {
                    dispatch(fetchUserCardsInfo())

                }
            }, 15000);

            return () => {
                clearTimeout(timeoutID);
            };
        }
    }, [selectedCard]);


    const setPhotoHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length) {
            setCardImage(e.target.files[0])
            e.target.value = ''
        }
    }

    const verificateCardHandler = () => {
        if (!cardInp?.value)
            return
        if (!userName?.value)
            return
        if (!cardImage)
            return
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
            const img = new Image();
            img.src = loadEvent.target?.result as string;

            img.onload = function (e) {
                const MAX_WIDTH = 1680;
                const scaleSize = MAX_WIDTH / (e.target as HTMLImageElement).width;

                const canvas = document.createElement("canvas");
                canvas.width = MAX_WIDTH;
                canvas.height = (e.target as HTMLImageElement).height * scaleSize;

                const ctx = canvas.getContext('2d');

                ctx?.drawImage(e.target as HTMLImageElement, 0, 0, canvas.width, canvas.height);

                const srcEncoded = canvas.toDataURL("image/jpeg")
                    .split(',')
                    .slice(1, (loadEvent.target as FileReader).result?.toString().split(',').length)
                    .join(',');

                setIsLoading(true)
                dispatch(fetchAddUserCardToVerification({
                    card_number: cardInp.value,
                    cardholder_name: userName.value,
                    image: srcEncoded
                })).then(
                    () => {
                        // setNewCardHolderValue('');
                        // setNewCardValue('');
                        dispatch(fetchUserCardsInfo()).then(() => {
                            setIsLoading(false)
                            toast.success('Заявка успешно создана!');
                            setCardImage(null);
                        })
                    }
                );
            };
        };
        reader.readAsDataURL(cardImage);
    }

    const verificationStatusHandler = () => {
        setSelectedCard(null)
    }

    const getStatusText = () => {
        switch (selectedCard?.status) {
            case 'CANCELED' :
                return 'Ваша заявка была отклонена. Вернитесь на страницу верификации и внимательно прочитайте инструкцию, возможно, Вы не учли все требования.'
            case 'PENDING' :
                return 'Ваша карта в данный момент находится на проверке.' +
                    'Вы сможете создать заявку, как только ваша карта будет одобрена'
            case "SUCCESS":
                return 'Продолжить'
        }
    }

    const getButtonText = () => {
        switch (selectedCard?.status) {
            case 'CANCELED' :
                return 'Новая заявка'
            case 'PENDING' :
                return 'Ждите'
            case "SUCCESS":
                return 'Продолжить'
        }
    }

    return (
        <>
            {isLoading
                ? <div className={styles.loader}>
                    <MoonLoader color={'#002674'}/>
                    <div>Отправляем карту на проверку..</div>
                </div>
                : selectedCard ?
                    <>
                        <div className={styles.mainTitle}>Верификация карты</div>
                        <div className={styles.headText}>
                            {getStatusText()}
                        </div>
                        <div className={styles.cardVerificationButtonsBlock}>
                            {
                                selectedCard && selectedCard.status === 'CANCELED'
                                && <button className={styles.cardVerificationActionButton}
                                           onClick={verificationStatusHandler}
                                >
                                    Новая заявка
                                </button>
                            }
                            {/*<button
                                className={`${styles['verification-status-button']} ${styles['status_' + selectedCard.status.toLowerCase()]}`}
                                disabled={!!selectedCard}
                            >{statusList[selectedCard.status]}
                            </button>
                            <button className={styles.cardVerificationActionButton}
                                    disabled={(selectedCard && selectedCard.status === "PENDING")}
                                    onClick={verificationStatusHandler}
                            >
                                {getButtonText()}
                            </button>*/}
                        </div>
                    </>
                    : <>
                        <div className='mt-4'>Вам необходимо верифицировать вашу банковскую карту</div>
                        <div className={styles.cardInputsBlock}>
                            <CustomInput required inputHandlers={cardInp} variant='main' name={namesType.CARD}
                                         type='text' label={mainPageTexts.INPUTS.CARD_NUMBER} maxLength={19}/>
                            <CustomInput required inputHandlers={userName} variant='main'
                                         name={namesType.CARD_NAME}
                                         type='text' label={mainPageTexts.INPUTS.NAME_SERNAME}/>
                            <label
                                className={`${styles['load-image-button']} ${cardImage ? styles.imageLoaded : ''}`}
                                htmlFor='downloadPhoto'>
                                <input
                                    type='file'
                                    id='downloadPhoto'
                                    accept="image/png, image/jpeg"
                                    onChange={setPhotoHandler}
                                />
                                {cardImage ? (
                                    'Фото загружено'
                                ) : (
                                    'Загрузить фото карты')}
                            </label>
                        </div>
                        <div className={styles.buttonsBlock}>
                            <Button variant='outlined'
                                    className='mt-3 position-relative'
                                    onClick={() => {
                                        setIsInstructionShow(true)
                                    }}
                            >
                                Инструкция
                            </Button>
                            <Button variant='primary'
                                    className='mt-3 position-relative'
                                    onClick={verificateCardHandler}
                            >
                                Далее
                            </Button>
                        </div>
                    </>
            }
            <div className={styles.overlay} data-show-instruction={isInstructionShow}>
                <div className={styles.overlayContent}>
                    <div className={styles.mainTitle}>Примеры того, как должна выглядеть верификация:</div>
                    <div className={styles.verification_rules_example_block_images}>
                        <img src="/images/static/verifiacation_rules_image1.png" alt=""/>
                        <img src="/images/static/verifiacation_rules_image2.png" alt=""/>
                    </div>
                    <div className={'verification_rules_text_block'}>
                        В целях борьбы с карточным мошенничеством Cashex проводит процедуру верификации банковской
                        карты
                        клиента,
                        чтобы гарантировать возможность ее безопасного использования в транзакциях. Процесс
                        верификации
                        прост: клиенту
                        необходимо загрузить в личный кабинет фотографию своей банковской карты. Поддержка в течение
                        нескольких минут
                        проверит фотографию и разрешит использование карты. Целью этого процесса является проверка
                        наличия и владения
                        карты клиентом, который является ее законным владельцем. В большинстве случаев мошенники не
                        смогут
                        предоставить
                        такие доказательства, поскольку могут лишь подделать данные карты жертвы.
                        <br/>
                        Мы обрабатываем изображения очень быстро, обычно в течение минуты. Пройдя одну верификацию,
                        вы
                        сможете
                        пользоваться картой без ограничений по операциям.
                        <br/><br/>
                        Мы храним только номер карты и имя владельца, другие данные нам не нужны. На картинке вы не
                        показываете все
                        данные, поэтому вам не придется беспокоиться о сохранности своих средств. Имея только номер
                        карты оплата с
                        помощью нее невозможна.
                        <br/><br/>
                        Прежде чем совершить обмен, используя новую карту, необходимо добавить ее в личный кабинет.
                        Для
                        этого
                        выполните
                        следующие действия:
                        <br/><br/>
                        1. Введите номер карты и имя владельца карты.
                        <br/>
                        2. Нажмите кнопку «Загрузить фото карты» и добавьте фотографию карты для проверки.
                        <br/><br/>
                        <span className={'verification_rules_warning_text'}>Внимание! Фото карты должно быть сделано на фоне открытого сайта обменного сервиса, либо в виде фото бумаги, на которой написано название обменного сервиса (Примеры фото для верификации также предоставляем).
          <br/>
          Виртуальные карты не верифицируем.</span>
                        <br/><br/>
                        3. Нажмите кнопку «Подтвердить».
                        <br/>
                        4. Подождите несколько минут, пока карта будет проверена.
                        <br/><br/>
                        После успешной проверки можно приступать к обмену.
                    </div>
                    <button className={styles.backButton}
                            onClick={() => setIsInstructionShow(false)}
                    >Вернуться
                    </button>
                </div>
            </div>
        </>
    );
};

export default StepThree;