import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { BestchangeIcon, ExNode, BestEmoneys, SvgMMgp, Reddit, Otzovik, AmlBot, MyWot, SurLy, Emon, MaulTalk, CryptoTalk, Bitsmedia, FooterLogo, TelegramIcon } from '../components/icons'
import { useAppSelector } from '../hooks/reduxHooks'
import useDocumentWidth from '../hooks/useDocumentWidth'
import useGetText from '../hooks/useGetText'
import { navs, otherLinks, path } from '../utils/consts'

const Footer = () => {
  const { language } = useAppSelector(state => state.main)
  const media: any = useDocumentWidth()
  const { footerText } = useGetText()

  return (
    <footer>
      <div className='container'>
        <div className='footer-nav'>
          {media.md && (
            <Link to={path.MAIN}>
              <FooterLogo />
            </Link>
          )}

          <ul>
            {navs[language]?.map((el: any, idx: number) => (
              <li key={idx}>
                <NavLink to={el.link}>{el.text}</NavLink>
              </li>
            ))}
          </ul>
          <div className={`d-flex flex-sm-row flex-column aligin-items-sm-start align-items-center justify-content-between flex-wrap ${!media.md ? 'w-100 g-g-10' : ''}`}>
            {!media.md && (
              <Link to={path.MAIN}>
                <FooterLogo />
              </Link>
            )}
            <a target={'_blank'} href={language === 'ru' ? otherLinks.BESTCHANGE : otherLinks.BESTCHANGE_EN} className='d-flex flex-sm-row flex-column'>
              <BestchangeIcon />
            </a>
            <a target={'_blank'} href={otherLinks.EX_NODE} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
              <ExNode />
            </a>
            <a target={'_blank'} href={otherLinks.Bitsmedia} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
              <Bitsmedia />
            </a>
            <a target={'_blank'} href={otherLinks.CryptoTalk} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
              <CryptoTalk />
            </a>
            {/* <a target={'_blank'} href={otherLinks.BestEmoneys} className="d-flex flex-sm-row flex-column me-sm-3 me-0">
                        <BestEmoneys /></a> */}
            <a target={'_blank'} href={otherLinks.Reddit} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
              <Reddit />
            </a>
          </div>
        </div>
        <div className={`d-flex flex-sm-row flex-column aligin-items-sm-start align-items-center justify-content-between ${!media.md ? 'w-100 g-g-10' : ''}`} style={{ justifyContent: 'center!important', marginTop: '5px' }}>
          {/* <a target={'_blank'} href={otherLinks.MaulTalk} className="d-flex flex-sm-row flex-column me-sm-3 me-0">
                        <MaulTalk /></a> */}
          <a target={'_blank'} href={otherLinks.Emon} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
            <Emon />
          </a>
          <a target={'_blank'} href={otherLinks.AmlBot} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
            <AmlBot />
          </a>
          <a target={'_blank'} href={otherLinks.SvgMMgp} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
            <SvgMMgp />
          </a>
          <a target={'_blank'} href={otherLinks.MyWot} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
            <MyWot />
          </a>
          {/* <a target={'_blank'} href={otherLinks.Otzovik} className="d-flex flex-sm-row flex-column me-sm-3 me-0">
                    <Otzovik /></a> */}
          <a target={'_blank'} href={otherLinks.SurLy} className='d-flex flex-sm-row flex-column me-sm-3 me-0'>
            <SurLy />
          </a>
        </div>
        <div className='mt-3'>
          {/* <p>
            <small> {footerText.COMPANY_NAME} Smart Exchange LLC</small>
          </p>
          <p>
            <small>{footerText.ORGANIZATION_FORM} Limited Liability Company</small>
          </p>
          <p>
            <small>{footerText.DATE_REG} 21/05/2021</small>
          </p>
          <p>
            <small>{footerText.REG_ORG} LEPL National Agency of Public Registry</small>
          </p>
          <p>
            <small>{footerText.UR_ADDRESS} Georgia, Kutaisi, Avtomshenebeli Street, N88, Hualing Free Industrial Zone</small>
          </p>
          <p>
            <small>{footerText.LICENZIES} Free Industrial Zone Licence No.0110/462</small>
          </p>
          <p>
            <small>{footerText.COUNTRY} Georgia</small>
          </p>
          <p>
            <small>{footerText.VIEW_WORK} Financial Service and Crypto Exchange</small>
          </p> */}
        </div>
      </div>
    </footer>
  )
}
export default Footer
