import React, { FormEvent, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import AuthLayout from '../../features/layouts/auth-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput from '../../hooks/useInput'
import { fetchResetPassword } from '../../store/actions/userActions'
import { path } from '../../utils/consts'

const ComparePassworPage = () => {
  const { reset_password_message } = useAppSelector(state => state.user)
  const { language } = useAppSelector(state => state.main)
  const dispatch = useAppDispatch()
  const emailInp = useInput()

  const { authTexts } = useGetText()

  const comparePasswordHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!emailInp.isValid) return
      dispatch(fetchResetPassword({ email: emailInp.value as string, language: language === 'ru' ? 'RU' : 'ENG' }))
    },
    [emailInp]
  )

  return (
    <AuthLayout title={authTexts.RESTORE}>
      <form onSubmit={comparePasswordHandler}>
        <CustomInput required inputHandlers={emailInp} variant='main' placeholder='example@gmail.com' label='Email' />
        <Button variant='primary' className='w-100 my-3 py-3 mt-4'>
          {authTexts.RECOVERY}
        </Button>
        {reset_password_message && <p className='t3 text-center'>{reset_password_message}</p>}
        <p className='t3 text-center'>
          <Link to={path.LOGIN}>{authTexts.LOGIN}</Link>
        </p>
      </form>
    </AuthLayout>
  )
}

export default ComparePassworPage
