import React from 'react';
import "../../../styles/components/verificationRules.scss"


const VerificationRules = () => {
  return (
    <div>
      <div className={'verification_rules_title'}>Почему мы требуем верификацию банковской карты и как это сделать?
      </div>
      <div className={'verification_rules_content'}>
        <div className={'verification_rules_example_block'}>
          <div className={'verification_rules_example_block_title'}>Примеры того, как должна выглядеть верификация:
          </div>
          <div className={'verification_rules_example_block_images'}>
            <img src="/images/static/verifiacation_rules_image1.png" alt=""/>
            <img src="/images/static/verifiacation_rules_image2.png" alt=""/>
          </div>
        </div>
        <div className={'verification_rules_text_block'}>
          В целях борьбы с карточным мошенничеством Cashex проводит процедуру верификации банковской карты клиента,
          чтобы гарантировать возможность ее безопасного использования в транзакциях. Процесс верификации прост: клиенту
          необходимо загрузить в личный кабинет фотографию своей банковской карты. Поддержка в течение нескольких минут
          проверит фотографию и разрешит использование карты. Целью этого процесса является проверка наличия и владения
          карты клиентом, который является ее законным владельцем. В большинстве случаев мошенники не смогут
          предоставить
          такие доказательства, поскольку могут лишь подделать данные карты жертвы.
          <br/>
          Мы обрабатываем изображения очень быстро, обычно в течение минуты. Пройдя одну верификацию, вы сможете
          пользоваться картой без ограничений по операциям.
          <br/><br/>
          Мы храним только номер карты и имя владельца, другие данные нам не нужны. На картинке вы не показываете все
          данные, поэтому вам не придется беспокоиться о сохранности своих средств. Имея только номер карты оплата с
          помощью нее невозможна.
          <br/><br/>
          Прежде чем совершить обмен, используя новую карту, необходимо добавить ее в личный кабинет. Для этого
          выполните
          следующие действия:
          <br/><br/>
          1. Введите номер карты и имя владельца карты.
          <br/>
          2. Нажмите кнопку «Загрузить фото карты» и добавьте фотографию карты для проверки.
          <br/><br/>
          <span className={'verification_rules_warning_text'}>Внимание! Фото карты должно быть сделано на фоне открытого сайта обменного сервиса, либо в виде фото бумаги, на которой написано название обменного сервиса (Примеры фото для верификации также предоставляем).
          <br/>
          Виртуальные карты не верифицируем.</span>
          <br/><br/>
          3. Нажмите кнопку «Подтвердить».
          <br/>
          4. Подождите несколько минут, пока карта будет проверена.
          <br/><br/>
          После успешной проверки можно приступать к обмену.
        </div>
      </div>
    </div>
  );
};

export default VerificationRules;