import React, { FC, useState } from 'react'
import { PasswordIcon } from '../icons'

interface CustomInputProps {
  placeholder?: string
  name?: string
  type?: string
  inputHandlers: any
  label?: string
  maxLength?: number
  variant?: 'main' | string
  required?: boolean
  readOnly?: boolean
  autoComplete?: 'on' | 'off'
  disabled? : boolean
}

const CustomInput: FC<CustomInputProps> = ({ placeholder, name, type, inputHandlers, label, maxLength, variant, required, readOnly, autoComplete, disabled = false }) => {
  const [isVisible, setVisible] = useState(false)

  return (
    <div className='inp-wrapper'>
      {label && <label htmlFor=''>{label}</label>}

      <input
        type={isVisible ? 'text' : type || 'text'}
        required={required}
        readOnly={readOnly}
        name={name}
        placeholder={placeholder}
        value={inputHandlers?.value}
        onChange={inputHandlers?.onChange}
        onBlur={inputHandlers?.onBlur}
        className={`form-control ${variant ? 'input-main' : ''} ${inputHandlers?.value !== '' ? inputHandlers?.classes : ''}`}
        onKeyDown={inputHandlers?.onKeyDown}
        maxLength={maxLength}
        autoComplete={autoComplete ? autoComplete : 'off'}
        disabled={disabled}
      />
      {!inputHandlers?.isValid && inputHandlers?.value !== '' && <small className='error'>{inputHandlers?.msg}</small>}
      {type === 'password' && (
        <button type='button' className='btn p-0 btn-password' onClick={() => setVisible(!isVisible)}>
          <PasswordIcon isBool={isVisible} />
        </button>
      )}
    </div>
  )
}

export default CustomInput
