import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { fetchStaticText } from '../store/actions/mainActions'
import utils from '../utils/utils'
import Preloader from '../components/Preloader'

// const terms = [
//     {
//         "id": "rule-1",
//         "anchor": "#rule-1",
//         "header": "Стороны соглашения",
//         "textList": [
//             "1. Договор заключается между интернет сервисом Cashex.cc по обмену титульных знаков, далее Исполнитель, — с одной стороны, и Заказчик, в лице того, кто воспользовался услугами Исполнителя, — с другой стороны."
//         ]
//     },
//     {
//         "id": "rule-2",
//         "anchor": "#rule-2",
//         "header": "Список терминов",
//         "textList": [
//             "2.1. Обмен титульных знаков — автоматизированный продукт интернет обслуживания, который предоставляется Исполнителем на основании данных правил.",
//             "2.2. Заказчик — физическое лицо, соглашающееся с условиями Исполнителя и данного соглашения, к которому присоединяется.",
//             "2.3. Титульный знак — условная единица той или иной платежной системы, которая соответствует расчетам электронных систем и обозначает объем прав, соответствующих договору системы электронной оплаты и ее Заказчика.",
//             "2.4. Заявка — сведения, переданные Заказчиком для использования средств Исполнителя в электронном виде и свидетельствующие о том, что он принимает условия пользования сервисом, которые предлагаются Исполнителем в данной заявке",
//             "2.5. Сервис — автоматическая система, предоставляющая услуги по обмену, покупке/продаже электронных валют.",
//             "2.6. Операция — перевод электронной валюты заказчику.",
//             "2.7. Платежная система — программно-аппаратный продукт, разработанный третьей стороной и представляющий собой механизм реализации учета денежных обязательств, а также оплату товаров и услуг в интернете,организации взаиморасчетов между своими пользователями.",
//         ]
//     },
//     {
//         "id": "rule-3",
//         "anchor": "#rule-3",
//         "header": "Условия соглашения",
//         "textList": [
//             `3. Данные правила считаются организованными за счет условий общественной оферты, которая образуется во время
//                     подачи Заказчиком заявки и является одной из главных составляющих настоящего договора. Общественной офертой
//                     именуются отображаемые исполнителем сведения об условиях подачи заявки.`,
//             `3.1. Главным составляющим общественной оферты являются действия, сделанные в завершении подачи заявки Заказчиком и
//                     говорящие о его точных намерениях совершить сделку на условиях предложенных Исполнителем перед завершением
//                     данной заявки. Время, дата, и параметры заявки создаются Исполнителем автоматически в момент окончания
//                     формирования данной заявки. Предложение должно приняться Заказчиком в течение 24 часов от окончания формирования
//                     заявки. Договор по обслуживанию вступает в силу с момента поступления титульных знаков в полном размере,
//                     указанном в заявке, от Заказчика на реквизиты Исполнителя. Операции с титульными знаками учитываются согласно
//                     правилам, регламенту и формату электронных систем по расчетам. Договор действителен в течение срока , который
//                     устанавливается с момента подачи заявки до расторжения по инициативе одной из сторон.`,
//         ]
//     },
//     {
//         "id": "rule-4",
//         "anchor": "#rule-4",
//         "header": "Предмет соглашения",
//         "textList": [
//             `4. Путем использования технических методов Исполнитель обязуется выполнять обмен титульных знаков за комиссионное
//             вознаграждение от Заказчика, после подачи данным лицом заявки и совершает это путем продажи титульных знаков
//             лицам, желающим их приобрести по сумме, указанной не ниже, чем в заявке поданной Заказчиком. Денежные средства
//             Исполнитель обязуется переводить на указанные Заказчиком реквизиты. В случае возникновения во время обмена
//             прибыли, она остается на счету Исполнителя, как дополнительная выгода и премия за комиссионные услуги.`
//         ]
//     },
//     {
//         "id": "rule-5",
//         "anchor": "#rule-5",
//         "header": "В дополнение",
//         "textList": [
//             `5.1. Если на счет Исполнителя поступает сумма, отличающаяся от указанной в заявке, Исполнитель делает
//                     перерасчет,
//                     который соответствует фактическому поступлению титульных знаков. Если фактически поступившая сумма оказывается
//                     меньше 3000 (трёх тысяч) рублей в эквиваленте, то выплата по заявке не производится и титульные знаки не
//                     возвращаются.`,
//             `5.2. В случае, когда титульные знаки не отправляются Исполнителем на указанные реквизиты Заказчика по истечении
//                     24 (двадцати четырех) часов, Заказчик имеет полное право потребовать расторжение соглашения и аннулировать свою
//                     заявку, тем самым совершая возврат титульных знаков на свой счет в полном объеме. Заявка на расторжение
//                     соглашения
//                     и возврата титульных знаков выполняется Исполнителем в том случае, если денежные средства еще не были переведены
//                     на указанные реквизиты Заказчика. В случае аннулирования договора, возврат электронной валюты производится в
//                     течение 24 часов с момента получения требовании о расторжении договора. Если задержки при возврате возникли не
//                     по
//                     вине Исполнителя, он не несет за них ответственности.`,

//             `5.3. Если Заказчик оплатил заявку, но решил отказаться от обмена до выполнения заявки, то возврат средств происходит за вычетом 10% от суммы оплаты, комиссии внутри сети платежной системы. После выполнения заявки обращения связанные с возвратом средств не рассматриваются, в связи с отсутствием технической возможности совершить возврат средств.`,

//             `5.4. В случае оплаты заявки с реквизитов отличающихся от тех, что были указаны в заявке, обмен может быть заблокирован, а платеж возвращен отправителю за вычетом 10% от суммы обмена и комиссии платежных систем.`,
//             `5.5. Если титульные знаки не поступают от Заказчика на счет Исполнителя в течение указанного срока, с момента
//                     подачи заявки Заказчиком, соглашение между сторонами расторгается Исполнителем с одной стороны, так как договор
//                     не
//                     вступает в действие. Заказчик может об этом не уведомляться. Если титульные знаки поступает на реквизиты
//                     Исполнителя после указанного срока, то такие средства переводятся обратно на счет Заказчика, причем все
//                     комиссионные расходы, связанные с переводом, вычитаются из данных средств.`,
//             `5.6. Если происходит задержка перевода средств на реквизиты, указанные Заказчиком, по вине расчетной системы,
//                     Исполнитель не несет ответственности за ущерб, возникающий в результате долгого поступления денежных средств. В
//                     этом случае Заказчик должен согласиться с тем, что все претензии будут предъявляться к расчетной системе, а
//                     Исполнитель оказывает свою помощь по мере своих возможностей в рамках закона.`,
//             `5.7. Также, в случае невозможности осуществления перевода титульных знаков на реквизиты Заказчика по вине
//                     заказчика либо платежной системы, то такая заявка может обрабатываться до 24 (двадцати четырех) часов.`,
//             `5.8. В случае обнаружения подделки коммуникационных потоков или оказания воздействия, с целью ухудшить работу
//                     Исполнителя, а именно его программного кода, заявка приостанавливается, а переведенные средства подвергаются
//                     перерасчету в соответствии с действующим соглашением. Если Заказчик не согласен с перерасчетом, он имеет полное
//                     право расторгнуть договор и титульные знаки отправятся на реквизиты указанные Заказчиком.`,
//             `5.9. В случае пользования услугами Исполнителя, Заказчик полностью соглашается с тем, что Исполнитель несет
//                     ограниченную ответственность соответствующую рамкам настоящих правил полученных титульных знаков и не дает
//                     дополнительных гарантий Заказчику, а также не несет перед ним дополнительной ответственности. Соответственно
//                     Заказчик не несет дополнительной ответственности перед Исполнителем.`,
//             `5.10. Заказчик обязуется выполнять нормы соответствующие законодательству, а также не подделывать
//                     коммуникационные потоки и не создавать препятствий для нормальной работы программного кода Исполнителя.`,
//             `5.11.Исполнитель не несет ответственности за ущерб и последствия при ошибочном переводе электронной валюты в
//                     том
//                     случае, если Заказчик указал при подаче заявки неверные реквизиты.`,
//             `5.12.При отправке двойного платежа по предоставленным реквизитам: `,
//             'Сумма в эквиваленте до 10 000₽ возврату не подлежит',
//             'Сумма в эквиваленте более 10 000₽ подлежит возврату за вычетом 10% комиссии в течение 14 дней',
//         ]
//     },
//     {
//         "id": "rule-6",
//         "anchor": "#rule-6",
//         "header": "Гарантийный срок",
//         "textList": [
//             "6. В течение 24 часов с момента исполнения обмена титульных знаков Исполнитель дает гарантию на оказываемые услуги при условии, если не оговорены иные сроки."
//         ]
//     },
//     {
//         "id": "rule-7",
//         "anchor": "#rule-7",
//         "header": "Непредвиденные обстоятельства",
//         "textList": [
//             "7. В случае, когда в процессе обработки заявки Заказчика возникают непредвиденные обстоятельства, способствующие невыполнению Исполнителем условий договора, сроки выполнения заявки переносятся на соответствующий срок длительности форс-мажора. За просроченные обязательства Исполнитель ответственности не несет."
//         ]
//     },
//     {
//         "id": "rule-8",
//         "anchor": "#rule-8",
//         "header": "Форма соглашения",
//         "textList": [
//             "8. Данное соглашение обе стороны, в лице Исполнителя и Заказчика, принимают как равноценный по юридической силе договор, обозначенный в письменной форме."
//         ]
//     },
//     {
//         "id": "rule-9",
//         "anchor": "#rule-9",
//         "header": "Работа с картами Англии, Германии и США",
//         "textList": [
//             "9. Для владельцев карт стран Англии, Германии и США условия перевода титульных знаков продляются на неопределенный срок, соответствующий полной проверке данных владельца карты. Денежные средства в течение всего срока не подвергаются никаким операциям и в полном размере находятся на счете Исполнителя."
//         ]
//     },
//     {
//         "id": "rule-10",
//         "anchor": "#rule-10",
//         "header": "Претензии и споры",
//         "textList": [
//             "10. Претензии по настоящему соглашению принимаются Исполнителем в форме электронного письма, в котором Заказчик указывает суть претензии. Данное письмо отправляется на указанные на сайте реквизиты Исполнителя."
//         ]
//     },
//     {
//         "id": "rule-11",
//         "anchor": "#rule-11",
//         "header": "Проведение обменных операций",
//         "textList": [
//             `11.1.Категорически запрещается пользоваться услугами Исполнителя для проведения незаконных переводов и
//                     мошеннических действий. При заключении настоящего договора, Заказчик обязуется выполнять эти требования и в
//                     случае
//                     мошенничества нести уголовную ответственность, установленную законодательством на данный момент.`,
//             `11.2. Заказчик несет полную ответственность за достоверность указанных в заявке данных. Исполнитель не несет
//                     ответственности за убытки Заказчика возникшие по причине допущенной ошибки`,
//             `11.3. В случае невозможности выполнения заявки автоматически, по не зависящим от Исполнителя обстоятельствам,
//                     таким как отсутствие связи, нехватка средств, ошибки платежной системы, или же ошибочные данные Заказчика,
//                     средства поступают на счет в течение последующих 24 часов или же возвращается на реквизиты Заказчика за вычетом
//                     комиссионных расходов.`,
//             `11.4.По первому требованию Исполнитель вправе передавать информацию о переводе электронной валюты
//                     правоохранительным органам, администрации расчетных систем, а также жертвам неправомерных действий, пострадавшим
//                     в
//                     результате доказанного судебными органами мошенничества.`,
//             `11.5. Заказчик обязуется представить все документы, удостоверяющие его личность, в случае подозрения о
//                     мошенничестве и отмывании денег.`,
//             `11.6. Заказчик обязуется не вмешиваться в работу Исполнителя и не наносить урон его программной и аппаратной
//                     части, а также Заказчик обязуется передавать точные сведения для обеспечения выполнения Исполнителем всех
//                     условий
//                     договора.`,
//             `11.7. Если Заказчик верифицировал одну карту, а произвел оплату с другой, не верифицированной карты, то обмен
//                     будет заблокирован до прохождения Заказчиком верификации карты. Администрация обменного сервиса оставляет за
//                     собой
//                     право потребовать от Заказчика расширенной верификации с предоставлением селфи документом подтверждающим
//                     личность
//                     и картой в руках.`,

//         ]
//     },
//     {
//         "id": "rule-12",
//         "anchor": "#rule-12",
//         "header": "Отказ от обязательств.",
//         "textList": [
//             "12. Исполнитель имеет право отказа на заключение договора и выполнение заявки, причем без объяснения причин. Данный пункт применяется по отношению к любому клиенту."
//         ]
//     },
//     {
//         "id": "rule-13",
//         "anchor": "#rule-13",
//         "header": "Запрос видео-подтверждения.",
//         "textList": [
//             `13. В случае, если у Исполнителя возникают подозрения в принадлежности титульных знаков к конкретной Заявке, либо
//             информация в Заявке не совпадает с фактическими данными (например, отправленная Заказчиком сумма титульных
//             знаков
//             меньше или больше, чем в Заявке, не совпадают платежные реквизиты), не совпадают контактная информация,
//             Исполнитель вправе приостановить исполнение заявки и запросить видео подтверждение транзакции по электронной
//             почте
//             на указанные на сайте реквизиты Исполнителя. Инструкция по созданию видео подтверждения отправляется в форме
//             электронного письма на реквизиты Заказчика, указанные в Заявке.`
//         ]
//     },
//     {
//         "id": "rule-14",
//         "anchor": "#rule-14",
//         "header": "Коммуникация с Заказчиком.",
//         "textList": [
//             `14.1Коммуникация с Заказчиком происходит в онлайн чате и по электронной почте, по реквизитам указанным на сайте Исполнителя. В онлайн чате предоставляется только информация общего характера. Любые спорные вопросы, а также вопросы, связанные с реквизитами заявки, обсуждаются только по электронной почте.`,
//             `14.2Исполнитель ведет переписку с Заказчиком по графику: понедельник-воскресенье 9:00 — 22:00 мск в Онлайн чате понедельник-пятница 8:00 - 22:00 по электронной почте`,
//             `14.3Исполнитель имеет право прекратить беседу с Заказчиком в онлайн чате, который некорректно себя ведет с сотрудниками Исполнителя, задает посторонние вопросы или отказывается предоставить необходимые сведения.`,
//         ]
//     },
//     {
//         "anchor": "#rule-15",
//         "header": "Политика AML",
//         "id": "rule-15",
//         "textList": [
//             `15.1 Обменный сервис Cashex.cc использует практики и меры для предотвращения легализации (отмывания) доходов, полученных преступным путем и финансированию терроризма `,
//             `15.2 Данные действия наглядно демонстрируют, что обменный сервис Cashex.cc серьезно относится к любым попыткам использовать свой сервис в незаконных целях.`,
//             `15.3 Обменный сервис Cashex.cc предостерегает пользователей от попыток использования сервиса Cashex.cc для легализации денежных средств, полученных незаконным путем, финансирования терроризма, мошенничества любого рода, а также от использования сервиса для покупки запрещённых товаров и услуг.`,
//             `15.4 Для предотвращения операций незаконного характера сервис Cashex.cc устанавливает требования ко всем Заявкам, создаваемым Заказчиком:`,
//             `15.4.1  Отправителем и получателем должно быть одно лицо. Категорически запрещается использовать сервис для перевода Третьим лицам.`,
//             `15.4.2 Все контактные данные, вносимые заказчиком в заявку, а также любые другие персональные данные обязаны быть актуальными и достоверными. `,
//             `15.4.3 Категорически запрещается создавать Заявку, используя анонимные прокси-сервера и любые другие анонимные подключения к сети Интернет.`,
//             `15.5 При возникновении обоснованных подозрений у администрации сервиса Cashex.cc в том, что пользователь пытается воспользоваться услугами Сервиса для отмывания денег или с целью проведения любых других незаконных операций, администрация имеет право:`,
//             `Приостановить проведение любых операций;`,
//             `Запросить у пользователя документы, идентифицирующие личность;`,
//             `Запросить любую другую информацию, касающуюся проведения платежей;`,
//             `15.6 Вся информация предоставляемая клиентом может быть передана соответствующим органам в следующих случаях:`,

//             `По запросу правоохранительных органов;`,
//             `По решению судов разных инстанций;`,
//             `По запросам администраций Платежных систем`,
//             `15.7 В связи с вышеизложенным, обменный сервис Cashex.cc, его администрация и сотрудники не несут никакой юридической и финансовой ответственности за неправомерное использование сервиса третьими лицами, действия злоумышленников, возможный ущерб, связанный с использованием обменного сервиса Cashex.cc, а также за его использование в целях легализации денежных средств, полученных преступным путем, финансирования терроризма либо покупки запрещённых товаров и услуг, но обязуется предпринять все возможные и доступные действия для предотвращения попыток использования Сервиса Cashex.cc в целях легализации денежных средств, полученных преступным путем, финансирования терроризма либо покупки запрещённых товаров и услуг.`,
//             `15.8 Совершая обмен Пользователь соглашается со всеми условиями данных правил и обязуется их выполнять.`,
//         ]
//     },
// ]

const terms: any = {
  ru: {
    HEADING: 'Правила сервиса'
  },
  en: {
    HEADING: 'Service rules'
  },
  tu: {
    HEADING: 'Hizmet kuralları'
  }
}

const Terms = () => {
  const { staticText, language, status } = useAppSelector(state => state.main)

  const dispatch = useAppDispatch()
  const Terms = useMemo(() => terms[language], [language])

  useEffect(() => {
    dispatch(fetchStaticText('AGREEMENT'))
  }, [language])

  const termsText = useMemo(() => {
    if (utils.isEmptyObject(staticText)) {
      return []
    }
    const text: any[] = [
      staticText?.agreementParties,
      staticText?.terminsInfo,
      staticText?.termsInfo,
      staticText?.subjectInfo,
      staticText?.addition,
      staticText?.guarantee,
      staticText?.circumstances,
      staticText?.formOfAgreement,
      staticText?.workingCardsInfo,
      staticText?.disputes,
      staticText?.transactions,
      staticText?.disclaimer,
      staticText?.confirmation,
      staticText?.communication,
      staticText?.aml
    ]

    return text
  }, [staticText])
  return (
    <div className='container terms mb-5 pb-5'>
      <h1>{Terms.HEADING}</h1>

      {status === 'loading static text' ? (
        <Preloader />
      ) : (
        <div className='row'>
          {termsText.map((el, idx) => {
            return (
              <div key={idx} className='accordion accordion-flush col-lg-6'>
                <div className='accordion-item'>
                  <h5 className='accordion-header mt-sm-4 mt-2' id={`flush-headingOne-${idx}`}>
                    <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={`#flush-collapseOne-${idx}`} aria-expanded='false' aria-controls={`flush-collapseOne-${idx}`}>
                      <h5 className='m-0'>{el?.title}</h5>
                    </button>
                  </h5>
                  <div id={`flush-collapseOne-${idx}`} className='accordion-collapse collapse' aria-labelledby={`flush-headingOne-${idx}`}>
                    <div className='accordion-body mb-0'>
                      {el?.descriptionList.map((text: string, idxText: number) => (
                        <p key={idxText}>{text}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Terms
