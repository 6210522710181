import { ModalUpdateApiLkKeys } from '../../features/api-lk/modal-change-api-key'
import ModalSignUpQr from '../../features/api-lk/modal-sign-up-qr'

export enum ModalContentTypes {
  QR_OTP_AFTER_SIGN_UP = 'QR_OTP_AFTER_SIGN_UP',
  UPDATE_API_LK_KEYS = 'UPDATE_API_LK_KEYS',
  WITHDRAW_API_LK = 'WITHDRAW_API_LK'
}

export const defineModalEl = (modalContentTypes: ModalContentTypes | null): JSX.Element => {
  switch (modalContentTypes) {
    case ModalContentTypes.QR_OTP_AFTER_SIGN_UP:
      return <ModalSignUpQr />
    case ModalContentTypes.UPDATE_API_LK_KEYS:
      return <ModalUpdateApiLkKeys />

    default:
      return <></>
  }
}
