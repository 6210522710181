import {ActionReducerMapBuilder, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import userService from '../../service/user-service'
import {IUserCardsItem, UserState} from '../reducers/userReducer'
import Cookies from 'cookies-ts'
import {cookiesName} from '../../utils/consts'
import {RootState} from '../store'
import {ChangePasswordInterface, UserAuthInterface} from '../../utils/interfaces'
import {ACCESS_TOKEN} from "../../utils/api";

const cookies = new Cookies()

export type ResetPasswordData = {
    email: string
    language: string
}

export const fetchLogin = createAsyncThunk('user/fetchLogin', async (data: UserAuthInterface) => {
    try {
        const res = await userService.login(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            const token = res.headers.authorization
            if (token) {
                localStorage.setItem(ACCESS_TOKEN, token)
            }
            return {
                ...res.data,
                email: data.email
            }
        }
    } catch (err) {
    }
})

export const fetchLoginHandler = (builder: any) => {
    builder.addCase(fetchLogin.pending, (state: any, action: PayloadAction<any>) => {
        state.isAuthDataLoading = true;
    })
    builder.addCase(fetchLogin.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        const email = action.payload.email
        state.isAuthDataLoading = false;
        if (action.payload.success) {
            state.isAuth = true
            state.email = action.payload.email
            state.client_id = action.payload.content?.id
            state.client_id = action.payload.content?.id
            cookies.set(cookiesName.EMAIL, email)
            cookies.set(cookiesName.ACCESS_TOKEN, action.payload.content['x-access-token'])
            cookies.set(cookiesName.REFRESH_TOKEN, action.payload.content['x-refresh-token'])
            cookies.set(cookiesName.REF_ID, action.payload.content?.id)
        } else {
            state.isAuth = false
        }
        if (action?.payload?.status === 400) {
            state.login_message = 'Неверный логин или пароль'
            return
        }
        state.login_message = action.payload.message
    })
}


export const fetchRegisterHandler = (builder: any) => {
    builder.addCase(fetchRegister.pending, (state: any, action: PayloadAction<any>) => {
    })
    builder.addCase(fetchRegister.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        if (action.payload.success) {
            state.email = action.payload.email
            state.client_id = action.payload.content
            cookies.set(cookiesName.EMAIL, action.payload.email)
            cookies.set(cookiesName.REF_ID, action.payload.content)
            state.register_message = action.payload.message
        } else {
            state.isAuth = false
        }
    })
}

export const fetchRegister = createAsyncThunk('user/fetchRegister', async (data: UserAuthInterface) => {
    try {
        const res = await userService.register(data)
        const status = res.status || res.response.status
        const response = status === 200 ? res.data : res?.response.data
        if (response.success) {
            return {
                ...response,
                email: data.email
            }
        } else {
            return {
                message: response.message,
                success: response.success
            }
        }
    } catch (err) {
    }
})

export const fetchRefresh = createAsyncThunk('user/fetchRefresh', async () => {
    try {
        const res = await userService.refresh()
        const status = res.status || res.response.status
        const response = status === 200 ? res.data : res?.response.data
        if (status >= 400) {
            localStorage.removeItem(ACCESS_TOKEN)
            return {
                message: response.message,
                success: response.success,
                status
            }
        } else {
            const token = res.headers.authorization
            if (token) {
                localStorage.setItem(ACCESS_TOKEN, token)
            }
            return {
                ...response
            }
        }
    } catch (err) {

    }
})

export const fetchRefreshHandler = (builder: any) => {
    builder
        .addCase(fetchRefresh.pending, (state: UserState, action: PayloadAction<any>) => {
            state.isAuthDataLoading = true;
        })
        .addCase(fetchRefresh.fulfilled, (state: UserState, action: PayloadAction<any>) => {
            state.isAuthDataLoading = false;
            if (action.payload.success) {
                state.isAuth = true
            } else {
                state.isAuth = false
                cookies.remove(cookiesName.REFRESH_TOKEN)
                // cookies.remove(cookiesName.EMAIL) //TODO: эти куки нигде не устанавливаются
            }
        })
}

export const fetchResetPassword = createAsyncThunk('user/fetchResetPassword', async (data: ResetPasswordData) => {
    try {
        const res = await userService.resetPassword(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchResetPasswordHandler = (builder: any) => {
    builder.addCase(fetchResetPassword.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        if (action.payload.success) {
            state.reset_password_message = 'Мы отправили сообщение на ваш e-mail. Чтобы восстановить пароль, пройдите по ссылке, указанной в сообщении.'
        } else {
            state.reset_password_message = action.payload.message
        }
    })
}

export const fetchResetPasswordFinish = createAsyncThunk('user/fetchResetPasswordFinish', async (data: {
    password: string;
    token: string
}) => {
    try {
        const res = await userService.resetPasswordFinish(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchResetPasswordFinishHandler = (builder: any) => {
    builder.addCase(fetchResetPasswordFinish.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        if (action.payload.success) {
            state.reset_password_message_finish = 'success'
        } else {
            state.reset_password_message_finish = action.payload.message
        }
    })
}

export const fetchLogout = createAsyncThunk('user/fetchLogout', async () => {
    try {
        const res = await userService.logout()
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchLogoutHandler = (builder: any) => {
    builder.addCase(fetchLogout.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.isAuth = false
        state.email = ''
        state.login_message = ''
        state.client_id = null
        state.userCards = []
        localStorage.removeItem(ACCESS_TOKEN)
        cookies.remove(cookiesName.ACCESS_TOKEN)
        cookies.remove(cookiesName.REFRESH_TOKEN)
        cookies.remove(cookiesName.EMAIL)
    })
}

export const fetchChangePassword = createAsyncThunk('user/fetchChangePassword', async (data: ChangePasswordInterface, {getState}) => {
    try {
        const {language} = (getState() as RootState).main
        const res = await userService.changePassword(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status,
                language
            }
        } else {
            return {
                language,
                ...res.data
            }
        }
    } catch (err) {
    }
})

export const fetchChangePasswordHandler = (builder: any) => {
    builder.addCase(fetchChangePassword.pending, (state: UserState, action: PayloadAction<any>) => {
        state.isChangedPassword = true
        state.change_password_mesage = ''
    })
    builder.addCase(fetchChangePassword.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.isChangedPassword = false

        const changedText: any = {
            ru: {
                PASSWORD_CHANGED: 'Пароль изменен',
                DO_NOT_MATCH: 'Пароли не совпадают',
                PASSWORD_MATCH: 'Введённые пароли совпадают'
            },
            en: {
                PASSWORD_CHANGED: 'Password changed',
                DO_NOT_MATCH: 'Passwords do not match',
                PASSWORD_MATCH: 'The entered passwords match.'
            },
            tu: {
                PASSWORD_CHANGED: 'şifre değişti',
                DO_NOT_MATCH: 'Parolalar uyuşmuyor',
                PASSWORD_MATCH: 'Girilen şifreler eşleşiyor.'
            }
        }

        if (action.payload?.success) {
            state.change_password_mesage = changedText[action.payload?.language].PASSWORD_CHANGED
        } else {
            if (action.payload?.status === 409) {
                if (action.payload.message === 'Введённые пароли совпадают') {
                    state.change_password_mesage = changedText[action.payload?.language].PASSWORD_MATCH
                } else {
                    state.change_password_mesage = changedText[action.payload?.language].DO_NOT_MATCH
                }
            }
        }
    })
}

export const fetchGetRef = createAsyncThunk('user/fetchGetRef', async () => {
    try {

        const refInfoResponse = await userService.getRefInfo()
        const refBalancesResponse = await userService.getReferralBalances();
        const clientInfoResponse = await userService.getClientInfo()

        return {
            total_referrals: refInfoResponse.data.content.total_referrals,
            ref_balance: refBalancesResponse.data.content.balance_usdt,
            total_exchanges: refInfoResponse.data.content.total_exchanges,
            ref_percent: clientInfoResponse.data.content.ref_percent,
            client_id: clientInfoResponse.data.content.client_id,
        }

        // const res = await userService.getRefStatus()
        // if (res?.response?.status >= 400) {
        //   return {
        //     ...res.response.data,
        //     msg: res.response.data,
        //     status: res?.response?.status
        //   }
        // } else {
        //   return res.data
        // }
    } catch (err) {
    }
})

export const fetchGetRefHandler = (builder: any) => {
    builder.addCase(fetchGetRef.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.refStatuses = action.payload
    })
}

export const fetchBids = createAsyncThunk('user/fetchBids', async (data: { limit: number; offset: number }) => {
    try {
        const res = await userService.getBids(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchBidsHandler = (builder: any) => {
    builder.addCase(fetchBids.pending, (state: UserState, action: PayloadAction<any>) => {
        state.bidsLoader = true
    })
    builder.addCase(fetchBids.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.bidsLoader = false
        if (!action.payload?.success) {
            state.bids = []
        } else {
            state.bids = action.payload.content
        }
    })
}

export const fetchMakePayout = createAsyncThunk('user/fetchMakePayout', async (data: any, {getState}) => {
    try {
        const res = await userService.makePayout(data)
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchMakePayoutHandler = (builder: any) => {
    builder.addCase(fetchMakePayout.pending, (state: UserState, action: PayloadAction<any>) => {
        state.makePayoutSended = {
            setSent: true,
            message: ''
        }
    })
    builder.addCase(fetchMakePayout.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.makePayoutSended = {
            setSent: false,
            message: !action.payload.success ? action.payload.message : 'успех'
        }
    })
}

export const fetchPayouts = createAsyncThunk('user/fetchPayouts', async (_, {getState}) => {
    try {
        const res = await userService.getPayouts()
        if (res?.response?.status >= 400) {
            return {
                ...res.response.data,
                msg: res.response.data,
                status: res?.response?.status
            }
        } else {
            return res.data
        }
    } catch (err) {
    }
})

export const fetchPayoutsHandler = (builder: any) => {
    builder.addCase(fetchPayouts.pending, (state: UserState, action: PayloadAction<any>) => {
    })
    builder.addCase(fetchPayouts.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.payouts = action.payload?.content
    })
}

export const fetchUserCards = createAsyncThunk('user/fetchUserCards', async (_, {getState}) => {
    try {
        // const { client_id } = (getState() as RootState).user
        // const res = await userService.getCards(client_id)
        // return res.data
    } catch (err) {
    }
})

export const fetchUserCardsHandler = (builder: any) => {
    builder.addCase(fetchUserCards.pending, (state: UserState, action: PayloadAction<any>) => {
    })
    builder.addCase(fetchUserCards.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        if (Array.isArray(action?.payload)) {
            state.userCards = action?.payload
        }
    })
}

export const fetchUserCardsInfo = createAsyncThunk('user/fetchGetUserCards', async () => {
    try {
        const cards = await userService.getUserCardsInfo();
        if (cards.response?.status && cards.response?.status >= 400) {
            return {
                ...cards.data,
            }
        } else {
            return {
                ...cards.data,
            }
        }
    } catch (error) {
        console.log(error);
    }
})

export const fetchUserCardsInfoHandler = (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(fetchUserCardsInfo.pending, (state: UserState, action: PayloadAction<any>) => {
        state.isCardsInfoLoading = true
    })
    builder.addCase(fetchUserCardsInfo.fulfilled, (state: UserState, action: PayloadAction<any>) => {
        state.cardsInfo = action.payload
        state.isCardsInfoLoading = false
        state.isCardsInfoLoading = false
    })
    builder.addCase(fetchUserCardsInfo.rejected, (state: UserState, action: PayloadAction<any>) => {
        state.isCardsInfoLoading = false
    })
}

export const fetchAddUserCardToVerification = createAsyncThunk('user/add_card_to_verify', async (data: any) => {
    try {
        const res = await userService.addUserCardToVerify(data);
    } catch (error) {
        console.log(error);
    }
})
