import React from 'react'
import Button from '../components/buttons/button'
import useGetText from '../hooks/useGetText'
import { useNavigate } from 'react-router-dom'
import { path } from '../utils/consts'

const PrivacyPolicy = () => {
  const { policyText } = useGetText()
  const navigate = useNavigate()

  return (
    <div className='container pb-5'>
      <h1>{policyText.TITLE}</h1>
      {policyText.LIST.map((el: any, idx: number) => (
        <div key={idx}>
          {el?.title && <p>{el.title}</p>}
          {el.description?.map((t: string, index: number) => (
            <p key={index}>{t}</p>
          ))}
        </div>
      ))}
      <div className='button__center'>
        <Button onClick={() => navigate(path.AML)} variant='primary'>
          {policyText.BUTTON}
        </Button>
      </div>
    </div>
  )
}

export default PrivacyPolicy
