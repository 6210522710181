import React, {useEffect, useState} from 'react'
import {path} from '../../utils/consts'
import {useNavigate} from 'react-router-dom'
import {setMessage} from '../../store/reducers/tradeReducer'
import useConditionDirection from '../../hooks/useConditionDirection'
import {useAppDispatch, useAppSelector} from '../../hooks/reduxHooks'
import {fetchCreateBid} from '../../store/actions/tradeActions'
import BtnLoader from '../../components/loaders/btn-loader'
import useInput from '../../hooks/useInput'
import Button from '../../components/buttons/button'
import {resetBid, setCardName} from '../../store/reducers/bidReducer'
import useGetText from '../../hooks/useGetText'
import TelegramIcon from '../../pictures/icons/logos_telegram.svg'
import CashToCryptoInputs from "./DirectionInputs/CashToCryptoInputs";
import CryptoToPaymentSystemInputs from "./DirectionInputs/CryptoToPaymentSystemInputs";
import CryptoToRubBanks from "./DirectionInputs/CryptoToRubBanks";
import CryptoToSbpInputs from "./DirectionInputs/CryptoToSBPInputs";
import PaymentSystemToCryptoInputs from "./DirectionInputs/PaymentSystemToCryptoInputs";
import RubBanksToCryptoInputs from "./DirectionInputs/RubBanksToCryptoInputs";
import FastRegistrationModal from "../../components/Modal/fastRegistrationModal/FastRegistrationModal";
import {fetchUserCardsInfo} from "../../store/actions/userActions";
import {PayloadAction} from "@reduxjs/toolkit";
import {IUserCards, IUserCardsItem} from "../../store/reducers/userReducer";
import { statexOrderGoal } from '../../utils/statexEvents'

function TradeForm() {
    const {
        from,
        to,
        valueFrom,
        valueTo,
        hashId,
        course,
        message,
        setSent
    } = useAppSelector(state => state.trade)
    const {email, isAuth} = useAppSelector(state => state.user)
    const {withCardVerification} = useAppSelector(state => state.bid)
    const [isTerms, setTerms] = useState(true)
    const {spb_bank} = useAppSelector(state => state.trade)
    const navigate = useNavigate()
    const condition = useConditionDirection(from, to)
    const [selectedCard, setSelectedCard] = useState('')
    const [isFastRegistrationModalOpened, setIsFastRegistrationModalOpened] = useState(false)
    const cardInp = useInput()
    const emailInp = useInput()
    // const [selectedSbp, setSelectedSbp] = useState(sbpBanks[0].code)
    const dispatch = useAppDispatch()
    const [verificatedCards, setVerificatedCards] = useState<IUserCardsItem[]>([])
    const qiwiInput = useInput()
    const phoneInput = useInput()
    const nameInput = useInput()
    const tgInput = useInput()
    const walletInput = useInput()
    const memoInput = useInput()
    const {mainPageTexts} = useGetText()


    useEffect(() => {
        cardInp.setValue('')
        walletInput.setValue('')
    }, [from, to])

    useEffect(() => {
        dispatch(resetBid())
    }, [])

    useEffect(() => {
        if (hashId?.length > 4) {
            localStorage.setItem('bid_id', hashId)
            if (condition.cash) {
                navigate(`${path.BID_CASH}?id=${hashId}`)
            } else {
                navigate(`${path.BID}?id=${hashId}`)
            }
        }
    }, [hashId])

    useEffect(() => {
        dispatch(setCardName(nameInput.value))
    }, [nameInput.value])

    useEffect(() => {
        if (message.length > 1) {
            setTimeout(() => {
                dispatch(setMessage(''))
            }, 5000)
        }
    }, [message])

    useEffect(() => {
        emailInp?.setValue(email || '')
        emailInp?.setValid(true)
    }, [])

    useEffect(() => {
        if (isFastRegistrationModalOpened) {
            document.body.style.overflowY = "hidden";
        }
        return () => {
            document.body.style.overflow = "";
        };
    }, [isFastRegistrationModalOpened]);

    async function createBidHandler(e: any) {
        e.preventDefault()
        if (
            Number(course?.minFrom) > Number(valueFrom) ||
            Number(course?.maxFrom) < Number(valueFrom) ||
            Number(valueTo) > Number(course?.maxTo) ||
            Number(valueTo) < Number(course?.minTo)
        ) {
            dispatch(setMessage('Неверная сумма'))

            return
        }

        if (condition.cash) {
            if (!emailInp?.isValid) return
            const data = {
                email: emailInp?.value,
                // name: nameInput.value,
                tg: tgInput.value,
                tel: phoneInput?.value
            }

            // @ts-ignore yandex metrika
            ym(61727125, 'reachGoal', 'create_cash')
            statexOrderGoal()

            dispatch(fetchCreateBid(data))
        }

        if (condition.cryptoToBank) {
            if (from === 'SBPRUB' || to === 'SBPRUB') {
                if (!emailInp.isValid || !phoneInput.isValid || !nameInput.isValid || !(spb_bank.length > 2)) return
                const data = {
                    email: emailInp?.value,
                    wallet: phoneInput?.value,
                    name: nameInput.value
                }
                dispatch(fetchCreateBid(data))
                return
            }
            if (emailInp.isValid === false || cardInp?.isValid === false) return
            const data = {
                email: emailInp?.value,
                card: cardInp?.value
            }

            dispatch(fetchCreateBid(data))
        }

        if (condition.cryptoToPaymentSystem) {
            if (!emailInp.isValid || !qiwiInput.isValid) return
            const data = {
                email: emailInp?.value,
                wallet: qiwiInput?.value
            }
            dispatch(fetchCreateBid(data))
        }
        if (condition.paymentSystemToCrypto) {
            if (!emailInp.isValid || !qiwiInput.isValid || !walletInput.isValid) return
            const data = {
                email: emailInp?.value,
                tel: qiwiInput?.value,
                wallet: walletInput.value
            }
            dispatch(fetchCreateBid(data))
        }
        if (condition.bankToCrypto) {
            if (from === 'SBPRUB' || to === 'SBPRUB') {
                if (!emailInp.isValid || !walletInput.isValid || !nameInput.isValid) return
                const data = {
                    email: emailInp?.value,
                    wallet: walletInput?.value,
                    name: nameInput.value,
                    dest_tag: memoInput?.value
                }
                dispatch(fetchCreateBid(data))
                return
            }

            if (!emailInp.isValid || !walletInput.isValid) return

            if (withCardVerification) {
                if (isAuth) {
                    const response = await dispatch(fetchUserCardsInfo()) as PayloadAction<IUserCards>
                    if (!response.payload.cards) {
                        setIsFastRegistrationModalOpened(true)
                        return
                    }
                    const data = {
                        email: emailInp?.value,
                        wallet: walletInput?.value,
                        cards_number: selectedCard
                    }
                    dispatch(fetchCreateBid(data))
                } else {
                    setIsFastRegistrationModalOpened(true)
                    return
                }
            } else {
                if (!walletInput?.isValid || !emailInp.isValid || (!selectedCard && !cardInp?.isValid)) return

                const data = {
                    email: emailInp?.value,
                    wallet: walletInput?.value,
                    dest_tag: memoInput?.value,
                    cards_number: selectedCard || cardInp.value
                }
                dispatch(fetchCreateBid(data))
            }
        }

        if (condition.BankRUBToBankUKAndReverse) {
            if (cardInp.isValid === false || walletInput.isValid === false || emailInp.isValid === false || !nameInput.isValid) return
            const data = {
                email: emailInp?.value,
                wallet: walletInput?.value?.replace(/\W/gi, ''),
                card: cardInp?.value?.replace(/\W/gi, ''),
                name: nameInput.value
            }

            dispatch(fetchCreateBid(data))
        }
        if (condition.BankUKToPaymentSystem) {
            if (cardInp.isValid === false || qiwiInput.isValid === false || emailInp.isValid === false || !nameInput.isValid) return
            const data = {
                email: emailInp?.value,
                wallet: qiwiInput?.value?.replace(/\W/gi, ''),
                card: cardInp?.value?.replace(/\W/gi, ''),
                name: nameInput.value
            }

            dispatch(fetchCreateBid(data))
        }
        if (condition.PaymentSystemToBankUK) {
            if (emailInp.isValid === false || qiwiInput.isValid === false || walletInput.isValid === false) return
            const data = {
                email: emailInp?.value,
                card: qiwiInput?.value,
                wallet: walletInput.value
            }

            dispatch(fetchCreateBid(data))
        }
        if (condition.cryptoToCrypto) {
            if (!emailInp.isValid || !walletInput.isValid) return
            const data = {
                email: emailInp?.value,
                wallet: walletInput.value
            }

            dispatch(fetchCreateBid(data))
        }
    }

    // ------------------------------------

    return (
        <>
            <form onSubmit={createBidHandler} className='row mt-md-0 mt-4'>

                {condition.cash && <CashToCryptoInputs
                    phoneInput={phoneInput}
                    mainPageTexts={mainPageTexts}
                    emailInp={emailInp}
                    tgInput={tgInput}
                />}
                {condition.cryptoToPaymentSystem && <CryptoToPaymentSystemInputs
                    mainPageTexts={mainPageTexts} qiwiInput={qiwiInput} emailInp={emailInp}/>}
                {condition.cryptoToBank && (
                    to === 'SBPRUB'
                        ? <CryptoToSbpInputs
                            mainPageTexts={mainPageTexts} nameInput={nameInput} phoneInput={phoneInput}
                            emailInp={emailInp}/>
                        : <CryptoToRubBanks
                            mainPageTexts={mainPageTexts} cardInp={cardInp} emailInp={emailInp}/>
                )}
                {condition.paymentSystemToCrypto && <PaymentSystemToCryptoInputs
                    mainPageTexts={mainPageTexts} qiwiInput={qiwiInput} emailInp={emailInp}
                    walletInput={walletInput}/>}
                {condition.bankToCrypto && <RubBanksToCryptoInputs
                    cardInp={cardInp}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    mainPageTexts={mainPageTexts}
                    emailInp={emailInp}
                    walletInput={walletInput}
                    withCardVerification={withCardVerification}
                />}
                <div className='col-md-6 mt-3'>
                    <div className='pe-lg-4'>
                        <p className=''>{mainPageTexts.TERMS_TEXT}</p>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className='ps-lg-4'>
                        <div>
                            <div className='form-check'>
                                <input className='form-check-input' type='checkbox' value='' id='no_save'/>
                                <label className='form-check-label' htmlFor='no_save'>
                                    {mainPageTexts.DONT_SAVE_DATA}
                                </label>
                            </div>
                        </div>
                        <div className='form-check'>
                            {/* <input onChange={() => setTerms(!isTerms)} checked={isTerms}
                                    className='form-check-input' type='checkbox' value='' id='terms_save'/> */}
                            <label className='form-check-label' htmlFor='terms_save'>
                                {mainPageTexts.CHECKBOX_TERMS}
                            </label>
                        </div>
                        <div className='flex'>
                            <Button
                                disabled={!isTerms || setSent}
                                variant='primary'
                                className='w-100 py-3 mt-3 position-relative'
                                type='submit'
                                style={{height: 54 + 'px'}}
                            >
                                {setSent ?
                                    <BtnLoader/> : message?.length > 1 ? message : mainPageTexts.EXCHANGE_BTN_TEXT}
                            </Button>
                            <a className='mt-3' href='https://t.me/Cashex_info' target='_blank' rel='noreferrer'>
                                <img className='img' src={TelegramIcon} alt='telegramIcon'
                                     style={{marginLeft: 15}}/>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
            {isFastRegistrationModalOpened && <FastRegistrationModal
                emailInp={emailInp}
                walletInput={walletInput}
                cardInp={cardInp}
                setIsFastRegistrationModalOpened={setIsFastRegistrationModalOpened}
            />}
        </>
    )
}

export default TradeForm

