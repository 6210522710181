import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import CreditCardSelect from "../../../components/inputs/CustomCardSelector";
import {IUserCardsItem} from "../../../store/reducers/userReducer";
import {IBidInputsProps} from "./CashToCryptoInputs";
import CustomInput from "../../../components/inputs/custom-input";
import {namesType} from "../../../hooks/useInput";
import {fetchUserCardsInfo} from "../../../store/actions/userActions";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import RequisitesBlock from "../RequisitesBlock";
import {RingLoader} from "react-spinners";


interface IRubBanksToCryptoInputs extends IBidInputsProps {
    emailInp: any,
    walletInput: any,
    cardInp: any,
    selectedCard: string,
    setSelectedCard: React.Dispatch<React.SetStateAction<string>>,
    withCardVerification: boolean
}

const RubBanksToCryptoInputs: FC<IRubBanksToCryptoInputs> = ({
                                                                 mainPageTexts,
                                                                 walletInput,
                                                                 emailInp,
                                                                 cardInp,
                                                                 setSelectedCard,
                                                                 selectedCard,
                                                                 withCardVerification
                                                             }) => {

    const [verificatedCards, setVerificatedCards] = useState<IUserCardsItem[]>([])
    const {cardsInfo, isCardsInfoLoading, isAuthDataLoading, isAuth} = useAppSelector(state => state?.user);
    const dispatch = useAppDispatch()
    const [verifiedCardsLength, setVerifiedCardsLength] = useState(0)
    const [isInit, setIsInit] = useState(false)
    const [isNewCardInputShow, setIsNewCardInputShow] = useState(false)


    useEffect(() => {
        if (isAuth && !isAuthDataLoading) {
            dispatch(fetchUserCardsInfo()).then(() => {
                setIsInit(true)
            })
        }
    }, [isAuthDataLoading]);

    useEffect(() => {
        if (verifiedCardsLength && cardsInfo?.cards) {
            const newCardsArray = cardsInfo.cards.filter(card => card.status === 'SUCCESS')
            if (newCardsArray && newCardsArray.length) {
                setSelectedCard(newCardsArray[0].card_number)
            }
        } else {
            setSelectedCard("")
        }
    }, [verificatedCards]);

    useEffect(() => {
        if (!isCardsInfoLoading) {
            if (cardsInfo?.cards) {
                const newCardsArray = cardsInfo.cards.filter(card => card.status === 'SUCCESS')
                if (newCardsArray && newCardsArray.length) {
                    setVerificatedCards(newCardsArray)
                    setVerifiedCardsLength(newCardsArray.length)
                }
            } else {
                setVerificatedCards([])
            }
        }
    }, [isCardsInfoLoading]);

    return (
        <>
            {
                ((isCardsInfoLoading && !isInit) || isAuthDataLoading)
                    ? <div className={'p-2'}>
                        <div className={'mt-3 text-center flex justify-content-center align-content-center'}
                             style={{height: 81}}
                        >
                            <RingLoader color={'#002674'}/>
                        </div>
                    </div>
                    : <>
                        <div className='col-md-6'>
                            <RequisitesBlock mainPageTexts={mainPageTexts}>
                                <div className={'mt-2'}>
                                    {(isAuth && verificatedCards.length > 0 && !isNewCardInputShow)
                                        ? <CreditCardSelect cardsList={verificatedCards}
                                                            onClick={(target: string) => {
                                                                setSelectedCard(target)
                                                            }}
                                                            value={selectedCard}
                                                            setIsNewCardInputShow={setIsNewCardInputShow}
                                                            withCardVerification={withCardVerification}
                                        />
                                        : <>
                                            <CustomInput
                                                required={false}
                                                variant='main'
                                                maxLength={19}
                                                placeholder={'4567 8726 2837 3847'}
                                                label={mainPageTexts.INPUTS.CARD_NUMBER}
                                                name='card'
                                                inputHandlers={cardInp}
                                            />
                                            {!withCardVerification && isAuth &&
                                                <div className={'showCardTextWrapper'}>
                                                    <div className={'showCardText'} onClick={() => {
                                                        setIsNewCardInputShow(false)
                                                    }}>
                                                        Вернуть список карт
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='mt-2 email-input'>
                                    <CustomInput
                                        required={true}
                                        variant='main'
                                        placeholder='example@gmail.com'
                                        label='Email*'
                                        name='email'
                                        inputHandlers={emailInp}
                                        autoComplete={'on'}
                                    />
                                </div>
                            </RequisitesBlock>
                        </div>
                        <div className='col-md-6'>
                            <div className={'pe-lg-4'}>
                                <div className='form-heading'></div>
                                <CustomInput
                                    name={namesType.WALLET}
                                    variant='main'
                                    inputHandlers={walletInput}
                                    label={mainPageTexts.INPUTS.WALLET}
                                    placeholder='3Uhdu9DJ7nd08DjdJD9Dns8hdn98DNu8adb'
                                    autoComplete={'on'}
                                />
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default RubBanksToCryptoInputs;