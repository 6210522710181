import React, { FC, ReactNode } from 'react'
import Modal from '../../components/modal'
import { useAppSelector } from '../../hooks/reduxHooks'
import { ModalId } from '../../utils/consts'
import ModalExchange from '../api-lk/modal-exchange'
import ModalWithdrow from '../api-lk/modal-withdrow'

interface ApiLkLayoutIn {
  children: ReactNode
}

const ApiLkLayout: FC<ApiLkLayoutIn> = ({ children }) => {
  const { selectedModal } = useAppSelector(state => state.apiLk)
  return (
    <>
      {children}

      <Modal id={ModalId.MODAL_LK} size='lg' center={true}>
        {selectedModal === ModalId.MODAL_LK_WITHDROW && <ModalWithdrow />}
        {selectedModal === ModalId.MODAL_LK_EXCHANGE && <ModalExchange />}
      </Modal>
    </>
  )
}

export default ApiLkLayout
