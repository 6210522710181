import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/buttons/button'
import { Logo } from '../../../components/icons'
import CustomInput from '../../../components/inputs/custom-input'
import { ModalContentTypes } from '../../../components/Modal/defineModalEl'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import useGetText from '../../../hooks/useGetText'
import useInput, { namesType } from '../../../hooks/useInput'
import { SignUpData } from '../../../service/api-lk-service'
import { fetchSignUpApiLk } from '../../../store/actions/apiLkAuthActions'
import { setMessage } from '../../../store/reducers/apiLkAuthReducer'
import { ModalMode, setModalContentType } from '../../../store/reducers/modalReducer'
import { path } from '../../../utils/consts'

const CreateAccount: React.FC = () => {
  const { authMessage, isCreated } = useAppSelector(state => state.apiLkAuth)

  const { authTexts, apiGeneral } = useGetText()

  const emailIn = useInput('')
  const passwordIn = useInput('')
  const repeatPasswordIn = useInput('', passwordIn.value)
  const inviteCode = useInput('')

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setMessage(''))
  }, [emailIn.value, passwordIn.value, repeatPasswordIn.value])

  // Open modal with opt qr if account is created
  React.useEffect(() => {
    if (isCreated) {
      dispatch(
        setModalContentType({
          modalContent: ModalContentTypes.QR_OTP_AFTER_SIGN_UP,
          mode: ModalMode.STRICT
        })
      )
    }
  }, [isCreated])

  function createAccountHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!emailIn.isValid || !passwordIn.isValid || !repeatPasswordIn.isValid) return

    const data: SignUpData = {
      email: emailIn.value?.trim(),
      password: passwordIn.value?.trim(),
      repeated_password: repeatPasswordIn.value?.trim(),
      invite_code: inviteCode.value?.trim()
    }

    dispatch(fetchSignUpApiLk(data))
  }

  return (
    <div className='login-lk-api'>
      <div className='container'>
        <div className='row justify-content-center align-items-center full-screen py-4'>
          <div className='col-xl-5 col-lg-6  col-md-8 col-sm-10'>
            <form onSubmit={createAccountHandler} className='card'>
              <div className='d-flex justify-content-between flex-lg-row flex-column-reverse align-items-center'>
                <h2 className='mb-0'>{apiGeneral.CREATE_HEADING}</h2>
                <Link to={path.MAIN}>
                  <Logo width='100' />
                </Link>
              </div>
              <div className='my-3'>
                <CustomInput required variant='input-main' type='text' name={namesType.EMAIL} inputHandlers={emailIn} placeholder='test@test.com' label={'Email'} autoComplete={'on'} />
                <CustomInput required variant='input-main' type='password' name={namesType.PASSWORD} inputHandlers={passwordIn} placeholder='*********' label={authTexts.PASSWORD} />
                <CustomInput required variant='input-main' type='password' name={namesType.COMPARE_PASSWORD} inputHandlers={repeatPasswordIn} placeholder='*********' label={authTexts.REPEAT_PASSWORD} />
                <CustomInput required variant='input-main' type='text' inputHandlers={inviteCode} placeholder='Введите код' label={'Инвайт код'} />
              </div>

              <Button variant='primary' className='py-3'>
                {apiGeneral.CREATED}
              </Button>
              <small className='text-center mt-4'>{apiGeneral.IS_CREATED}</small>

              {authMessage.length > 0 && <div className='alert alert-danger mt-3 '>{authMessage}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount
