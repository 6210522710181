import React, {FC, SetStateAction} from 'react';
import styles from "./FastRegistrationModal.module.scss"
import Button from "../../buttons/button";

interface IStepFourProps{
    setIsFastRegistrationModalOpened: React.Dispatch<SetStateAction<boolean>>
}

const StepFour:FC<IStepFourProps> = ({setIsFastRegistrationModalOpened}) => {

    const closeModal = () => {
        setIsFastRegistrationModalOpened(false)
    }

    return (
        <div>
            <div className={styles.doneIcon}><img src={"/images/icons/done.svg"} alt={'done_icon'}/></div>
            <div className={styles.headText}>
                <div>Всё готово!</div>
                <div>Теперь вы можете продолжить создание заявки!</div>
            </div>
            <div className={styles.buttonsBlock}>
                <Button variant='primary'
                        className='py-3 mt-3 position-relative'
                        onClick={closeModal}
                        >
                    Далее
                </Button>
            </div>
        </div>
    );
};

export default StepFour;