import React, {FC, useState} from 'react'
import {PasswordIcon} from '../icons'
import {useAppDispatch, useAppSelector} from "../../hooks/reduxHooks";
import {setSpbBank} from "../../store/reducers/tradeReducer";

interface CustomInputProps {
  placeholder: string
  label?: string
  variant?: 'main' | string
  value : string
}

const CustomSPBInput: FC<CustomInputProps> = ({placeholder, variant, label, value}) => {
  const [isVisible, setVisible] = useState(false)
  const dispatch = useAppDispatch()
  return (
    <div className='inp-wrapper'>
      {label && <label htmlFor=''>{label}</label>}

      <input
        required={true}
        placeholder={placeholder}
        value={value}
        onChange={(e) => dispatch(setSpbBank(e.target.value))}
        className={`form-control ${variant ? 'input-main' : ''}`}
        autoComplete={'off'}
      />
    </div>
  )
}

export default CustomSPBInput
