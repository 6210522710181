import { FC } from 'react'

const Preloader: FC = () => {
  return (
    <div className='preloader'>
      <img src='/images/icons/spinner.svg' alt='spinner' />
    </div>
  )
}

export default Preloader
