import { configureStore } from '@reduxjs/toolkit'
import adminSlice from './reducers/adminReducer'
import apiLkAuthSlice from './reducers/apiLkAuthReducer'
import apiLkSlice from './reducers/apiLkReducer'
import bidSlice from './reducers/bidReducer'
import mainSlice from './reducers/mainReducer'
import tradeSlice from './reducers/tradeReducer'
import userSlice from './reducers/userReducer'
import modalSlice from './reducers/modalReducer'

const store = configureStore({
  reducer: {
    main: mainSlice,
    bid: bidSlice,
    trade: tradeSlice,
    user: userSlice,
    admin: adminSlice,
    apiLkAuth: apiLkAuthSlice,
    apiLk: apiLkSlice,
    modal: modalSlice
  }
})
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
