import { memo, useEffect, useState } from 'react'

const Toast = memo((props: any) => {
  const { notification, onClose } = props
  useEffect(() => {
    const timeoutId = setTimeout(onClose, notification.delay)
    return () => clearTimeout(timeoutId)
  }, [])

  const [animation, setAnimation] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setAnimation(1)
    })
    setTimeout(() => {
      setAnimation(0)
    }, 4500)
  }, [])

  const renderItem = (content: any) => {
    if (typeof content === 'function') {
      return <span style={{ zIndex: 99999999999 }}>{content()}</span>
    } else {
      return <pre>{JSON.stringify(content, null, 1)}</pre>
    }
  }

  return <div className={notification.type === 'success' ? 'toast-container notify-success' : 'toast-container notify-danger'}>{renderItem(notification.content)}</div>
})

Toast.displayName = 'Toast'

const Notifier = (props: any) => {
  const { notifications, onClose } = props
  return (
    <ul className='toast-list'>
      {notifications.map((notification: any, idx: number) => (
        <li key={notification.id} onClick={() => onClose(notification.id)}>
          <Toast onClose={() => onClose(notification.id)} notification={notification} />
        </li>
      ))}
    </ul>
  )
}

export default memo(Notifier)
