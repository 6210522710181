import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalContentTypes } from '../../components/Modal/defineModalEl'

export enum ModalMode {
  DEFAULT = 'DEFAULT',
  STRICT = 'STRICT'
}

export interface ModalState {
  isModalOpen: boolean
  mode: ModalMode
  modalContent: ModalContentTypes | null
}

const initialState: ModalState = {
  isModalOpen: false,
  mode: ModalMode.DEFAULT,
  modalContent: null
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalContentType(
      state,
      action: PayloadAction<{
        modalContent: ModalContentTypes | null
        mode: ModalMode
      }>
    ) {
      state.modalContent = action.payload.modalContent
      state.mode = action.payload.mode
      state.isModalOpen = true
    },
    closeModal(state) {
      state.isModalOpen = false
      state.mode = ModalMode.DEFAULT
    }
  }
})

export const { setModalContentType, closeModal } = modalSlice.actions

export default modalSlice.reducer
