import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/reduxHooks'
import { setFromCategorySelected, setToCategorySelected } from '../../store/reducers/tradeReducer'
import utils from '../../utils/utils'

interface TradeCategoryProps {
  type: string
}

const TradeCategory: FC<TradeCategoryProps> = ({ type }) => {
  const { fromCategorySelected, toCategorySelected, filter, filterTo } = useAppSelector(state => state.trade)
  const { language } = useAppSelector(state => state.main)
  const [selectedCategory, setSelectedCategory] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    // получаю дефолтное состояние категорий
    if (type === 'from') {
      setSelectedCategory(fromCategorySelected || 'all')
    } else {
      setSelectedCategory(toCategorySelected || 'all')
    }
  }, [fromCategorySelected, toCategorySelected])

  useEffect(() => {
    if (type === 'from') {
      dispatch(setFromCategorySelected(selectedCategory || 'all'))
      dispatch(setToCategorySelected('all'))
    } else {
      dispatch(setToCategorySelected(selectedCategory || 'all'))
    }
  }, [selectedCategory])

  const filterCategoryFrom = useMemo(() => utils.sortCategory(filter, 'from', language), [fromCategorySelected, filter, language])
  const filterCategoryTo = useMemo(() => utils.sortCategory(filterTo, 'to', language), [toCategorySelected, fromCategorySelected, filterTo, filter, language])

  return (
    <ul className='trade-category'>
      {type === 'from'
        ? filterCategoryFrom.map((el: any, idx: number) => (
            <li key={el.category + idx} onClick={() => setSelectedCategory(el.category)} className={`${selectedCategory === el.category ? 'active' : ''}`}>
              {el.label}
            </li>
          ))
        : filterCategoryTo.map((el: any, idx: number) => (
            <li key={el.category + idx} onClick={() => setSelectedCategory(el.category)} className={`${selectedCategory === el.category ? 'active' : ''}`}>
              {el.label}
            </li>
          ))}
    </ul>
  )
}

export default memo(TradeCategory)
