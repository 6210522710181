import React from 'react'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import InputExchange from '../../components/inputs/input-exchange'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput from '../../hooks/useInput'
import { SwapCreateDataIn } from '../../service/api-lk-service'
import { fetchExchangeRate, fetchForExchange, fetchSwapCreate } from '../../store/actions/apiLkActions'

const ModalExchange: React.FC = () => {
  const { selectedTokenToExchange, selectedTokenForExchange, exchange_rate, valueFrom, valueTo } = useAppSelector(state => state.apiLk)

  const { mainPageTexts } = useGetText()

  const dispatch = useAppDispatch()

  const otpIn = useInput('')

  const closeModal = React.useRef<HTMLButtonElement | null>(null)

  function clearInp() {
    otpIn.setValue('')
  }

  // fetch available tokens for exchange
  React.useEffect(() => {
    if (selectedTokenToExchange) {
      dispatch(fetchForExchange(selectedTokenToExchange?.token))
    }
  }, [selectedTokenToExchange])

  // fetch exchange rate for selected token
  React.useEffect(() => {
    if (selectedTokenToExchange && selectedTokenForExchange) {
      dispatch(
        fetchExchangeRate({
          token_from: selectedTokenToExchange?.token,
          token_to: selectedTokenForExchange
        })
      )
    }
  }, [selectedTokenToExchange, selectedTokenForExchange])

  // OnSubmit form for exchange Handler
  const createExchange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!selectedTokenToExchange || !selectedTokenForExchange || !exchange_rate || !otpIn?.isValid) {
      return
    }

    const data: SwapCreateDataIn = {
      otp_code: otpIn.value,
      amount_from: Number(valueFrom),
      amount_to: Number(valueTo),
      token_to: selectedTokenForExchange,
      token_from: selectedTokenToExchange.token,
      exchange_rate: Number(exchange_rate)
    }

    dispatch(fetchSwapCreate(data))
  }

  return (
    <div>
      <form onSubmit={createExchange}>
        <div className='trade'>
          <div className='row'>
            {/* FROM INPUT */}
            <div className='col-lg-6'>
              <InputExchange type='from' label={mainPageTexts?.LABEL_EXCHANGE_FROM} />
            </div>

            {/* TO INPUT */}
            <div className='col-lg-6'>
              <InputExchange type='to' label={mainPageTexts?.LABEL_EXCHANGE_TO} reverseBtn={true} />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          {/* COURSE */}
          <div className='d-md-flex justify-content-center'>
            <div>
              <p className='mb-0'>
                {mainPageTexts?.COURSE}: 1 {selectedTokenToExchange?.token} <img src={`/images/currencies/${selectedTokenToExchange?.token}.svg`} className='mb-1' width='24' alt='' /> = {1 * Number(exchange_rate)}
                {selectedTokenForExchange} <img src={`/images/currencies/${selectedTokenForExchange}.svg`} alt='' height='24' className='mb-1' />
              </p>
            </div>
          </div>

          {/* GOOGLE 2FA */}
          <div className='col-12 mb-3'>
            <CustomInput variant='main' type='text' required inputHandlers={otpIn} placeholder='Введите 2FA код' label={'Google 2FA'} />
          </div>
        </div>

        {/* BUTTON EXCHANGE */}
        <Button variant='primary' className='w-100 py-3' type='submit'>
          Обменять
        </Button>
      </form>
      {/* {witdrowCreated === MyCustomStatus.ERROR && <div className="alert alert-danger mt-3">Произашла ошибка</div>} */}
      <button className='d-none' ref={closeModal}></button>
    </div>
  )
}

export default React.memo(ModalExchange)
