import { api } from '../utils/api'
import { apiEndPoints } from '../utils/consts'
import { DynamicTextInterface } from '../utils/interfaces'

class Main {
  getTexts(): any {
    return api
      .post(apiEndPoints.GET_TEXT, {
        appointment: null,
        city: null,
        from: null,
        to: null
      })
      .then(res => res)
      .catch(rej => rej)
  }
  getAutoExchangeText(data: any): any {
    return api
      .post(apiEndPoints.GET_AUTO_EXCHANGE_TEXT, data)
      .then(res => res)
      .catch(rej => rej)
  }

  sendSupport(data: any) {
    return api
      .post(apiEndPoints.HELP, data)
      .then(res => res)
      .catch(rej => rej)
  }
  dynamicText(data: DynamicTextInterface) {
    const { type, from, to, language, appointment, city } = data
    const cash = type === 'cash' ? `${appointment && `&appointment=${appointment}`}${city && `&city=${city}`}` : ''
    return api
      .get(apiEndPoints.DINAMIC_TEXT + `/?paymentType=${type}&name=${from}-${to}&lang=${language === 'ru' ? language.toUpperCase() : 'ENG'}${cash}`)
      .then(res => res)
      .catch(rej => rej)
  }

  staticText(data: { language: string; name: string }) {
    return api
      .get(apiEndPoints.STATIC_TEXT + `/?name=${data.name}&lang=${data.language === 'RU' ? data.language : 'ENG'}`)
      .then(res => res)
      .catch(rej => rej)
  }
  staticBalance() {
    return api
      .get(apiEndPoints.CURRENT_BALANCE)
      .then(res => res)
      .catch(rej => rej)
  }
}

export default new Main()
