import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { path } from '../utils/consts'
import { useAppSelector } from './reduxHooks'

const useApiLk = () => {
  const location = useLocation()
  const { isAuthApi } = useAppSelector(state => state.apiLkAuth)

  const isLK = useMemo(() => location.pathname.includes('api/lk/'), [location.pathname])
  const isAuthLk = useMemo(() => {
    if (isLK) {
      return !(location.pathname.includes(path.API_LOGIN) || location.pathname.includes(path.API_CREATE_ACCOUNT))
    } else {
      return true
    }
  }, [location.pathname, isLK])

  useEffect(() => {
    if (isLK) {
      document.body.classList.add('is-api-lk-bg')
    } else {
      document.body.classList.remove('is-api-lk-bg')
    }
  }, [isLK])
  return {
    isLK,
    isAuthLk
  }
}

export default useApiLk
