import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useDebounce from '../../hooks/useDebounce'
import validation from '../../utils/validation'
import { setFromValue, setSelectedTokenForExchange, setSelectedTokenToExchange, setToValue } from '../../store/reducers/apiLkReducer'
import { ReverceExchangeTradeIcon } from '../icons'

interface InputTradeProps {
  label?: string
  reverseBtn?: boolean
  type: string
}

const InputExchange: React.FC<InputTradeProps> = ({ label, reverseBtn, type }) => {
  
  const dispatch = useAppDispatch()
  const { directions, from, to } = useAppSelector(state => state.trade)

  const { tokens, selectedTokenToExchange, selectedTokenForExchange, for_exchange_tokens, valueFrom, valueTo, exchange_rate } = useAppSelector(state => state.apiLk)
  const { language } = useAppSelector(state => state.main)

  const [inputClasses, setInputClasses] = React.useState<string>('')

  // const debounceInputValue = useDebounce(type === 'from' ? valueFrom : valueTo, 300)

  // Check for reverse direction
  const checkWalletFrom = () => {}

  // Reverse button Handler
  const reverseDirectionHandler = React.useCallback(() => {}, [])

  React.useEffect(() => setInputClasses(''), [valueFrom, valueTo])

  React.useEffect(() => {
    dispatch(setFromValue(valueFrom))
  }, [exchange_rate])


  const getName = React.useCallback(
    (el: any) => {
      switch (language) {
        case 'ru':
          return el.name
        case 'en':
          return el.en_name
        case 'tu':
          return el.en_name
      }
    },
    [language]
  )

  return (
    <div className={`main-input ${reverseBtn ? 'ps-lg-4' : 'pe-lg-4'} ${reverseBtn ? 'mt-lg-0 mt-3' : ''}`}>
      {reverseBtn && (
        <button type='button' onClick={reverseDirectionHandler} className='btn btn-reverce'>
          <ReverceExchangeTradeIcon />
        </button>
      )}

      <div className='d-sm-flex justify-content-between'>
        <h2 className='mb-sm-3 mb-0'>{label}</h2>
      </div>
      <div className='position-relative'>
        <div className={`input-group ${valueFrom !== '' && inputClasses}`}>
          <div className='dropdown dropdown-tokens'>
            <button className='btn dropdown-btn d-flex align-items-center dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
              {type === 'from' ? (
                selectedTokenToExchange && (
                  <>
                    <img src={`/images/currencies/${selectedTokenToExchange.token}.svg`} width={35} height={35} alt='' />
                  </>
                )
              ) : (
                <>
                  <img src={`/images/currencies/${selectedTokenForExchange}.svg`} width={35} height={35} alt='' />
                </>
              )}
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <ul className='ps-0'>
                {type === 'from'
                  ? tokens.map((el, idx) => (
                      <li className={el.token === selectedTokenToExchange?.token ? 'active' : ''} onClick={() => dispatch(setSelectedTokenToExchange(el))} key={idx}>
                        <img src={`/images/currencies/${el?.token}.svg`} width={30} height={30} alt='' />
                        <p className='mb-0'>{el?.full_name}</p>
                      </li>
                    ))
                  : for_exchange_tokens?.tokens.map((el, idx) => (
                      <li className={el === selectedTokenForExchange ? 'active' : ''} onClick={() => dispatch(setSelectedTokenForExchange(el))} key={idx}>
                        <img src={`/images/currencies/${el}.svg`} width={30} height={30} alt='' />
                        <p className='mb-0'>{el}</p>
                      </li>
                    ))}
              </ul>
            </div>
          </div>

          {/* INPUT */}
          {
            <input
              type='text'
              placeholder={'Введите сумму'}
              value={type === 'from' ? valueFrom : valueTo}
              className='trade-input exchange'
              onChange={(e: any) => {
                if (type === 'from') {
                  dispatch(setFromValue(validation.numbersOneDot(e.target?.value)))
                } else {
                  dispatch(setToValue(validation.numbersOneDot(e.target?.value)))
                }
              }}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default InputExchange
