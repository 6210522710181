import React, { FormEvent } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import Modal from '../../components/modal'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useInput, { namesType } from '../../hooks/useInput'
import { fetchCreateWithdrow } from '../../store/actions/apiLkActions'
import { setSelectedTokenToWithdrow, setWitdrowCreated } from '../../store/reducers/apiLkReducer'
import { setTo } from '../../store/reducers/tradeReducer'
import { MyCustomStatus } from '../../utils/consts'
import utils from '../../utils/utils'

const ModalWithdrow = () => {
  const { tokens, selectedTokenToWithdrow, witdrowCreated } = useAppSelector(state => state.apiLk)

  const dispatch = useAppDispatch()

  const sumIn = useInput('')
  const walletIn = useInput('')
  const otpIn = useInput('')

  const closeModal = useRef<HTMLButtonElement | null>(null)

  function clearInp() {
    sumIn.setValue('')
    walletIn.setValue('')
    otpIn.setValue('')
  }

  React.useEffect(() => {
    if (selectedTokenToWithdrow !== null) {
      walletIn.setValue('')
      dispatch(setTo(selectedTokenToWithdrow.token))
    }
  }, [selectedTokenToWithdrow])

  useEffect(() => {
    if (selectedTokenToWithdrow !== null) {
      walletIn.setValue('')
    }
  }, [selectedTokenToWithdrow])

  useEffect(() => {
    if (witdrowCreated === MyCustomStatus.SUCCESS) {
      if (closeModal.current !== null) closeModal.current.click()

      clearInp()
    }
  }, [witdrowCreated])

  function createWithdrow(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    if (selectedTokenToWithdrow === null || !walletIn?.isValid || !otpIn?.isValid) {
      return
    }

    dispatch(
      fetchCreateWithdrow({
        amount: Number(sumIn.value),
        call_back_url: '',
        client_transaction_id: utils.makeRandomStr(10),
        otp_code: otpIn.value,
        receiver: walletIn.value,
        token: selectedTokenToWithdrow?.token
      })
    )
  }

  return (
    <div>
      <form action='' onSubmit={createWithdrow}>
        <h2>Вывод средств</h2>
        <div className='row mt-3'>
          <div className='col-sm-6 mb-3'>
            <CustomInput variant='main' type='number' required inputHandlers={sumIn} placeholder='Введите сумму' />
          </div>
          <div className='col-sm-6 mb-3'>
            <div className='dropdown dropdown-tokens'>
              <button className='btn btn-secondary border dropdown-toggle d-flex justify-content-between px-3 py-2 align-items-center p-0 w-100' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                {selectedTokenToWithdrow !== null && (
                  <>
                    <img src={`/images/currencies/${selectedTokenToWithdrow.token}.svg`} width={35} height={35} alt='' />
                    <span>{selectedTokenToWithdrow?.full_name}</span>
                  </>
                )}
              </button>
              <div className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                <ul className='ps-0'>
                  {tokens.map((el, idx) => (
                    <li className={el.token === selectedTokenToWithdrow?.token ? 'active' : ''} onClick={() => dispatch(setSelectedTokenToWithdrow(el))} key={idx}>
                      <img src={`/images/currencies/${el?.token}.svg`} width={30} height={30} alt='' />
                      <p className='mb-0'>{el?.full_name}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 mb-3'>
            <CustomInput variant='main' type='text' required inputHandlers={walletIn} name={namesType.WALLET} placeholder='Введите номер Кошелька' />
          </div>
          <div className='col-12 mb-3'>
            <CustomInput variant='main' type='text' required inputHandlers={otpIn} placeholder='Введите OTP-код' />
          </div>
        </div>
        <Button variant='primary' className='w-100 py-3'>
          Вывести
        </Button>
      </form>
      {/* {witdrowCreated === MyCustomStatus.ERROR && <div className="alert alert-danger mt-3">Произашла ошибка</div>} */}
      <button className='d-none' ref={closeModal}></button>
    </div>
  )
}

export default React.memo(ModalWithdrow)
