class Validation {
  password(password: string) {
    return /^(?![A-Z]+$)(?![a-z]+$)(?![a-zA-Z]+$)(?![0-9a-z]+$)(?![0-9A-Z]+$)[a-zA-Z0-9]+$/g.test(password) && password.length >= 8 && password.length <= 32
  }
  name(name: string) {
    return /^[\wа-я]{3,}$/gi.test(name)
  }
  telegram(telegram: string) {
    return /^\w{3,}$/gi.test(telegram)
  }
  email(email: string) {
    return /^[a-zA-Z_.\-0-9]+[@][a-z]+[.][a-z]{2,}$|\s+|^admin1337$/g.test(email)
  }
  card(card: string) {
    return /^\d{16}$|^\d{18}$/g.test(String(card).replace(/[ ]+/g, ''))
  }
  cardCleaner(card: string) {
    return card.replace(/[a-z\\~#%&*{}/:<>?=|"-]+/gi, '')
  }
  cardReplacer(card: string) {
    return card?.replace(/\W/gi, '')?.replace(/(.{4})/g, '$1 ')
  }
  purse(purse: string) {
    return /\w{5,}/g.test(purse)
  }
  phone(phone: string) {
    return phone.replace(/[+\-() ]+/g, '').length === 11
  }
  phoneCleaner(phone: string) {
    return phone.replace(/[a-z\\~#%&*{}/.,';:<>?=|"]+/gi, '')
  }
  id(id: string) {
    return /[0-9]{4,}/g.test(id)
  }
  message(message: string) {
    return message.length > 0
  }
  holder(holder: string) {
    return /\w+[ ]\w+/gi.test(holder) || !holder.length
  }
  holderReplacer(initials: string) {
    return initials.toUpperCase().replace(/[*/\-+@!#$%^&?()~`.,:;\[\]"№_'=0-9а-я]/gi, '')
  }
  pay_sum(sum: string) {
    return sum.length > 0
  }
  formattingPhone(phone: string, length: number) {
    switch (length) {
      case 0:
      case 1:
      case 2:
        return '+7 ('
      case 3:
        return phone.replace(/\+7 /g, '$&(')
      case 7:
        return phone.replace(/\+7 \(\d{3}/g, '$&) ')
      case 12:
        return phone.replace(/\+7 \(\d{3}\) \d{3}/g, '$&-')
      case 15:
        return phone.replace(/\+7 \(\d{3}\) \d{3}-\d{2}/g, '$&-')
      default:
        return phone
    }
  }
  splitCourseWithSpaces(course: string) {
    let int = String(course)?.split('.')?.[0]
    let fractions = String(course)?.split('.')?.[1] || ''
    let length = int?.length
    switch (length) {
      case 8:
        return int.replace(/^\d{2}|\d{3}/g, '$& ')?.trim() + '.' + fractions
      case 7:
        return int.replace(/^\d|\d{3}/g, '$& ')?.trim() + '.' + fractions
      case 6:
        return int.replace(/^\d{3}/g, '$& ')?.trim() + '.' + fractions
      case 5:
        return int.replace(/^\d{2}/g, '$& ')?.trim() + '.' + fractions
      default:
        return course
    }
  }
  numbersOneDot(value: string): string {
    return value
      .replace(',', '.')
      .replace(/[^\d\.]/g, '')
      .replace(/\./, 'x')
      .replace(/\./g, '')
      .replace(/x/, '.')
  }
}

export default new Validation()
