import React, {FormEvent, useCallback, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/buttons/button'
import CustomInput from '../../components/inputs/custom-input'
import AuthLayout from '../../features/layouts/auth-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import useInput from '../../hooks/useInput'
import { fetchLogin } from '../../store/actions/userActions'
import { path } from '../../utils/consts'

const LoginPage = () => {
  const { login_message } = useAppSelector(state => state.user)
  const { isAuth } = useAppSelector(state => state.user)

  const emailInp = useInput('')
  const passwordInp = useInput('')
  const { authTexts } = useGetText()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const loginHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!emailInp.isValid || !passwordInp.isValid) return
      setIsLoading(true)
      dispatch(
        fetchLogin({
          email: emailInp.value.toLowerCase(),
          password: passwordInp.value
        })
      ).finally(() => {setIsLoading(false)})
    },
    [emailInp, passwordInp]
  )

  useEffect(() => {
    if (isAuth) {
      // debugger
      navigate(path.MAIN)
    }
  }, [isAuth])

  return (
    <AuthLayout title={authTexts.LOGIN}>
      <form onSubmit={loginHandler}>
        <CustomInput required inputHandlers={emailInp} variant='main' placeholder='example@gmail.com' label='Email' />
        <CustomInput required inputHandlers={passwordInp} variant='main' type='password' placeholder='**********' label={authTexts.PASSWORD} />
        <div className='text-end my-3'>
          <Link to={path.COMPARE_PASSWORD}>{authTexts.FORIGIN_PASSWORD}</Link>
        </div>
        <Button variant='primary' className='w-100 my-3 py-3' isLoading={isLoading} disabled={isLoading}>
          {authTexts.SIGN_IN}
        </Button>
        <p className='t3 text-center'>{authTexts.NO_ACCOUNT}</p>
        {login_message && <p className='t3 text-center'>{login_message}</p>}
      </form>
    </AuthLayout>
  )
}

export default LoginPage
