import React, { useMemo } from 'react'
import { AboutUSTexts } from '../local/about-us-texts'
import { ApiGeneral } from '../local/api-lk/api-general'
import { ApiTexts } from '../local/api-texts'
import { AuthTexts } from '../local/auth-texts'
import { contestTexts } from '../local/contest-texts'
import { ExchangeTexts } from '../local/exchange-texts'
import { FooterTexts } from '../local/footer-texts'
import { generalTexts } from '../local/general-texts'
import { mainPageText } from '../local/main-page-text'
import { ProfileTexts } from '../local/profile-texts'
import { BidStatusNameRus } from '../utils/consts'
import { useAppSelector } from './reduxHooks'
import { privacy } from '../local/privacy'
import { aml } from '../local/aml'
import { admin } from '../local/admin'
import { ApiStatisticText } from '../local/api-lk/api-statistic-text'

const useGetText = () => {
  const { language } = useAppSelector(state => state.main)
  const mainPageTexts = useMemo(() => mainPageText[language], [language])
  const generalText = useMemo(() => generalTexts[language], [language])
  const contestText = useMemo(() => contestTexts[language], [language])
  const statusName = useMemo(() => BidStatusNameRus[language], [language])
  const footerText = useMemo(() => FooterTexts[language], [language])
  const exchangeText = useMemo(() => ExchangeTexts[language], [language])
  const authTexts = useMemo(() => AuthTexts[language], [language])
  const profileTexts = useMemo(() => ProfileTexts[language], [language])
  const apiText = useMemo(() => ApiTexts[language], [language])
  const aboutUsTexts = useMemo(() => AboutUSTexts[language], [language])
  const apiGeneral = useMemo(() => ApiGeneral[language], [language])
  const policyText = useMemo(() => privacy[language], [language])
  const amlText = useMemo(() => aml[language], [language])
  const adminText = useMemo(() => admin[language], [language])
  const apiStatisticText = useMemo(() => ApiStatisticText[language], [language])

  return {
    mainPageTexts,
    generalText,
    contestText,
    statusName,
    footerText,
    exchangeText,
    authTexts,
    profileTexts,
    apiText,
    aboutUsTexts,
    apiGeneral,
    policyText,
    amlText,
    adminText,
    apiStatisticText
  }
}

export default useGetText
