import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TradeCategory from '../../features/trade/trade-category'
import TradePopular from '../../features/trade/trade-popular'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useDebounce from '../../hooks/useDebounce'
import useGetText from '../../hooks/useGetText'
import useInput from '../../hooks/useInput'
import { setCity, setFrom, setFromCategorySelected, setFromValue, setTo, setToValue } from '../../store/reducers/tradeReducer'
import { Category } from '../../utils/consts'
import validation from '../../utils/validation'
import { ArrowBottom, CloseDropdownWalletsIcon, InputIconArrow, ReverceExchangeTradeIcon } from '../icons'
import MinMax from '../min-max'
import CustomInput from './custom-input'
import { useNavigate, useParams } from 'react-router-dom'

interface InputTradeProps {
  label?: string
  reverseBtn?: boolean
  directions?: []
  type?: string
}

const InputTrade: FC<InputTradeProps> = ({ label, reverseBtn, type }) => {
  const navigate = useNavigate()
  const { fromCategorySelected, filter, filterTo, valueFrom, valueTo, toCategorySelected, course, courseLoader, directions } = useAppSelector(state => state.trade)
  const { tokens, selectedTokenToExchange, selectedTokenForExchange, for_exchange_tokens, exchange_rate } = useAppSelector(state => state.apiLk)

  let { from, to, city } = useParams()
  const dispatch = useAppDispatch()

  const { language } = useAppSelector(state => state.main)

  const [currencies, setCurrencies] = useState<any[]>([])
  const [isDropdown, setDropdown] = useState<boolean>(false)
  const [inputClasses, setInputClasses] = useState<string>('')

  const searchInput = useInput('')

  const { mainPageTexts } = useGetText()

  const dropdownButtonRef = useRef<HTMLButtonElement>(null)
  const debounceInputValue = useDebounce(type === 'from' ? valueFrom : valueTo, 300)

  function checkWalletFrom(array = [], wallet: string) {
    // проверка на обратные направления
    let codes: any[] = []
    array.forEach((el: any) => {
      if (el?.from?.code === wallet) {
        codes.push(el.from)
      }
    })
    return codes.some(el => el.code === wallet)
  }

  const reverseDirectionHandler = useCallback(
    (e: any) => {
      // функция обратного направления

      if (checkWalletFrom(filter, to!)) {
        e.target.style.opacity = '1'
        dispatch(setFrom(to!))
        dispatch(setTo(from!))
        dispatch(setFromCategorySelected(Category.ALL))
      } else {
        e.target.style.opacity = '.5'
      }
    },
    [filter, to, from, type]
  )

  const fromCurrenciesDirectionFiltered = useCallback(() => {
    // фильтрация списка валют from
    if (fromCategorySelected === Category.ALL) {
      let cleanArray: any = []
      filter
        ?.filter((el: any) => el?.from.category !== fromCategorySelected)
        .forEach((item: any) => {
          cleanArray.push(item.from)
        })
      return cleanArray
    } else {
      let cleanArray: any = []
      filter
        ?.filter((el: any) => el?.from.category === fromCategorySelected)
        .forEach((item: any) => {
          cleanArray.push(item.from)
        })
      return cleanArray
    }
  }, [filter, fromCategorySelected])
  const toCurrenciesDirectionFiltered = useCallback(() => {
    // фильтрация списка валют to
    if (toCategorySelected === Category.ALL) {
      return filterTo
    } else {
      const toArrayFiltered = filterTo.filter((el: any) => el?.category === toCategorySelected)
      return toArrayFiltered
    }
  }, [toCategorySelected, filterTo])

  let isNotHaveMinValue = false

  if (from && to) {
    isNotHaveMinValue = from.toLowerCase().includes('cash') || to!.toLowerCase().includes('cash')
  }

  const directionClickHandler = useCallback(
    (el: any) => {
      // выбор валюты
      if (type === 'from') {
        dispatch(setFrom(el.code))
        setCurrencies(fromCurrenciesDirectionFiltered())
      } else {
        dispatch(setTo(el.code))
      }
      setDropdown(false)
    },
    [type]
  )

  useEffect(() => {
    // фильтрация списка валют
    if (type === 'from') setCurrencies(fromCurrenciesDirectionFiltered())
    else setCurrencies(toCurrenciesDirectionFiltered())

    searchInput.setValue('')
  }, [fromCategorySelected, toCategorySelected, filter, filterTo])

  useEffect(() => {
    //Выбор первого элемента в отфильтрованном массиве from
    if (type === 'from') {
      if (fromCategorySelected !== Category.ALL) {
        dispatch(setFrom(fromCurrenciesDirectionFiltered()[0]?.code))
      }
    }
  }, [fromCategorySelected])

  useEffect(() => {
    //Выбор первого элемента в отфильтрованном массиве from
    if (type !== 'from') {
      if (toCurrenciesDirectionFiltered() !== undefined) dispatch(setTo(toCurrenciesDirectionFiltered()[0]?.code))
    }
  }, [toCategorySelected])

  useEffect(() => {
    if (Number(isNotHaveMinValue ? 0 : course?.minFrom) > Number(valueFrom) || Number(course?.maxFrom) < Number(valueFrom) || Number(valueTo) > Number(course?.maxTo) || Number(valueTo) < Number(isNotHaveMinValue ? 0 : course?.minTo)) {
      setInputClasses('error')
    } else {
      setInputClasses('success')
    }
  }, [debounceInputValue])
  useEffect(() => setInputClasses(''), [valueFrom, valueTo])

  useEffect(() => {
    // закрытие dropdown
    document.addEventListener('click', () => {
      setDropdown(false)
    })
  }, [])

  useEffect(() => {
    if (!isDropdown) {
      searchInput.setValue('')
    }
  }, [isDropdown])

  useEffect(() => {
    // поиск
    if (type === 'from') {
      setCurrencies(fromCurrenciesDirectionFiltered()?.filter((el: any) => el?.name?.toUpperCase().includes(searchInput.value?.toUpperCase())))
    } else {
      setCurrencies(toCurrenciesDirectionFiltered()?.filter((el: any) => el?.name?.toUpperCase().includes(searchInput.value?.toUpperCase())))
    }
  }, [searchInput.value])

  const direction = useMemo(() => (type === 'from' ? from : to), [type, from, to])

  React.useEffect(() => {
    const choosenFrom: any = directions?.find((item: any) => item.code === from)
    const choosenTo: any = directions?.find((item: any) => item.code === to)

    if (choosenFrom?.category === 'crypto' && choosenTo?.category !== 'cash') {
      dispatch(setFromValue(''))
    } else if (choosenFrom?.category === 'crypto' && choosenTo?.category === 'cash') {
      dispatch(setFromValue('1'))
    }

    if (choosenTo?.category === 'crypto' && choosenFrom?.category !== 'cash') {
      dispatch(setToValue(''))
    } else if (choosenTo?.category === 'crypto' && choosenFrom?.category === 'cash') {
      dispatch(setToValue('1'))
    }
  }, [fromCategorySelected, filter, from, to, filterTo, toCategorySelected, course, courseLoader, directions, dispatch, reverseBtn])

  const getName = useCallback(
    (el: any) => {
      switch (language) {
        case 'ru':
          return el.name
        case 'en':
          return el.en_name
        case 'tu':
          return el.en_name
      }
    },
    [language]
  )

  const elemFrom = directions.find((curr: any) => curr.code === from)
  const elemTo = directions.find((curr: any) => curr.code === to)

  const cryptOne = () => {
    if (elemFrom?.category === 'crypto' && elemTo?.category === 'cash') {
      dispatch(setFromValue(validation.numbersOneDot('1')))
      console.log(1)
    }
  }

  return (
    <div className={`main-input ${reverseBtn ? 'ps-lg-4' : 'pe-lg-4'} ${reverseBtn ? 'mt-lg-0 mt-3' : ''}`}>
      {reverseBtn && (
        <button type='button' onClick={reverseDirectionHandler} className='btn btn-reverce'>
          <ReverceExchangeTradeIcon />
        </button>
      )}

      <div className='d-sm-flex justify-content-between'>
        <h2 className='mb-sm-3 mb-0'>{label}</h2>
        {/* <TradePopular /> */}
      </div>
      <div className='position-relative'>
        <div className={`input-group ${valueFrom !== '' && inputClasses}`} onClick={(e: any) => e.stopPropagation()}>
          <div className={`dropdow-wallets ${isDropdown ? 'open-wallet-dropdown' : ''}`}>
            <button className='btn dropdown-btn d-flex align-items-center' onClick={() => setDropdown(!isDropdown)} ref={dropdownButtonRef} type='button'>
              <img width={37} height={37} src={`/images/currencies/${direction}.svg`} alt='' />
              <ArrowBottom className={` ms-2 ${isDropdown ? 'rotate-180' : ''}`} />
            </button>
            <div className='dropdown-content'>
              <button type='button' className='btn p-0 btn-close-chise-wallet' onClick={useCallback(() => setDropdown(false), [])}>
                <CloseDropdownWalletsIcon />
              </button>
              <h3>{mainPageTexts?.SELECT_CURRENCY}</h3>
              <div className='pb-2'>
                <CustomInput placeholder={mainPageTexts?.SEARCH} inputHandlers={searchInput} />
              </div>
              <TradeCategory type={type === 'from' ? 'from' : 'to'} />
              {currencies?.length !== 0 ? (
                <ul className='wallets'>
                  {currencies?.map((el: any, idx) => (
                    <li key={idx} className={`${(type === 'from' ? from : to) === el.code ? 'active' : ''}`}>
                      <button
                        onClick={() => {
                          directionClickHandler(el)
                          cryptOne()
                        }}
                        type='button'
                        className={`dropdown-item`}
                      >
                        <div>
                          <span className='image-wrapper'>
                            <img src={`/images/currencies/${el.code}.svg`} width={22} height={22} alt='' />
                          </span>
                        </div>
                        <p>{getName(el)}</p>
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-center'>{mainPageTexts?.NOT_FOUND_CURRENCY}</p>
              )}
            </div>
          </div>
          {
            <input
              type='text'
              min={type === 'from' ? course.minFrom : course.minTo}
              placeholder={type === 'from' ? (courseLoader ? 'loading' : `${course.minFrom}-${course.maxFrom}`) : courseLoader ? 'loading' : `${course.minTo}-${course.maxTo}`}
              value={type === 'from' ? valueFrom : valueTo}
              className='trade-input'
              onChange={(e: ChangeEvent<any>) => {
                if (type === 'from') dispatch(setFromValue(validation.numbersOneDot(e.target?.value)))
                else dispatch(setToValue(validation.numbersOneDot(e.target?.value)))
              }}
            />
          }
        </div>
      </div>
      <MinMax type={type || ''} />
    </div>
  )
}

export default InputTrade
