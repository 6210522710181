import React, {FC} from 'react';
import {IBidInputsProps} from "./CashToCryptoInputs";
import CustomInput from "../../../components/inputs/custom-input";
import {namesType} from "../../../hooks/useInput";
import CustomSPBInput from "../../../components/inputs/CustomSPBInput";
import {useAppSelector} from "../../../hooks/reduxHooks";
import RequisitesBlock from "../RequisitesBlock";

interface ICryptoToSbpInputs extends IBidInputsProps {
  nameInput: any,
  phoneInput: any,
  emailInp: any
}

const CryptoToSbpInputs: FC<ICryptoToSbpInputs> = ({mainPageTexts, phoneInput, nameInput, emailInp}) => {

  const {spb_bank} = useAppSelector(state => state.trade)


  return (
    <>
      <div className='col-md-6'>
        <RequisitesBlock mainPageTexts={mainPageTexts}>
          <div className='mt-2'>
            <CustomInput
              placeholder='Иван Иванов'
              required={true}
              variant='main'
              name={namesType.NAME_SURNAME}
              label={mainPageTexts.INPUTS.NAME_SURNAME + '*'}
              inputHandlers={nameInput}
            />
          </div>
          <div className='mt-2'>
            <CustomInput
              placeholder='+7 (9**) ***-**-**'
              maxLength={18}
              variant='main'
              name={namesType.PHONE}
              label={mainPageTexts.INPUTS.PHONE}
              inputHandlers={phoneInput}
            />
          </div>
        </RequisitesBlock>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='form-heading'></div>
          <div className='mt-2'>
            <CustomSPBInput
              placeholder='Райффайзен'
              variant='main'
              label={mainPageTexts.INPUTS.SELECT_SBP_BANK}
              value={spb_bank}
            />
          </div>
          <div className='mt-2 email-input'>
            <CustomInput
              required={true}
              variant='main'
              placeholder='example@gmail.com'
              label='Email*'
              name='email'
              inputHandlers={emailInp}
              autoComplete={'on'}
            />
          </div>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='pe-lg-4'>
          <div className='alert alert-danger mt-4 p-2'>{mainPageTexts.TO_SBP_MESSAGE}</div>
        </div>
      </div>
    </>
  );
};

export default CryptoToSbpInputs;