import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { path } from '../../utils/consts'

const links = [
  { text: 'Introduction', link: '#Introduction', method: '' },
  {
    text: 'transaction_create_in',
    link: '#transaction_create_in',
    method: 'POST'
  },
  {
    text: 'transaction_create_out',
    link: '#transaction_create_out',
    method: 'POST'
  },
  {
    text: 'pay_form_create',
    link: '#pay_form_create',
    method: 'POST'
  },
  { text: 'transaction_get', link: '#transaction_get', method: 'POST' },
  { text: 'transaction_fetch', link: '#transaction_fetch', method: 'POST' },
  { text: 'user_commission', link: '#user_commission', method: 'POST' },
  { text: 'token_balance', link: '#token_balance', method: 'POST' },
  { text: 'token_fetch', link: '#token_fetch', method: 'POST' },
  { text: 'sign_up', link: '#sign_up', method: 'POST' },
  { text: 'swap_create', link: '#swap_create', method: 'POST' },
  { text: 'swap_execute', link: '#swap_execute', method: 'POST' },
  { text: 'swap_get', link: '#swap_get', method: 'GET' }
]

const contentItems = [
  {
    id: 'Introduction',
    right: (
      <div className='py-4'>
        <h2>CashexAPI</h2>
        <p className='right-line'>
          <strong>CashexAPI </strong>allows to use all features of CashexWallet
        </p>
        <p className='right-line'>
          <strong>Key Pairs:</strong>
        </p>
        <p>
          <strong>ApiPublic -</strong> it is API Key
        </p>
        <p>
          <strong>PrivateKey -</strong> it is Private Key <strong>(do not share with any body)</strong>
        </p>
        <p className='right-line'>
          <strong>Authorization:</strong>
        </p>
        <p>
          <strong>ApiPublic</strong> header
        </p>
        <p>
          <strong>Signature header</strong> - generates by signing your json body
          <strong>by PrivateKey</strong>
        </p>
        <p>
          Here is a few examples of <strong>Signature</strong> generation Go/Python
        </p>
        <div className='data-code'>
          mac := hmac.New(sha512.New, []byte(secret))
          <br />
          mac.Write([]byte(json_body))
          <br />
          return hex.EncodeToString(mac.Sum(nil))
          <br />
        </div>

        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `  data = json.dumps(body) if body else self.api_key
  return {
    <span class="key">"Content-Type"</span>: <span class="str">"application/json"</span>,
    <span class="key">"ApiPublic"</span>: <span class="int">self.api_key</span>,
    <span class="key">"Signature"</span>: <span class="int">hmac.new(
              self.api_secret.encode("utf-8"),
              data.encode("utf-8"),
              hashlib.sha512,
              ).hexdigest(),</span>,
  }`
          }}
        ></pre>
      </div>
    ),
    left: <div></div>
  },
  {
    id: 'transaction_create_in',
    right: (
      <div className='py-4 border-top'>
        <h2>
          <strong>'POST'</strong> transaction_create_in
        </h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/transaction/create/in</span>
        </p>
        <p>
          In call_back_url field you have to set url of your notify consumer. You will receive <strong>POST</strong> request with this body when <strong>income</strong> transaction status will change:
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fadfba1a49"</source>
}`
          }}
        ></pre>
        <p>
          After that you have to make request <strong>transaction_get</strong> to read actual information about transaction by providing <strong>tracker_id</strong>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
    <span class="key">"call_back_url"</span>: <span class="str">"https://ccb1-83-69-220-238.ngrok.io",</span>
    <span class="key">"card_number"</span>: <span class="str">"1111222233334444",</span>
    <span class="key">"amount"</span>: <span class="str">"1.0",</span>
    <span class="key">"email"</span>: <span class="str">"email@example.com"</span>
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>transaction_create_in</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/transaction/create/in' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"token" :</span> <span class="str">"USDTTRC"</span>,
    <span class="key">"call_back_url" :</span> <span class="str">"https://ccb1-83-69-220-238.ngrok.io",</span>
    <span class="key">"card_number" :</span> <span class="str">"1111222233334444",</span>
    <span class="key">"amount" :</span> <span class="str">"1.0",</span>
    <span class="key">"email" :</span> <span class="str">"email@example.com"</span>
  }'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"description"</span>:<span class="str"> ""</span>,
    <span class="key">"dest_tag"</span>:<span class="str"> ""</span>,
    <span class="key">"refer"</span>:<span class="str"> "TL8C3cDoMaFfKw3ZzGUQpZ3eXKzfs9rzLT"</span>,
    <span class="key">"status"</span>:<span class="str"> "ACCEPTED"</span>,
    <span class="key">"token_name"</span>:<span class="str"> "USDTTRC"</span>,
    <span class="key">"external_id"</span>:<span class="str"> "c68d4924a011535321bbf1be1098ea448dbe70a16ea6280d9a2b0f23deb4</span>"
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'transaction_create_out',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' transaction_create_out</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/transaction/create/out</span>
        </p>
        <p>
          Same as wallet_create, in call_back_url field you have to set url of your notify consumer. You will receive <strong>POST</strong> request with this body when <strong>withdrawal</strong> transaction status will change:
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fa"</span>
}`
          }}
        ></pre>
        <p>
          After that you have to make request <strong>transaction_get</strong> to read actual information about transaction by providing <strong>tracker_id</strong>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
    <span class="key">"receiver"</span>: <span class="str">"TFXY3TqdBiMEqdN6VQjBHFk9gpmn7iYkG5"</span>,
    <span class="key">"amount"</span>: <span class="int">100</span>,
    <span class="key">"call_back_url"</span>: <span class="str">"https://your.app.url"</span>    
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>transaction_create_out</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/transaction/create/out' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
    <span class="key">"receiver"</span>: <span class="str">"TFXY3TqdBiMEqdN6VQjBHFk9gpmn7iYkG5"</span>,
    <span class="key">"amount"</span>: <span class="int">100</span>,
    <span class="key">"call_back_url"</span>: <span class="str">"https://your.app.url</span>"
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"description"</span>: <span class="str">""</span>,
    <span class="key">"status"</span>: <span class="str">"ACCEPTED"</span>,
    <span class="key">"external_id"</span>: <span class="str">"f2b72094d6b42e6ab7a2a4ecb0f4cdc8f5685251a21a209f5a3e9e8992a9876466319701ddc6839b46185505565b7f2e1271b0626788f2b7d7870f6eb5b51970"</span>
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'pay_form_create',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' pay_form_create</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/order/pay_form/create</span>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token"</span>: <span class="str">"ADA"</span>,
    <span class="key">"amount"</span>: <span class="str">124</span>,
    <span class="key">"call_back_url"</span>: <span class="str">"https://your.app.url"</span>    
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>pay_form_create</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/order/pay_form/create' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"token"</span>: <span class="str">"ADA"</span>,
    <span class="key">"amount"</span>: <span class="int">124</span>,
    <span class="key">"call_back_url"</span>: <span class="str">"https://your.app.url</span>"
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"pay_form_url"</span>: <span class="str">""https://swap.cashex.cc/?id=achbhebfihbwdfhbweifdbnh"</span>,
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'transaction_get',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' transaction_get</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/transaction/get</span>
        </p>
        <p>
          Transaction Types: <strong>IN / OUT</strong>
        </p>
        <p>Transaction Statuses: </p>
        <p>
          Processing status - <strong>ACCEPTED</strong>
        </p>
        <p>
          Final status - <strong>SUCCESS</strong>
        </p>
        <p>
          Error status - <strong>ERROR</strong>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fadfba1a49f85e"</span>
    <span class="key">"type"</span>: <span class="str">"IN"</span>
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>transaction_get</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/transaction/get' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1f"</span>
    <span class="key">"type"</span>: <span class="str">"IN"</span>
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"amount"</span>: <span class="str">10</span>,
    <span class="key">"callback_url"</span>: <span class="str">"https://ccb1-83-69-220-238.ngrok.io"</span>,
    <span class="key">"date_create"</span>: <span class="str">"2022-09-06T16:03:06Z"</span>,
    <span class="key">"dest_tag"</span>: <span class="str">""</span>,
    <span class="key">"hash"</span>: <span class="str">"07e395a5857acd572335ea82abd3bd7a9ff6c263d6"</span>,
    <span class="key">"receiver"</span>: <span class="str">"TL8C3cDoMaFfKw3ZzGUQpZ3eXKzfs9rzLT"</span>,
    <span class="key">"success"</span>: <span class="str">true</span>,
    <span class="key">"error"</span>: <span class="str">true</span>,
    <span class="key">"done"</span>: <span class="str">true</span>,
    <span class="key">"token"</span>: <span class="str">USDTTRC</span>,
    <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fadfba1a49f85"</span>,
    <span class="key">"fee"</span>: <span class="str">"0"</span>,
    <span class="key">"type"</span>: <span class="str">"IN"</span>,
    <span class="key">"done_time"</span>: <span class="str">"2022-09-06T16:03:06Z"</span>,
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'transaction_fetch',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' transaction_fetch</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/transaction/fetch</span>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"limit"</span>: <span class="int">2</span>,
    <span class="key">"offset"</span>: <span class="int">0</span>,
    <span class="key">"sort_order"</span>: <span class="int">"desc"</span>
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>transaction_fetch</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/transaction/fetch' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"limit"</span>: <span class="int">2</span>,
    <span class="key">"offset"</span>: <span class="int">0</span>,
    <span class="key">"sort_order"</span>: <span class="str">"desc"</span>
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"transaction_list</span>": [
        {
            <span class="key">"amount"</span>: <span class="int">10</span>,
            <span class="key">"callback_url"</span>: <span class="str">"https://ccb1-83-69-220-238.ngrok.io"</span>,
            <span class="key">"date_create"</span>: <span class="str">"2022-07-16T12:22:27Z"</span>,
            <span class="key">"dest_tag"</span>: <span class="str">""</span>,
            <span class="key">"hash"</span>: <span class="str">"07e395a5857acd572335ea82abd3bd7a9ff6c263d611a44807"</span>,
            <span class="key">"receiver"</span>: <span class="str">"TL8C3cDoMaFfKw3ZzGUQpZ3eXKzfs9rzLT"</span>,
            <span class="key">"success"</span>: <span class="str">true</span>,
            <span class="key">"error"</span>: <span class="str">true</span>,
            <span class="key">"done"</span>: <span class="str">true</span>,
            <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
            <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fadf"</span>,
            <span class="key">"fee"</span>: <span class="str">"0"</span>,
            <span class="key">"type"</span>: <span class="str">"IN"</span>,
            <span class="key">"done_time"</span>: <span class="str">"2022-09-06T16:03:06Z"</span>,
        },
        {
            <span class="key">"amount"</span>: <span class="int">10</span>,
            <span class="key">"callback_url"</span>: <span class="str">"https://ccb1-83-69-220-238.ngrok.io"</span>,
            <span class="key">"date_create"</span>: <span class="str">"2022-07-16T12:22:27Z"</span>,
            <span class="key">"dest_tag"</span>: <span class="str">""</span>,
            <span class="key">"hash"</span>: <span class="str">"07e395a5857acd572335ea82abd3bd7a9ff6c263d611a44807"</span>,
            <span class="key">"receiver"</span>: <span class="str">"TL8C3cDoMaFfKw3ZzGUQpZ3eXKzfs9rzLT"</span>,
            <span class="key">"success"</span>: <span class="str">true</span>,
            <span class="key">"error"</span>: <span class="str">true</span>,
            <span class="key">"done"</span>: <span class="str">true</span>,
            <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
            <span class="key">"external_id"</span>: <span class="str">"e7546f7362133ddb17c4304f02fedd9d47be0c1fadf"</span>,
            <span class="key">"fee"</span>: <span class="str">"0"</span>,
            <span class="key">"type"</span>: <span class="str">"IN"</span>,
            <span class="key">"done_time"</span>: <span class="str">"2022-09-06T16:03:06Z"</span>,
        }
    ]
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'user_commission',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' user_commission</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/user/commission</span>
        </p>

        <p>
          <strong>sum_comm_in</strong> - commission in fraction dedicated by amount of incoming sum
        </p>
        <p>
          <strong>sum_comm_out</strong> - commission in fraction dedicated by amount of withdrawal sum
        </p>
        <p>
          <strong>fix_comm_in</strong> - fixed network fee of incoming transactions
        </p>
        <p>
          <strong>fix_comm_out</strong> - fixed network fee of withdrawal transactions
        </p>
        <p>
          <strong>Total commission transaction: amount * sum_comm + fix_comm</strong>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>user_commission</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `<span class"def">curl --location --request POST 'https://swap.cashex.cc/user/commission' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"token"</span>: <span class="str">"ETH"</span>
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"token"</span>: <span class="str">"BTC"</span>,
    <span class="key">"sum_comm_in"</span>: <span class="int">0</span>,
    <span class="key">"sum_comm_out"</span>: <span class="int">0.0015</span>,
    <span class="key">"fix_comm_in"</span>: <span class="int">0</span>,
    <span class="key">"fix_comm_out"</span>: <span class="int">0.00015</span>,
    <span class="key">"min_deposit_sum"</span>: <span class="int">0.0005</span>,
    <span class="key">"min_withdrawal_sum"</span>: <span class="int">0.0001</span>
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'token_balance',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' token_balance</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/user/balance</span>
        </p>

        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token"</span>: <span class="str">"USDTTRC"</span>,
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>token_balance</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `<span class"def">curl --location --request POST 'https://swap.cashex.cc/user/balance' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"token" </span>: <span class="str">"USDTTRC"</span>
}'`
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"balance"</span>: <span class="int">2352342.43242</span>,
}`
          }}
        />
      </div>
    )
  },
  {
    id: 'token_fetch',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' token_fetch</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/user/token/fetch</span>
        </p>

        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>token_fetch</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/user/token/fetch' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw ''
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"tokens"</span>: [
        <span class="str">"BTC"</span>,
        <span class="str">"BCH"</span>,
        <span class="str">"BTG"</span>,
        <span class="str">"ETH"</span>,
        <span class="str">"USDT"</span>,
        <span class="str">"ETC"</span>,
        <span class="str">"XRP"</span>,
        <span class="str">"LTC"</span>,
        <span class="str">"ADA"</span>,
        <span class="str">"DASH"</span>,
    ]
}`
          }}
        />
      </div>
    )
  },
  {
    id: 'sign_up',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' sign_up</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/user/invite/sign_up</span>
        </p>

        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"invite_code"</span>: <span class="str">"QQQQQQQ"</span>,
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>sign_up</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/user/invite/sign_up'</span>
--data-raw '{
    <span class="key">"invite_code"</span>: <span class="str">"QQQQQQQ"</span>,
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"public_key"</span>: <span class="str">"zwdnixjs9ajgdaztinopx5zz4ey5dw9jutq4c1gogbr98dkervev0rnsj9rbio6i"</span>,
    <span class="key">"private_key"</span>: <span class="str">"yib4rxz2fs4hesiehbmky376y8i5x1j3dul9gqi5qoxgfgi8t064e3ddqmj8qbjc2yvjds7j37x41f03fxa6xnndnoz35aa642zvddc7lraucvamdnsbkh44291e7vy1"</span>
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'swap_create',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' swap_create</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/swap/create</span>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Content-Type</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"token_from"</span>: <span class="str">"USDT"</span>,
    <span class="key">"token_to"</span>: <span class="str">"ETH"</span>,
    <span class="key">"amount"</span>: <span class="int">500</span>,
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>swap_create</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/swap/create' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"currency_from"</span>: <span class="str">"ETH"</span>,
    <span class="key">"currency_to"</span>: <span class="str">"USDT"</span>,
    <span class="key">"amount"</span>: <span class="int">1.0</span>
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"exc_rate"</span>: <span class="int">1169.055</span>,
    <span class="key">"amount_to"</span>: <span class="int">1169.055</span>,
    <span class="key">"tracker_id"</span>: <span class="str">"b7e774b7c86ef10dfe5204b18c5e2865705cfa986a5bf2b04"</span>
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'swap_execute',
    right: (
      <div className='py-4 border-top'>
        <h2>'POST' swap_execute</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/swap/execute</span>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
        <p className='border-bottom pt-3'>
          <strong>BODY</strong> raw
        </p>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"external_id"</span>: <span class="str">"b7e774b7c86ef10dfe5204b18c5e2865705cfa986a5bf2b0403e4f16dc871b26bb742cbff4d665f9d3d48e4fe74cfb9fc78ada3ce5e54f2c3b0c7ca657483d98"</span>
}`
          }}
        ></pre>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>swap_execute</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request POST 'https://swap.cashex.cc/swap/execute' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'

--data-raw '{
    <span class="key">"external_id"</span>: <span class="str">"b7e774b7c86ef10dfe5204b18c5e2865705cfa986a5"</span>
}'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
{
    <span class="key">"amount_from"</span>: <span class="int">1</span>,
    <span class="key">"amount_to"</span>: <span class="int">1169.055</span>,
    <span class="key">"exchange_rate"</span>: <span class="int">1169.055</span>,
    <span class="key">"currency_to"</span>: <span class="str">"USDT"</span>,
    <span class="key">"currency_from"</span>: <span class="str">"ETH"</span>,
    <span class="key">"tracker_id"</span>: <span class="str">"b7e774b7c86ef10dfe5204b18c5e2865705cfa98"</span>,
    <span class="key">"date_create"</span>: <span class="str">"2022-10-20 13:48:21.649503"</span>
}
            `
          }}
        />
      </div>
    )
  },
  {
    id: 'swap_get',
    right: (
      <div className='py-4 border-top'>
        <h2>'GET' swap_get</h2>
        <p className='data-code'>
          <span>https://swap.cashex.cc/swap/:external_id/get</span>
        </p>
        <p className='mb-0 mt-4'>
          <strong>HEADERS</strong>
        </p>
        <div className='border-bottom border-top py-3'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Content-Type</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>application/json</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>ApiPublic</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>ApiPublic</div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>
              <strong>Signature</strong>
            </div>
            <div className='col-lg-4 col-md-6 mb-3 col-12 option'>Signature</div>
          </div>
        </div>
      </div>
    ),
    left: (
      <div className='example'>
        <div className='d-flex justify-content-between'>
          <h5>Example Request</h5>
          <h6>swap_get</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `
<span class"def">curl --location --request GET 'https://swap.cashex.cc/swap/dsgvsrhawrgw4ewgwe/get' \<br />
--header 'Content-Type: application/json'
--header 'ApiPublic: ApiPublic'
--header 'Signature: Signature'
            `
          }}
        />
        <div className='d-flex justify-content-between'>
          <h5>Example Response</h5>
          <h6>200 OK</h6>
        </div>
        <pre
          className='data-code'
          dangerouslySetInnerHTML={{
            __html: `{
    <span class="key">"create_time"</span>: <span class="str">"2022-10-20 13:48:21.649503"</span>,
    <span class="key">"execute_time"</span>: <span class="str">"2022-10-20 13:50:14.445781"</span>,
    <span class="key">"status"</span>: <span class="str">"EXECUTED"</span>,
    <span class="key">"currency_from"</span>: <span class="str">"TRX"</span>,
    <span class="key">"currency_to"</span>: <span class="str">"USDTTRC"</span>,
    <span class="key">"rate"</span>: <span class="str">"0.054507"</span>,
    <span class="key">"external_id"</span>: <span class="str">"6UQ4jEmGh64CKXt9UBnbqqA30DDpsjVPsxysnTrZrXTIfCNh"</span>,
    <span class="key">"amount_from"</span>: <span class="str">1.0</span>,
    <span class="key">"amount_to"</span>: <span class="str">0.054507</span>,
    <span class="key">"callback_url"</span>: <span class="str">"http://0.0.0.1:8080/callback_path"</span>,
    <span class="key">"pay_from_url"</span>: <span class="str">"http://0.0.0.1:8080/callback_path"</span>,
}`
          }}
        />
      </div>
    )
  }
]

const ApiDocs = () => {
  const { apiText } = useGetText()

  const { isAuthApi } = useAppSelector(state => state.apiLkAuth)

  return (
    <div className=''>
      <div className='api-wrapper'>
        <aside>
          <h6>CashexAPI</h6>
          <ul>
            {links.map((el, index) => (
              <li key={index + el.method}>
                <a href={`${isAuthApi ? path.API_LK_DOCS : path.API_DOCS}` + el.link}>
                  {el.method.length > 0 && <span>{el.method} </span>}
                  {el.text}
                </a>
              </li>
            ))}
          </ul>
        </aside>
        <div className='api-content'>
          <div className='container-fluid'>
            {contentItems.map((el, index) => (
              <div className='row' key={el.id + index} id={el.id}>
                <div className='col-lg-6 col-12'>{el.right}</div>
                <div className='col-lg-6 col-12 bg-dark'>{el.left}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiDocs
