import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import adminService from '../../service/admin-service'
import { AdminState } from '../reducers/adminReducer'

export const fetchCards = createAsyncThunk('bid/getCards', async (data: any) => {
  try {
    // const offset = {offset: 1}
    // const res = await adminService.getCards(data)
    // return res?.data
    // if (res?.response?.status >= 400) {
    //     return {
    //         msg: res.response.data,
    //         status: res?.response?.status
    //     }
    // } else {
    //     return res.data
    // }
  } catch (err) {}
})

export const fetchCardsHandler = (builder: any) => {
  builder.addCase(fetchCards.pending, (state: AdminState, action: PayloadAction<any>) => {
    state.cards = []
    state.status = 'loading'
  })
  builder.addCase(fetchCards.fulfilled, (state: AdminState, action: PayloadAction<any>) => {
    state.cards = action?.payload?.content
    state.status = 'success'
  })
  builder.addCase(fetchCards.rejected, (state: any, action: PayloadAction<any>) => {
    state.cards = []
    state.status = 'error'
  })
}

export const approveCard = createAsyncThunk('bid/verificate', async (data: any) => {
  try {
    // const offset = {offset: 1}
    const res = await adminService.approveCard(data)
    return res
    // if (res?.response?.status >= 400) {
    //     return {
    //         msg: res.response.data,
    //         status: res?.response?.status
    //     }
    // } else {
    //     return res.data
    // }
  } catch (err) {}
})

export const approveCardHandler = (builder: any) => {
  builder.addCase(approveCard.pending, (state: AdminState, action: PayloadAction<any>) => {
    // state.cards = []
    // state.status = 'loading'
  })
  builder.addCase(approveCard.fulfilled, (state: AdminState, action: PayloadAction<any>) => {
    // state.cards = action.payload.content
    // state.status = 'success'
  })
  builder.addCase(approveCard.rejected, (state: any, action: PayloadAction<any>) => {
    // state.cards = []
    // state.status = 'error'
  })
}

export const deleteCard = createAsyncThunk('bid/delete', async (data: any) => {
  try {
    // const offset = {offset: 1}
    const res = await adminService.deleteUserCard(data)
    return res
    // if (res?.response?.status >= 400) {
    //     return {
    //         msg: res.response.data,
    //         status: res?.response?.status
    //     }
    // } else {
    //     return res.data
    // }
  } catch (err) {}
})

export const deleteCardHandler = (builder: any) => {
  builder.addCase(deleteCard.pending, (state: AdminState, action: PayloadAction<any>) => {
    // state.cards = []
    // state.status = 'loading'
  })
  builder.addCase(deleteCard.fulfilled, (state: AdminState, action: PayloadAction<any>) => {
    // state.cards = action.payload.content
    // state.status = 'success'
  })
  builder.addCase(deleteCard.rejected, (state: any, action: PayloadAction<any>) => {
    // state.cards = []
    // state.status = 'error'
  })
}

export const fetchPhoto = createAsyncThunk('bid/photo', async (data: any) => {
  try {
    const res = await adminService.getPhoto(data)
    return res.data
  } catch (err) {}
})

export const fetchPhotoHandler = (builder: any) => {
  builder.addCase(fetchPhoto.pending, (state: AdminState, action: PayloadAction<any>) => {
    state.photo = null
    state.status = 'photo loading'
  })
  builder.addCase(fetchPhoto.fulfilled, (state: AdminState, action: PayloadAction<any>) => {
    state.photo = action.payload
    state.status = 'photo loaded'
  })
  builder.addCase(fetchPhoto.rejected, (state: any, action: PayloadAction<any>) => {
    state.photo = null
    state.status = 'photo error'
  })
}

export const fetchIsAdmin = createAsyncThunk('admin/admin', async () => {
  try {
    const res = await adminService.getIsAdmin()
    return res.data
  } catch (err) {}
})

export const fetchIsAdminHandler = (builder: any) => {
  builder.addCase(fetchIsAdmin.pending, (state: AdminState, action: PayloadAction<any>) => {
    state.isAdmin = false
  })
  builder.addCase(fetchIsAdmin.fulfilled, (state: AdminState, action: PayloadAction<any>) => {
    if (action?.payload?.code === 200) {
      state.isAdmin = true
    } else {
      state.isAdmin = false
    }
  })
  builder.addCase(fetchIsAdmin.rejected, (state: any, action: PayloadAction<any>) => {
    state.isAdmin = false
  })
}
