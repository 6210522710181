import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import Button from '../../../components/buttons/button'
import { AuthLoader } from '../../../components/loaders/loaders'
import Tokens from '../../../features/api-lk/tokens'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { fetchAllTokens, fetchTransactions } from '../../../store/actions/apiLkActions'
import ApiLkLayout from '../../../features/layouts/api-lk-layout'
import { ModalMode, setModalContentType } from '../../../store/reducers/modalReducer'
import { ModalContentTypes } from '../../../components/Modal/defineModalEl'
import { setSelectedModal, setTypeTransactions } from '../../../store/reducers/apiLkReducer'
import { ModalId } from '../../../utils/consts'

const ApiTransactions = () => {
  const { xToken, rToken } = useAppSelector(state => state.apiLkAuth)
  const { selectedToken, transactions, isLoadingTransactions } = useAppSelector(state => state.apiLk)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllTokens())
  }, [xToken, rToken])

  useEffect(() => {
    dispatch(fetchTransactions())
  }, [selectedToken, xToken, rToken])

  const formatDate = useCallback((date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'), [transactions])

  // Handler for changes value in select
  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setTypeTransactions(event.target.value))
    dispatch(fetchTransactions())
  }

  return (
    <ApiLkLayout>
      <div className='container api-user pt-5 transactions'>
        <div className='row'>
          <div className='col-xl-2 col-lg-4'>
            <Button variant='primary' type='button' onClick={() => dispatch(setSelectedModal(ModalId.MODAL_LK_WITHDROW))} dataToggle='modal' dataTarget={`#${ModalId.MODAL_LK}`} className='w-100 mb-3 py-3'>
              Вывод средств
            </Button>

            <Tokens />
          </div>
          <div className='col-xl-10 col-lg-8'>
            <div className='card px-0'>
              <div className='d-flex align-items-center justify-content-between px-3'>
                <h3>Транзакции</h3>
                <select className='form' onChange={onChangeSelect}>
                  <option value={'IN'}>Входящие</option>
                  <option value={'OUT'}>Исходящие</option>
                </select>
              </div>
              <div className='overflow-auto'>
                <table className='table api-table'>
                  <thead>
                    <tr>
                      <th style={{ maxWidth: '130px' }}>ID</th>
                      <th>Валюта</th>
                      <th>Дата</th>
                      <th>Сумма</th>
                      <th>Комиссия</th>
                      <th>Тип</th>
                      <th>Tracker id</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.map((el, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ maxWidth: '140px' }}>
                            <p className='l1'>{el.client_transaction_id}</p>
                          </td>
                          <td>
                            <p>{el.token}</p>
                          </td>
                          <td>
                            <p>{formatDate(el.date_create)}</p>
                          </td>
                          <td>
                            <p>{el.amount}</p>
                          </td>
                          <td>
                            <p>{el.transaction_commission}</p>
                          </td>
                          <td>
                            <p>{el.type}</p>
                          </td>
                          <td style={{ maxWidth: '140px' }}>
                            <p className='l1'>{el.tracker_id}</p>
                          </td>
                          <td>
                            <p>{el.status}</p>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              {isLoadingTransactions ? (
                <div className='d-flex justify-content-center'>
                  <AuthLoader />
                </div>
              ) : (
                !transactions && <h5 className='px-3 text-center'>Транзакции отсуствуют</h5>
              )}

              {/* {!selectedToken && <h5 className="px-3 text-center">Токен не выбран</h5>} */}
            </div>
          </div>
        </div>
      </div>
    </ApiLkLayout>
  )
}

export default ApiTransactions
