import React from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const MyCardsSkeleton = () => {
  return (
    <div className={'skeleton-container'}>
      <SkeletonTheme baseColor="#FFF" highlightColor="#002674">
        <div className={'skeleton-title'}>
          <Skeleton height={75} width={450}/>
        </div>
        <div className={'card-list-block'}>
          <div className={'card-list-block-item'}>
            <div className={'card-list-orange-top-border'}></div>
            <div className={'skeleton-verification-block-content'}>
              <Skeleton width={300} height={35}/>

              <Skeleton height={75}/>
              <Skeleton height={75}/>
              <div className={'skeleton-verification-block-action-buttons'}>
                <div className={'skeleton-verification-block-action-buttons-left'}><Skeleton height={95}/></div>
                <div className={'skeleton-verification-block-action-buttons-right'}><Skeleton height={47}/></div>
              </div>
            </div>
          </div>
          <div className={'card-list-block-item'}>
            <div className={'card-list-orange-top-border'}></div>
            <div className={'skeleton-my-cards-block-content'}>
              <Skeleton height={55} count={5} style={{display: 'block'}}/>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default MyCardsSkeleton;