import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'cookies-ts'

import { AddressIn, ApiKeysIn, SwapFetchIn, TokenBalanceIn, TokenIn, Tokens, TransactionsIn } from '../../service/api-lk-service'
import {
  fetchAddresesHandler,
  fetchAllTokensHandler,
  fetchCreateWithdrowHandler,
  fetchExchangeRateHandler,
  fetchForExchangeHandler,
  fetchInvoiceHandler,
  fetchKeysHandler,
  fetchPatchKeysHandler,
  fetchSwapCreateHandler,
  fetchSwapsHandler,
  fetchBalancesHandler,
  fetchTransactionsHandler,
  fetchTokensHandler
} from '../actions/apiLkActions'

const cookies = new Cookies()

export interface ApiLkState {
  tokens: TokenIn[]
  isLoadingTokens: boolean

  tokens_balance: TokenBalanceIn[]
  isLoadingStatistic: boolean

  apiKeys: ApiKeysIn

  selectedToken: TokenIn | null

  // Withdraw
  selectedTokenToWithdrow: TokenIn | null
  selectedTypeTransactions: string

  // Exchanges
  selectedTokenToExchange: TokenIn | null
  selectedTokenForExchange: string | null
  for_exchange_tokens: Tokens | null
  exchange_rate: number
  valueFrom: string
  valueTo: string

  transactions: TransactionsIn[]
  isLoadingTransactions: boolean

  addresess: AddressIn[]
  isLoadingAddresess: boolean

  witdrowCreated: string

  selectedModal: string

  swaps: SwapFetchIn[]

  data: any
}

const initialState: ApiLkState = {
  tokens: [],
  isLoadingTokens: false,

  tokens_balance: [],
  isLoadingStatistic: false,

  apiKeys: {
    public_key: '',
    private_key: ''
  },

  selectedToken: null,

  // Withdraw
  selectedTokenToWithdrow: null,
  selectedTypeTransactions: 'IN',

  // Exchanges
  selectedTokenToExchange: null,
  selectedTokenForExchange: null,
  for_exchange_tokens: null,
  exchange_rate: 0,
  valueFrom: '',
  valueTo: '',

  transactions: [],
  isLoadingTransactions: false,

  addresess: [],
  isLoadingAddresess: false,

  witdrowCreated: '',

  selectedModal: '',

  swaps: [],
  data: {}
}

export const apiLkSlice = createSlice({
  name: 'apiLkAuth',
  initialState,
  reducers: {
    setSelectedToken(state: ApiLkState, action: PayloadAction<TokenIn | null>) {
      state.selectedToken = action.payload
    },
    setSelectedTokenToWithdrow(state: ApiLkState, action: PayloadAction<TokenIn | null>) {
      state.selectedTokenToWithdrow = action.payload
    },
    setTypeTransactions(state, action: PayloadAction<string>) {
      state.selectedTypeTransactions = action.payload
    },
    setWitdrowCreated(state: ApiLkState, action: PayloadAction<string>) {
      state.witdrowCreated = action.payload
    },
    setSelectedTokenToExchange(state: ApiLkState, action: PayloadAction<TokenIn | null>) {
      state.selectedTokenToExchange = action.payload
    },
    setSelectedTokenForExchange(state: ApiLkState, action: PayloadAction<string | null>) {
      state.selectedTokenForExchange = action.payload
    },
    setFromValue(state: ApiLkState, action: PayloadAction<string>) {
      state.valueFrom = action.payload

      if (action.payload) {
        if (action.payload === '.') {
          state.valueTo = '0'
          return
        }
        state.valueTo = String(Number(action.payload) * Number(state.exchange_rate))
      } else {
        state.valueFrom = ''
        state.valueTo = ''
      }
    },
    setToValue(state: ApiLkState, action: PayloadAction<string>) {
      state.valueTo = action.payload

      if (action.payload) {
        if (action.payload === '.') {
          state.valueFrom = '0'
          return
        }
        state.valueFrom = String(Number(action.payload) / Number(state.exchange_rate))
      } else {
        state.valueFrom = ''
        state.valueTo = ''
      }
    },
    setSelectedModal(state: ApiLkState, action: PayloadAction<string>) {
      state.selectedModal = action.payload
    }
  },
  extraReducers: builder => {
    fetchTokensHandler(builder)
    fetchBalancesHandler(builder)
    fetchKeysHandler(builder)
    fetchPatchKeysHandler(builder)
    fetchAllTokensHandler(builder)
    fetchTransactionsHandler(builder)
    fetchAddresesHandler(builder)
    fetchInvoiceHandler(builder)
    fetchCreateWithdrowHandler(builder)
    fetchSwapsHandler(builder)
    fetchSwapCreateHandler(builder)
    fetchForExchangeHandler(builder)
    fetchExchangeRateHandler(builder)
  }
})

export const { setSelectedToken, setSelectedTokenToWithdrow, setSelectedTokenToExchange, setSelectedTokenForExchange, setWitdrowCreated, setSelectedModal, setFromValue, setToValue, setTypeTransactions } = apiLkSlice.actions

export default apiLkSlice.reducer
