import { api } from '../utils/api'
import { apiEndPoints } from '../utils/consts'
import {IGetFlag} from "../utils/interfaces";

class Bid {
  getBid(data: any): any {
    return api
      .get(apiEndPoints.GET_BID, {
        headers: {
          hashid: data
        }
      })
      .then(res => res)
      .catch(rej => rej)
  }

  updateBid(data: any): any {
    return api
      .get(apiEndPoints.UPDATE_STATUS, {
        headers: {
          hashid: data
        }
      })
      .then(res => res)
      .catch(rej => rej)
  }

  checkIsVerified(data: any) {
    return api
      .post(apiEndPoints.CHECK_CARD_IS_VERIFIED, data)
      .then(res => res)
      .catch(rej => rej)
  }
  cardCheck(data: any) {
    return api
      .post(apiEndPoints.CHECK_CARD, data)
      .then(res => res)
      .catch(rej => rej)
  }
  createNewCard(data: any, headers: any) {
    // return api.post(apiEndPoints.CREATE_CARD, data, { headers: { "Content-Type": "multipart/form-data" } })
    return api
      .post(apiEndPoints.CREATE_CARD, data, { headers })
      .then(res => res)
      .catch(rej => rej)
  }
  finishVerification(data: any, headers: any) {
    return api
      .post(apiEndPoints.FINISH_CARD, data, {
        headers
      })
      .then(res => res)
      .catch(rej => rej)
  }

  deleteBid() {
    return api
      .delete(apiEndPoints.DELETE_BID)
      .then(res => res)
      .catch(rej => rej)
  }

  getCardVerificationStatus() {
    return api.get<IGetFlag>(apiEndPoints.SITES_FLAGS + '/vrf_rqd')
  }

}

export default new Bid()
