// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".registerTimer_tradeTimer__ZJVrk {\n  position: static;\n}\n\n.registerTimer_resendCodeButton__rqgkQ {\n  margin-top: 5px;\n  margin-bottom: 5px;\n  cursor: pointer;\n  color: black;\n  border: none;\n  background: none;\n}\n.registerTimer_resendCodeButton__rqgkQ:hover {\n  color: orange;\n}\n\n.registerTimer_verificationTimerText__UfKbr {\n  color: #c3c3c3;\n}\n\n.registerTimer_registerTimerSpan__6qOgC {\n  padding: 0 !important;\n  white-space: nowrap;\n}\n.registerTimer_registerTimerSpan__6qOgC * {\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/registerTimer/registerTimer.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACC,aAAA;AACH;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,qBAAA;EACA,mBAAA;AADF;AAIE;EACE,0BAAA;EACA,2BAAA;AAFJ","sourcesContent":[".tradeTimer{\n  position: static;\n}\n\n.resendCodeButton{\n  margin-top: 5px;\n  margin-bottom: 5px;\n  cursor: pointer;\n  color: black;\n  border: none;\n  background: none;\n\n  &:hover{\n   color: orange;\n  }\n\n}\n\n.verificationTimerText{\n  color: #c3c3c3;\n}\n\n.registerTimerSpan{\n  padding: 0!important;\n  white-space: nowrap;\n\n\n  & * {\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tradeTimer": "registerTimer_tradeTimer__ZJVrk",
	"resendCodeButton": "registerTimer_resendCodeButton__rqgkQ",
	"verificationTimerText": "registerTimer_verificationTimerText__UfKbr",
	"registerTimerSpan": "registerTimer_registerTimerSpan__6qOgC"
};
export default ___CSS_LOADER_EXPORT___;
