import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {
  fetchBidsHandler,
  fetchChangePasswordHandler,
  fetchGetRefHandler,
  fetchLoginHandler,
  fetchLogoutHandler,
  fetchMakePayoutHandler,
  fetchPayoutsHandler,
  fetchRefreshHandler,
  fetchRegisterHandler,
  fetchResetPasswordFinishHandler,
  fetchResetPasswordHandler,
  fetchUserCardsHandler, fetchUserCardsInfoHandler
} from '../actions/userActions'
import Cookies from 'cookies-ts'
import {cookiesName} from '../../utils/consts'
import {IUserCard} from '../../utils/interfaces'

const cookies = new Cookies()

export interface IUserCards {
  cards: IUserCardsItem[] | null
}

export interface IUserCardsItem {
  card_number: string,
  status: string,
  cardholder_name: string
}

export interface UserState {
  isAuth?: boolean
  email?: string
  register_message?: string
  login_message?: string
  reset_password_message?: string
  reset_password_message_finish?: string
  client_id?: string | null
  isChangedPassword?: boolean
  change_password_mesage?: string
  refStatuses?: {
    total_referrals: number
    ref_balance: number
    total_exchanges: number
    ref_percent: number
    client_id: number
  }

  bids?: any[]
  bidsLoader?: boolean
  makePayoutSended: {
    setSent?: boolean
    message: string
  }
  isCardsInfoLoading: boolean,
  cardsInfo: IUserCards | null,
  payouts: any[]
  userCards: IUserCard[],
  isAuthDataLoading: boolean
}


const initialState: UserState = {
  isAuth: Boolean(localStorage.getItem('access-token')) ?? false,
  isAuthDataLoading: Boolean(localStorage.getItem('access-token')) ?? false,
  isCardsInfoLoading: Boolean(localStorage.getItem('access-token')) ?? false,
  email: cookies.get(cookiesName.EMAIL) || '',
  register_message: '',
  login_message: '',
  reset_password_message: '',
  reset_password_message_finish: '',
  client_id: cookies.get(cookiesName.REF_ID),

  isChangedPassword: false,
  change_password_mesage: '',
  refStatuses: {
    total_referrals: 0,
    ref_balance: 0,
    total_exchanges: 0,
    ref_percent: 0,
    client_id: 0
  },

  bids: [],
  bidsLoader: false,
  makePayoutSended: {
    message: ''
  },
  payouts: [],
  userCards: [],
  cardsInfo: null,

}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginMessage(state: UserState, action: PayloadAction<string>) {
      state.login_message = action.payload
    },
    setRegisterMessage(state: UserState, action: PayloadAction<string>) {
      state.login_message = action.payload
    },
    setChangedPassword(state: UserState, action: PayloadAction<boolean>) {
      state.isChangedPassword = action.payload
    }
  },
  extraReducers: builder => {
    fetchLoginHandler(builder)
    fetchRegisterHandler(builder)
    fetchRefreshHandler(builder)
    fetchLogoutHandler(builder)
    fetchChangePasswordHandler(builder)
    fetchGetRefHandler(builder)
    fetchBidsHandler(builder)
    fetchMakePayoutHandler(builder)
    fetchPayoutsHandler(builder)
    fetchResetPasswordHandler(builder)
    fetchResetPasswordFinishHandler(builder)
    fetchUserCardsHandler(builder)
    fetchUserCardsInfoHandler(builder)
  }
})

export const { setLoginMessage, setRegisterMessage } = userSlice.actions

export default userSlice.reducer
