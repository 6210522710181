import React, {FC, FormEvent, SetStateAction, useCallback} from 'react';
import CustomInput from "../../inputs/custom-input";
import Button from "../../buttons/button";
import {fetchResetPassword} from "../../../store/actions/userActions";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import useInput from "../../../hooks/useInput";
import useGetText from "../../../hooks/useGetText";
import styles from "./FastRegistrationModal.module.scss"

interface IStepRestorePasswordProps {
    emailInp: any;
    setStep: React.Dispatch<SetStateAction<number>>
    setIsLoading: React.Dispatch<SetStateAction<boolean>>
}

const StepRestorePassword: FC<IStepRestorePasswordProps> = ({emailInp, setStep, setIsLoading}) => {
    const dispatch = useAppDispatch()
    const {reset_password_message} = useAppSelector(state => state.user)
    const {language} = useAppSelector(state => state.main)

    const {authTexts} = useGetText()

    const resetPasswordHandler = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (!emailInp.isValid) return
            setIsLoading(true)
            dispatch(fetchResetPassword({email: emailInp.value.toLowerCase() as string, language: language === 'ru' ? 'RU' : 'ENG'}))
                .finally(() => {
                setIsLoading(false)
            })
        },
        [emailInp]
    )

    return (
        <div>
            <div className={styles.mainTitle}>Восстановление пароля</div>
            <div className={styles.headText}>Смените пароль, с помощью письма, которое придёт на почту и возвращайтесь
                обратно ко входу.
            </div>
            <form onSubmit={resetPasswordHandler}>
                <CustomInput required inputHandlers={emailInp} variant='main' placeholder='example@gmail.com'
                             label='Email'/>
                <Button variant='primary' className='w-100 my-3 py-3 mt-4'>
                    {authTexts.RECOVERY}
                </Button>
                {reset_password_message && <p className='t3 text-center'>{reset_password_message}</p>}
                <p className={`t3 text-center ${styles.backToLoginButton}`}
                   onClick={() => setStep(2)}
                >
                    Вернуться ко входу
                </p>
            </form>
        </div>
    );
};

export default StepRestorePassword;