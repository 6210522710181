import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string | number) => Promise<boolean> // Return success

export function useCopyToClipboard() {
	const [copiedText, setCopiedText] = useState<CopiedValue>(null)

	const copyToClipboard: CopyFn = async text => {
		if (!navigator?.clipboard) {
			console.warn('Clipboard not supported')
			return false
		}

		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(String(text))
			setCopiedText(String(text))
			return true
		} catch (error) {
			console.warn('Copy failed', error)
			setCopiedText(null)
			return false
		}
	}

	return { copiedText, copyToClipboard }
}
