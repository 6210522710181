import axios, { AxiosError } from 'axios'
import { Category, CodeCategory, ErrorTexts, filterCategoryOption } from './consts'

class Utils {
  comparePassword(password: string, secondPassword: string) {
    return password === secondPassword
  }
  isEmptyObject(obj: Object) {
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false
      }
    }
    return true
  }

  findExchangeType(from: string, to: string) {
    return CodeCategory[from] === Category.CASH || CodeCategory[to] === Category.CASH
      ? 'cash'
      : (CodeCategory[from] === Category.BANK_RUB || CodeCategory[from] === Category.BANK_UK) && CodeCategory[to] === Category.CRYPTO && from !== 'QWRUB'
      ? 'bank_to_crypto'
      : from === 'QWRUB' && CodeCategory[to] === Category.CRYPTO
      ? 'qiwi_to_crypto'
      : (CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.BANK_RUB && to !== 'QWRUB') || (CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.BANK_UK && to !== 'QWRUB')
      ? 'crypto_to_bank'
      : CodeCategory[from] === Category.CRYPTO && to === 'QWRUB'
      ? 'crypto_to_qiwi'
      : CodeCategory[from] === Category.CRYPTO && CodeCategory[to] === Category.CRYPTO
      ? 'crypto_to_crypto'
      : (CodeCategory[from] === Category.BANK_RUB && CodeCategory[to] === Category.BANK_UK) || (CodeCategory[to] === Category.BANK_RUB && CodeCategory[from] === Category.BANK_UK)
      ? 'bank_to_bank'
      : 'unexpected'
  }

  makeSymbol(to: string) {
    switch (to) {
      case 'BTC':
        return 'BTC'
      case 'USDTTRC':
        return 'TRC'
      case 'TRX':
        return 'TRC'
      case 'ETH':
        return 'ETH'
      case 'QWRUB':
        return 'PHONE'
      case 'PMUSD':
        return 'PMUSD'
      case 'TCSBRUB':
      case 'SBERRUB':
      case 'TBRUB':
      case 'ACRUB':
      case 'CARDRUB':
      case 'MIRCRUB':
      case 'P24UAH':
      case 'UAH':
      case 'USBUAH':
      case 'CARDUAH':
      case 'MONOBUAH':
      case 'WIREUAH':
      case 'OSDBUAH':
        return 'CARD'
      default:
        return 'CASH'
    }
  }

  rounder(value: number, from: string, to: string) {
    return from === 'USDTTRC' && to === 'CASHUSD'
      ? fixed(value, 6)
      : from === 'CASHUSD' && to === 'USDTTRC'
      ? fixed(value, 6)
      : from === 'BTC' && to === 'USDTTRC'
      ? fixed(value, 2)
      : from === 'USDTTRC' && CodeCategory[to] === Category.BANK_RUB
      ? fixed(value, 4)
      : from === 'USDTTRC' && to === 'BTC'
      ? fixed(value, 8)
      : CodeCategory[to] === Category.CRYPTO
      ? fixed(value, 8)
      : fixed(value, 2)

    function replacer(value: string) {
      return value.toString().replace(/0+$/, '').replace(/\.$/, '')
    }

    function fixed(value: number, n: number) {
      return replacer(value.toFixed(n))
    }
  }

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0
  }

  sortBankArray(arr: any) {
    let sberBank = arr?.filter((item: any) => item?.code === 'SBERRUB')
    const s = new Set(sberBank.concat(arr))
    // return [...s]
  }

  sortCategory(filter: any, direction: any, language: string) {
    let stack: any = []
    filter?.forEach((el: any) => {
      if (direction === 'from') {
        const fromEl = el.from
        stack.push(CodeCategory[fromEl?.code])
      }
      if (direction === 'to') {
        stack.push(CodeCategory[el?.code])
      }
    })
    stack = Array.from(new Set(stack))
    stack = stack.map((el: any) => {
      return {
        label: filterCategoryOption[language][el],
        category: el
      }
    })
    const label: any = {
      ru: 'Все',
      en: 'All',
      tu: 'Herşey'
    }
    stack.unshift({ label: label[language], category: Category.ALL })
    return stack
  }

  conditionCash(from: string, to: string) {
    return CodeCategory[from] === Category.CASH || CodeCategory[to] === Category.CASH
  }

  formatNumber(val: number, round = 7) {
    return Intl.NumberFormat('ru-RU', { maximumFractionDigits: round }).format(val)
  }

  fromValueFormating(valueFrom?: number, direction?: any, state?: any, action?: any) {
    let toFixedValue
    const checkDirection = direction === 'from' ? state.from : state.to

    function conditionFixedValue() {
      if ((state.from === 'USDTTRC' && state.to === 'CASHUSD') || (state.to === 'USDTTRC' && state.from === 'CASHUSD') || (state.to === 'USDTERC' && state.from === 'CASHUSD') || (state.from === 'USDTERC' && state.to === 'CASHUSD')) {
        toFixedValue = 6
      }
    }

    if (CodeCategory[checkDirection] === 'crypto') {
      toFixedValue = 6
      if (checkDirection === 'USDTTRC' || checkDirection === 'USDTERC') {
        toFixedValue = 6
      }
      conditionFixedValue()
    } else if ((CodeCategory[state.from] === Category.BANK_RUB && CodeCategory[state.to] === Category.BANK_UK) || (CodeCategory[state.from] === Category.BANK_UK && CodeCategory[state.to] === Category.BANK_RUB)) {
      toFixedValue = 6
    } else {
      toFixedValue = 6
      if (checkDirection === 'CASHUSD') {
        toFixedValue = 6
      }
      conditionFixedValue()
    }

    if (direction === 'from') {
      if (action === 'reverce') {
        if (valueFrom !== undefined) {
          return Number(String(Number(valueFrom / state.rate).toFixed(toFixedValue)))
        }
      } else {
        return Number(String(Number(valueFrom)?.toFixed(toFixedValue)))
      }
    } else {
      if (valueFrom !== undefined) return Number(String(Number(valueFrom * state.rate).toFixed(toFixedValue)))
    }
  }

  delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  writeCardNumber = (cardNumber: string) => {
    const data = cardNumber.replace(/ /g, '')
    return data.slice(0, 4) + ' ' + data.slice(4, 8) + ' ' + data.slice(8, 12) + ' ' + data.slice(12)
  }

  makeRandomStr(length: number) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  apiLcErrorHandler(description: string, language: string) {
    switch (true) {
      case description === 'invalid user credentials':
        return ErrorTexts.InvalidUserCredentials[language]
      case description === 'invalid email':
        return ErrorTexts.InvalidEmail[language]
      case description === 'email already exists':
        return ErrorTexts.EmailAlreadyExists[language]
      case description === 'invalid code':
        return ErrorTexts.InvalidInviteCode[language]
      case description === 'code is already used':
        return ErrorTexts.CodeIsAlreadyUsed[language]
      case description === 'invalid jwt token':
        return ErrorTexts.InvalidJwtToken[language]

      default:
        return ErrorTexts.CommonError[language]
    }
  }

  isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
    return axios.isAxiosError(error)
  }

  isWarningCondition() {}
}

export default new Utils()
