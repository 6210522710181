import React, { FC, ReactNode } from 'react'
import useGetText from '../hooks/useGetText'

interface ModalPropIn {
  id?: string
  children: ReactNode
  size?: string
  center?: boolean
}
function checkModalSize(size: string) {
  switch (size) {
    case 'sm':
      return 'modal-sm'
    case 'xl':
      return 'modal-xl'
    case 'lg':
      return 'modal-lg'
    default:
      return ''
  }
}
const Modal: FC<ModalPropIn> = ({ id, children, size, center }) => {
  const { mainPageTexts } = useGetText()
  return (
    <>
      <div className='modal fade' id={id} tabIndex={-1} aria-labelledby='exampleModalLabel' aria-hidden='true'>
        <div className={`modal-dialog ${checkModalSize(size || '')} ${center && 'modal-dialog-centered'}`}>
          <div className='modal-content'>
            <div className='modal-body'>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
