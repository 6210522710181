import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import PayoutLayout from '../../features/layouts/payout-layout'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { fetchPayouts } from '../../store/actions/userActions'

const HistoryPayoutPage = () => {
  const { payouts } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()

  const { profileTexts } = useGetText()

  useEffect(() => {
    dispatch(fetchPayouts())
  }, [])

  return (
    <PayoutLayout>
      <h3>{profileTexts.HEADING_PAYOUT_HISTORY}</h3>
      <div className=' overflow-auto'>
        <table className='table'>
          <thead>
            <tr>
              {/*<th scope='col'>*/}
              {/*  <div>{profileTexts.PAYOUT_TABLE.APPLICATION_NUM}</div>*/}
              {/*</th>*/}
              <th scope='col'>
                <div style={{ minWidth: '120px' }}>{profileTexts.PAYOUT_TABLE.DATE}</div>
              </th>
              <th scope='col'>
                <div>{profileTexts.PAYOUT_TABLE.WALLET}</div>
              </th>
              {/*<th scope='col'>*/}
              {/*  <div>{profileTexts.PAYOUT_TABLE.AMOUNT}</div>*/}
              {/*</th>*/}
              <th scope='col'>
                <div>{profileTexts.PAYOUT_TABLE.AMOUNT_USD}</div>
              </th>
              <th scope='col'>
                <div>{profileTexts.PAYOUT_TABLE.STATUS}</div>
              </th>
            </tr>
          </thead>
          <tbody className='border-top-0'>
            {payouts?.map(el => (
              <tr>
                {/*<td>{el?.id}</td>*/}
                <td>{dayjs(el?.created_at).add(0, 'hour').format('DD.MM.YYYY в HH:mm:ss')}</td>
                <td>{el?.wallet}</td>
                {/*<td>{el?.amount_usdt}</td>*/}
                <td>{el?.amount_usdt}</td>
                <td>{el?.is_payed ? profileTexts.PAID : profileTexts.UNPAID}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {payouts?.length === 0 && <p>{profileTexts.NO_PAYMANTS}</p>}
      </div>
    </PayoutLayout>
  )
}

export default HistoryPayoutPage
