import React, { FC } from 'react'
import { otherLinks } from '../utils/consts'
import useGetText from '../hooks/useGetText'

const Telegram: FC = () => {
  const { mainPageTexts } = useGetText()

  return (
    <div className='text-lg-start text-center'>
      <h2>{mainPageTexts.CONTESTS_BAN.TELEGRAM_HEADING}</h2>
      <p className='t3 col-lg-8'>{mainPageTexts.CONTESTS_BAN.TELEGRAM_DESCRIPTION}</p>
      <div>
        <img src='/images/static/Ethereum_Flatline.svg' alt='' className='img-fluid' />
      </div>
      <div className=' mt-3'>
        <a className='link' target={'_blank'} href={otherLinks.CASHEX_TELEGRAM_LINK}>
          {mainPageTexts.CONTESTS_BAN.TELEGRAM_LINK}
        </a>
      </div>
    </div>
  )
}

export default Telegram
