import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import apiLkService, { SignUpData, SignInData, SwapCreateDataIn, SwapExchangeRateData, WithdorwIn, TokenBalanceIn, TokenIn } from '../../service/api-lk-service'
import Cookies from 'cookies-ts'
import { cookiesName, MyCustomStatus } from '../../utils/consts'
import { RootState } from '../store'
import { ApiLkState } from '../reducers/apiLkReducer'
import utils from '../../utils/utils'

const cookies = new Cookies()

const ErrorText: any = {
  ru: {
    InvalidEmail: 'Некорректный email'
  },
  en: {
    InvalidEmail: 'Incorrect email address'
  },
  tu: {
    InvalidEmail: 'Incorrect email address'
  }
}

export const fetchBalances = createAsyncThunk('ApiLk/fetchBalances', async (query: string, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.getBalance(query)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchBalancesHandler = (builder: any) => {
  builder.addCase(fetchBalances.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingStatistic = true
  })
  builder.addCase(fetchBalances.fulfilled, (state: ApiLkState, action: PayloadAction<{ balances: TokenBalanceIn[] }>) => {
    state.tokens_balance = action.payload?.balances.sort((a, b) => b.balance - a.balance)
    state.isLoadingStatistic = false
  })
}

export const fetchTokens = createAsyncThunk('ApiLk/fetchTokens', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.getTokens()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchTokensHandler = (builder: any) => {
  builder.addCase(fetchTokens.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingStatistic = true
  })
  builder.addCase(fetchTokens.fulfilled, (state: ApiLkState, action: PayloadAction<{ tokens: TokenIn[] }>) => {
    state.tokens = action.payload?.tokens
  })
}

export const fetchKeys = createAsyncThunk('ApiLk/fetchKeys', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.getApiKays()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchKeysHandler = (builder: any) => {
  builder.addCase(fetchKeys.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchKeys.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    state.apiKeys = action.payload
  })
}

export const fetchPatchKeys = createAsyncThunk('ApiLk/fetchPatchKeys', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.patchApiKays()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchPatchKeysHandler = (builder: any) => {
  builder.addCase(fetchPatchKeys.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchPatchKeys.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    state.apiKeys = action.payload
  })
}

export const fetchAllTokens = createAsyncThunk('ApiLk/fetchAllTokens', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const res = await apiLkService.getAllTokens()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchAllTokensHandler = (builder: any) => {
  builder.addCase(fetchAllTokens.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingTokens = true
  })
  builder.addCase(fetchAllTokens.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    state.tokens = action.payload?.tokens
    state.selectedTokenToWithdrow = action.payload?.tokens[0]
    state.selectedTokenToExchange = action.payload?.tokens[0]
    state.isLoadingTokens = false
  })
}

export const fetchTransactions = createAsyncThunk('ApiLk/fetchTransactions', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const { selectedToken, selectedTypeTransactions } = (getState() as RootState).apiLk

    if (selectedToken === null) return
    const res = await apiLkService.getTransactionInfo({
      limit: 1000000,
      offset: 0,
      type: selectedTypeTransactions,
      sort_order: 'DESC',
      token: selectedToken?.token
    })
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.transaction_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchTransactionsHandler = (builder: any) => {
  builder.addCase(fetchTransactions.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingTransactions = true
  })
  builder.addCase(fetchTransactions.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingTransactions = false
    if (action.payload?.status >= 400) {
      return
    } else {
      state.transactions = action.payload
    }
  })
}

export const fetchAddreses = createAsyncThunk('ApiLk/fetchAddreses', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const { selectedToken } = (getState() as RootState).apiLk

    if (selectedToken === null) return
    const res = await apiLkService.getAddresses({
      limit: 1000000,
      offset: 0,
      token: selectedToken?.token
    })
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.address_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchAddresesHandler = (builder: any) => {
  builder.addCase(fetchAddreses.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.isLoadingAddresess = true
  })
  builder.addCase(fetchAddreses.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
      return
    } else {
      state.addresess = action.payload
    }
    state.isLoadingAddresess = false
  })
}

export const fetchInvoice = createAsyncThunk('ApiLk/fetchInvoice', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main
    const { selectedToken } = (getState() as RootState).apiLk

    if (selectedToken === null) return
    const res = await apiLkService.createInvoice({
      client_transaction_id: utils.makeRandomStr(Math.pow(5, 2) * 1),
      call_back_url: '',
      token: selectedToken?.token
    })
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.address_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchInvoiceHandler = (builder: any) => {
  builder.addCase(fetchInvoice.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchInvoice.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {})
}

export const fetchCreateWithdrow = createAsyncThunk('ApiLk/fetchCreateWithdrow', async (data: WithdorwIn, { getState }) => {
  try {
    const { language } = (getState() as RootState).main

    const res = await apiLkService.createWithdrow(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.address_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchCreateWithdrowHandler = (builder: any) => {
  builder.addCase(fetchCreateWithdrow.pending, (state: ApiLkState, action: PayloadAction<any>) => {
    state.witdrowCreated = MyCustomStatus.LOADING
  })
  builder.addCase(fetchCreateWithdrow.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    state.witdrowCreated = MyCustomStatus.COMPLATED
    if (action.payload?.status >= 400) {
      state.witdrowCreated = MyCustomStatus.ERROR
    } else {
      state.witdrowCreated = MyCustomStatus.SUCCESS
    }
  })
}

export const fetchSwaps = createAsyncThunk('ApiLk/fetchSwaps', async (_, { getState }) => {
  try {
    const { language } = (getState() as RootState).main

    const res = await apiLkService.getAllSwaps({
      limit: 1000000,
      offset: 0
    })
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.orders_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchSwapsHandler = (builder: any) => {
  builder.addCase(fetchSwaps.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchSwaps.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
    } else {
      state.swaps = action.payload
    }
  })
}

export const fetchSwapCreate = createAsyncThunk('ApiLk/fetchSwapCreate', async (data: SwapCreateDataIn, { getState }) => {
  try {
    const { language } = (getState() as RootState).main

    const res = await apiLkService.createSwap(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data?.orders_list
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchSwapCreateHandler = (builder: any) => {
  builder.addCase(fetchSwapCreate.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchSwapCreate.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
    } else {
      state.data = action.payload
    }
  })
}

export const fetchForExchange = createAsyncThunk('ApiLk/fetchForExchange', async (token: string, { getState }) => {
  try {
    const { language } = (getState() as RootState).main

    const res = await apiLkService.getForExchange({ token })
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchForExchangeHandler = (builder: any) => {
  builder.addCase(fetchForExchange.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchForExchange.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
    } else {
      state.for_exchange_tokens = action.payload
      state.selectedTokenForExchange = action.payload.tokens[0]
    }
  })
}

export const fetchExchangeRate = createAsyncThunk('ApiLk/fetchExchangeRate', async (data: SwapExchangeRateData, { getState }) => {
  try {
    const { language } = (getState() as RootState).main

    const res = await apiLkService.getExchangeRate(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data.description,
        status: res?.response?.status,
        language
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
})

export const fetchExchangeRateHandler = (builder: any) => {
  builder.addCase(fetchExchangeRate.pending, (state: ApiLkState, action: PayloadAction<any>) => {})
  builder.addCase(fetchExchangeRate.fulfilled, (state: ApiLkState, action: PayloadAction<any>) => {
    if (action.payload?.status >= 400) {
    } else {
      state.exchange_rate = action.payload.exchange_rate
    }
  })
}
