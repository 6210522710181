import { api } from '../utils/api'
import { apiEndPoints } from '../utils/consts'

class AdminService {
  getCards(data: any) {
    return api
      .post(apiEndPoints.ADMIN_CARDS, data)
      .then(response => response)
      .catch(reject => reject)
  }

  approveCard(data: any) {
    return api
      .post(apiEndPoints.APPROVE_CARD, data)
      .then(response => response)
      .catch(rej => rej)
  }
  deleteUserCard(data: any) {
    return api
      .delete(apiEndPoints.DELETE_CARD + `${data}`)
      .then(response => response)
      .catch(rej => rej)
  }
  getPhoto(data: any) {
    return api
      .get(apiEndPoints.FETCH_PHOTO + `${data}`)
      .then(response => response)
      .catch(rej => rej)
  }
  getIsAdmin() {
    return api
      .post(apiEndPoints.FETCH_ADMIN)
      .then(response => response)
      .catch(rej => rej)
  }
}

export default new AdminService()
