import React, {FC, ReactNode} from 'react';
import TradeTimer from "./trade-timer";
import {IBidInputsProps} from "./DirectionInputs/CashToCryptoInputs";

interface IRequisitesBlockProps extends IBidInputsProps {
  children: ReactNode
}

const RequisitesBlock: FC<IRequisitesBlockProps> = ({mainPageTexts, children}) => {
  return (
    <div className={'pe-lg-4'}>
      <div className='form-heading'>
        <h2>{mainPageTexts.REQUISITES}</h2>
        <div>
          <TradeTimer/>
        </div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default RequisitesBlock;