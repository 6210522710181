export const FooterTexts: any = {
  ru: {
    US_IN_TG: 'Мы в Telegram',
    EXHCANGE_SERVICE: (
      <>
        Сервис обмена
        <br /> электронных валют
      </>
    ),
    ADDRESS: 'Юридический адрес:',
    COMPANY_NAME: 'Наименование компании: ',
    ORGANIZATION_FORM: 'Организационная форма: ',
    DATE_REG: 'Дата регистрации: ',
    REG_ORG: 'Регистрирующий орган: ',
    UR_ADDRESS: 'Юридический адрес: ',
    LICENZIES: 'Лицензия: ',
    COUNTRY: 'Страна:',
    VIEW_WORK: 'Вид деятельности:',
    EMAIL: 'Электронная почта:'
  },
  en: {
    US_IN_TG: 'We are in Telegram',
    EXHCANGE_SERVICE: (
      <>
        E-currency
        <br /> exchange service
      </>
    ),
    ADDRESS: 'Legal address:',

    COMPANY_NAME: 'Company name: ',
    ORGANIZATION_FORM: 'Organizational form: ',
    DATE_REG: 'Date of registration: ',
    REG_ORG: 'Registration authority:',
    UR_ADDRESS: 'Legal address:',
    LICENZIES: 'License: ',
    COUNTRY: 'Country:',
    VIEW_WORK: 'Kind of activity:',
    EMAIL: 'Email:'
  },
  tu: {
    US_IN_TG: "Telegram'dayız",
    EXHCANGE_SERVICE: (
      <>
        E-para birimi
        <br /> Değişim servisi
      </>
    ),
    ADDRESS: 'Yasal adres:',

    COMPANY_NAME: 'Yasal adres: ',
    ORGANIZATION_FORM: 'Organizasyon formu:',
    DATE_REG: 'Kayıt Tarihi:',
    REG_ORG: 'Kayıt Otoritesi: ',
    UR_ADDRESS: 'Yasal adres: ',
    LICENZIES: 'Lisans:',
    COUNTRY: 'Ülke:',
    VIEW_WORK: 'etkinlik türü:',
    EMAIL: 'E-posta:'
  }
}
