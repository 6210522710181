import React, {FC, FormEvent, SetStateAction, useCallback, useState} from 'react';
import styles from "./FastRegistrationModal.module.scss";
import Button from "../../buttons/button";
import {IFastRegistrationModalProps} from "./FastRegistrationModal";
import {useAppDispatch, useAppSelector} from "../../../hooks/reduxHooks";
import CustomInput from "../../inputs/custom-input";
import useInput, {namesType} from "../../../hooks/useInput";
import useGetText from "../../../hooks/useGetText";
import {Link} from "react-router-dom";
import {apiEndPoints, path} from "../../../utils/consts";
import {fetchLogin, fetchUserCardsInfo} from "../../../store/actions/userActions";
import {PayloadAction} from "@reduxjs/toolkit";
import {IUserCards} from "../../../store/reducers/userReducer";
import {registerApi} from "../../../utils/api";
import {toast} from "react-toastify";
import {ILoginResponse} from "../../../utils/interfaces";
import {MoonLoader} from "react-spinners";

interface IStepTwoProps {
    isUserExist: boolean
    isLoading: boolean
    emailInp: any
    cardInp: any
    setStep: React.Dispatch<SetStateAction<number>>
    setIsLoading: React.Dispatch<SetStateAction<boolean>>
}

const StepTwo: FC<IStepTwoProps> = ({
                                        isUserExist,
                                        emailInp,
                                        setStep,
                                        cardInp,
                                        setIsLoading,
                                        isLoading
                                    }) => {

    const dispatch = useAppDispatch()
    const passwordInp = useInput()
    const {authTexts} = useGetText()

    const loginHandler = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (!emailInp.isValid || !passwordInp.isValid) return
            setIsLoading(true)
            const loginResponse = await dispatch(
                fetchLogin({
                    email: emailInp.value,
                    password: passwordInp.value
                })
            ) as PayloadAction<ILoginResponse>
            setIsLoading(false)
            if (loginResponse.payload.success) {
                setIsLoading(true)
                const response = await dispatch(fetchUserCardsInfo()) as PayloadAction<IUserCards>
                if (!response.payload.cards || response.payload.cards.findIndex(item => item.card_number.trim() === cardInp.value.trim() && item.status === "SUCCESS") === -1) {
                    setStep(3)
                    setIsLoading(false)
                    return
                }
                setIsLoading(false)
                setStep(4)
            } else {
                toast.error('Вы ввели неправильный пароль!')
            }
        },
        [emailInp, passwordInp]
    )


    const checkPasswordHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!passwordInp.isValid) return
        setIsLoading(true)
        await registerApi.post(apiEndPoints.CHECK_PASSWORD, {
            email: emailInp.value.toLowerCase(),
            password: passwordInp.value
        })
            .then((res) => {
                setIsLoading(false)
                if (res.data.is_match) {
                    setIsLoading(true)
                    dispatch(
                        fetchLogin({
                            email: emailInp.value,
                            password: passwordInp.value
                        })
                    ).then(() => {
                        setStep(3)
                    })
                } else {
                    toast.error('Введен неверный пароль!')
                }
            })
            .catch(() => {
                console.log('error')
            }).finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading
                ? <div className={styles.loader}>
                    <MoonLoader color={'#002674'}/>
                    <div>{isUserExist ? 'Идёт вход, пожалуйста, подождите..' : 'Проверяем данные, пожалуйста, подождите..'}</div>
                </div>
                : isUserExist
                    ? <>
                        <div className={styles.headText}>Данная эл. почта уже зарегистрирована, авторизуйтесь, чтобы
                            продолжить!
                        </div>
                        <form className={styles.stepOneForm} onSubmit={loginHandler}>
                            <CustomInput required inputHandlers={emailInp} variant='main' placeholder='example@gmail.com'
                                         label='Email'/>
                            <CustomInput required inputHandlers={passwordInp} variant='main' type='password'
                                         placeholder='**********' label={authTexts.PASSWORD}/>
                            <div className='text-end my-3'
                                 role={'button'}
                                 onClick={() => setStep(5)}
                            >
                                {authTexts.FORIGIN_PASSWORD}
                            </div>
                            <Button variant='primary' className='w-100 my-3 py-3'>
                                {authTexts.SIGN_IN}
                            </Button>
                            {/*{login_message && <p className='t3 text-center'>{login_message}</p>}*/}
                        </form>
                    </>
                    :
                    <form className={styles.stepOneForm} onSubmit={checkPasswordHandler}>
                        <div className={styles.headText}>
                            <div className='mt-4'>Вам на почту был выслан автоматически сгенерированный пароль.</div>
                            <div>Пожалуйста, введите его в поле ниже.</div>
                        </div>
                        <CustomInput required inputHandlers={passwordInp} variant='main'
                                     type='password' label={authTexts.PASSWORD}/>
                        <div className={styles.buttonsBlock}>
                            <Button variant='primary'
                                    className='py-3 mt-3 position-relative'
                            >
                                Далее
                            </Button>
                        </div>
                    </form>
            }
        </>
    );
};

export default StepTwo;