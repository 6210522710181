import { api, BASE_API_URL } from '../utils/api'
import { apiEndPoints } from '../utils/consts'
import { CourseInterface, CreateBidInterface, ValidationPurseInterface } from '../utils/interfaces'
import axios from 'axios'

class Trade {
  getFrom(): any {
    return api
      .get(apiEndPoints.GET_FROM)
      .then(res => res)
      .catch(rej => rej)
  }

  getCourse(data: CourseInterface) {
    return api
      .post(apiEndPoints.COURSE, data)
      .then(res => res)
      .catch(rej => rej)
  }

  validatePurse(data: ValidationPurseInterface) {
    return api
      .post(apiEndPoints.BID_VALIDATE, data)
      .then(res => res)
      .catch(rej => rej)
  }

  createBid(data: CreateBidInterface) {
    return api.post(apiEndPoints.CREATE_BID, data)
      .then((res) => res)
  }
}

export default new Trade()
